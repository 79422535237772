import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import AddAlertOutlined from "@mui/icons-material/AddAlertOutlined";
import ReportOutlined from "@mui/icons-material/ReportOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SummarizeIcon from "@mui/icons-material/Summarize";
import StorageIcon from "@mui/icons-material/Storage";
import GroupIcon from "@mui/icons-material/Group";

import { ROLES } from "../../utils/constants";
import useAuth from "./../../hooks/useAuth";
import MainLogo from "../../assets/icons/MainLogo";
import DashboardIcon from "../../assets/icons/DashboardIcon";
import ThemeToggle from "./ThemeToggle";
import IncidentIcon from "../../assets/icons/IncidentIcon";

/* creating a list of navItems to navigate through them */
const menuItems = [
  {
    text: "Dashboard",
    slug: "/dashboards",
    roles: [ROLES.Manager],
    Icon: DashboardIcon,
  },
  {
    text: "Dashboard",
    slug: "/dashboards/operator",
    roles: [ROLES.Operator],
    Icon: DashboardIcon,
  },
  {
    text: "Users",
    slug: "/users",
    roles: [ROLES.Manager],
    Icon: PeopleAltOutlinedIcon,
  },
  {
    text: "Reports",
    slug: "/reports",
    roles: [ROLES.Manager, ROLES.Operator],
    Icon: ReportOutlined,
  },
  {
    text: "Incidents",
    slug: "/incidents",
    roles: [ROLES.Manager, ROLES.Operator],
    Icon: AddAlertOutlined,
  },
  {
    text: "Re-open Requests",
    slug: "/reopen-requests",
    roles: [ROLES.Manager],
    Icon: AddAlertOutlined,
  },
  {
    text: "Tickets",
    slug: "/tickets",
    roles: [ROLES.Manager, ROLES.Operator],
    Icon: AssignmentIcon,
  },
  {
    text: "Forensics",
    slug: "/forensics",
    roles: [ROLES.Manager, ROLES.Operator],
    Icon: ReportOutlined,
  },
  {
    text: "Incidents & Responses",
    slug: "/actions",
    roles: [ROLES.Manager, ROLES.Operator],
    Icon: IncidentIcon,
    slugOptions: ["/incidents", "/tickets", "/forensics", "/actions"],
  },
  {
    text: "Assets",
    slug: "/endpoints",
    roles: [ROLES.Manager, ROLES.Operator],
    Icon: StorageIcon,
    slugOptions: ["/endpoints", "/endpoint-users"],
  },
  {
    text: "Resolution Summary",
    slug: "/resolution-summaries",
    roles: [ROLES.Manager, ROLES.Operator],
    Icon: GroupIcon,
  },
  {
    text: "Audit",
    slug: "/audit",
    roles: [ROLES.Manager],
    Icon: SummarizeIcon,
  },
];

const StyledNav = styled(List)({
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 16,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});

const SidebarOption3 = ({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const theme = useTheme();
  const { mode } = useSelector((state) => state.themeMode);
  const { user } = useAuth();

  const mappedItems = menuItems?.filter(
    (item) =>
      item?.roles && item?.roles.length > 0 && item?.roles.includes(user?.role)
  );

  return (
    <Box
      id="sidebar-3"
      className={`${mode === "dark" ? "sidebar-dark" : "sidebar-light"}`}
      sx={{ display: "flex" }}
    >
      <Paper elevation={0} sx={{ maxWidth: 256 }}>
        <StyledNav component="nav" disablePadding>
          {isSidebarOpen && (
            <Drawer
              open={isSidebarOpen}
              onClose={() => setIsSidebarOpen(false)}
              variant="permanent"
              anchor="left"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  padding: 2,
                  borderColor:
                    mode === "dark" ? theme.palette.background.alt : "",
                  boxSizing: "border-box",
                  borderWidth: isNonMobile ? 0 : "2px",
                  width: drawerWidth,
                  // display: "flex",
                  gap: 2,
                  borderRadius: 2,
                },
              }}
            >
              <Header />
              <Box sx={{ flex: 1 }}>
                {mappedItems?.map((menu) => {
                  return <SideItem key={menu?.slug} menu={menu} />;
                })}
              </Box>
              <Footer />
            </Drawer>
          )}
        </StyledNav>
      </Paper>
    </Box>
  );
};

const SideItemWrapper = ({ children, background = "", sx }) => {
  return (
    <Box
      sx={{
        background: background,
        paddingInline: "16px",
        paddingBlock: "13px",
        borderRadius: "12px",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

const SideItem = ({ menu }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const path = `/${pathname.split("/")[1]}`;
  const theme = useTheme();

  const isDark = theme.palette.mode === "dark";
  const activeBackground = isDark ? "#040910" : theme.palette.common.white;
  const nonActiveIconColor = isDark
    ? theme.palette.common.white
    : theme.palette.grey["900"];

  const handleNavigate = (slug) => {
    // Reopen request
    if (slug === "/reopen-requests") {
      /** If the user is inside the reopen request page set the status='pending' */
      if (slug === pathname) {
        setSearchParams((searchParams) => {
          searchParams.set("status", "pending");
          if (!searchParams.get("rowsPerPage")) {
            console.log("Not Available");
          }
          return searchParams;
        });
        return;
      }

      /** If the user is not inside the reopen request page set the status='pending' and navigate to it */
      setSearchParams((searchParams) => {
        searchParams.set("status", "pending");
        searchParams.set("rowsPerPage", 20);
        return searchParams;
      });
      navigate(`${slug}?status=pending`);
      return;
    }

    // Incidents
    if (slug === "/incidents") {
      if (slug === pathname) return;

      setSearchParams((searchParams) => {
        searchParams.delete("type");
        searchParams.set("limit", 100);
        return searchParams;
      });
      // navigate(`${slug}?limit=${searchParams.get("limit")}`);
      navigate(slug);
      return;
    }

    // Audit
    if (slug === "/audit") {
      if (slug === pathname) return;
    }

    if (slug === "/reports") {
      console.log(slug, pathname);
      // If the user is inside incident-summary-report don't do anything.
      if (pathname === "/reports/incident-summary-report") {
        return;
      }

      // If the user is inside one of the reports routes other than incident-summary-report, navigate to the incident-summary-report page.
      if (pathname.startsWith("/reports")) {
        navigate("/reports/incident-summary-report", { replace: true });
        console.log(pathname);
        // setSearchParams((searchParams) => {
        //   searchParams.set("status", "pending");
        //   if (!searchParams.get("rowsPerPage")) {
        //     console.log("Not Available");
        //   }
        //   return searchParams;
        // });
        return;
      }

      // If a user clicks on the 'Reports' menu for the first time (from another page), then navigate to the incident summary report page.
      navigate("/reports/incident-summary-report", { replace: true });
      return;
    }

    // Default
    navigate(slug, { replace: true });
  };

  let active = path === menu?.slug;

  // This tackles the active state for the sidebar item when it has more than one nested routes
  // For example, /endpoints and /endpoint-users
  if (menu?.slugOptions) active = !!menu?.slugOptions?.includes(path);

  return (
    <SideItemWrapper
      key={menu?.slug}
      background={active ? activeBackground : ""}
      sx={{
        mb: "5px",
        cursor: active ? "" : "pointer",
        color: active ? "#05F2AF" : "",
        "&:hover": {
          backgroundColor: activeBackground,
          color: "#05F2AF",
        },
        "&:hover path": {
          fill: "#05F2AF",
        },
      }}
    >
      <Box
        component="Link"
        onClick={() => handleNavigate(menu?.slug)}
        sx={{ display: "flex", alignItems: "center", gap: 2 }}
      >
        <menu.Icon fill={active ? "#05F2AF" : nonActiveIconColor} />
        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
          {menu?.text}
        </Typography>
      </Box>
    </SideItemWrapper>
  );
};

const Header = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  return (
    <SideItemWrapper
      background={mode === "dark" ? "#040910" : theme.palette.grey["400"]}
    >
      <MainLogo />
    </SideItemWrapper>
  );
};
const Footer = () => {
  return (
    <SideItemWrapper sx={{ paddingInline: 0 }}>
      <Profile />
      <ThemeToggle />
    </SideItemWrapper>
  );
};

const Profile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleNavigate = () => {
    navigate(`/users/${user?.id}`, { replace: true });
  };

  return (
    <Tooltip title="Go to profile" placement="top">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          my: 1,
          borderRadius: "12px",
          gap: 1,
          my: 1,
          background: theme.palette.background.default,
          borderRadius: "12px",
          cursor: "pointer",
          padding: "8px 12px",
          "&:hover": {
            background: theme.palette.background.alt,
          },
        }}
        onClick={handleNavigate}
      >
        <Avatar alt="Tad" />
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            {user?.name}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, color: theme.palette.grey[400] }}
          >
            {user?.role}
          </Typography>
        </Box>
        <ExpandLessIcon />
      </Box>
    </Tooltip>
  );
};

export default SidebarOption3;
