import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import BlurCircularIcon from "@mui/icons-material/BlurCircular";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import CopyToClipboard from "../../ui/CopyToClipboard";

const EndpointInfo = ({ endpoint }) => {
  return (
    <Card variant="outlined" sx={{ height: "100%", mb: -2 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} xl={2}>
            <Item label="Endpoint Name" value={endpoint?.host?.name} />
            <Item
              label="Endpoint ID"
              value={
                <CopyToClipboard item={endpoint?.host?.id}>
                  {endpoint?.host?.id}
                </CopyToClipboard>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={2}>
            <Item
              label="IP Address"
              value={endpoint?.host?.ip[endpoint?.host?.ip?.length - 1]}
            />
            <Users values={endpoint?.users} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={2}>
            <Item label="MAC Address" value={endpoint?.host?.name} />
            <Item label="Last used by" value={endpoint?.latestUser} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={2}>
            <Item label="OS" value={endpoint?.host?.os?.name} />
            <Item label="Agent Version" value={endpoint?.agent?.version} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={2}>
            <Item label="Kernel" value={endpoint?.host?.os?.kernel} />
            <IncidentCountByWeek values={endpoint?.incidentsCount} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={2}>
            <Item label="Architecture" value={endpoint?.host?.architecture} />
            <Item label="Status" value={"Connected"} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Item = ({ label, value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        gap: 1,
        mb: 2,
      }}
    >
      <Typography sx={{ minWidth: 110, fontSize: 12 }} color="gray">
        {label}
      </Typography>{" "}
      <Box>{value}</Box>{" "}
    </Box>
  );
};

const Users = ({ values }) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 1 }}>
      <Typography sx={{ minWidth: 110, fontSize: 12 }} color="gray">
        Users{" "}
      </Typography>{" "}
      <Box sx={{ display: "flex", gap: 0.25, flexWrap: "wrap" }}>
        {values?.map((value) => (
          <CopyToClipboard key={value} item={value}>
            <Chip
              onClick={() => navigate(`/endpoint-users/${value}`)}
              label={value}
              size="small"
              variant="outlined"
            />
          </CopyToClipboard>
        ))}
      </Box>
    </Box>
  );
};

const IncidentCountByWeek = ({ values }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        gap: 1,
        mb: 2,
      }}
    >
      <Typography sx={{ minWidth: 110, fontSize: 12 }} color="gray">
        Incident Count by Week{" "}
      </Typography>{" "}
      <Box sx={{ display: "flex", gap: 1 }}>
        <IncidentCountBySeverity
          value={values["high"] || 0}
          title="High"
          color="error"
        />
        <IncidentCountBySeverity
          value={values["medium"] || 0}
          title="Medium"
          color="warning"
        />
        <IncidentCountBySeverity
          value={values["low"] || 0}
          title="Low"
          color="primary"
        />
      </Box>
    </Box>
  );
};

const IncidentCountBySeverity = ({ value, title, color }) => {
  const customColor = `${color}.main`;
  return (
    <Tooltip
      title={title}
      color="inherit"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: customColor,
            "& .MuiTooltip-arrow": {
              color: customColor,
            },
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 0.15,
          border: "1px solid transparent",
          alignItems: "center",
          padding: "2px 6px",
          borderRadius: 1,
          "&:hover": {
            border: "1px solid inherit",
            borderColor: customColor,
          },
        }}
      >
        <BlurCircularIcon color={color} sx={{ fontSize: 14 }} />
        <Typography>{value}</Typography>
      </Box>
    </Tooltip>
  );
};

export default EndpointInfo;
