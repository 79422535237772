import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";

const TabButton = ({ item }) => {
  const navigate = useNavigate();
  const active = item?.active;
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const handleNavigate = () => {
    navigate(`/${item?.slug}`, { replace: true });
  };
  return (
    <Button
      variant="contained"
      onClick={handleNavigate}
      sx={{
        width: "178px",
        background: active ? "#05F2AF" : isDark ? "#1B202A" : "#ededed",
        color: active || !isDark ? "black" : "#FFF",
        paddingBlock: "10px",
        borderRadius: 0,
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
        textTransform: "capitalize",
        boxShadow: "none",
      }}
    >
      {item?.label}
    </Button>
  );
};

export default TabButton;
