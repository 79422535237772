import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchIncidentsByStatus } from "./index";

export const getIncidentsByStatus = createAsyncThunk(
  "incidents/byStatus",
  fetchIncidentsByStatus
);

const initialState = {
  labels: [],
  series: [],
  loading: false,
  error: null,
};
const incidentsByStatusSlice = createSlice({
  name: "incidents-by-status",
  initialState,
  reducers: {
    clearIncidentByStatusState: (state) => {
      state.labels = [];
      state.series = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIncidentsByStatus.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getIncidentsByStatus.fulfilled, (state, action) => {
      const { labels, series } = action.payload;
      state.loading = false;
      state.error = "";
      state.labels = labels;
      state.series = series;
    });
    builder.addCase(getIncidentsByStatus.rejected, (state, action) => {
      state.loading = false;
      state.incidents = [];
      state.error = action.error.message;
    });
  },
});

export const { clearIncidentByStatusState } = incidentsByStatusSlice.actions;
export default incidentsByStatusSlice.reducer;
