import { configureStore } from "@reduxjs/toolkit";
import { apiSlice as api } from "./services/apiSlice";

import authReducer from "../features/auth/authSlice";
import themeModeReducer from "../features/theme/themeModeSlice";
import toastReducer from "../features/toast/toastSlice";
import incidentsReducer from "../features/incidents/incidentsSlice";
import incidentReducer from "../features/incidents/incidentSlice";
import executiveDashboardReducer from "../features/dashboards/executive/reducer";
import operatorDashboardReducer from "../features/dashboards/operator/reducer";
import reopenRequestReducer from "../features/reopen/reopenSlice";
import forensicReducer from "../features/forensics/reducer";
import ticketsReducer from "../features/tickets/reducer";
import auditReducer from "../features/audit/auditSlice";
import resolutionSummaryReducer from "../features/resolution-summaries/resolutionSummarySlice";
import endpointDetailsReducer from "../features/endpoints/endpointDetailsSlice";
import endpointUserDetailReducer from "../features/endpoint-users/endpointUserDetailSlice";
import usersReducer from "../features/users/reducer";
import endpointReducer from "../features/endpoints/reducer";
import endpointUserReducer from "../features/endpoint-users/reducer";
import actionReducer from "../features/actions/actionSlice";
import liveSessionReducer from "../features/actions/liveSessionSlice";
import notificationReducer from "../features/notifications/notificationSlice";

//MIDDLEWARE
const localStorageMiddleware = ({ getState }) => {
  return (next) => (action) => {
    const result = next(action);
    if (getState().auth?.rememberMe) {
      localStorage.setItem("state", JSON.stringify(getState()));
    }
    localStorage.setItem("theme", JSON.stringify(getState().themeMode));
    return result;
  };
};

const reHydrateStore = () => {
  if (localStorage.getItem("state") !== null) {
    return JSON.parse(localStorage.getItem("state")); // re-hydrate the store
  } else {
    localStorage.removeItem("state");
  }
};

export const store = configureStore(
  {
    reducer: {
      themeMode: themeModeReducer,
      toast: toastReducer,
      auth: authReducer,
      users: usersReducer,
      incidents: incidentsReducer,
      incident: incidentReducer,
      executiveDashboard: executiveDashboardReducer,
      operatorDashboard: operatorDashboardReducer,
      reopenRequests: reopenRequestReducer,
      forensics: forensicReducer,
      tickets: ticketsReducer,
      audit: auditReducer,
      resolutionSummary: resolutionSummaryReducer,
      endpoints: endpointReducer,
      endpointDetails: endpointDetailsReducer,
      endpointUsers: endpointUserReducer,
      endpointUserDetails: endpointUserDetailReducer,
      actions: actionReducer,
      liveSession: liveSessionReducer,
      notifications: notificationReducer,
      [api.reducerPath]: api.reducer,
    },

    preloadedState: reHydrateStore(),

    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(api.middleware)
        .concat(localStorageMiddleware),
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
