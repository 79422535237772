import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchUserUnResolvedIncidentsByType } from "..";

export const getUserInprogressIncidentsByType = createAsyncThunk(
  "user/inprogress-incidents-by-type",
  fetchUserUnResolvedIncidentsByType
);

const initialState = {
  incidents: {},
  loading: false,
  error: null,
};

export const userInprogressIncidentsByTypeSlice = createSlice({
  name: "user-inprogress-incidents-by-type",
  initialState,
  reducers: {
    clearUserInprogressIncidentsByTypeState: (state) => {
      state.incidents = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // User Info
    builder.addCase(getUserInprogressIncidentsByType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getUserInprogressIncidentsByType.fulfilled,
      (state, action) => {
        state.loading = false;
        state.incidents = action.payload;
      }
    );
    builder.addCase(
      getUserInprogressIncidentsByType.rejected,
      (state, action) => {
        state.loading = false;
        state.incidents = {};
        state.error = action.error.message;
      }
    );
  },
});

export const { clearUserInprogressIncidentsByTypeState } =
  userInprogressIncidentsByTypeSlice.actions;

export default userInprogressIncidentsByTypeSlice.reducer;
