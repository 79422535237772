import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import useTheme from "../../hooks/useTheme";

export default function BaseWidget({ children }) {
  const theme = useTheme();
  const mode = theme.palette.mode
  return (
    <Card
      variant="outlined"
      className={`card-theme ${mode}`}
      sx={{
        border: mode === "dark" ? "none" : null,
        width: "100%",
        // overflowX: "auto",
      }}
    >
      <CardContent sx={{ padding: 0 }}>{children}</CardContent>
    </Card>
  );
}
