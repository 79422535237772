import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import BaseWidget from "./BaseWidget";
import WidgetContent from "./WidgetContent";
import WidgetHeader from "./WidgetHeader";
import ResolvedIncidentsByAssigneeChart from "../charts/ResolvedIncidentsByAssigneeChart";
import { getResolvedIncidentsByAssignee } from "../../redux/features/dashboards/executive/resolvedIncidentsByAssigneeSlice";
import MuiMenu from "./MuiMenu";

const ResolvedIncidentsByAssigneeWidget = () => {
  const [days, setDays] = useState("7days");
  const dispatch = useDispatch();

  const formatDate = () => {
    if (!days) return null;
    if (days === "24hours") return "1";
    if (days === "7days") return "7";
    if (days === "30days") return "30";
  };

  useEffect(() => {
    dispatch(getResolvedIncidentsByAssignee({ days: formatDate() }));
  }, [dispatch, days]);

  return (
    <BaseWidget>
      <WidgetHeader title="Resolved Incidents by Assignee">
        <MuiMenu days={days} setDays={setDays} />
      </WidgetHeader>
      <WidgetContent>
        <ResolvedIncidentsByAssigneeChart />
      </WidgetContent>
    </BaseWidget>
  );
};

export default ResolvedIncidentsByAssigneeWidget;
