import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

const NumberInput = (props) => {
  const { name, label, value, onChange } = props;

  return (
    <FormControl variant="outlined" sx={{ width: "100%", marginY: 1 }}>
      <TextField
        name={name}
        label={label}
        value={value}
        type="number"
        onChange={onChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </FormControl>
  );
};

export default NumberInput;
