import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import Form from "../ui/Form";
import { useAddNewUserMutation } from "../../redux/app/services/userApiSlice";
import { showToast } from "../../redux/features/toast";

const newUserValidationSchema = z
  .object({
    name: z.string().min(1, { message: "Name is required" }),
    email: z
      .string()
      .min(1, { message: "Email is required" })
      .email({ message: "Provide a valid email" }),
    password: z
      .string()
      .min(1, { message: "Password is required" })
      .min(6, { message: "Password should be at least 6 characters" }),
    confirmPassword: z
      .string()
      .min(1, { message: "Please confirm your password." }),
    roleName: z.string({
      required_error: "Name is required",
      invalid_type_error: "Name must be a string",
    }),
  })
  //   Check in the passwords match
  .refine((form) => form.password === form.confirmPassword, {
    message: "Passwords must match",
    path: ["confirmPassword"],
  });

const AddNewUserForm = ({ setModalOpen }) => {
  const { register, handleSubmit, reset, formState } = useForm({
    defaultValue: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      roleName: "Operator",
    },
    resolver: zodResolver(newUserValidationSchema),
  });
  const { errors } = formState;
  const [addNewUser] = useAddNewUserMutation();

  const onSubmit = async (data) => {
    const { name, email, password, roleName } = data;

    try {
      await addNewUser({ name, email, password, roleName });

      //Check if the request is success - (when it changed to the createAsyncAwait feature)
      // const res = await addNewUser({ name, email, password, roleName });
      // if (res.status === 200) {
      reset({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        roleName: "Operator",
      });
      handleClose();
      showToast({
        type: "success",
        message: "User is created successfully!",
      });
      // }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Form>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          autoFocus
          {...register("name")}
          error={!!errors.name}
          helperText={errors.name?.message || " "}
        />
        <TextField
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          {...register("email")}
          error={!!errors.email}
          helperText={errors.email?.message || " "}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          type="password"
          id="password"
          label="Password"
          name="password"
          autoComplete="password"
          {...register("password")}
          error={!!errors.password}
          helperText={errors.password?.message || " "}
        />

        <TextField
          required
          fullWidth
          type="password"
          id="confirm-password"
          label="Confirm Password"
          name="confirmPassword"
          autoComplete="confirm password"
          {...register("confirmPassword")}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword?.message || " "}
        />

        <FormControl fullWidth>
          <InputLabel id="roleName">Role Name</InputLabel>
          <Select
            {...register("roleName")}
            labelId="roleName"
            id="roleNameId"
            label="Role Name"
          >
            <MenuItem value="Operator">Operator</MenuItem>
            <MenuItem value="Manager">Manager</MenuItem>
            <MenuItem value="Admin">Admin</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 3,
          gap: 2,
        }}
      >
        <Button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          variant="contained"
          color="primary"
        >
          Create User
        </Button>
        <Button variant="contained" color="inherit" onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Form>
  );
};

export default AddNewUserForm;
