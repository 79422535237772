import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchResolvedIncidentsMTTR } from ".";

export const getResolvedIncidentsMTTR = createAsyncThunk(
  "incidents/operator-resolved-mttr",
  fetchResolvedIncidentsMTTR
);

const initialState = {
  riskClasses: [],
  MTTRAverage: [],
  averageResolutionTime: [],
  loading: false,
  error: null,
};

const resolvedIncidentsMTTR = createSlice({
  name: "operator-resolved-incidents-mttr",
  initialState,
  reducers: {
    clearOperatorResolvedIncidentsMTTRState: (state) => {
      state.riskClasses = [];
      state.MTTRAverage = [];
      state.averageResolutionTime = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getResolvedIncidentsMTTR.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getResolvedIncidentsMTTR.fulfilled, (state, action) => {
      const { riskClasses, MTTRAverage, averageResolutionTime } =
        action.payload;
      state.loading = false;
      state.error = "";
      state.riskClasses = riskClasses;
      state.MTTRAverage = MTTRAverage;
      state.averageResolutionTime = averageResolutionTime;
    });
    builder.addCase(getResolvedIncidentsMTTR.rejected, (state, action) => {
      state.loading = false;
      state.riskClasses = [];
      state.MTTRAverage = [];
      state.averageResolutionTime = [];
      state.error = action.error.message;
    });
  },
});

export const { clearOperatorResolvedIncidentsMTTRState } =
  resolvedIncidentsMTTR.actions;
export default resolvedIncidentsMTTR.reducer;
