import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import {
  EpochTimeToHumanReadableTime,
  arrayFromObjectKeys,
  arrayFromObjectValues,
  capitalize,
} from "../../utils/functions";
import CopyToClipboard from "../ui/CopyToClipboard";

function EventItem(props) {
  const [itemOpen, setItemOpen] = React.useState(false);

  return (
    <React.Fragment>
      <EventHeader
        props={props}
        itemOpen={itemOpen}
        setItemOpen={setItemOpen}
      />
      <EventCollapsibleContent props={props} itemOpen={itemOpen} />
    </React.Fragment>
  );
}

export default EventItem;

const EventHeader = ({ props, itemOpen, setItemOpen }) => {
  const { event, open, setOpen } = props;
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const handleToggle = () => {
    if (open) {
      setItemOpen(false);
    } else {
      setItemOpen(!itemOpen);
    }
    setOpen(false);
  };

  return (
    <TableRow
      sx={{
        "@media print": {
          border: "none !important",
        },
        backgroundColor: itemOpen
          ? isDark
            ? theme.palette.grey[800]
            : theme.palette.grey[300]
          : null,
        "& > *": {
          borderBottom: "unset",
          backgroundColor: open ? theme.palette.background.paper : null,
          color: isDark ? theme.palette.grey[400] : theme.palette.grey[600],
          "@media print": {
            background: "#eee",
            color: "black",
          },
        },
      }}
    >
      <TableCell>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={handleToggle}
          sx={{
            "@media print": {
              color: "black",
            },
          }}
        >
          {open || itemOpen ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </IconButton>
      </TableCell>

      {/* Event ID */}
      <TableCell component="th" scope="row">
        {event?.id}
      </TableCell>
      <TableCell component="th" scope="row">
        {event?.host?.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {capitalize(event?.type)}
      </TableCell>
      <TableCell component="th" scope="row">
        {event?.user}
      </TableCell>
      <TableCell component="th" scope="row">
        {event?.createdAt}
      </TableCell>
    </TableRow>
  );
};

const EventCollapsibleContent = ({ props, itemOpen }) => {
  const { event, open } = props;
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  return (
    <TableRow
      sx={{
        backgroundColor: itemOpen
          ? isDark
            ? theme.palette.grey[900]
            : theme.palette.grey[200]
          : null,
        //TODO: Change the background of currently opened event when the user click on the Download button.
        //To make sure all events have the same background.
        "@media print": {
          background: "#fefefe",
          // color: "black",
        },
      }}
    >
      <TableCell
        sx={{
          paddingBlock: !open || !itemOpen ? 0 : 2,
        }}
        colSpan={6}
      >
        <Collapse in={open || itemOpen} timeout="auto" unmountOnExit>
          <>
            <Box
              sx={{
                // margin: 1,
                paddingBlock: 2,
                display: "flex",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              <Box>
                <DetailInfo label="Type" value={capitalize(event?.type)} />
                <DetailInfo label="Session User" value={event?.sessionUser} />
              </Box>
              <Box>
                <DetailInfo label="OS" value={event?.os} />
              </Box>
              <Box>
                <DetailInfo
                  label="IpV6"
                  value={event?.host?.ip[event?.host?.ip?.length - 2]}
                />
                <DetailInfo
                  label="IpV4"
                  value={event?.host?.ip[event?.host?.ip?.length - 1]}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                // padding: "1rem",
              }}
            >
              <ProcessTable processes={event?.process} />
              {!!event.childProcess && (
                <ChildProcessTable
                  title="Child Process"
                  process={event?.childProcess}
                />
              )}
              {!!event.registry && (
                <RegistryTable title="Registry" registry={event?.registry} />
              )}
              {!!event.network && (
                <NetworkTable title="Network" network={event?.network} />
              )}
            </Box>
          </>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

const DetailInfo = ({ label, value }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", gap: 1, marginBottom: 1 }}
    >
      <Typography color="gray" variant="body2">
        {label}:
      </Typography>
      <CopyToClipboard item={value}>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.grey[600], fontWeight: 600 }}
        >
          {value}
        </Typography>
      </CopyToClipboard>
    </Box>
  );
};

const ProcessTable = ({ processes }) => {
  let columns = [];
  let rows = [];
  const capitalize = true;

  processes?.map((process) => {
    let formattedProcess = {
      ...process,
      creationTime: EpochTimeToHumanReadableTime(
        process.creationTime,
        "MMM DD, YYYY HH:mm"
      ),
    };

    columns = arrayFromObjectKeys(formattedProcess, capitalize);
    rows = [...rows, arrayFromObjectValues(formattedProcess)];
    return;
  });

  return <EventTable type="process" columns={columns} rows={rows} />;
};

const ChildProcessTable = ({ process }) => {
  let formattedProcess = {
    creationTime: EpochTimeToHumanReadableTime(
      process.creationTime,
      "MMM DD, YYYY HH:mm"
    ),
    verdict: process.verdict,
    "image path": process.imagePath,
    "image hash": process.imageHash,
  };

  const columns = arrayFromObjectKeys(formattedProcess, capitalize);
  const rows = [arrayFromObjectValues(formattedProcess)];

  return <EventTable type="child process" columns={columns} rows={rows} />;
};

const RegistryTable = ({ registry }) => {
  const columns = arrayFromObjectKeys(registry, capitalize).reverse();
  const rows = [arrayFromObjectValues(registry).reverse()];

  return <EventTable type="registry" columns={columns} rows={rows} />;
};

const NetworkTable = ({ network }) => {
  const columns = arrayFromObjectKeys(network, capitalize).reverse();
  const rows = [arrayFromObjectValues(network).reverse()];

  return <EventTable type="registry" columns={columns} rows={rows} />;
};

const EventTable = ({ type = "process", columns, rows }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const tableTitle = (
    <Typography
      sx={{
        textAlign: "center",
        marginBottom: 1,
        "@media print": {
          color: "black",
        },
      }}
    >
      {capitalize(type)}
    </Typography>
  );

  const tableHeader = (
    <TableHead>
      <TableRow>
        {columns &&
          columns?.map((column) => (
            <TableCell
              key={column}
              sx={{ color: theme.palette.grey[600], fontWeight: 600 }}
            >
              {column}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );

  const tableBody = (
    <TableBody>
      {rows &&
        rows?.map((row, index) => (
          <TableRow
            key={`process-${index}`}
            sx={{
              "@media print": {
                borderBlock: "none !important",
              },
            }}
          >
            {row &&
              row?.map((item) => (
                <TableCell
                  key={item}
                  sx={{
                    color: theme.palette.grey[600],
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  {item}
                </TableCell>
              ))}
          </TableRow>
        ))}
    </TableBody>
  );

  return (
    <Box sx={{ marginBlock: "10px" }}>
      {tableTitle}
      <TableContainer
        component={Paper}
        sx={{
          "&.MuiPaper-root": {
            backgroundColor: "transparent",
          },
          "@media print": {
            backgroundColor: "transparent !important",
            boxShadow: "none",
          },
        }}
      >
        <Table
          size="small"
          aria-label={type}
          sx={{
            "& .MuiTableRow-root": {
              borderBottom: isDark ? "2px solid #181818" : "2px solid #fafafa",
            },
            "@media print": {
              border: "none",
            },
          }}
        >
          {tableHeader}
          {tableBody}
        </Table>
      </TableContainer>
    </Box>
  );
};