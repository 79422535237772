import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchIncidentsBySeverity } from "./index";

export const getIncidentsBySeverity = createAsyncThunk(
  "incidents/by-severity",
  fetchIncidentsBySeverity
);

const initialState = {
  riskClasses: [],
  incidents: [],
  loading: false,
  error: null,
};

const incidentsBySeveritySlice = createSlice({
  name: "incidents-by-severity",
  initialState,
  reducers: {
    clearIncidentsBySeverityState: (state) => {
      state.riskClasses = [];
      state.incidents = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIncidentsBySeverity.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getIncidentsBySeverity.fulfilled, (state, action) => {
      const { riskClasses, incidents } = action.payload;
      state.loading = false;
      state.error = "";
      state.riskClasses = riskClasses;
      state.incidents = incidents;
    });
    builder.addCase(getIncidentsBySeverity.rejected, (state, action) => {
      state.loading = false;
      state.riskClasses = [];
      state.incidents = [];
      state.error = action.error.message;
    });
  },
});

export const { clearIncidentsBySeverityState } =
  incidentsBySeveritySlice.actions;
export default incidentsBySeveritySlice.reducer;
