import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import BaseWidget from "./BaseWidget";
import WidgetHeader from "./WidgetHeader";
import useAuth from "./../../hooks/useAuth";
import { getMyIncidents } from "../../redux/features/dashboards/operator/myIncidentsSlice";
import { useDashboard } from "../../hooks/useDashboard";
import IncidentTable from "./IncidentTable";

export default function MyIncidentsWidget() {
  const { user } = useAuth();
  const { myIncidents } = useDashboard();
  const { inProgressIncidents: incidents, loading } = myIncidents;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getMyIncidents({ userId: user?.id, limit: 10, status: "InProgress" })
    );
  }, [dispatch]);

  return (
    <BaseWidget>
      <WidgetHeader title="My Incidents" />
      <IncidentTable incidents={incidents} loading={loading} />
    </BaseWidget>
  );
}
