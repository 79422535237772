import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchResolutionSummaries } from ".";

export const getResolutionSummaries = createAsyncThunk(
  "resolution-summary",
  fetchResolutionSummaries
);

const initialState = {
  summaries: [],
  loading: false,
  error: null,
};

const resolutionSummarySlice = createSlice({
  name: "resolution-summary",
  initialState,
  reducers: {
    clearResolutionSummaryState: (state) => {
      state.summaries = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getResolutionSummaries.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getResolutionSummaries.fulfilled, (state, action) => {
      state.loading = false;
      state.summaries = action.payload;
    });
    builder.addCase(getResolutionSummaries.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { clearResolutionSummaryState } = resolutionSummarySlice.actions;
export default resolutionSummarySlice.reducer;
