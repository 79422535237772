import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const ReportTable = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>File</TableCell>
            <TableCell>Host</TableCell>
            <TableCell>Network </TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              High
            </TableCell>
            <TableCell>{data["file"]?.high["value"] || 0}</TableCell>
            <TableCell>{data["host"]?.high["value"] || 0}</TableCell>
            <TableCell>{data["network"]?.high["value"] || 0}</TableCell>
            <TableCell>{data["total"]?.high["value"] || 0}</TableCell>
          </TableRow>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              Medium
            </TableCell>
            <TableCell>{data["file"]?.medium["value"] || 0}</TableCell>
            <TableCell>{data["host"]?.medium["value"] || 0}</TableCell>
            <TableCell>{data["network"]?.medium["value"] || 0}</TableCell>
            <TableCell>{data["total"]?.medium["value"] || 0}</TableCell>
          </TableRow>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              Low
            </TableCell>
            <TableCell>{data["file"]?.low["value"] || 0}</TableCell>
            <TableCell>{data["host"]?.low["value"] || 0}</TableCell>
            <TableCell>{data["network"]?.low["value"] || 0}</TableCell>
            <TableCell>{data["total"]?.low["value"] || 0}</TableCell>
          </TableRow>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              Total
            </TableCell>
            <TableCell>{data["file"]?.value || 0}</TableCell>
            <TableCell>{data["host"]?.value || 0}</TableCell>
            <TableCell>{data["network"]?.value || 0}</TableCell>
            <TableCell>{data["total"]?.value || 0}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportTable;
