import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Box, useTheme } from "@mui/material";

const Wrapper = ({ title = "", children, withCTA }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  return (
    <Paper
      sx={{
        padding: ".75rem",
        minHeight: "100%",
        overflow: "hidden",
        boxShadow: isDark ? null : "none",
        border: "1px solid",
        // TODO: Use theme colors
        borderColor: isDark ? "#131313" : "#ececec",
        background: isDark ? "#0f1317" : "#fff",
      }}
    >
      {!!withCTA ? (
        <>
          <WrapperTitleWithCallToAction title={title} component={children[0]} />
          {children[1]}
        </>
      ) : (
        <>
          <WrapperTitle title={title} />
          {children}
        </>
      )}
    </Paper>
  );
};

const WrapperTitle = ({ title }) => (
  <Typography
    variant="h5"
    sx={{
      fontWeight: "600",
      color: "primary.main",
      fontSize: "1rem",
      mb: "5px",
    }}
  >
    {title}
  </Typography>
);

const WrapperTitleWithCallToAction = ({ title, component }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          color: "primary.main",
          fontSize: "1rem",
        }}
      >
        {title}
      </Typography>
      {component}
    </Box>
  );
};

export default Wrapper;
