import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  editBoardItemComment,
  fetchBoardViewResponse,
  removeBoardItem,
} from ".";

export const getBoardViewList = createAsyncThunk(
  "forensic/board-view-list",
  fetchBoardViewResponse
);

export const updateBoardItemComment = createAsyncThunk(
  "forensic/update-board-item-comment",
  editBoardItemComment
);

export const deleteBoardItem = createAsyncThunk(
  "forensic/delete-board-item",
  removeBoardItem
);

const initialState = {
  loading: false,
  error: null,
  responses: [],
};

const boardViewSlice = createSlice({
  name: "board-view",
  initialState,
  reducers: {
    clearBoardViewState: (state) => {
      state.loading = false;
      state.error = null;
      state.responses = [];
    },
    clearBoardView: (state) => {
      state.responses = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBoardViewList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBoardViewList.fulfilled, (state, action) => {
      state.loading = false;
      state.responses = action.payload;
      state.error = null;
    });
    builder.addCase(getBoardViewList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateBoardItemComment.fulfilled, (state, action) => {
      const { remark, boardItemId } = action.payload;

      state.responses.map((response) => {
        // Update a board the selected board item
        if (response.boardItemId === boardItemId) {
          response.remark = remark;
        }
        return response;
      });
    });
    builder.addCase(deleteBoardItem.fulfilled, (state, action) => {
      // Remove the item from the state list
      state.responses = state.responses.filter(
        (item) => item.boardItemId !== action.payload
      );
    });
  },
});

export const { clearBoardViewState, clearBoardView } = boardViewSlice.actions;
export default boardViewSlice.reducer;
