import React from "react";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import Box from "@mui/material/Box";

import { capitalize } from "../../../../utils/functions";
import WidgetTitle from "../../WidgetTitle";
import Rank from "../../Rank";

const LeadingOperators = ({ operators }) => {
  return (
    <>
      <WidgetTitle title="Leading Operators" />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Rank
          value={capitalize(operators["file"]?.name)}
          color="info"
          label="File"
          icon={<FolderOutlinedIcon color="info" />}
        />
        <Rank
          value={capitalize(operators["host"]?.name)}
          color="warning"
          label="Host"
          icon={<DesktopWindowsOutlinedIcon color="warning" />}
        />
        <Rank
          value={capitalize(operators["network"]?.name)}
          color="success"
          label="Network"
          icon={<LanOutlinedIcon color="success" />}
        />
      </Box>
    </>
  );
};

export default LeadingOperators;
