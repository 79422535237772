import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAllUsers } from ".";

export const getAllUsers = createAsyncThunk(
  "users/get-all-users",
  fetchAllUsers
);

const initialState = {
  users: [],
  loading: false,
  error: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearUsersState: (state) => {
      state.users = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.users = action.payload;
    });
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.loading = false;
      state.users = [];
      state.error = action.error.message;
    });
  },
});

export const { clearUsersState } = usersSlice.actions;

export default usersSlice.reducer;
