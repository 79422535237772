import React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";

import useFetch from "../../hooks/useFetch";
import ReportHeader from "../../components/reports/ReportHeader";
import IncidentCountByType from "../../components/reports/incident-summary-report/incident-count-by-type";
import ResolutionTimeByType from "../../components/reports/incident-summary-report/resolution-time-by-type";
import Timeliness from "../../components/reports/operator-performance-summary/timeliness/";
import OperatorRankByType from "../../components/reports/my-overview/operator-rank-by-type";
import OperatorProfile from "../../components/reports/OperatorProfile";
import TopIncidents from "../../components/reports/incident-summary-report/top-incidents";

const formatDate = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

const MyOverview = ({ startDate, endDate }) => {
  const user = useSelector((state) => state.auth.user);
  const endpoint = `/reports/users/${
    user?.id
  }/operator-overview-summary?startDate=${formatDate(
    startDate
  )}&endDate=${formatDate(endDate)}`;
  const { data, loading } = useFetch(endpoint);

  if (loading) {
    return (
      <Box>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "flex-start",
        gap: 1,
        width: 700,
        marginTop: "-25px",
      }}
    >
      <ReportHeader
        template="My Overview"
        startDate={startDate}
        endDate={endDate}
      />
      {data && data.user_profile ? (
        <OperatorProfile profile={data?.user_profile} />
      ) : null}
      {data && data?.incidents ? (
        <IncidentCountByType data={data?.incidents} />
      ) : null}
      {/* <div className="page-break"></div> */}
      <Box>
        {data && data?.avg_resolution_time ? (
          <ResolutionTimeByType data={data?.avg_resolution_time} />
        ) : null}
      </Box>
      <div className="page-break"></div>
      <Box sx={{ marginBottom: 3 }}>
        {data && data?.top_incidents ? (
          <TopIncidents incidents={data?.top_incidents} />
        ) : null}
      </Box>
      <Grid container spacing={2}>
        <Box
          sx={{
            "@media print": {
              marginTop: 5,
            },
          }}
        />
        <Grid item xs={6}>
          {data && data?.operator_rank ? (
            <OperatorRankByType data={data?.operator_rank} />
          ) : null}
        </Grid>
        <Grid item xs={6}>
          {data && data?.operatorTimeliness ? (
            <Timeliness data={data?.operatorTimeliness} />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MyOverview;
