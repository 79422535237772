import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import BaseWidget from "./BaseWidget";
import WidgetContent from "./WidgetContent";
import WidgetHeader from "./WidgetHeader";
import WidgetLoading from "./WidgetLoading";
import IncidentsOverdueChart from "../charts/IncidentsOverdueChart";
import { getIncidentsOverdue } from "../../redux/features/dashboards/executive/incidentsOverdueSlice";
import { useDashboard } from "../../hooks/useDashboard";
import MuiMenu from "./MuiMenu";

const IncidentsOverdueWidget = () => {
  const [overdueDate, setOverdueDate] = useState("30days");
  const { incidentsOverdue } = useDashboard();
  const { loading } = incidentsOverdue;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIncidentsOverdue());
  }, [dispatch]);

  return (
    <BaseWidget>
      <WidgetHeader title="Overdue Incidents by Assignee">
        <MuiMenu days={overdueDate} setDays={setOverdueDate} />
      </WidgetHeader>
      {loading ? (
        <WidgetLoading />
      ) : (
        <WidgetContent>
          <IncidentsOverdueChart overdueDate={overdueDate} />
        </WidgetContent>
      )}
    </BaseWidget>
  );
};

export default IncidentsOverdueWidget;
