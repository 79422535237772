import React from "react";
import Box from "@mui/material/Box";

import ReportTable from "../../ReportTable";
import ReportDonutChart from "../../ReportDonutChart";
import WidgetTitle from "../../WidgetTitle";

const ResolutionTimeByType = ({ data }) => {
  if (data.total.value === "0m:0s") return null;

  const typeChart = {
    labels: ["File", "Host", "Network"],
    series: [data.file.ms || 0, data.host.ms || 0, data.network.ms || 0],
  };
  const severityChart = {
    labels: ["High", "Medium", "Low"],
    series: [
      data.total.high.ms || 0,
      data.total.medium.ms || 0,
      data.total.low.ms || 0,
    ],
  };

  return (
    <div
    // style={{ marginTop: "-25px" }}
    >
      <WidgetTitle title="Incidents Resolution Time" />
      <ReportTable data={data} />
      <Box
        sx={{ display: "flex", justifyContent: "space-evenly", marginBlock: 3 }}
      >
        {data ? (
          <>
            <ReportDonutChart
              title="By Type"
              labels={typeChart.labels}
              series={typeChart.series}
            />
            <ReportDonutChart
              title="By Severity"
              labels={severityChart.labels}
              series={severityChart.series}
            />
          </>
        ) : null}
      </Box>
    </div>
  );
};

export default ResolutionTimeByType;
