import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAllIncidents, updateStar } from "./index";

export const getIncidents = createAsyncThunk(
  "incidents/getAllIncidents",
  getAllIncidents
);

//TODO: Change the naming if possible
export const starSingleIncident = createAsyncThunk(
  "incident/updateStar",
  updateStar
);

const initialState = {
  incidents: [],
  total: 0,
  page: 1,
  pages: 1,
  loading: false,
  error: null,
};

const incidentsSlice = createSlice({
  name: "incidents",
  initialState,
  reducers: {
    clearIncidentsState: (state) => {
      state.incidents = [];
      state.total = 0;
      state.page = 1;
      state.pages = 1;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIncidents.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getIncidents.fulfilled, (state, action) => {
      const { incidents, total, page, pages } = action.payload;
      state.loading = false;
      state.incidents = incidents;
      state.total = total;
      state.page = page;
      state.pages = pages;
    });
    builder.addCase(getIncidents.rejected, (state, action) => {
      state.loading = false;
      state.incidents = [];
      state.error = action.error.message;
    });
    builder.addCase(starSingleIncident.fulfilled, (state, action) => {
      const { incidentId, userId } = action.payload;

      state.incidents.map((item) => {
        if (item.star && item.incident_id === incidentId) {
          if (item.star.includes(userId)) {
            const index = item.star.findIndex((value) => value === userId);

            // Remove the item from the star array
            item.star.splice(index, 1);
          } else {
            item.star.push(userId);
          }

          return item;
        }
      });
    });
  },
});

export const { clearIncidentsState } = incidentsSlice.actions;
export default incidentsSlice.reducer;
