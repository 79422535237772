import * as React from "react";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowRight from "@mui/icons-material/ArrowRight";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Home from "@mui/icons-material/Home";
import { VerifiedUser } from "@mui/icons-material";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Drawer } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import HomeOutlined from "@mui/icons-material/HomeOutlined";
import AddAlertOutlined from "@mui/icons-material/AddAlertOutlined";
import ReportOutlined from "@mui/icons-material/ReportOutlined";
import StorageIcon from "@mui/icons-material/Storage";
import SummarizeIcon from "@mui/icons-material/Summarize";
import GroupIcon from "@mui/icons-material/Group";
// import SettingsOutlined from "@mui/icons-material/SettingsOutlined";

import { ROLES } from "../../utils/constants";
import logo from "../../assets/logo.png";
import useAuth from "./../../hooks/useAuth";

/* creating a list of navItems to navigate through them */
const menus = [
  {
    category: "General",
    navItems: [
      {
        text: "My Dashboard",
        slug: "/dashboards",
        roles: [ROLES.Manager],
        icon: <HomeOutlined />,
      },
      {
        text: "My Dashboard",
        slug: "/dashboards/operator",
        roles: [ROLES.Operator],
        icon: <HomeOutlined />,
      },
      {
        text: "Users",
        slug: "/users",
        roles: [ROLES.Manager],
        icon: <PeopleAltOutlinedIcon />,
      },
      {
        text: "Reports",
        slug: "/reports",
        roles: [ROLES.Manager, ROLES.Operator],
        icon: <ReportOutlined />,
      },
    ],
  },
  {
    category: "Notifications",
    navItems: [
      {
        text: "Incidents",
        slug: "/incidents",
        roles: [ROLES.Manager, ROLES.Operator],
        icon: <AddAlertOutlined />,
      },
      {
        text: "Re-open Requests",
        slug: "/reopen-requests",
        roles: [ROLES.Manager],
        icon: <AddAlertOutlined />,
      },
    ],
  },

  // {
  //   category: "Data",
  //   navItems: [

  //   ],
  // },

  {
    category: "Management",
    navItems: [
      {
        text: "Tickets",
        slug: "/tickets",
        roles: [ROLES.Manager, ROLES.Operator],
        icon: <AssignmentIcon />,
      },
      {
        text: "Forensics",
        slug: "/forensics",
        roles: [ROLES.Manager, ROLES.Operator],
        icon: <ReportOutlined />,
      },
      {
        text: "Endpoints",
        slug: "/endpoints",
        roles: [ROLES.Manager, ROLES.Operator],
        icon: <StorageIcon />,
      },
      {
        text: "Endpoint Users",
        slug: "/endpoint-users",
        roles: [ROLES.Manager, ROLES.Operator],
        icon: <GroupIcon />,
      },
      {
        text: "Resolution Summary",
        slug: "/resolution-summaries",
        roles: [ROLES.Manager, ROLES.Operator],
        icon: <GroupIcon />,
      },
      {
        text: "Audit",
        slug: "/audit",
        roles: [ROLES.Manager],
        icon: <SummarizeIcon />,
      },
      // {
      //   text: "Settings",
      //   slug: "/admin",
      //   roles: [ROLES.Admin, ROLES.Manager],
      //   icon: <SettingsOutlined />,
      // },
    ],
  },
];

const StyledNav = styled(List)({
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 16,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});

export default function Sidebar({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) {
  const theme = useTheme();
  const { mode } = useSelector((state) => state.themeMode);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleUserProfile = () => {
    navigate(`/users/${user?.id}`, { replace: true });
  };

  const mappedMenuItems = menus.map((group) => {
    const category = group.category;
    const navItems = group.navItems.filter(
      ({ roles }) => roles && roles.length > 0 && roles.includes(user?.role)
    );

    /* Prevent categories from rendering if they have an element*/
    return { category, navItems };
  });

  return (
    <Box
      id="sidebar"
      className={`${mode === "dark" ? "sidebar-dark" : "sidebar-light"}`}
      sx={{ display: "flex" }}
    >
      <Paper elevation={0} sx={{ maxWidth: 256 }}>
        <StyledNav component="nav" disablePadding>
          {isSidebarOpen && (
            <Drawer
              open={isSidebarOpen}
              onClose={() => setIsSidebarOpen(false)}
              variant="permanent"
              anchor="left"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  // color: theme.palette.secondary[200],,
                  // background:
                  //   mode === "light" ? theme.palette.neutral[900] : null,
                  borderColor:
                    mode === "dark" ? theme.palette.background.alt : "",
                  boxSizing: "border-box",
                  borderWidth: isNonMobile ? 0 : "2px",
                  width: drawerWidth,
                },
              }}
            >
              <Box>
                <ListItemButton component="a" href="dashboards">
                  <ListItemIcon sx={{ fontSize: 20 }}>
                    <img width="100" src={logo} alt="eSafe" />
                  </ListItemIcon>
                </ListItemButton>
                <Divider />
                <ListItem
                  component="div"
                  onClick={handleUserProfile}
                  disablePadding
                >
                  <ListItemButton sx={{ height: 56 }}>
                    <ListItemIcon>
                      <Home color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary="User Profile"
                      primaryTypographyProps={{
                        color: "primary",
                        fontWeight: "medium",
                        variant: "body2",
                      }}
                    />
                  </ListItemButton>
                  <Tooltip title="Edit Profile">
                    <IconButton
                      size="large"
                      sx={{
                        "& svg": {
                          color:
                            mode === "dark" ? "rgba(255,255,255,0.8)" : null,
                          transition: "0.2s",
                          transform: "translateX(0) rotate(0)",
                        },
                        "&:hover, &:focus": {
                          bgcolor: "unset",
                          "& svg:first-of-type": {
                            transform: "translateX(-4px) rotate(-20deg)",
                          },
                          "& svg:last-of-type": {
                            right: 0,
                            opacity: 1,
                          },
                        },
                        "&:after": {
                          content: '""',
                          position: "absolute",
                          height: "80%",
                          display: "block",
                          left: 0,
                          width: "1px",
                          bgcolor: "divider",
                        },
                      }}
                    >
                      <VerifiedUser />
                      <ArrowRight
                        sx={{ position: "absolute", right: 4, opacity: 0 }}
                      />
                    </IconButton>
                  </Tooltip>
                </ListItem>
                <Divider />
                {mappedMenuItems?.map((menu) =>
                  menu?.navItems?.length > 0 ? (
                    <ListItemGroup key={menu?.category} menu={menu} />
                  ) : null
                )}
              </Box>
            </Drawer>
          )}
        </StyledNav>
      </Paper>
    </Box>
  );
}

const ListItemGroup = ({ menu }) => {
  const [open, setOpen] = React.useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  let secondaryText = `${menu?.navItems[0]?.text}...`;

  if (menu?.navItems?.length >= 3)
    secondaryText = `${menu?.navItems[0]?.text}, ${menu?.navItems[1]?.text}, ${menu?.navItems[2]?.text}, ...`;

  if (menu?.navItems?.length >= 2)
    secondaryText = `${menu?.navItems[0]?.text}, ${menu?.navItems[1]?.text}, ...`;

  const handleClick = (slug) => {
    // Reopen request
    if (slug === "/reopen-requests") {
      /** If the user is inside the reopen request page only set the status='pending' */
      if (slug === pathname) {
        setSearchParams((searchParams) => {
          searchParams.set("status", "pending");
          if (!searchParams.get("rowsPerPage")) {
            console.log("Not Available");
          }
          return searchParams;
        });
        return;
      }

      /** If the user is not inside the reopen request page set the status='pending' and navigate to it */
      setSearchParams((searchParams) => {
        searchParams.set("status", "pending");
        searchParams.set("rowsPerPage", 20);
        return searchParams;
      });
      navigate(`${slug}?status=pending`);
      return;
    }

    // Incidents
    if (slug === "/incidents") {
      if (slug === pathname) return;

      setSearchParams((searchParams) => {
        searchParams.delete("type");
        searchParams.set("limit", 100);
        return searchParams;
      });
      navigate(`${slug}?limit=${searchParams.get("limit")}`);
      return;
    }

    // Audit
    if (slug === "/audit") {
      if (slug === pathname) return;
    }

    // Default
    navigate(slug);
  };

  return (
    <Box
      sx={{
        // bgcolor: open ? "rgba(71, 98, 130, 0.2)" : null,
        pb: open ? 2 : 0,
      }}
    >
      <ListItemButton
        alignItems="flex-start"
        onClick={() => setOpen(!open)}
        sx={{
          px: 3,
          pt: 2.5,
          pb: open ? 0 : 2.5,
          "&:hover, &:focus": { "& svg": { opacity: open ? 1 : 0 } },
        }}
      >
        <ListItemText
          primary={menu?.category}
          primaryTypographyProps={{
            fontSize: 15,
            fontWeight: "medium",
            lineHeight: "20px",
            mb: "2px",
          }}
          secondary={secondaryText}
          secondaryTypographyProps={{
            noWrap: true,
            fontSize: 12,
            lineHeight: "16px",
            color: open ? "rgba(0,0,0,0)" : "rgba(255,255,255,0.5)",
          }}
          sx={{ my: 0 }}
        />
        <KeyboardArrowDown
          sx={{
            mr: -1,
            opacity: 0,
            transform: open ? "rotate(-180deg)" : "rotate(0)",
            transition: "0.2s",
          }}
        />
      </ListItemButton>
      {open &&
        menu?.navItems?.map((item, index) => (
          <ListItemButton
            key={`${item.text?.trim()}-${index}`}
            onClick={() => handleClick(item?.slug)}
            sx={{ py: 0, minHeight: 32, opacity: 0.8 }}
          >
            <ListItemIcon sx={{ color: "inherit" }}>{item.icon}</ListItemIcon>
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: "medium",
              }}
            />
          </ListItemButton>
        ))}
    </Box>
  );
};
