import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";

import TabButton from "./TabButton";

const TabButtons = ({ items }) => {
  const { pathname } = useLocation();
  const noItem = items?.length < 1;
  const [menuItems, setMenuItems] = useState(items);

  const page = pathname?.split("/")[1]; //Get the base pathname (page)

  const handleActive = () => {
    const modifiedItems = menuItems?.map((item) =>
      item?.slug === page
        ? { ...item, active: true }
        : { ...item, active: false }
    );

    setMenuItems(modifiedItems);
  };

  useEffect(() => {
    handleActive();
  }, []);

  if (noItem) return <Typography>No button is found!</Typography>;

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      {menuItems?.map((item) => (
        <TabButton key={item?.id} item={item} />
      ))}
    </Box>
  );
};

export default TabButtons;
