import { combineReducers } from "@reduxjs/toolkit";

import forensicsReducer from "./forensicsSlice";
import boardBuilderReducer from "./boardBuilderSlice";
import boardViewReducer from "./boardViewSlice";

const forensicReducer = combineReducers({
  forensicBoards: forensicsReducer,
  boardBuilder: boardBuilderReducer,
  boardView: boardViewReducer,
});

export default forensicReducer;
