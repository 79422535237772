import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createEndpointUserGroup,
  fetchEndpointUserGroup,
  removeEndpointUserGroup,
} from ".";

export const getEndpointUserGroup = createAsyncThunk(
  "endpoint-user/group",
  fetchEndpointUserGroup
);
export const addEndpointUserGroup = createAsyncThunk(
  "endpoint-user/add-group",
  createEndpointUserGroup
);

export const deleteEndpointUserGroup = createAsyncThunk(
  "endpoint-user/delete-group",
  removeEndpointUserGroup
);

const initialState = {
  data: [],
  currentUserGroup: 0,
  loading: false,
  error: null,
};
const endpointUserGroupSlice = createSlice({
  name: "endpoint-user-group",
  initialState,
  reducers: {
    setCurrentUserGroup: (state, action) => {
      state.currentUserGroup = action.payload;
    },
    clearEndpointUserGroupState: (state) => {
      state.data = [];
      state.currentUserGroup = 0;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEndpointUserGroup.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getEndpointUserGroup.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getEndpointUserGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(addEndpointUserGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = [...state.data, action.payload];
      state.currentUserGroup = action.payload.id;
    });

    builder.addCase(deleteEndpointUserGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = state.data.filter(
        (endpoint) => endpoint.id !== action.payload
      );
    });
  },
});

export const { setCurrentUserGroup, clearEndpointUserGroupState } =
  endpointUserGroupSlice.actions;
export default endpointUserGroupSlice.reducer;
