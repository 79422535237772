import React from "react";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material";

const CustomIconButton = ({ size = "large", onClick, children }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  return (
    <IconButton
      onClick={onClick}
      size={size}
      sx={{
        color:
          mode === "dark" ? theme.palette.grey[100] : theme.palette.grey[800],
        backgroundColor:
          mode === "dark"
            ? theme.palette.background.paper
            : theme.palette.grey[200],
        borderRadius: 2,
      }}
    >
      {children}
    </IconButton>
  );
};

export default CustomIconButton;
