import * as React from "react";
import Box from "@mui/material/Box";

import List from "@mui/material/List";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Typography, useTheme } from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import { useDispatch, useSelector } from "react-redux";

import Dropdown, { DropdownContent, DropdownTrigger } from "./dropdown";
import CustomIconButton from "../ui/CustomIconButton";
import Notification from "./notification";
import MarkAllAsRead from "./MarkAllAsRead";
import SkeletonLoading from "./SkeletonLoading";
import NotificationNotFound from "./NotificationNotFound";
import useAuth from "../../hooks/useAuth";
import useSocket from "../../hooks/useSocket";
import { getNotifications } from "../../redux/features/notifications/notificationSlice";
import { getReopenRequests } from "../../redux/features/reopen/reopenSlice";

export default function Notifications() {
  const { socket } = useSocket({ URL: process.env.REACT_APP_NOTIFICATION_URL });
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const { user } = useAuth();
  const theme = useTheme();
  const { data: notifications, loading } = useSelector(
    (state) => state.notifications
  );
  const unseenNotifications = notifications?.filter(
    (item) => item.status !== true
  );

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClickAway = (e) => {
    setAnchorEl(null);
    setOpen(false);
  };

  React.useEffect(() => {
    dispatch(getNotifications());
  }, []);

  React.useEffect(() => {
    if (!socket) return;

    socket.on("notificationsUpdated", (data) => {
      // Check if it is sent for the logged-in user
      if (user?.id === data?.userId) {
        // Notify user if there is an action other than 'save to board'
        if (data?.activity?.toLowerCase() !== "save to board") {
          dispatch(getNotifications());
        }

        // If the activity is 'reopen incident' re-fetch and update the reopen requests page and icon
        if (data?.activity?.toLowerCase() === "reopen incident") {
          dispatch(getReopenRequests({ status: "pending" }));
        }
      }
    });
  }, [socket]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Dropdown>
          <DropdownTrigger handleClick={handleClick}>
            <CustomIconButton>
              <Badge
                badgeContent={
                  notifications?.filter((item) => item.status === false)
                    ?.length || 0
                }
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: theme.palette.primary.light,
                  },
                }}
              >
                <NotificationsIcon sx={{ fontSize: "25px" }} />
              </Badge>
            </CustomIconButton>
          </DropdownTrigger>
          <DropdownContent id={id} open={open} anchorEl={anchorEl}>
            <NotificationHeader unseenNotifications={unseenNotifications} />

            <List sx={{ width: "100%", maxWidth: 480, minWidth: 480 }}>
              {loading ? (
                <SkeletonLoading />
              ) : (
                <>
                  {notifications?.length > 0 ? (
                    notifications?.map((item) => (
                      <Notification
                        key={item.id}
                        item={item}
                        handleClickAway={handleClickAway}
                      />
                    ))
                  ) : (
                    <NotificationNotFound />
                  )}
                </>
              )}
            </List>
          </DropdownContent>
        </Dropdown>
      </div>
    </ClickAwayListener>
  );
}

const NotificationHeader = ({ unseenNotifications }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Typography variant="h3" sx={{ fontWeight: "bold" }}>
        Notifications
      </Typography>
      <Box>
        {/* Show only if there is an unseen (unread) notification */}
        {unseenNotifications?.length < 1 ? null : <MarkAllAsRead />}
      </Box>
    </Box>
  );
};