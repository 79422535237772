import React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { useDispatch, useSelector } from "react-redux";
import { hideToast } from "../../redux/features/toast/toastSlice";

const Toast = () => {
  const { isOpen, type, message } = useSelector((state) => state.toast);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(hideToast());
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
        <AlertMessage
          onClose={handleClose}
          severity={type}
          sx={{ width: "100%" }}
        >
          {message}
        </AlertMessage>
      </Snackbar>
    </Stack>
  );
};

const AlertMessage = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default Toast;
