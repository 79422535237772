import React from "react";
import { useTheme } from "@mui/material";

import Wrapper from "./Wrapper";
import PerformanceChart from "./PerformanceChart";

const HistoricalPerformanceGraph = ({ performances }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  return (
    <Wrapper title="Historical Performance Graph">
      <PerformanceChart
        performances={performances}
        isDark={isDark}
        color={theme.palette.primary.light}
      />
    </Wrapper>
  );
};

export default HistoricalPerformanceGraph;
