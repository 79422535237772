export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const ROLES = {
  Operator: "Operator",
  Manager: "Manager",
};

// Object.freeze is used to make the enum immutable
export const STATUS = Object.freeze({
  Backlog: "Backlog",
  InProgress: "InProgress",
  Completed: "Completed",
});

// Elasticsearch index
export const INDEX = Object.freeze({
  INCIDENT_INDEX: "INCIDENT_INDEX",
  MAIN_INDEX: "MAIN_INDEX",
});

// Endpoints and Endpoint Users
export const assetsMenu = [
  { id: 1, label: "Endpoints", slug: "endpoints", active: false },
  { id: 2, label: "Endpoint Users", slug: "endpoint-users", active: false },
];

// Incidents and Responses
export const INCIDENT_RESPONSES = [
  // { id: 1, label: "Incidents", slug: "incidents", active: false },
  // { id: 2, label: "Tickets", slug: "tickets", active: false },
  // { id: 3, label: "forensics", slug: "forensics", active: false },
  { id: 4, label: "Actions", slug: "actions", active: false },
];

// Action Types
export const actions = Object.freeze({
  // Process actions
  KILL_PROCESS: 37,

  // File actions
  DELETE_FILE: 3,
  RENAME_FILE: 6,

  // User actions
  ENABLE_USER: 44,
  DISABLE_USER: 45,

  // Network actions

  // IP
  ALLOW_IP: 21,
  BLOCK_IP: 22,
  CLEAR_IP_RULES: 52,

  // URL
  ALLOW_DOMAIN: 49,
  BLOCK_DOMAIN: 50,
  CLEAR_DOMAIN_RULES: 51,

  // Registry actions
  CREATE_REGISTRY: 11,
  DELETE_REGISTRY: 12,
  SET_REGISTRY: 13,
  EDIT_REGISTRY: 48,

  // Host Actions
  SHUTDOWN_HOST: 40,
  RESTART_HOST: 47,
});

// Widget Content Width
export const contentWidth = Object.freeze({
  // Process actions
  BOXED: "boxed",
  FULL_WIDTH: "fullWidth",
});
