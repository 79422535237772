import axios from "axios";

import { BASE_URL } from "./constants";
import { store } from "../redux/app/store";
import { logOut } from "../redux/features/auth/authSlice";
import { showToast } from "../redux/features/toast";

/**
 * Interceptor function for Axios requests that adds an Authorization header if an access token is present in the localStorage.
 * @param {object} req - The request object intercepted by Axios.
 * @returns {object} - The modified request object with the Authorization header added, if an access token is present.
 */
const authInterceptor = (req) => {
  const accessToken = JSON.parse(localStorage.getItem("state"))?.auth?.token;

  // Check if the access token is available
  if (accessToken) {
    // Check if the access token is valid
    if (isTokenExpired(accessToken)) {
      console.log("Access token is expired");

      showToast({
        type: "error",
        message: "Your session has expired. Please log in again.",
      });
      store.dispatch(logOut());
      // Add your token refresh logic here
    } else {
      req.headers.Authorization = `Bearer ${accessToken}`;
    }
  }
  return req;
};

const isTokenExpired = (token) => {
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const currentTime = Date.now() / 1000; // in seconds

    return payload.exp < currentTime;
  } catch (e) {
    console.error("Failed to decode token", e);
    return true; // Assume token is expired if decoding fails
  }
};

export const publicRequest = axios.create({ baseURL: BASE_URL });
export const privateRequest = axios.create({ baseURL: BASE_URL });

privateRequest.interceptors.request.use(authInterceptor);