import React from "react";

import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoute = ({ roles }) => {
  const isAuthenticated = useSelector((state) => state.auth.token !== null);
  const userRole = useSelector((state) => state.auth?.user?.role);
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (roles && roles.length > 0 && !roles.includes(userRole)) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Outlet />;
};
export default ProtectedRoute;
