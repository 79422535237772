import React from "react";
import { Select as MuiSelect } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

const Select = (props) => {
  const { name, label, value, onChange, options } = props;
  
  return (
    <FormControl variant="outlined" sx={{ width: "100%", marginY: 1 }}>
      <InputLabel>{label}</InputLabel>
      {options.length > 0 && (
        <MuiSelect name={name} label={label} value={value} onChange={onChange}>
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.value}
            </MenuItem>
          ))}
        </MuiSelect>
      )}
    </FormControl>
  );
};

export default Select;
