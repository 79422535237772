import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

const ConfirmDialog = (props) => {
  const { confirm, setConfirm } = props;

  return (
    <Dialog open={confirm.isOpen}>
      <DialogTitle></DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <IconButton
          disableRipple
          sx={{
            width: "50px",
            height: "50px",
            backgroundColor: "error.main",
            padding: "2px",
            marginBottom: "5px",
          }}
        >
          <ContactSupportIcon />
        </IconButton>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {confirm?.title || "Are you sure you want to remove this item?"}
        </Typography>
        <Typography variant="subtitle2">
          {confirm?.subtitle || "This operation can't be undo."}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", marginBottom: 2 }}>
        <Button
          variant="contained"
          color="success"
          onClick={() => setConfirm({ ...confirm, isOpen: false })}
        >
          No
        </Button>
        <Button variant="contained" color="error" onClick={confirm.onConfirm}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
