import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const NotificationNotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "500px",
      }}
    >
      <Typography sx={{ maxWidth: 300, textAlign: "center" }}>
        You've read all your notifications from the last 30 days.
      </Typography>
    </Box>
  );
};
export default NotificationNotFound;
