import React from "react";
import useTheme from "../../hooks/useTheme";

const DarkModeIcon = () => {
  const theme = useTheme();
  const fill =
    theme?.palette?.mode === "dark" ? "white" : theme.palette.secondary.dark;

  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3344 3.75C7.73142 3.75 4 7.44365 4 12C4 16.5563 7.73142 20.25 12.3344 20.25C14.9517 20.25 17.2876 19.0548 18.8142 17.1888C19.0164 16.9417 19.0571 16.6016 18.9189 16.3145C18.7806 16.0275 18.488 15.8447 18.1668 15.8446C14.4852 15.8437 11.5009 12.8891 11.5009 9.24459C11.5009 7.67112 12.0561 6.22825 12.9839 5.09422C13.1861 4.8471 13.2268 4.50697 13.0885 4.21994C12.9503 3.9329 12.6577 3.75008 12.3364 3.75H12.3344Z"
        fill={fill}
      />
      <path
        d="M14.5938 7.66667C15.0597 7.66667 15.4375 7.29357 15.4375 6.83333C15.4375 6.3731 15.0597 6 14.5938 6C14.1278 6 13.75 6.3731 13.75 6.83333C13.75 7.29357 14.1278 7.66667 14.5938 7.66667Z"
        fill={fill}
      />
      <path
        d="M18.8125 12.6667C18.8125 13.1269 18.4347 13.5 17.9688 13.5C17.5028 13.5 17.125 13.1269 17.125 12.6667V11.8333H16.2812C15.8153 11.8333 15.4375 11.4602 15.4375 11C15.4375 10.5398 15.8153 10.1667 16.2812 10.1667H17.125V9.33333C17.125 8.8731 17.5028 8.5 17.9688 8.5C18.4347 8.5 18.8125 8.8731 18.8125 9.33333V10.1667H19.6562C20.1222 10.1667 20.5 10.5398 20.5 11C20.5 11.4602 20.1222 11.8333 19.6562 11.8333H18.8125V12.6667Z"
        fill={fill}
      />
    </svg>
  );
};

export default DarkModeIcon;
