import axios from "axios";

import { BASE_URL } from "../utils/constants";
import useAuth from "./useAuth";

const useSaveToAudit = ({ body }) => {
  const { token } = useAuth();

  const saveToAudit = async () => {
    try {
      const res = await axios.post(`${BASE_URL}/audit/log`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        return res;
      }
      if (res.status === 200) return res;
    } catch (e) {
      console.error(e);
    }
  };
  return { saveToAudit };
};

export default useSaveToAudit;
