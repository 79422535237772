import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";

import useAuth from "../../hooks/useAuth";
import {
  addComment,
  editComment,
} from "../../redux/features/incidents/incidentSlice";

const CommentForm = ({
  formState,
  setFormState,
  selectedComment,
  editMode,
  setEditMode,
}) => {
  const [showCommentButton, setShowCommentButton] = useState(false);
  const { incidentId } = useParams();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormState(e.target.value);
  };
  useEffect(() => {
    if (formState?.trim() !== "") {
      setShowCommentButton(true);
    } else {
      setShowCommentButton(false);
    }
  }, [formState]);

  const handleAddComment = (e) => {
    e.preventDefault();

    dispatch(addComment({ incidentId, formState }));

    setFormState("");
    setShowCommentButton(false);
  };

  const handleEditComment = async (e) => {
    e.preventDefault();

    dispatch(
      editComment({ commentId: selectedComment, incidentId, formState })
    );
    setEditMode(false);
    setFormState("");
    setShowCommentButton(false);
  };

  return (
    <Paper
      onSubmit={editMode ? handleEditComment : handleAddComment}
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <IconButton sx={{ p: "1px" }} aria-label="menu">
        <Avatar
          sx={{ width: 32, height: 32, backgroundColor: "primary.main" }}
          alt={user?.name}
          title={user?.name}
          color="primary.main"
          src="/static/images/avatar/1.jpg"
        />
      </IconButton>
      <InputBase
        sx={{
          ml: 1,
          flex: 1,
          height: "40px",
          mr: 1,
          mt: 1,
          overflow: "auto",
        }}
        value={formState}
        onChange={handleChange}
        placeholder="Write your comment ..."
        multiline
      />
      {showCommentButton ? (
        <Button
          onClick={editMode ? handleEditComment : handleAddComment}
          size="small"
          variant="contained"
          color="primary"
        >
          {editMode ? "Update" : "Comment"}
        </Button>
      ) : null}
    </Paper>
  );
};

export default CommentForm;
