import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import BaseWidget from "./BaseWidget";
import WidgetContent from "./WidgetContent";
import WidgetHeader from "./WidgetHeader";
import WidgetLoading from "./WidgetLoading";
import IncidentsByAssigneeChart from "../charts/IncidentsByAssigneeChart";
import { getIncidentsByAssignee } from "../../redux/features/dashboards/executive/incidentsByAssigneeSlice";
import { useDashboard } from "../../hooks/useDashboard";

const IncidentsByAssignee = () => {
  const { incidentsByAssignee } = useDashboard();
  const { loading } = incidentsByAssignee;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIncidentsByAssignee({ limit: 10 }));
  }, [dispatch]);

  return (
    <BaseWidget>
      <WidgetHeader title="Incidents by Operators" />
      {loading ? (
        <WidgetLoading />
      ) : (
        <WidgetContent>
          <IncidentsByAssigneeChart />
        </WidgetContent>
      )}
    </BaseWidget>
  );
};

export default IncidentsByAssignee;
