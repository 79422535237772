import React, { useEffect, useState } from "react";

import { useDashboard } from "../../hooks/useDashboard";
import { capitalize } from "../../utils/functions";
import ChartTemplate from "./ChartTemplate";

const IncidentsOverdueChart = ({ overdueDate }) => {
  const [assignee, setAssignee] = useState([]);
  const [value, setValue] = useState([]);
  const { incidentsOverdue } = useDashboard();
  const { incidents } = incidentsOverdue;

  useEffect(() => {
    const data = incidents[overdueDate];

    // Check if data is not undefined and filter out incidents without a user information
    const filtered = !!data
      ? data?.assignee?.filter((user) => user.name !== undefined)
      : [];
    setAssignee(filtered?.map((user) => capitalize(user?.name)));
    setValue(filtered?.map((user) => user.doc_count));
  }, [overdueDate, incidents]);

  const chartOptions = {
    series: [
      {
        name: "Incidents",
        data: value,
      },
    ],

    xaxis: {
      labels: {
        // rotate: -45,
      },
      categories: assignee,
      // tickPlacement: "on",
    },
  };

  return (
    <ChartTemplate
      options={chartOptions}
      series={chartOptions.series}
      notFoundMessage="There is no incident in this period."
    />
  );
};

export default IncidentsOverdueChart;
