import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";

const Rank = ({ value, icon, label, color = "info" }) => {
  return (
    <Paper>
      <Box
        sx={{ display: "flex", padding: 1, alignItems: "center", gap: "3px" }}
      >
        {icon}
        <Typography variant="h6">{value}</Typography>
        <Chip size="small" color={color} sx={{ ml: "auto" }} label={label} />
      </Box>
    </Paper>
  );
};

export default Rank;
