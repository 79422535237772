import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTheme } from "@mui/material";

function Modal(props) {
  const { title, modalOpen, setModalOpen, children, maxWidth = "sm" } = props;
  const theme = useTheme();
  const mode = theme.palette.mode;

  return (
    <Dialog
      open={modalOpen}
      fullWidth
      maxWidth={maxWidth}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor:
            mode === "dark" ? "#000" : theme.palette.background.paper,
        },
      }}
    >
      <DialogTitle
        sx={
          {
            // backgroundColor: "background.paper"
          }
        }
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Typography variant="h6" component="div">
            {title}
          </Typography>
          <Button variant="default" onClick={() => setModalOpen(false)}>
            Close
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}

export default Modal;
