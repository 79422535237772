import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import ActionIcon from "../actions/action-icon";
import { useTheme } from "@mui/material";
import { useDispatch } from "react-redux";

import { removeExtension } from "../../utils/functions";
import { sendPredefinedAction } from "../../redux/features/actions/actionSlice";
import Modal from "../ui/Modal";
import RenameFile from "../actions/RenameFile";

const ProcessNodeConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },

  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(90deg, rgba(199,219,198,1) 0%, rgba(198,199,198,1) 44%, rgba(180,199,186,1) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ProcessNodeWrapper = styled("div")(({ theme, ownerState }) => ({
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 60,
  display: "flex",
  borderRadius: "5px",
  borderTopRightRadius: "20px",
  justifyContent: "center",
  alignItems: "center",

  ...(ownerState.completed &&
    ownerState.verdict === 0 && {
      backgroundImage:
        "linear-gradient(90deg, rgba(199,219,198,1) 0%, rgba(198,199,198,1) 44%, rgba(180,199,186,1) 100%)",
    }),
  ...(ownerState.completed &&
    ownerState.verdict === 1 && {
      backgroundImage:
        "linear-gradient(90deg, rgba(40,77,255,1) 0%, rgba(83,94,246,1) 45%, rgba(59,144,251,1) 95%)",
      // "linear-gradient(90deg, rgba(73,237,69,1) 0%, rgba(9,121,69,1) 35%, rgba(0,255,72,1) 100%)",
    }),
  ...(ownerState.completed &&
    ownerState.verdict === 2 && {
      backgroundImage:
        "linear-gradient(90deg, rgba(182,93,88,1) 0%, rgba(255,110,110,1) 45%, rgba(182,93,88,1) 95%)",
      // "linear-gradient(90deg, rgba(237,69,81,1) 0%, rgba(121,12,9,1) 35%, rgba(255,0,14,1) 100%)",
    }),
}));

function ProcessNode(props) {
  const theme = useTheme();

  const { verdict, completed, className } = props;
  let color = "grey";
  if (verdict === 1) color = theme.palette.success.main;
  if (verdict === 2) color = theme.palette.error.main;

  return (
    <ProcessNodeWrapper
      ownerState={{ completed, verdict }}
      className={className}
      onClick={() => console.log(props)}
      style={{ background: color }}
    >
      <AccountTreeOutlinedIcon sx={{ width: 32, height: 32 }} />
    </ProcessNodeWrapper>
  );
}

export default function ProcessTree({ incident }) {
  const [currentProcess, setCurrentProcess] = React.useState(incident?.details?.processes ? incident?.details?.processes[0]:null);
  const handleSetProcess = (process) => {
    setCurrentProcess(process);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          // alignItems: "center",
        }}
      >
        <Stack sx={{ alignSelf: "center" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={10}
            connector={<ProcessNodeConnector />}
          >
            {incident?.details?.processes?.map((process) => (
              <Step key={process?.id}>
                <StepButton
                  onClick={() => {
                    handleSetProcess(process);
                  }}
                >
                  <StepLabel
                    StepIconProps={{
                      verdict: process?.verdict, // process?.verdict,
                    }}
                    StepIconComponent={ProcessNode}
                  >
                    {process?.name}
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Stack>
        <ProcessDetail
          currentProcess={currentProcess}
          hostname={incident?.info?.host}
          incidentId={incident?.info?.incident_id}
        />
      </Box>
      {/* <br /> */}
    </>
  );
}

const ProcessDetail = ({ currentProcess, hostname, incidentId }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [originalFile, setOriginalFile] = React.useState();
  const [renameTo, setRenameTo] = React.useState("");
  const dispatch = useDispatch();

  let body = {};

  const common = {
    incidentId: incidentId,
    targetHost: hostname,
  };

  // Action Params
  const processNameBody = {
    ...common,
    targetItem: currentProcess?.name,
    others: {
      processName: removeExtension(currentProcess?.name),
    },
  };
  const userBody = {
    ...common,
    targetItem: currentProcess?.userName,
    others: {
      user: currentProcess?.userName,
    },
  };
  const fileBody = {
    ...common,
    targetItem: currentProcess?.imagePath,
    others: {},
  };

  const handleSendAction = (menu) => {
    // Process actions - kill Process
    if (menu?.action === 37) body = processNameBody;

    // User actions - enable or disable user
    if (menu?.action === 44 || menu?.action === 45) body = userBody;

    // File actions
    // Delete File
    if (menu?.action === 3) {
      fileBody.others = {
        path: currentProcess?.imagePath,
        type: "trash", // trash || erase
      };
      body = fileBody;
    }

    // Rename file
    if (menu?.action === 6) {
      setOriginalFile(currentProcess?.imagePath);
      // Open rename modal
      setModalOpen(true);
    }

    // Append action code and name
    body = {
      ...body,
      action: menu?.action, //Action code
      name: menu?.actionType, // Action name
    };

    // Send action for all actions except for rename action
    if (menu?.action !== 6) {
      dispatch(sendPredefinedAction({ body }));
    }
  };

  const handleSendRenameAction = () => {
    const renameBody = {
      incidentId: incidentId,
      targetHost: hostname,
      action: 6,
      name: "renameFile",
      others: {
        from: currentProcess?.imagePath,
        to: renameTo,
      },
    };

    dispatch(sendPredefinedAction({ body: renameBody }));
  };

  return (
    <>
      <Paper sx={{}}>
        <Card variant="outlined" sx={{ height: "100%", position: "sticky" }}>
          <CardContent
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Info
              label="Process Name"
              value={currentProcess?.name}
              handleSendAction={handleSendAction}
              hasAction={true}
            />
            <Info label="Verdict" value={currentProcess?.verdict} />
            <Info
              label="Username"
              value={currentProcess?.userName}
              handleSendAction={handleSendAction}
              hasAction={true}
            />
            <Info
              label="Image Path"
              value={currentProcess?.imagePath}
              handleSendAction={handleSendAction}
              hasAction={true}
            />
          </CardContent>
        </Card>
      </Paper>
      <Modal
        title="Rename File"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <RenameFile
          originalFile={originalFile}
          renameTo={renameTo}
          setRenameTo={setRenameTo}
          onSubmit={handleSendRenameAction}
          setModalOpen={setModalOpen}
        />
      </Modal>
    </>
  );
};

const Info = ({ label, value, hasAction, handleSendAction }) => {
  return (
    <Box
    // sx={{ display: "flex", gap: 1 }}
    >
      <Typography sx={{ color: "gray" }}>{label}: </Typography>
      <Box sx={{ display: "flex" }}>
        <Typography>{value}</Typography>
        {hasAction ? (
          <ActionIcon targetItem={value} handleSendAction={handleSendAction} />
        ) : null}
      </Box>
    </Box>
  );
};
