import React from "react";
import { io } from "socket.io-client";

const useSocket = ({ URL }) => {
  const [socket, setSocket] = React.useState();

  React.useEffect(() => {
    const newSocket = io(URL);
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
      console.log("Disconnected");
      newSocket.off();
      console.log("Socket disconnected");
    };
  }, []);

  React.useEffect(() => {
    if (!socket) return;

    socket.on("connect", async () => {
      console.log("Connected");
    });

    socket.on("disconnect", () => {
      console.log("Disconnected");
    });
  }, [socket]);

  return { socket };
};

export default useSocket;
