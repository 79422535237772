import * as React from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import dayjs from "dayjs";

import EventItem from "./EventItem";

export default function EventList({ events, open, setOpen }) {
  const theme = useTheme();
  const { data } = events;
  const isDark = theme.palette.mode === "dark";
  const grey600 = theme.palette.grey[600];

  const tempEvents = data?.map((event) => {
    const process = [];
    event["proccess.creationtime"]?.forEach((item, index) => {
      process[index] = {
        creationTime: item,
        verdict: event["proccess.verdict"][index],
        imagePath: event["proccess.imagePath"][index],
        imageHash: event["proccess.imageHash"][index],
      };
    });

    const formatted = {
      id: event.id,
      host: {
        name: event.hostName,
        ip: event.hostIp,
      },
      type: event.type,
      os: event.os,
      user: event.user,
      sessionUser: event.sessionUser,
      createdAt: dayjs(event.timestamp).format("MMM DD, YYYY HH:mm"),
      process,
    };

    // Host
    if (event.childProcess) formatted["childProcess"] = event.childProcess;

    // File
    if (event.registry) formatted["registry"] = event.registry;
    if (event.filename) formatted["fileName"] = event.filename;
    if (event.fileHash) formatted["fileHash"] = event.fileHash;

    // Network
    if (event.network) formatted["network"] = event.network;

    return formatted;
  });

  if (data?.length < 1) {
    return <Typography>No response yet.</Typography>;
  }

  return (
    <TableContainer
      // component={Paper}
      sx={{
        "&.MuiPaper-root": {
          backgroundColor: isDark ? "black" : "white",
        },
        "@media print": {
          // display: "none",
          // background: "red",
        },
      }}
    >
      <Table
        size="small"
        aria-label="collapsible table"
        stickyHeader
        sx={{
          "& .MuiTableRow-root": {
            borderBottom: isDark ? `2px solid #181818` : `2px solid #fafafa`,
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell sx={{ color: grey600 }}>Event ID</TableCell>
            <TableCell sx={{ color: grey600 }}>Host</TableCell>
            <TableCell sx={{ color: grey600 }}>Type</TableCell>
            <TableCell sx={{ color: grey600 }}>User</TableCell>
            <TableCell sx={{ color: grey600 }}>Creation Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tempEvents?.map((event) => (
            <EventItem
              key={event.id}
              event={event}
              open={open}
              setOpen={setOpen}
              // isOpen={true}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
