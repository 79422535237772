import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import IncidentList from "./incident-list";
import ErrorState from "./ErrorState";
import Skeleton from "../Skeleton";
import { getUnassignedHighIncidents } from "../../../redux/features/tickets/unassigned-by-severity/unassignedHighSlice";
import { getUnassignedMediumIncidents } from "../../../redux/features/tickets/unassigned-by-severity/unassignedMediumSlice";
import { getUnassignedLowIncidents } from "../../../redux/features/tickets/unassigned-by-severity/unassignedLowSlice";

const UnassignedIncidentsBySeverity = ({ severity }) => {
  if (severity === "High") return <UnassignedHighIncidents />;
  if (severity === "Medium") return <UnassignedMediumIncidents />;
  if (severity === "Low") return <UnassignedLowIncidents />;
};

// TODO: Try to refactor this if there is a better way to do this without repeating the same logic. High - Medium - Low

const UnassignedHighIncidents = () => {
  const [page, setPage] = useState(1);
  const [limit] = useState(7);
  const { incidents, loading, error } = useSelector(
    (state) => state.tickets.unassignedBySeverity.High
  );
  const dispatch = useDispatch();

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    dispatch(getUnassignedHighIncidents({ severity: "High", limit, page }));
  }, [limit]);

  if (loading) return <Skeleton />;
  if (error) return <ErrorState message={error} />;

  return (
    <IncidentList
      incidents={incidents}
      total={incidents?.length || 0}
      limit={limit}
      page={page}
      onPageChange={handlePageChange}
    />
  );
};

const UnassignedMediumIncidents = () => {
  const [page, setPage] = useState(1);
  const [limit] = useState(7);
  const dispatch = useDispatch();
  const { incidents, loading, error } = useSelector(
    (state) => state.tickets.unassignedBySeverity.Medium
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    dispatch(getUnassignedMediumIncidents({ severity: "Medium", limit, page }));
  }, [limit]);

  if (loading) return <Skeleton />;
  if (error) return <ErrorState message={error} />;

  return (
    <IncidentList
      incidents={incidents}
      total={incidents?.length || 0}
      limit={limit}
      page={page}
      onPageChange={handlePageChange}
    />
  );
};

const UnassignedLowIncidents = () => {
  const [page, setPage] = useState(1);
  const [limit] = useState(7);
  const dispatch = useDispatch();
  const { incidents, loading, error } = useSelector(
    (state) => state.tickets.unassignedBySeverity.Low
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    dispatch(getUnassignedLowIncidents({ severity: "Low", limit, page }));
  }, [limit]);

  if (loading) return <Skeleton />;
  if (error) return <ErrorState message={error} />;

  return (
    <IncidentList
      incidents={incidents}
      total={incidents?.length || 0}
      limit={limit}
      page={page}
      onPageChange={handlePageChange}
    />
  );
};

export default UnassignedIncidentsBySeverity;
