import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Header from "../../components/Header";
import BoardBuilderComponent from "../../components/forensics/board-builder";

const BoardBuilder = () => {
  return (
    <Box
      m="21px"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      {/* Header */}
      <Grid container>
        <Grid item xs={12}>
          <Header
            title="Board Builder Page"
            subtitle={`Welcome to the Board Builder page!`}
          />
        </Grid>
        <Grid item xs={12}>
          <BoardBuilderComponent />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BoardBuilder;
