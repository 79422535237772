import React from "react";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material";

import AssignEngineer from "./AssignEngineer";
import FormatDescription from "./FormatDescription";
import useAuth from "../../../../hooks/useAuth";

const IncidentList = ({ incidents, total, limit, page, onPageChange }) => {
  const rowsPerPage = 5;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <List>
        {incidents
          ?.slice(
            (page - 1) * rowsPerPage,
            (page - 1) * rowsPerPage + rowsPerPage
          )
          .map(({ incident }) => (
            <Item incident={incident} key={incident?.incident_id} />
          ))}
      </List>
      <Pagination
        count={Math.ceil(total / limit)}
        size="small"
        page={page}
        onChange={onPageChange}
      />
    </Box>
  );
};

const Item = ({ incident }) => {
  const theme = useTheme();
  const {
    user: { role },
  } = useAuth();

  return (
    <ListItem
      key={incident?.incident_id}
      alignItems="flex-start"
      sx={{
        background: theme.palette.background.paper,
        border: `1px solid ${
          theme.palette.mode === "dark" ? "#202020" : "#ddd"
        }`,
        borderRadius: 2,
        overflowX: "hidden",
        "&:hover": { background: theme.palette.background.default },
        mb: 1,
        paddingBlock: 0,
        paddingInline: 1,
      }}
    >
      <ListItemText
        primary={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Link
              color={"primary"}
              size="small"
              component={RouterLink}
              sx={{
                fontStyle: "italic",
                fontSize: 10,
                textDecoration: "none",
                "&:hover": { textDecoration: "underline" },
              }}
              to={`/incidents/${incident.incident_id}`}
            >
              {incident?.incident_id}
            </Link>
            {role?.toLowerCase() !== "operator" ? (
              <AssignEngineer incidentId={incident?.incident_id} />
            ) : null}
          </Box>
        }
        secondary={
          <React.Fragment>
            <FormatDescription value={incident?.description} />
          </React.Fragment>
        }
      />
    </ListItem>
  );
};
export default IncidentList;
