import React from "react";
import CastConnectedIcon from "@mui/icons-material/CastConnected";
import { IconButton, Tooltip } from "@mui/material";

const LiveTerminalTrigger = ({ onClick }) => {
  return (
    <Tooltip title="Live Terminal">
      <IconButton onClick={onClick}>
        <CastConnectedIcon />
      </IconButton>
    </Tooltip>
  );
};

export default LiveTerminalTrigger;
