import { combineReducers } from "@reduxjs/toolkit";
import endpointListReducer from "./endpointSlice";
import endpointGroupReducer from "./endpointGroupSlice";

const endpointReducer = combineReducers({
  group: endpointGroupReducer,
  list: endpointListReducer,
});

export default endpointReducer;
