import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchIncidentInsights } from ".";

export const getIncidentInsightData = createAsyncThunk(
  "tickets/incident-insights",
  fetchIncidentInsights
);

const initialState = {
  incidents: {
    FILE: {},
    HOST: {},
    NETWORK: {},
    USER: {},
  },
  loading: false,
  error: null,
};

const incidentInsightSlice = createSlice({
  name: "incident-insight",
  initialState,
  reducers: {
    clearIncidentInsightState: (state) => {
      state.incidents.FILE = {};
      state.incidents.HOST = {};
      state.incidents.NETWORK = {};
      state.incidents.USER = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIncidentInsightData.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getIncidentInsightData.fulfilled, (state, action) => {
        state.incidents = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(getIncidentInsightData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { clearIncidentInsightState } = incidentInsightSlice.actions;
export default incidentInsightSlice.reducer;
