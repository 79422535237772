import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchResolvedIncidentsByAssignee } from "./index";

export const getResolvedIncidentsByAssignee = createAsyncThunk(
  "incidents/resolved-by-assignee",
  fetchResolvedIncidentsByAssignee
);

const initialState = {
  assignees: [],
  resolvedIncidents: [],
  loading: false,
  error: null,
};

const resolvedIncidentsByAssignee = createSlice({
  name: "resolved-incidents-by-assignee",
  initialState,
  reducers: {
    clearResolvedIncidentsByAssigneeState: (state) => {
      state.assignees = [];
      state.resolvedIncidents = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getResolvedIncidentsByAssignee.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getResolvedIncidentsByAssignee.fulfilled,
      (state, action) => {
        const { assignees, resolvedIncidents } = action.payload;
        state.loading = false;
        state.error = "";
        state.assignees = assignees;
        state.resolvedIncidents = resolvedIncidents;
      }
    );
    builder.addCase(
      getResolvedIncidentsByAssignee.rejected,
      (state, action) => {
        state.loading = false;
        state.incidents = {};
        state.error = action.error.message;
      }
    );
  },
});

export const { clearResolvedIncidentsByAssigneeState } =
  resolvedIncidentsByAssignee.actions;
export default resolvedIncidentsByAssignee.reducer;
