import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import BoardInformation from "./BoardInformation";
import QueryForm from "./QueryForm";
import { getBoardInformation } from "./../../../redux/features/forensics/boardBuilderSlice";

const BoardBuilder = () => {
  const [showBoardViewButton, setShowBoardViewButton] = useState(false);
  const { boardId } = useParams();
  const { info: data, loading } = useSelector(
    (state) => state.forensics.boardBuilder
  );
  const dispatch = useDispatch();

  useEffect(() => {
    //Make sure the board information is ready before getting it
    setTimeout(() => {
      dispatch(getBoardInformation({ boardId }));
    }, [500]);
  }, []);

  // TODO: improve this
  if (loading) return <Typography>Loading...</Typography>;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <BoardInformation
        data={data}
        showBoardViewButton={showBoardViewButton}
        setShowBoardViewButton={setShowBoardViewButton}
      />
      <QueryForm
        data={data}
        showBoardViewButton={showBoardViewButton}
        setShowBoardViewButton={setShowBoardViewButton}
      />
    </Box>
  );
};

export default BoardBuilder;
