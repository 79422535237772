import { useSelector } from "react-redux";

/**
 * A custom hook that provides access to the authenticated user and token from the Redux store.
 *
 * @returns {Object} An object containing the user and token.
 */
const useAuth = () => {
  const user = useSelector((state) => state?.auth?.user);
  const token = useSelector((state) => state?.auth?.token);

  return {
    user,
    token,
  };
};

export default useAuth;
