import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Header from "../../components/Header";
import ResolutionSummaries from "../../components/incident-resolution-summaries/ResolutionSummaries";

const IncidentResolutionSummaries = () => {
  return (
    <Box
      m="21px"
      sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}
    >
      {/* Header */}
      <Grid container>
        <Grid item xs={12}>
          <Header
            title="Incident Resolution Summaries"
            subtitle={`Welcome to the incident resolution summaries page!`}
          />
        </Grid>
        <ResolutionSummaries />
      </Grid>
    </Box>
  );
};
export default IncidentResolutionSummaries;
