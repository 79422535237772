import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {
  splitStringByCommaAndConvertToUniqueArray,
  getSlugFromString,
} from "../../../utils/functions";
import { addEndpointUserGroup } from "../../../redux/features/endpoint-users/endpointUserGroupSlice";
import AddGroupButton from "./AddGroupButton";
import UserGroupForm from "./UserGroupForm";
import Modal from "../../ui/Modal";

const AddUserGroup = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [recruitingRule, setRecruitingRule] = useState("");
  const [users, setUsers] = useState(""); //Users in a group
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleShowEditModal = () => {
    setModalOpen(true);
  };

  const createGroup = () => {
    const formattedUsers = splitStringByCommaAndConvertToUniqueArray(users);
    const body = {
      group_name: name,
      description,
      recruitingRule,
      users: formattedUsers,
    };

    dispatch(addEndpointUserGroup({ body }));

    const slug = getSlugFromString(name);
    navigate(`/endpoint-users/group/${slug}`, { replace: true });
  };

  return (
    <>
      <Box>
        <AddGroupButton onClick={handleShowEditModal} />
      </Box>

      <Modal
        title="Create User Group"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <>
          <UserGroupForm
            setModalOpen={setModalOpen}
            ButtonLabel="Add Group"
            name={name}
            setName={setName}
            description={description}
            setDescription={setDescription}
            recruitingRule={recruitingRule}
            setRecruitingRule={setRecruitingRule}
            users={users}
            setUsers={setUsers}
            setError={setError}
            onSubmit={createGroup}
          />
          <Typography color="error">{error && error}</Typography>
        </>
      </Modal>
    </>
  );
};

export default AddUserGroup;
