import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import PageWrapper from "../../components/PageWrapper";
import Audit from "../../components/users/user-details/Audit";
import RankByType from "../../components/users/user-details/RankByType";
import PersonalInfo from "../../components/users/user-details/PersonalInfo";
import IncidentsByType from "../../components/users/user-details/IncidentsByType";
import HistoricalPerformanceGraph from "../../components/users/user-details/HistoricalPerformanceGraph";
import { getUserDetails } from "../../redux/features/users/userSlice";
import { compareString, isObjectEmpty } from "../../utils/functions";
import { ROLES } from "../../utils/constants";
import NoUserFound from "../../components/users/NoUserFound";

const UserDetails = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, loading } = useSelector((state) => state.users.user);

  useEffect(() => {
    dispatch(getUserDetails({ userId }));
  }, [userId]);

  if (loading) return <Loading />;

  // No user is found
  if (isObjectEmpty(data)) {
    return <NoUserFound />;
  }

  // Don't allow operators to see other users'
  if (compareString(data?.role, ROLES.Operator) && data?.id !== +userId) {
    navigate(`/dashboards/operator`, { replace: true });
  }

  const user = { ...data, role: data && data?.roles && data?.roles[0]?.name };

  return (
    <PageWrapper>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12} md={9}>
            <Grid container spacing={1} sx={{ minHeight: "100%" }}>
              <Grid item xs={12}>
                <PersonalInfo user={user} />
              </Grid>
              <Grid item xs={12}>
                <IncidentsByType user={user} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <Audit />
          </Grid>
        </Grid>
        {!!compareString(
          user && user?.roles && user?.roles[0]?.name,
          ROLES.Operator
        ) ? (
          <Grid container spacing={1} sx={{ mt: ".1rem" }}>
            <Grid item xs={12} md={9}>
              <HistoricalPerformanceGraph
                performances={user?.operatorsPerformance}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <RankByType
                rank={user?.operatorsRank && user?.operatorsRank[0]}
              />
            </Grid>
          </Grid>
        ) : null}
      </Box>
    </PageWrapper>
  );
};

const Loading = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Typography>Loading...</Typography>
    </Box>
  );
};
export default UserDetails;
