import React from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import DeleteGroupButton from "./DeleteGroupButton";
import EditGroup from "./EditGroup";
import EditUserGroup from "./EditUserGroup";
import { useTheme } from "@mui/material";

const GroupDetails = ({ data, type }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  return (
    <Box
      sx={{
        border: `1px solid ${isDark ? "#2D3342" : "#dedede"}`,
        p: "18px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontSize: "16px",
          fontWeight: 600,
          color: "#05F2AF",
          textTransform: "uppercase",
        }}
      >
        Group Details
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Detail title="Group Name" value={data?.groupName} />
        <Detail title="Group Description" value={data?.description} />
        <Detail title="Recruiting Rule" value={data?.recruitingRule} />
        <Actions groupId={data?.id} type={type} />
        <Box></Box>
      </Box>
    </Box>
  );
};

const Detail = ({ title, value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingBlock: "24px",
      }}
    >
      <Typography variant="h5" sx={{ fontSize: "15px", fontWeight: 400 }}>
        {title}
      </Typography>
      <Typography sx={{ fontSize: "12px", color: "#C7C7C7" }}>
        {value}
      </Typography>
    </Box>
  );
};

const Actions = ({ groupId, type }) => {
  const { pathname } = useLocation();
  const isAll = pathname === "/endpoints";

  return (
    <Box
      sx={{
        paddingBlock: "24px",
        textAlign: "center",
      }}
    >
      <Typography>Actions</Typography>
      {!isAll ? (
        <Box
          sx={{
            display: "flex",
          }}
        >
          {!!groupId ? (
            <DeleteGroupButton groupId={groupId} type={type} />
          ) : null}
          {!!groupId ? (
            <>
              {type === "host" ? <EditGroup /> : null}{" "}
              {type === "user" ? <EditUserGroup /> : null}
            </>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default GroupDetails;
