import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { compareString } from "../../../utils/functions";
import Form from "../../ui/Form";
import Controls from "../../ui/controls";

const RegistryForm = ({
  onSubmit,
  onClose,
  name,
  setName,
  value,
  setValue,
  path,
  mode = "create",
}) => {
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    if (name !== "") setError(null);
    if (event.target.name === "name") {
      setName(event.target.value);
    }
    if (event.target.name === "value") {
      setValue(event.target.value);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (name === "") {
      setError(`${mode === "set" ? "Property" : "Registry"} name is required.`);
      return;
    }
    onSubmit();
    handleClose();
  };

  const clearForm = () => {
    setName("");
    setValue("");
  };
  const handleClose = () => {
    clearForm();
    onClose(); // close registry modal
  };

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controls.TextInputField
            multiline
            name="name"
            label={mode === "set" ? "Property Name" : "Registry Name"}
            value={name}
            onChange={handleInputChange}
          />
          {error && (
            <Typography variant="subtitle2" color="error">
              {error}
            </Typography>
          )}
          <Controls.TextInputField
            multiline
            name="path"
            label="Path"
            value={path}
            disabled
            // onChange={handleInputChange}
          />
          <Controls.TextInputField
            multiline
            name="value"
            label="Value"
            value={value}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        <Button
          onClick={handleClick}
          type="submit"
          variant="contained"
          color="primary"
        >
          {compareString(mode, "create") ? "Create" : null}
          {compareString(mode, "edit") ? "Update" : null}
          {compareString(mode, "set") ? "Set" : null}
        </Button>
        <Button variant="contained" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
      </Box>
    </Form>
  );
};
export default RegistryForm;
