import { combineReducers } from "@reduxjs/toolkit";

import newestIncidentsReducer from "./newestIncidentsSlice";
import incidentsByAssigneeReducer from "./incidentsByAssigneeSlice";
import incidentsByStatusReducer from "./incidentsByStatusSlice";
import incidentsOvertimeReducer from "./incidentsOvertimeSlice";
import incidentsOverdueReducer from "./incidentsOverdueSlice";
import incidentsStatusBoardReducer from "./incidentsStatusBoardSlice";
import resolvedIncidentsByAssigneeReducer from "./resolvedIncidentsByAssigneeSlice";
import incidentsBySeverityReducer from "./incidentsBySeveritySlice";
import resolvedIncidentsMTTRReducer from "./resolvedIncidentsMTTRSlice";
import topHostsByIncidentReducer from "./TopHostsByIncidentSlice";

const executiveDashboardReducer = combineReducers({
  newestIncidents: newestIncidentsReducer,
  incidentsByAssignee: incidentsByAssigneeReducer,
  incidentsByStatus: incidentsByStatusReducer,
  incidentsOvertime: incidentsOvertimeReducer,
  incidentsOverdue: incidentsOverdueReducer,
  incidentsStatusBoard: incidentsStatusBoardReducer,
  resolvedIncidentsByAssignee: resolvedIncidentsByAssigneeReducer,
  incidentsBySeverity: incidentsBySeverityReducer,
  resolvedIncidentsMTTR: resolvedIncidentsMTTRReducer,
  topHostsByIncident: topHostsByIncidentReducer,
});

export default executiveDashboardReducer;
