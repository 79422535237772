import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import BlurCircularIcon from "@mui/icons-material/BlurCircular";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, styled, useTheme } from "@mui/material";
import dayjs from "dayjs";

import Loading from "./Loading";
import NoDataFound from "./NoDataFound";
import GroupDetails from "./groups/GroupDetails";
import EndpointUserSidebar from "./groups/EndpointUserSidebar";
import RemoveRow from "./RemoveRow";
import { deleteEndpointUser } from "../../redux/features/endpoint-users/endpointUserSlice";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.mode === "dark" ? "#252b36" : null,
  },
  td: {
    border: 0,
  },
}));

export default function EndpointUsersTable() {
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const [rowsPerPage, setRowsPerPage] = useState(
    +searchParams.get("rowsPerPage") || 10
  );

  const { data, loading } = useSelector((state) => state.endpointUsers.list);

  const { matchedUsers: rows, ...rest } = data;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSearchParams((newSearchParams) => {
      newSearchParams.set("rowsPerPage", parseInt(event.target.value, 10));
      return newSearchParams;
    });
    setPage(0);
  };

  useEffect(() => {
    setSearchParams((newSearchParams) => {
      newSearchParams.set("rowsPerPage", rowsPerPage);
      return newSearchParams;
    });
  }, []);

  useEffect(() => {
    setRowsPerPage(+searchParams.get("rowsPerPage") || 10);
  }, [searchParams]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <Grid container columns={16} sx={{ height: "100%" }}>
        <Grid item xs={16} md={3}>
          <EndpointUserSidebar />
        </Grid>

        <Grid
          item
          xs={16}
          md={13}
          sx={{
            minHeight: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              minHeight: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
              marginInline: 2,
              // background: "black",
            }}
          >
            <GroupDetails data={rest} type="user" />
            {loading ? (
              <Loading />
            ) : (
              <Box
                sx={{
                  border: `1px solid ${isDark ? "#2D3342" : null}`,
                  flex: 1,
                }}
              >
                {rows?.length < 1 || rowsPerPage < 1 ? (
                  <NoEndpointUserFound />
                ) : (
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750, width: "100%" }}
                      aria-labelledby="tableTitle"
                      size={dense ? "small" : "medium"}
                    >
                      <EnhancedTableHead />
                      <TableBody>
                        {rows
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <Row
                              key={row.endpoint_user}
                              row={row}
                              groupId={rest?.id}
                            />
                          ))}
                        {emptyRows > 0 && (
                          <TableRow
                            style={{
                              height: (dense ? 33 : 53) * emptyRows,
                            }}
                          >
                            <TableCell colSpan={12} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[1, 5, 10, 25, 100]}
        component="div"
        count={rows?.length}
        rowsPerPage={+rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

const EnhancedTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell rowSpan={2}></TableCell>
        <TableCell rowSpan={2}>Name</TableCell>
        <TableCell rowSpan={2}>Domain</TableCell>
        <TableCell rowSpan={2}>Frequent Host</TableCell>
        <TableCell rowSpan={2}>Status</TableCell>
        <TableCell rowSpan={2}>IP</TableCell>
        <TableCell rowSpan={2}>OS</TableCell>
        <TableCell rowSpan={2}>Last Incident Date</TableCell>
        <TableCell rowSpan={2} align="center">
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const Row = ({ row, groupId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDeleteRow = () => {
    const body = {
      user: row?.name,
      endpoint_user: row?.endpoint_user,
    };

    dispatch(deleteEndpointUser({ groupId, body }));
  };

  return (
    <StyledTableRow
      hover
      // onClick={(event) => handleClick(event, row.endpoint_user)}
      // role="checkbox"
      tabIndex={-1}
      //   sx={{ cursor: "pointer" }}
    >
      <TableCell padding="checkbox"></TableCell>
      <TableCell>
        <Box
          onClick={() => {
            navigate(`/endpoint-users/${encodeURI(row.endpoint_user)}`);
          }}
          sx={{
            fontWeight: 600,
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
          }}
          size="small"
          variant="text"
        >
          {row.name}
        </Box>
      </TableCell>
      <TableCell>{row.domain}</TableCell>
      <TableCell>{row.frequent_endpoint_hostname}</TableCell>
      <TableCell>Connected</TableCell>
      <TableCell>{row.ip[row.ip.length - 1]}</TableCell>
      <TableCell>{row.os.name}</TableCell>
      <TableCell>
        {row.last_incident_date &&
          dayjs(row.last_incident_date).format("MMM DD, YYYY HH:mm")}
      </TableCell>

      <TableCell align="center">
        <RemoveRow type="User" onClick={handleDeleteRow} />
      </TableCell>
    </StyledTableRow>
  );
};

const NoEndpointUserFound = () => {
  return (
    <NoDataFound>
      <NoDataFound.Message>
        <Typography variant="h4" textAlign="center">
          Oops! There's no Endpoint User available.
        </Typography>
      </NoDataFound.Message>
    </NoDataFound>
  );
};