import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Person from "@mui/icons-material/Person";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";

import ResolutionSummaryResult from "../../components/incidents/resolution-summary/ResolutionSummaryResult";
import IncidentResolutionSummary from "../../components/incidents/resolution-summary";
import IncidentRemainingTime from "../../components/incidents/IncidentRemainingTime";
import CreateTicketForm from "../../components/tickets/CreateTicketForm";
import IncidentItems from "../../components/incidents/IncidentItems";
import SeverityBadge from "../../components/ui/SeverityBadge";
import Comments from "../../components/incidents/Comments";
import {
  capitalize,
  compareString,
  isObjectEmpty,
} from "../../utils/functions";
import useIncident from "../../hooks/useIncident";
import Modal from "../../components/ui/Modal";
import useAuth from "../../hooks/useAuth";
import {
  changeIncidentName,
  getIncident,
  sendReopenRequest,
  starIncident,
} from "../../redux/features/incidents/incidentSlice";
import DropdownPopper from "../../components/ui/DropdownPopper";
import { ROLES, STATUS } from "../../utils/constants";
import ReAssignIncident from "../../components/incidents/re-assign";
import Loading from "../../components/incidents/Loading";

const IncidentDetails = () => {
  const { getIncidentById } = useIncident();
  const { incident, resolutionSummary, loading } = getIncidentById;
  const questions = getIncidentById?.resolutionSummaryQuestions;
  const [resolutionModalOpen, setResolutionModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [reassignModalOpen, setReassignModalOpen] = useState(false);
  const [currentIncident, setCurrentIncident] = useState(false);
  const { incidentId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { role, id: userId } = user;

  const severity = incident?.info?.severity;
  const ticketStatus = incident?.info?.ticket_status;
  const mttr = incident?.info?.mttr;
  const mttr_end_time = incident?.info?.mttr_end_time;
  const remark = incident?.info?.remark;
  const engineer_assigned = incident?.info?.engineer_assigned;
  const engineer_id = incident?.info?.engineer_id;
  const description = incident?.summaries?.description;
  const root_cause = incident?.summaries?.root_cause;
  const host = incident?.summaries?.impact?.hosts[0]?.host;
  const hostUser = incident?.summaries?.impact?.hosts[0]?.user;

  useEffect(() => {
    dispatch(getIncident({ incidentId }));
  }, [dispatch]);

  const handleAssignEngineer = ({ incident, reassign }) => {
    const {
      info: {
        incident_id,
        engineer_id,
        ticket_status,
        engineer_assigned,
        remark,
      },
    } = incident;
    setCurrentIncident({
      incident_id,
      engineer_id,
      ticket_status,
      engineer_assigned,
      remark,
    });
    if (reassign) {
      setReassignModalOpen(true);
    } else {
      setModalOpen(true);
    }
  };

  const handleGoToForensics = () => {
    navigate(`/forensics?incidentId=${incidentId}`);
  };

  if (loading) return <Loading />;

  if (isObjectEmpty(incident)) return <NoIncidentFound />;

  return (
    <>
      <Box p="21px">
        <Grid container spacing={1}>
          {/* Info section */}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <Card variant="outlined" sx={{ height: "100%" }}>
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <SeverityBadge riskClass={severity} />
                      <Star incident={incident} />
                      <IncidentName incident={incident} />
                      <Typography color="gray">ID</Typography>{" "}
                      <Typography>{incidentId}</Typography>
                      <Box sx={{ ml: "auto" }}>
                        {!compareString(ticketStatus, STATUS.Completed) ? (
                          <IncidentRemainingTime
                            endDate={
                              !compareString(ticketStatus, STATUS.Completed)
                                ? mttr_end_time
                                : 0
                            }
                          />
                        ) : null}
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <HostCount value={1} />
                      <Divider orientation="vertical" />
                      <UserCount value={1} />

                      {remark ? <Remark remark={remark} /> : null}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {/*  */}
              <Grid item xs={12} md={4}>
                <Card variant="outlined" sx={{ height: "100%" }}>
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <Typography color="gray">Assignee</Typography>{" "}
                      {compareString(role, ROLES.Operator) ? (
                        <Typography>{capitalize(engineer_assigned)}</Typography>
                      ) : (
                        <Link
                          component="button"
                          variant="body2"
                          sx={{ fontWeight: "bold" }}
                          onClick={() =>
                            handleAssignEngineer({ incident, reassign: false })
                          }
                        >
                          {capitalize(engineer_assigned)}
                        </Link>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      {!loading && (
                        <>
                          <Typography color="gray">Status</Typography>
                          {userId !== engineer_id ? (
                            <Typography>{ticketStatus}</Typography>
                          ) : (
                            <>
                              {compareString(ticketStatus, STATUS.Completed) ? (
                                <Typography variant="body1">
                                  Completed
                                </Typography>
                              ) : (
                                <StatusSelect
                                  setResolutionModalOpen={
                                    setResolutionModalOpen
                                  }
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Typography color="gray">MTTR</Typography>
                      <Typography>{mttr}</Typography>
                      {/* {} */}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      {engineer_assigned !== "Unassigned" &&
                      compareString(ticketStatus, STATUS.InProgress) === true &&
                      (engineer_id === user?.id ||
                        compareString(user?.role, ROLES.Manager)) ? (
                        <Link
                          component="button"
                          variant="body2"
                          sx={{ fontWeight: "bold" }}
                          onClick={handleGoToForensics}
                        >
                          Go to Forensics
                        </Link>
                      ) : null}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      {compareString(ticketStatus, STATUS.Completed) &&
                      engineer_id === user?.id ? (
                        <ReOpenIncident />
                      ) : null}
                    </Box>
                    <Box>
                      <ReAssignIncident
                        ticketStatus={ticketStatus}
                        role={user?.role}
                      >
                        <Link
                          component="button"
                          variant="body2"
                          sx={{ fontWeight: "bold" }}
                          onClick={() =>
                            handleAssignEngineer({
                              incident,
                              reassign: true,
                            })
                          }
                        >
                          Reassign Incident
                        </Link>
                      </ReAssignIncident>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          {/* Summary Section */}
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <Grid container spacing={1}>
                  <SummaryBox
                    title="Description"
                    value={description}
                    columnWidth={4}
                  />
                  <SummaryBox title="Root Cause" value={root_cause} />
                  <SummaryBox title="Impact">
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Typography color="gray" variant="body2">
                        Host:
                      </Typography>
                      <Typography variant="body2">{host}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Typography color="gray" variant="body2">
                        User:
                      </Typography>
                      <Typography variant="body2">{hostUser}</Typography>
                    </Box>
                  </SummaryBox>
                  <SummaryBox title="Auto Remediation" value="None" />
                  <SummaryBox title="Further Action" value="None" />
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Artifacts,Process Tree and Assets */}
          <Grid item xs={12} sm={6} lg={8} sx={{ minHeight: 400 }}>
            <Card variant="outlined" sx={{ height: "100%" }}>
              <CardContent>
                {loading ? (
                  <Typography variant="h5">Loading...</Typography>
                ) : (
                  <IncidentItems incident={incident && incident} />
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* Comment Section */}
          <Grid item xs={12} sm={6} lg={4}>
            <Card
              variant="outlined"
              sx={{ height: "100%", position: "sticky" }}
            >
              <CardContent>
                {ticketStatus === "Completed" ? (
                  <CustomTabs>
                    <>
                      {loading ? (
                        <Typography variant="h5">Loading...</Typography>
                      ) : (
                        <Comments status={ticketStatus} />
                      )}
                    </>

                    <Box
                      sx={{
                        height: "100%",
                        maxHeight: "21rem",
                        overflowY: "auto",
                      }}
                    >
                      <Typography variant="h5">
                        Incident Resolution Summary
                      </Typography>
                      {resolutionSummary && resolutionSummary.length > 1 ? (
                        <ResolutionSummaryResult
                          questions={questions}
                          answers={resolutionSummary}
                        />
                      ) : (
                        <Typography>No resolution summary found.</Typography>
                      )}
                    </Box>
                  </CustomTabs>
                ) : (
                  <>
                    {loading ? (
                      <Typography variant="h5">Loading...</Typography>
                    ) : (
                      <Comments status={ticketStatus} />
                    )}
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Modal
        title="Create Ticket"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <CreateTicketForm
          setModalOpen={setModalOpen}
          incidentId={incidentId}
          selected={currentIncident}
        />
      </Modal>
      <Modal
        title="Reassign Incident"
        modalOpen={reassignModalOpen}
        setModalOpen={setReassignModalOpen}
      >
        <CreateTicketForm
          setModalOpen={setReassignModalOpen}
          incidentId={incidentId}
          selected={currentIncident}
          reAssign={true}
        />
      </Modal>

      <Modal
        title="Incident Resolution Summary"
        modalOpen={resolutionModalOpen}
        setModalOpen={setResolutionModalOpen}
      >
        <IncidentResolutionSummary
          incident={incident}
          setResolutionModalOpen={setResolutionModalOpen}
        />
      </Modal>
    </>
  );
};

const IncidentName = () => {
  const { incidentId } = useParams();
  const dispatch = useDispatch();
  const { getIncidentById } = useIncident();
  const { incident, incidentName } = getIncidentById;
  const [name, setName] = useState(incidentName);
  const [isEditingMode, setIsEditingMode] = useState(false);

  const userId = incident?.info?.engineer_id;
  const { user: loggedInUser } = useAuth();
  const canUpdateIncidentName =
    userId === loggedInUser?.id ||
    compareString(loggedInUser?.role, ROLES.Manager);

  const handleAddName = async (e) => {
    e.preventDefault();

    if (name?.trim() === "") {
      console.log("Please provide a name");
      return;
    }

    dispatch(changeIncidentName({ incidentId, name }));
    setIsEditingMode(false);
  };

  // Return if the user is not a manager or its own incident
  if (!canUpdateIncidentName) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          height: 40,
        }}
      >
        <Typography color="grey">Name</Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {name ? name : "Add incident name"}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: 40,
      }}
    >
      {isEditingMode ? (
        <Box
          component="form"
          onSubmit={handleAddName}
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="Incident Name"
            value={name || ""}
            onChange={(event) => {
              setName(event.target.value);
            }}
            size="small"
            placeholder="Add incident name"
            sx={{
              fontStyle: "italic",
              borderColor: "transparent",
              border: "none",
            }}
          />
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Button
            size="large"
            sx={{
              color: "gray",
              fontStyle: "italic",
              textTransform: "capitalize",
            }}
            onClick={() => setIsEditingMode(true)}
          >
            {name ? name : "Add incident name"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

const Star = ({ incident }) => {
  const { user } = useAuth();
  const initialStarValue = incident?.info?.star?.includes(user?.id);
  const [star, setStar] = useState(initialStarValue);
  const { incidentId } = useParams();
  const dispatch = useDispatch();

  const toggleStar = async (starred) => {
    dispatch(starIncident({ incidentId, starred, userId: user.id }));

    setStar(starred);
  };

  const handleAddStar = () => {
    toggleStar(true);
  };

  const handleRemoveStar = () => {
    toggleStar(false);
  };

  return star ? (
    <IconButton onClick={handleRemoveStar}>
      <StarIcon color="warning" />
    </IconButton>
  ) : (
    <IconButton onClick={handleAddStar}>
      <StarOutlineIcon />
    </IconButton>
  );
};

const ReOpenIncident = () => {
  const [reason, setReason] = useState("");
  const { incidentId } = useParams();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null); //Dropdown popper state
  const [formError, setFormError] = useState("");

  const body = {
    reason,
  };
  const handleSendRequest = (e) => {
    e.preventDefault();
    if (reason?.trim() === "") {
      setFormError("Reason is required. Please provide your reason.");
      console.log("Please provide your reason");
      return;
    }

    dispatch(sendReopenRequest({ incidentId, body }));
    setAnchorEl(null); //Close popper
    setReason("");
  };

  const handleChange = (e) => {
    setReason(e.target.value);
  };
  const trigger = (
    <Link component="button" variant="body2" sx={{ fontWeight: "bold" }}>
      Re-open Incident
    </Link>
  );

  return (
    <DropdownPopper
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      triggerComponent={trigger}
    >
      <Box
        sx={{
          minHeight: 120,
          display: "flex",
          flexDirection: "column",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          padding: 1,
          borderRadius: 1,
        }}
      >
        <Box>
          <TextField
            size="small"
            multiline
            rows={2}
            variant="standard"
            name="reason"
            label="Reason"
            value={reason}
            onChange={handleChange}
            helperText={formError}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
          <Button
            size="small"
            variant="contained"
            color="success"
            onClick={handleSendRequest}
          >
            Send Request
          </Button>
        </Box>
      </Box>
    </DropdownPopper>
  );
};

const HostCount = ({ value = 1 }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
      }}
    >
      <DesktopWindowsIcon sx={{ color: theme.palette.primary.main }} /> {value}
    </Box>
  );
};

const UserCount = ({ value = 1 }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
      }}
    >
      <Person sx={{ color: theme.palette.primary.main }} /> {value}
    </Box>
  );
};

const Remark = ({ remark }) => {
  return (
    <Box sx={{ marginLeft: "10px" }}>
      <Typography
        variant="body2"
        sx={{
          color: "gray",
          textTransform: "uppercase",
        }}
      >
        Manager's Remark
      </Typography>
      <Typography>{remark}</Typography>
    </Box>
  );
};

const NoIncidentFound = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/incidents?limit=100`, { replace: true });
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "80vh",
      }}
    >
      <Typography variant="h2">Oops!</Typography>
      <Typography variant="h5" sx={{ mb: 2 }}>
        There is no incident with this ID.
      </Typography>
      <Button onClick={handleClick} variant="contained">
        Go to Incidents!
      </Button>
    </Box>
  );
};

function StatusSelect({ setResolutionModalOpen }) {
  const { getIncidentById } = useIncident();
  const { incident } = getIncidentById;

  let ticketStatus = incident.info.ticket_status;

  if (ticketStatus === "In Progress") ticketStatus = "InProgress";

  const handleStatusChange = async (e) => {
    setResolutionModalOpen(true);
  };

  if (compareString(ticketStatus, STATUS.Backlog)) {
    return <Typography>Backlog</Typography>;
  }

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <Select
        id="incident-id"
        value={ticketStatus}
        onChange={handleStatusChange}
        label="Status"
      >
        {/* <MenuItem value={"Backlog"}>Backlog</MenuItem> */}
        <MenuItem
          disabled={compareString(ticketStatus, STATUS.Completed)}
          value={"InProgress"}
        >
          In Progress
        </MenuItem>
        <MenuItem value={"Completed"}>Completed</MenuItem>
      </Select>
    </FormControl>
  );
}

function SummaryBox({ title, value, columnWidth, children }) {
  const theme = useTheme();
  return (
    <Grid item xs={12} sm={6} md={4} lg={columnWidth || 2}>
      <Paper
        sx={{
          minHeight: "100%",
          borderRadius: 0,
          backgroundColor: theme.palette.mode === "dark" ? "black" : null,
        }}
      >
        <Typography
          color="primary.main"
          variant="body2"
          sx={{ paddingInline: 1, fontWeight: "bold" }}
          noWrap
        >
          {title?.toUpperCase()}
        </Typography>
        <Divider />
        {children ? (
          <Box sx={{ padding: 1 }}>{children}</Box>
        ) : (
          <Typography sx={{ padding: 1 }}>{value}</Typography>
        )}
      </Paper>
    </Grid>
  );
}

function CustomTabs({ children }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange} aria-label="Comment tabs">
        <Tab disableRipple sx={{ px: 0 }} label="Comments" />
        <Tab disableRipple sx={{ px: 0 }} label="Incident Resolution Summary" />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        {children[0]}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {children[1]}
      </CustomTabPanel>
    </>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default IncidentDetails;
