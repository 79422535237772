import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "dark",
  themeColor: "themeThree",
};

export const themeModeSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setThemeMode: (state, action) => {
      state.mode = action.payload;
    },
    changeColorScheme: (state, action) => {
      state.themeColor = action.payload;
    },
  },
});

export const { setThemeMode, changeColorScheme } = themeModeSlice.actions;

export default themeModeSlice.reducer;
