import dayjs from "dayjs";

/* Add a leading zero for numbers that are < 10*/
const addLeadingZero = (value, numberLength = 2) => {
  return String(value).padStart(numberLength, "0");
};

export const getRemainingTime = (endDate) => {
  const endDateDayJs = dayjs(endDate);
  const now = dayjs();

  return {
    seconds: getRemainingSeconds(now, endDateDayJs),
    minutes: getRemainingMinutes(now, endDateDayJs),
    hours: getRemainingHours(now, endDateDayJs),
    days: getRemainingDays(now, endDateDayJs),
  };
};

export const isIncidentOverdue = (endDate) => {
  const endDateDayJs = dayjs(endDate);
  const now = dayjs();

  const overdue =
    +getRemainingSeconds(now, endDateDayJs) ||
    +getRemainingMinutes(now, endDateDayJs) ||
    +getRemainingHours(now, endDateDayJs) ||
    +getRemainingDays(now, endDateDayJs);

  return overdue === 0 ? true : false;
};

const getRemainingSeconds = (now, endDate) => {
  const seconds = endDate.diff(now, "seconds") % 60;
  return seconds > 0 ? addLeadingZero(seconds) : "00";
};
const getRemainingMinutes = (now, endDate) => {
  const minutes = endDate.diff(now, "minutes") % 60;
  return minutes > 0 ? addLeadingZero(minutes) : "00";
};
const getRemainingHours = (now, endDate) => {
  const hours = endDate.diff(now, "hours") % 24;
  return hours > 0 ? addLeadingZero(hours) : "00";
};
const getRemainingDays = (now, endDate) => {
  const days = endDate.diff(now, "days");
  return days > 0 ? addLeadingZero(days) : "00";
};

export const getMTTREndDate = (value, timeOption) => {
  switch (timeOption) {
    case "minutes":
      return value * 1000 * 60;
    case "hours":
      return value * 1000 * 60 * 60;
    case "days":
      return value * 1000 * 60 * 60 * 24;
    case "weeks":
      return value * 1000 * 60 * 60 * 24 * 7;
    case "months":
      return value * 1000 * 60 * 60 * 24 * 30;

    default:
      return value;
  }
};

export const formatTimeAgo = (date) => {
  const formatter = new Intl.RelativeTimeFormat(undefined, {
    numeric: "auto",
  });

  const DIVISIONS = [
    { amount: 60, name: "seconds" },
    { amount: 60, name: "minutes" },
    { amount: 24, name: "hours" },
    { amount: 7, name: "days" },
    { amount: 4.34524, name: "weeks" },
    { amount: 12, name: "months" },
    { amount: Number.POSITIVE_INFINITY, name: "years" },
  ];

  let duration = (date - new Date()) / 1000;

  for (let i = 0; i < DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }
};

export const capitalize = (str) => {
  if (typeof str !== "string") return str;
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export function getHumanReadableValue(millisecond) {
  const millisecondValue = Math.abs(millisecond);
  const secValue = millisecondValue / 1000;
  const minValue = Math.round(secValue) / 60;
  const hoursValue = Math.round(minValue) / 60;
  const daysValue = Math.round(hoursValue) / 24;
  const monthsValue = Math.round(daysValue) / 30;

  let result;
  if (Math.floor(monthsValue) > 0) {
    const month = `${Math.floor(monthsValue)}mon`;
    result = month;
  } else if (Math.floor(daysValue) > 0) {
    result = `${Math.floor(daysValue)}d`;
  } else if (Math.floor(hoursValue) > 0) {
    result = `${Math.floor(hoursValue)}h`;
  } else if (Math.floor(minValue) > 0) {
    result = `${Math.floor(minValue)}m`;
  } else {
    result = `${secValue}sec`;
  }

  return result;
}

export function EpochTimeToHumanReadableTime(
  epochTime,
  format = "YYYY-MM-DD HH:mm:ss"
) {
  // Convert epoch time to milliseconds
  const milliseconds = epochTime * 1000;

  // Use Day.js to format the date
  return dayjs(milliseconds).format(format);
}

export function compareString(input, status) {
  if (input === undefined || input === null) return;
  if (typeof input !== "string" && typeof status !== "string") return;

  // Case-insensitive comparison
  return input?.toLowerCase() === status?.toLowerCase();
}

export function sortObjectByKey(obj) {
  const keys = Object.keys(obj);
  keys.sort();

  const sortedObj = {};
  for (const key of keys) {
    sortedObj[key] = obj[key];
  }

  return sortedObj;
}

export function isObjectEmpty(object) {
  return JSON.stringify(object) === "{}";
}

export function arrayFromObjectKeys(object, capitalizeString = false) {
  if (!object) return;
  if (capitalizeString) {
    return Object.keys(object)?.map((key) => capitalize(key));
  }
  return Object.keys(object);
}

export function arrayFromObjectValues(object) {
  return Object.values(object);
}

/**
 * Rounds a number to two decimal places.
 *
 * @param {number} num The number to be rounded.
 * @returns {number} The rounded number.
 */
export function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

/**
 * Generates a slug from a given string value.
 *
 * @param {string} value The string value to generate a slug from.
 * @returns {string} The generated slug.
 */
export function getSlugFromString(value) {
  return value
    .toLowerCase()
    .split(" ")
    .join("-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}

/**
 * Converts a string value into an array of unique values separated by commas.
 *
 * @param {string} value The string value to be converted.
 * @returns {array} An array of unique values extracted from the input string, separated by commas.
 */
export function splitStringByCommaAndConvertToUniqueArray(value) {
  const formattedValues = value
    ?.split(",")
    .map((value) => (value !== "" ? value.trim() : null)) //remove unnecessary spaces
    .filter((value) => value); //remove empty elements (e.g. "", null)

  return [...new Set(formattedValues)]; //Make it unique and return
}

/**
 * Removes the file extension from a given string.
 *
 * @param {string} str - The string from which to remove the extension.
 * @returns {string} The input string without the file extension.
 *
 * @example
 * removeExtension("example.txt"); // returns "example"
 * removeExtension("file.with.multiple.extensions.txt"); // returns "file.with.multiple.extensions"
 * removeExtension("noExtension"); // returns "noExtension"
 * removeExtension(""); // returns ""
 * removeExtension(null); // returns null
 * removeExtension(undefined); // returns undefined
 */
export function removeExtension(str) {
  return str?.split(".").slice(0, -1).join(".");
}

/**
 * Converts a hexadecimal color code to an RGBA color string.
 *
 * @param {string} hex - The hexadecimal color code to convert. It should start with a '#' symbol and be followed by 6 hexadecimal characters.
 * @param {number} [alpha=1] - The alpha (transparency) value for the RGBA color. It should be a number between 0 and 1. The default value is 1.
 * @returns {string} - The RGBA color string representation of the input hexadecimal color code.
 *
 * @example
 * hexToRgba('#ff0000'); // returns 'rgba(255, 0, 0, 1)'
 * hexToRgba('#00ff00', 0.5); // returns 'rgba(0, 255, 0, 0.5)'
 */
export function hexToRgba(hex, alpha = 1) {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  if (!r || !g || !b) {
    console.error("Invalid hexadecimal color code. It should start with a '#' symbol and be followed by 6 hexadecimal characters.");
    return hex
  }

  if (alpha < 0 || alpha > 1) {
    console.error("Alpha value should be a number between 0 and 1.");
    return hex

  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
