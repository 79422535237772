import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchUnassignedIncidentsBySeverity } from "..";

export const getUnassignedHighIncidents = createAsyncThunk(
  "tickets/unassigned_high",
  fetchUnassignedIncidentsBySeverity
);

const initialState = {
  incidents: [],
  loading: false,
  error: null,
};
const unassignedHighSlice = createSlice({
  name: "unassigned_high",
  initialState,
  reducers: {
    clearUnassignedHighState: (state) => {
      state.incidents = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnassignedHighIncidents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUnassignedHighIncidents.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.incidents = action.payload;
      })
      .addCase(getUnassignedHighIncidents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearUnassignedHighState } = unassignedHighSlice.actions;
export default unassignedHighSlice.reducer;
