import React from "react";

import ActionList from "../../components/actions";

const Actions = () => {
  return (
    <>
      <ActionList />
    </>
  );
};

export default Actions;
