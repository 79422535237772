import { privateRequest } from "../../../utils/apiRequest";
import { capitalize, getSlugFromString } from "../../../utils/functions";

export const fetchEndpointUsers = async ({ groupId = 0 }) => {
  const isAll = !groupId || groupId === 0; // Check if the selected group is All
  const endpoint = isAll
    ? `/endpoint-users`
    : `/endpoint-users/group/${groupId}`;

  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    // If the user not selected a group (selected 'All')
    if (isAll) {
      return {
        id: 0,
        groupName: "All",
        description: "All Users",
        recruitingRule: "No rule",
        matchedUsers: res.data,
      };
    }

    // If the user selected a specific group
    return {
      ...res.data,
      groupItems: res?.data?.matchedUsers?.map((user) => user?.name),
    };
  }
};

export const removeEndpointUser = async ({ groupId, body }) => {
  const endpoint = `/endpoint-users/users/${groupId}`;
  const res = await privateRequest.delete(endpoint, { data: body });

  if (res.status === 200) {
    return body;
  }
};

export const fetchEndpointUserDetails = async ({ endpointUser }) => {
  const endpoint = `/endpoint-users/${endpointUser}`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return res.data;
  }
};

export const fetchEndpointUserGroup = async () => {
  const endpoint = `/endpoint-users/user/groups`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    const group = res?.data?.map((item) => ({
      id: item.id,
      name: capitalize(item?.group_name),
      slug: getSlugFromString(item?.group_name),
    }));

    return group;
  }
};

export const createEndpointUserGroup = async ({ body }) => {
  const endpoint = `/endpoint-users/group`;
  const res = await privateRequest.post(endpoint, body);

  if (res.status === 201) {
    const group = {
      id: res?.data.id,
      name: capitalize(res?.data?.group_name),
      slug: getSlugFromString(res?.data?.group_name),
    };

    return group;
  }
};

export const editEndpointUserGroup = async ({ body, groupId }) => {
  const endpoint = `/endpoint-users/group/${groupId}`;
  const res = await privateRequest.put(endpoint, body);

  if (res.status === 200) {
    const updatedGroup = {
      id: groupId,
      groupName: body?.group_name,
      description: body?.description,
      recruitingRule: body?.recruiting_rule,
      groupItems: body?.users,
    };

    return updatedGroup;
  }
};

export const removeEndpointUserGroup = async ({ groupId }) => {
  const endpoint = `/endpoint-users/group/${groupId}`;
  const res = await privateRequest.delete(endpoint);

  if (res.status === 200) {
    return groupId;
  }
};