import { useMemo } from "react";
import { createTheme } from "@mui/material";
import { useSelector } from "react-redux";
import themes from "../themes";

const useTheme = () => {
  const currentTheme = useSelector((state) => state.themeMode?.themeColor);
  const mode = useSelector((state) => state.themeMode.mode) || "dark";

  const theme = useMemo(() => {
    if (currentTheme === "default") {
      return createTheme(themes.defaultTheme(mode));
    } else if (currentTheme === "themeOne") {
      return createTheme(themes.themeOne(mode));
    } else if (currentTheme === "themeTwo") {
      return createTheme(themes.themeTwo(mode));
    } else if (currentTheme === "themeThree") {
      return createTheme(themes.themeThree(mode));
    } else {
      return createTheme(themes.defaultTheme(mode));
    }
  }, [mode, currentTheme]);
  return theme;
};

export default useTheme;
