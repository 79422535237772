import React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";

const EndpointTable = ({ endpoints }) => {
  const { mode } = useSelector((state) => state.themeMode);

  return (
    <TableContainer component={Paper} sx={{ marginBlock: 1 }}>
      <Table
        size="small"
        sx={{ minWidth: 650 }}
        aria-label="endpoint summary report table "
      >
        <TableHead sx={{ background: mode === "dark" ? "#030303" : "#eaeaea" }}>
          <TableRow sx={{ "&>": { textAlign: "center" } }}>
            <TableCell rowSpan={2}>Host Name</TableCell>
            <TableCell align="center" colSpan={3}>
              File
            </TableCell>
            <TableCell align="center" colSpan={3}>
              Host
            </TableCell>
            <TableCell align="center" colSpan={3}>
              Network
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              Users
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">H</TableCell>
            <TableCell align="center">M</TableCell>
            <TableCell align="center">L</TableCell>

            <TableCell align="center">H</TableCell>
            <TableCell align="center">M</TableCell>
            <TableCell align="center">L</TableCell>

            <TableCell align="center">H</TableCell>
            <TableCell align="center">M</TableCell>
            <TableCell align="center">L</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {endpoints?.map((endpoint) => (
            <EndpointRow key={endpoint.host_name} endpoint={endpoint} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const EndpointRow = ({ endpoint }) => {
  return (
    <StyledTableRow>
      <TableCell component="th" scope="row" sx={{}}>
        {endpoint.host_name?.toUpperCase()}
      </TableCell>

      <TableCell align="center">{endpoint?.file?.high}</TableCell>
      <TableCell align="center">{endpoint?.file?.medium}</TableCell>
      <TableCell align="center">{endpoint?.file?.low}</TableCell>

      <TableCell align="center">{endpoint?.host?.high}</TableCell>
      <TableCell align="center">{endpoint?.host?.medium}</TableCell>
      <TableCell align="center">{endpoint?.host?.low}</TableCell>

      <TableCell align="center">{endpoint?.network?.high}</TableCell>
      <TableCell align="center">{endpoint?.network?.medium}</TableCell>
      <TableCell align="center">{endpoint?.network?.low}</TableCell>
      <TableCell align="center">
        <EndpointUsers users={endpoint?.users} />
      </TableCell>
    </StyledTableRow>
  );
};

const EndpointUsers = ({ users }) => {
  return (
    <Box sx={{ display: "flex", gap: 0.5, flexWrap: "wrap" }}>
      {users?.map((user) => (
        <Chip
          key={user}
          label={user}
          color="info"
          sx={{ fontSize: 8, fontWeight: "bold", padding: 0 }}
          size="small"
        />
      ))}
    </Box>
  );
};
export default EndpointTable;
