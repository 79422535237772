import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";

const NoDataFound = ({ children }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  return (
    <Box
      sx={{
        width: "100%",
        // padding: 3,
      }}
    >
      <Paper
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 3,
          minHeight: "20rem",
          mb: 2,
          p: 2,
          boxShadow: !isDark && "none",
          background: "transparent",
        }}
      >
        {children}
      </Paper>
    </Box>
  );
};

const Message = ({ children }) => {
  return <>{children}</>;
};

const Link = ({ onClick, children }) => {
  return (
    <Button
      variant="contained"
      sx={{ textTransform: "inherit" }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

NoDataFound.Message = Message;
NoDataFound.Link = Link;

export default NoDataFound;
