import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Form from "../ui/Form";
import Controls from "../ui/controls";
import { getMTTREndDate } from "../../utils/functions";
import { getOperators } from "../../redux/features/users/operatorsSlice";
import { createTicket } from "../../redux/features/incidents/incidentSlice";

const timeOptions = [
  { id: "minutes", value: "Minute(s)" },
  { id: "hours", value: "Hour(s)" },
  { id: "days", value: "Day(s)" },
  { id: "weeks", value: "Week(s)" },
  { id: "months", value: "Month(s)" },
];

const CreateTicketFormAlt = ({ incidentId, setAnchorEl }) => {
  const [operators, setOperators] = useState([]);
  const [operator, setOperator] = useState();
  const [mttr, setMttr] = useState(5);
  const [remark, setRemark] = useState("");
  const [timeOption, setTimeOption] = useState(timeOptions[0]);
  const { operators: allOperators, loading } = useSelector(
    (state) => state.users.operators
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOperators());
  }, [dispatch]);

  /** Get list of operators from state */
  useEffect(() => {
    const formatted = allOperators.map((operation) => ({
      id: operation.id,
      value: operation.name,
    }));
    setOperators(formatted);
  }, []);

  const handleUpdate = (e) => {
    e.preventDefault();

    const remaining = getMTTREndDate(mttr, timeOption?.id);

    let body = {
      engineer_id: operator?.id,
      engineer_assigned: operator?.value,
      mttr_end_time: Number(new Date().getTime() + remaining),
      mttr: mttr + timeOption?.id, // For the redux state only
      remark: remark,
    };

    dispatch(createTicket({ incidentId, body }));

    // Hide the popper
    setAnchorEl(null);
  };

  return (
    <Form>
      <Grid spacing={2} container sx={{ padding: 2 }}>
        <Grid item xs={12}>
          {operators?.length > 0 ? (
            <Controls.AutoComplete
              label="Assignee"
              options={operators}
              propertyName="value"
              state={operator}
              setState={setOperator}
              // defaultValue={operators[0]}
            />
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="number"
            size="small"
            variant="standard"
            name="meanTimeToResolve"
            label="Time to Resolve"
            value={mttr}
            onChange={(e) => setMttr(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Controls.AutoComplete
            label="Time Options"
            options={timeOptions}
            propertyName="value"
            state={timeOption}
            setState={setTimeOption}
            defaultValue={timeOptions[0]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            variant="standard"
            name="remark"
            label="Remark"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        <Button
          onClick={handleUpdate}
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          disabled={loading}
          sx={{ marginRight: 2 }}
        >
          Assign
        </Button>
        {/* <Button variant="contained" color="inherit" onClick={handleClose}>
          Close
        </Button> */}
      </Box>
    </Form>
  );
};

export default CreateTicketFormAlt;
