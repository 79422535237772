import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchUserUnResolvedIncidentsByType } from "..";

export const getUserOverdueIncidentsByType = createAsyncThunk(
  "user/overdue-incidents-by-type",
  fetchUserUnResolvedIncidentsByType
);

const initialState = {
  incidents: {},
  loading: false,
  error: null,
};

export const userOverdueIncidentsByTypeSlice = createSlice({
  name: "user-overdue-incidents-by-type",
  initialState,
  reducers: {
    clearUserOverdueIncidentsByTypeState: (state) => {
      state.incidents = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // User Info
    builder.addCase(getUserOverdueIncidentsByType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getUserOverdueIncidentsByType.fulfilled,
      (state, action) => {
        state.loading = false;
        state.incidents = action.payload;
      }
    );
    builder.addCase(getUserOverdueIncidentsByType.rejected, (state, action) => {
      state.loading = false;
      state.incidents = {};
      state.error = action.error.message;
    });
  },
});

export const { clearUserOverdueIncidentsByTypeState } =
  userOverdueIncidentsByTypeSlice.actions;

export default userOverdueIncidentsByTypeSlice.reducer;
