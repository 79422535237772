import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import BaseWidget from "./BaseWidget";
import WidgetHeader from "./WidgetHeader";
import { getNewestIncidents } from "../../redux/features/dashboards/executive/newestIncidentsSlice";
import { useDashboard } from "../../hooks/useDashboard";
import IncidentTable from "./IncidentTable";

export default function NewIncidentsWidget() {
  const { newestIncidents } = useDashboard();
  const { incidents, loading } = newestIncidents;

  const formattedIncidents = incidents.map((incident) => ({
    incident_id: incident._id,
    severity: incident._source.severity,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNewestIncidents());
  }, [dispatch]);

  return (
    <BaseWidget>
      <WidgetHeader title="Latest Top 10 Incidents" />
      <IncidentTable incidents={formattedIncidents} loading={loading} />
    </BaseWidget>
  );
}
