import { Typography, useTheme } from "@mui/material";

const FormatDescription = ({ value }) => {
  const theme = useTheme();
  const mapped = value?.split(" ");
  const process = mapped[2];
  const actionType = mapped[6];
  const host = mapped[8];

  return (
    <Typography
      sx={{ display: "inline", color: theme.palette.grey[600] || "gray" }}
      component="span"
      variant="body2"
    >
      <StrongText value={process} /> attempted to perform{" "}
      <StrongText value={actionType} /> on <StrongText value={host} />
    </Typography>
  );
};

export default FormatDescription;

const StrongText = ({ value }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  return (
    <strong
      style={{
        color:
          mode === "dark" ? theme.palette.grey[500] : theme.palette.grey[700],
      }}
    >
      {value}
    </strong>
  );
};
