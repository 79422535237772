import React from "react";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { saveToBoard } from "../../../redux/features/forensics/boardBuilderSlice";

const SaveToBoard = ({
  query,
  showSaveToBoardButton,
  setShowSaveToBoardButton,
  setShowBoardViewButton,
}) => {
  const { boardId } = useParams();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const body = {
      ...query,
      boardId,
    };
    dispatch(saveToBoard({ body }));
    setShowSaveToBoardButton(false);
    setShowBoardViewButton(true);
  };

  return (
    <Button
      variant="contained"
      disabled={!showSaveToBoardButton}
      onClick={handleSubmit}
    >
      Save To Board
    </Button>
  );
};

export default SaveToBoard;
