import { store } from "../../redux/app/store";

import { privateRequest } from "../../utils/apiRequest";

export const identifyActionType = async ({ target }) => {
  const endpoint = `/action/identify?input=${target}`;

  try {
    const res = await privateRequest.get(endpoint);

    if (res.status === 200) {
      return res.data.type;
    }
  } catch (err) {
    console.error(err);
  }
};

export const getSelectedAction = ({ actionType }) => {
  const { actionArtifacts } = store.getState().actions;

  if (!actionArtifacts || !actionType) return;

  return actionArtifacts[actionType];
};

export const getActionParams = () => {
  const incident = store.getState().incident.incident;

  return incident;
};
