import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchBoardInformation,
  forensicQueryRequest,
  saveResponseToBoard,
  updateBoardStatus,
} from ".";

export const getBoardInformation = createAsyncThunk(
  "forensic/board-info",
  fetchBoardInformation
);

export const closeForensicBoard = createAsyncThunk(
  "forensic/close-board",
  updateBoardStatus
);

export const forensicQuery = createAsyncThunk(
  "forensic/query",
  forensicQueryRequest
);

export const saveToBoard = createAsyncThunk(
  "forensic/save-to-board",
  saveResponseToBoard
);

const initialState = {
  loading: false,
  error: null,
  info: [],
  events: {},
};

const boardBuilderSlice = createSlice({
  name: "board-builder",
  initialState,
  reducers: {
    clearBoardBuilderState: (state) => {
      state.loading = false;
      state.error = null;
      state.info = [];
      state.events = {};
    },
    clearEvents: (state) => {
      state.events = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBoardInformation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBoardInformation.fulfilled, (state, action) => {
      state.loading = false;
      state.info = action.payload;
      state.error = null;
    });
    builder.addCase(getBoardInformation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(closeForensicBoard.fulfilled, (state, action) => {
      const temp = state.info[0];
      state.info[0] = { ...temp, status: "closed" };
    });

    builder.addCase(forensicQuery.fulfilled, (state, action) => {
      state.events = action.payload;
    });
  },
});

export const { clearBoardBuilderState, clearEvents } =
  boardBuilderSlice.actions;
export default boardBuilderSlice.reducer;
