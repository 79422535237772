import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import useIncident from "../../../hooks/useIncident";

const ResolutionSummaryResult = ({ answers }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const darkMode = mode === "dark";
  const { getIncidentById } = useIncident();
  const questions = getIncidentById.resolutionSummaryQuestions;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {questions.map((question, index) => (
        <Box
          key={question + index}
          sx={{
            background: darkMode ? "#333" : "#eaeaea",
            "&:hover": { background: darkMode ? "#373737" : "#fafafa" },
            p: 1,
            transition: "background 200ms, color 200ms",
            borderRadius: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              color: darkMode ? "grey.200" : "grey.800",
            }}
          >
            {index + 1}. {question}
          </Typography>
          <Typography color={darkMode ? "grey.400" : "grey.600"}>
            {answers[index]}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ResolutionSummaryResult;
