import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import Skeleton from "./Skeleton";
import { getOperatorInsightData } from "../../redux/features/tickets/operatorInsightSlice";

export default function OperatorInsightTable() {
  const theme = useTheme();
  const mode = theme.palette.mode;

  const dispatch = useDispatch();
  const { operators, loading } = useSelector(
    (state) => state.tickets.operatorInsight
  );

  useEffect(() => {
    dispatch(getOperatorInsightData());
  }, []);

  if (loading) return <Skeleton rows={5} height={200} />;

  return (
    <TableContainer component={Paper} sx={{ marginBlock: 1 }}>
      <Table
        size="small"
        sx={{ minWidth: 650 }}
        aria-label="incidents insight table "
      >
        <TableHead sx={{ background: mode === "dark" ? "#030303" : "#eee" }}>
          <TableRow sx={{ "&>": { textAlign: "center" } }}>
            <TableCell rowSpan={2}>Operator Name</TableCell>
            <TableCell align="center" colSpan={3}>
              Open Tickets
            </TableCell>
            <TableCell align="center" colSpan={3}>
              Historical Highest Capacity
            </TableCell>
            <TableCell align="center" colSpan={3}>
              Average Resolution Time
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">H</TableCell>
            <TableCell align="center">M</TableCell>
            <TableCell align="center">L</TableCell>

            <TableCell align="center">H</TableCell>
            <TableCell align="center">M</TableCell>
            <TableCell align="center">L</TableCell>

            <TableCell align="center">H</TableCell>
            <TableCell align="center">M</TableCell>
            <TableCell align="center">L</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {operators?.map((operator) => (
            <OperatorRow key={operator.id} operator={operator} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const OperatorRow = ({ operator }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell
        component="th"
        scope="row"
        sx={{ background: mode === "dark" ? "#030303" : "#eee" }}
      >
        {operator.operatorName?.toUpperCase()}
      </TableCell>

      <TableCell align="center">{operator?.openTickets?.high}</TableCell>
      <TableCell align="center">{operator?.openTickets?.medium}</TableCell>
      <TableCell align="center">{operator?.openTickets?.low}</TableCell>

      <TableCell align="center">
        {operator?.historicalHighestCapacity?.high}
      </TableCell>
      <TableCell align="center">
        {operator?.historicalHighestCapacity?.medium}
      </TableCell>
      <TableCell align="center">
        {operator?.historicalHighestCapacity?.low}
      </TableCell>

      <TableCell align="center">
        {operator?.averageResolutionTime?.high}
      </TableCell>
      <TableCell align="center">
        {operator?.averageResolutionTime?.medium}
      </TableCell>
      <TableCell align="center">
        {operator?.averageResolutionTime?.low}
      </TableCell>
    </TableRow>
  );
};
