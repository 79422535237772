import React from "react";

import NoDataFound from "./NoDataFound";
import { useDashboard } from "../../hooks/useDashboard";
import ChartTemplate from "./ChartTemplate";

const IncidentsOvertimeChart = () => {
  const { incidentsOvertime } = useDashboard();
  const { incidents } = incidentsOvertime;

  const chartOptions = {
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        columnWidth: 20,
      },
    },
    xaxis: {
      categories: Object.keys(incidents),
      labels: {
        formatter: (val) => {
          let values = val.split("-");
          return values[1] + "-" + values[2];
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => {
          return val > 1000 ? val / 1000 + "K" : val;
        },
      },
    },

    series: [
      {
        name: "Total Incidents",
        data: Object.values(incidents).map((item) => item.doc_count),
      },
      {
        name: "Resolved Incidents",
        data: Object.values(incidents).map((item) => item.resolved.doc_count),
      },
    ],
  };

  return Object.keys(incidents)?.length > 0 ? (
    <ChartTemplate
      options={chartOptions}
      series={chartOptions.series}
      type="bar"
    />
  ) : (
    <NoDataFound />
  );
};

export default IncidentsOvertimeChart;
