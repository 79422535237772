import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchTopHostsByIncident } from "./index";

export const getTopHostsByIncident = createAsyncThunk(
  "incidents/top-hosts-by-operator",
  fetchTopHostsByIncident
);

const initialState = {
  hosts: [],
  loading: false,
  error: null,
};

const topHostsByIncidentSlice = createSlice({
  name: "operator-top-hosts-by-incident",
  initialState,
  reducers: {
    clearOperatorTopHostsByIncidentState: (state) => {
      state.hosts = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTopHostsByIncident.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getTopHostsByIncident.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.hosts = action.payload;
    });
    builder.addCase(getTopHostsByIncident.rejected, (state, action) => {
      state.loading = false;
      state.hosts = [];
      state.error = action.error.message;
    });
  },
});

export const { clearOperatorTopHostsByIncidentState } =
  topHostsByIncidentSlice.actions;
export default topHostsByIncidentSlice.reducer;
