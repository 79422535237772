import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAuditActivities } from "./index";

export const getAuditActivities = createAsyncThunk(
  "audit/activities",
  fetchAuditActivities
);

const initialState = {
  activities: [],
  total: 0,
  loading: false,
  error: null,
};
const auditSlice = createSlice({
  name: "audit",
  initialState,
  reducers: {
    clearAuditState: (state) => {
      state.activities = [];
      state.total = 0;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuditActivities.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAuditActivities.fulfilled, (state, action) => {
        const { data, total } = action.payload;
        state.activities = data;
        state.total = total;
        state.loading = false;
      })
      .addCase(getAuditActivities.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { clearAuditState } = auditSlice.actions;
export default auditSlice.reducer;
