import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

import Users from "./scenes/users";
import Layout from "./scenes/layout";
import Reports from "./scenes/reports";
import Tickets from "./scenes/tickets";
import { ROLES } from "./utils/constants";
import Incidents from "./scenes/incidents";
import Dashboards from "./scenes/dashboards";
import OperatorDashboard from "./scenes/dashboards/operator";
import IncidentDetails from "./scenes/incidents/IncidentDetails";
import ProtectedRoute from "./redux/features/auth/ProtectedRoute";
import Login from "./redux/features/auth/Login";
import Toast from "./components/ui/Toast";
import Forensics from "./scenes/forensics";
import BoardBuilder from "./scenes/forensics/BoardBuilder";
import BoardView from "./scenes/forensics/BoardView";
import Endpoints from "./scenes/endpoints";
import EndpointDetails from "./scenes/endpoints/EndpointDetails";
import AuditPage from "./scenes/audit";
import EndpointUsers from "./scenes/endpoint-users";
import EndpointUserDetails from "./scenes/endpoint-users/EndpointUserDetails";
import IncidentResolutionSummaries from "./scenes/incident-resolution-summaries";
import ReOpenRequests from "./scenes/re-open";
import RouteNotFound from "./components/404";
import UserDetails from "./scenes/users/UserDetails";
import Assets from "./scenes/layout/Assets";
import IncidentsAndResponse from "./scenes/layout/IncidentsAndResponse";
import Actions from "./scenes/actions";

function RoutesAlt() {
  const user = useSelector((state) => state?.auth?.user);
  const isLoggedIn = useSelector((state) => state?.auth?.token);

  return (
    <Box
      sx={{
        // display: "flex",
        height: "100% !important",
        // overflowY: "auto",
      }}
    >
      <Toast />
      <Routes>
        <Route
          path={"/"}
          element={
            !isLoggedIn ? (
              <Login />
            ) : (
              <Navigate
                to={
                  ROLES.Manager === user?.role
                    ? "/dashboards"
                    : `/dashboards/operator`
                }
              />
            )
          }
        />
        <Route
          path={"/login"}
          element={
            !isLoggedIn ? (
              <Login />
            ) : (
              <Navigate
                to={
                  ROLES.Manager === user?.role
                    ? "/dashboards"
                    : `/dashboards/operator`
                }
              />
            )
          }
        />

        {/* Protected Routes */}
        <Route element={<Layout />}>
          {/** Accessible for Operators only */}
          <Route element={<ProtectedRoute roles={[ROLES.Operator]} />}>
            <Route
              path="/dashboards/operator"
              element={<OperatorDashboard />}
            />
          </Route>
          {/** Accessible for Managers only  */}
          <Route element={<ProtectedRoute roles={[ROLES.Manager]} />}>
            <Route path="/dashboards" element={<Dashboards />} />
            <Route path="/users" element={<Users />} />
            <Route path="/reopen-requests" element={<ReOpenRequests />} />
          </Route>
          {/** Accessible for both Managers and Operators */}
          <Route
            element={<ProtectedRoute roles={[ROLES.Manager, ROLES.Operator]} />}
          >
            <Route path="/users/:userId" element={<UserDetails />} />
            <Route path="/incidents" element={<Incidents />} />
            <Route
              path="/incidents/:incidentId"
              element={<IncidentDetails />}
            />
            <Route path="/tickets" element={<Tickets />} />
            <Route path="/reports/*" element={<Reports />} />
            <Route path="/forensics" element={<Forensics />} />
            <Route
              path="/forensics/board-builder/:boardId"
              element={<BoardBuilder />}
            />
            <Route
              path="/forensics/board-view/:boardId"
              element={<BoardView />}
            />
            <Route element={<IncidentsAndResponse />}>
              <Route path="/actions" element={<Actions />} />
            </Route>
            <Route element={<Assets />}>
              <Route path="/endpoints" element={<Endpoints />} />
              <Route path="/endpoints/group/:group" element={<Endpoints />} />
              <Route
                path="/endpoints/:endpointId"
                element={<EndpointDetails />}
              />
              <Route path="/endpoint-users" element={<EndpointUsers />} />
              <Route
                path="/endpoint-users/group/:group"
                element={<EndpointUsers />}
              />
              <Route
                path="/endpoint-users/:endpointUser"
                element={<EndpointUserDetails />}
              />
            </Route>
            <Route
              path="/resolution-summaries"
              element={<IncidentResolutionSummaries />}
            />
            <Route path="/audit" element={<AuditPage />} />
          </Route>
        </Route>
        <Route path="*" element={<RouteNotFound />} />
      </Routes>
    </Box>
  );
}

export default RoutesAlt;
