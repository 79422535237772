import { privateRequest } from "../../../utils/apiRequest";

export const fetchResolutionSummaries = async () => {
  const endpoint = `/resolution-summaries`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return res.data.response;
  }
};
