import React from "react";
import TextField from "@mui/material/TextField";

import FilterWrapper from "./FilterWrapper";

const HostSelector = ({
  title,
  ip,
  setIp,
  mac,
  setMac,
  hostname,
  setHostname,
  os,
  setOS,
  onAddFilter,
}) => {
  return (
    <FilterWrapper title={title} onAddFilter={onAddFilter} paddingX="8px">
      <TextField
        label="IP"
        id="host-ip"
        size="small"
        value={ip}
        onChange={(e) => setIp(e.target.value)}
        sx={{ minWidth: "125px", width: "100% !important" }}
      />
      <TextField
        label="MAC"
        id="mac-address"
        size="small"
        value={mac}
        onChange={(e) => setMac(e.target.value)}
        sx={{ minWidth: "125px", width: "100% !important" }}
      />
      <TextField
        label="Name"
        id="host-name"
        size="small"
        value={hostname}
        onChange={(e) => setHostname(e.target.value)}
        sx={{ minWidth: "125px", width: "100% !important" }}
      />
      <TextField
        label="OS"
        id="os"
        size="small"
        value={os}
        onChange={(e) => setOS(e.target.value)}
        sx={{ minWidth: "125px", width: "100% !important" }}
      />
    </FilterWrapper>
  );
};

export default HostSelector;
