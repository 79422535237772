import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import dayjs from "dayjs";

import useAuth from "../../../hooks/useAuth";
import { sendSessionRequest } from "../../../redux/features/actions/actionSlice";
import LiveTerminalPrompt from "./LiveTerminalPrompt";

const LiveSession = ({ targetHost, modalOpen, setModalOpen }) => {
  const [socket, setSocket] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [conversations, setConversations] = useState([]);
  const { user } = useAuth();
  const { incidentId } = useParams();

  const { data, loading } = useSelector((state) => state.liveSession);
  const address = `${data?.Return_Ip}:${data?.randomPort}`;

  const dispatch = useDispatch();

  useEffect(() => {
    if (socket) return;

    const body = {
      targetHost,
      responseFor: incidentId,
    };

    dispatch(sendSessionRequest({ userId: user.id, body }));
  }, []);

  if (loading)
    return <Box sx={{ minHeight: "500px", display: "flex" }}>Loading...</Box>;

  return (
    <SocketWrapper
      socket={socket}
      setSocket={setSocket}
      socketAddress={address}
      modalOpen={modalOpen}
      setConversations={setConversations}
    >
      <Box sx={{ minHeight: "500px", display: "flex" }}>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Box id="result" sx={{ flex: 1 }}>
            <Conversations data={conversations} />
          </Box>
          <LiveTerminalPrompt
            socket={socket}
            prompt={prompt}
            setPrompt={setPrompt}
            conversations={conversations}
            setConversations={setConversations}
          />
        </Box>
      </Box>
    </SocketWrapper>
  );
};

export default LiveSession;

const Conversations = ({ data }) => {
  return (
    <Box sx={{}}>
      {data?.map((conversation, index) => (
        <Box key={`message-${index + 1}`} sx={{ display: "flex", gap: "7px" }}>
          <Typography
            variant="caption"
            noWrap
            sx={{
              fontSize: "12px",
              letterSpacing: 1.2,
              fontWeight: "600",
              color:
                conversation.type === "request"
                  ? "primary.main"
                  : "secondary.main",
            }}
          >
            {conversation?.createdAt}
            {/* C:\&gt; */}
          </Typography>{" "}
          {conversation?.message}
        </Box>
      ))}
    </Box>
  );
};

const SocketWrapper = ({
  socket,
  setSocket,
  socketAddress,
  setConversations,
  children,
}) => {
  useEffect(() => {
    const newSocket = io(socketAddress, {
      autoConnect: false,
      reconnection: false,
    });

    setSocket(newSocket);
    newSocket.connect();

    return () => {
      console.log("Disconnecting socket instance", newSocket);
      if (newSocket) {
        newSocket.disconnect();
        setSocket(null);
      }
    };
  }, [socketAddress]);

  useEffect(() => {
    if (!socket) return;

    socket.on("connect", () => {
      console.log("Connected to server");
    });

    socket.on(`agent-send-message`, (data) => {
      setConversations((prev) => [
        ...prev,
        {
          message: data,
          type: "response",
          createdAt: dayjs().format("HH:MM:ss A"),
        },
      ]);
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from server");
      setConversations([]);
      setSocket(null);
    });
  }, [socket]);

  return <>{children} </>;
};
