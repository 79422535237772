import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function NoDataFound({ message = "No Incident to show!" }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#555",
        height: "100%",
      }}
    >
      <Typography>{message}</Typography>
    </Box>
  );
}

export default NoDataFound;
