import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const TriggerButton = () => (
  <Link
    color={"primary"}
    size="small"
    component={RouterLink}
    sx={{
      fontStyle: "italic",
      fontSize: 10,
      textDecoration: "none",
      "&:hover": { textDecoration: "underline" },
    }}
  >
    Assign
  </Link>
);

export default TriggerButton;
