import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import Form from "../ui/Form";
import Controls from "../ui/controls";

const RenameFile = ({
  originalFile,
  onSubmit,
  setModalOpen,
  renameTo,
  setRenameTo,
}) => {
  const handleInputChange = (event) => {
    if (event.target.name === "rename-to") {
      setRenameTo(event.target.value);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();

    onSubmit();
    handleClose();
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  React.useEffect(() => {
    setRenameTo(originalFile);
  }, []);

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controls.TextInputField
            multiline
            name="rename-from"
            label="Rename from"
            value={originalFile}
            disabled
            // onChange={handleInputControlChange}
          />
          <Controls.TextInputField
            multiline
            name="rename-to"
            label="Rename to"
            value={renameTo}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        <Button
          onClick={handleClick}
          type="submit"
          variant="contained"
          color="primary"
        >
          Rename
        </Button>
        <Button variant="contained" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
      </Box>
    </Form>
  );
};

export default RenameFile;
