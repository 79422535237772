import React from "react";
import TextField from "@mui/material/TextField";

import FilterWrapper from "./FilterWrapper";

const UserSelector = ({
  title,
  username,
  setUsername,
  domain,
  setDomain,
  onAddFilter,
}) => {
  return (
    <FilterWrapper title={title} onAddFilter={onAddFilter} paddingX="8px">
      <TextField
        label="Username"
        id="username"
        size="small"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        sx={{ minWidth: "150px", width: "100% !important" }}
      />
      <TextField
        label="Domain"
        id="domain"
        size="small"
        value={domain}
        onChange={(e) => setDomain(e.target.value)}
        sx={{ minWidth: "150px", width: "100% !important" }}
      />
    </FilterWrapper>
  );
};

export default UserSelector;
