import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchNotifications, markAllAsRead, markAsRead } from ".";

export const getNotifications = createAsyncThunk(
  "notifications/get",
  fetchNotifications
);

export const markNotificationAsRead = createAsyncThunk(
  "notifications/mark-as-read",
  markAsRead
);

export const markAllNotificationsAsRead = createAsyncThunk(
  "notifications/mark-all-as-read",
  markAllAsRead
);

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    clearNotificationState: (state) => {
      state.loading = false;
      state.data = [];
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder.addCase(markNotificationAsRead.fulfilled, (state, action) => {
      state.data = state.data.map((notification) => {
        if (notification.id === action.payload.id) {
          notification.status = true;
        }
        return notification;
      });
    });
    builder.addCase(markAllNotificationsAsRead.fulfilled, (state) => {
      state.data = state.data.map((notification) => {
        notification.status = true;
        return notification;
      });
    });
  },
});

export const { clearNotificationState } = notificationSlice.actions;
export default notificationSlice.reducer;
