import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

import BaseWidget from "../BaseWidget";
import WidgetHeader from "../WidgetHeader";
import TopHosts from "../TopHosts";
import WidgetLoading from "../WidgetLoading";
import useAuth from "../../../hooks/useAuth";
import { getTopHostsByIncident } from "../../../redux/features/dashboards/operator/TopHostsByIncidentSlice";
import { useDashboard } from "../../../hooks/useDashboard";
import MuiMenu from "../MuiMenu";

export default function TopHostsByIncidentOperatorWidget() {
  const [days, setDays] = useState("7days");
  const [openDetails, setOpenDetails] = useState(false);
  const { user } = useAuth();

  const dispatch = useDispatch();
  const { topOperatorHostsByIncident } = useDashboard();
  const { hosts, loading } = topOperatorHostsByIncident;

  const formatDate = () => {
    if (!days) return null;
    if (days === "24hours") return 1;
    if (days === "7days") return 7;
  };

  useEffect(() => {
    dispatch(getTopHostsByIncident({ userId: user?.id, days: formatDate() }));
  }, [dispatch, days]);

  const handleClose = () => {
    setOpenDetails(false);
  };

  return (
    <BaseWidget>
      <WidgetHeader title="Top Hosts by Incident">
        {!openDetails && (
          <Box>
            <MuiMenu
              days={days}
              setDays={setDays}
              options={[
                { key: "24hours", label: "Today" },
                { key: "7days", label: "7 Days" },
              ]}
            />
          </Box>
        )}
        {openDetails && (
          <Box>
            <IconButton onClick={handleClose}>
              <ArrowBackRoundedIcon size={14} />
            </IconButton>
          </Box>
        )}
      </WidgetHeader>
      {loading ? (
        <WidgetLoading />
      ) : (
        <TopHosts
          hosts={hosts}
          openDetails={openDetails}
          setOpenDetails={setOpenDetails}
        />
      )}
    </BaseWidget>
  );
}
