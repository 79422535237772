import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import { LightMode as LightModeIcon } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";

import useTheme from "./../../hooks/useTheme";
import DarkModeIcon from "./../../assets/icons/DarkModeIcon";
import { setThemeMode } from "../../redux/features/theme/themeModeSlice";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  width: "100% !important",
  minWidth: "60px",
  border: "none",
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(1),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    textTransform: "capitalize",
    letterSpacing: 1.2,
    fontWeight: 700,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: "1px solid transparent",
    },
}));

const ThemeToggle = () => {
  const theme = useTheme();
  const currentMode = theme?.palette?.mode;
  const [mode, setMode] = React.useState(currentMode);
  const dispatch = useDispatch();

  const handleMode = (event, newThemeMode) => {
    setMode(newThemeMode);
    dispatch(setThemeMode(newThemeMode));
  };

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          borderRadius: "12px",
          border: 0,
          background:
            currentMode === "dark" ? "#292E37" : theme.palette.grey["200"],
        }}
      >
        <StyledToggleButtonGroup
          size="small"
          value={mode}
          exclusive
          onChange={handleMode}
          aria-label="theme mode"
        >
          <ToggleButton
            value="light"
            aria-label="light mode"
            disableRipple
            disabled={currentMode === "light"}
            sx={{
              display: "flex",
              gap: 1,
              flex: 1,
              borderRadius: "9px !important",
            }}
          >
            <LightModeIcon /> Light
          </ToggleButton>
          <ToggleButton
            value="dark"
            aria-label="dark mode"
            disableRipple
            disabled={currentMode === "dark"}
            sx={{
              display: "flex",
              gap: 1,
              flex: 1,
              borderRadius: "9px !important",
            }}
          >
            <DarkModeIcon /> Dark
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
};

export default ThemeToggle;
