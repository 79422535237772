import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";

import { getEndpointUserGroup } from "../../../redux/features/endpoint-users/endpointUserGroupSlice";
import UserGroup from "./UserGroup";
import AddUserGroup from "./AddUserGroup";

const EndpointUserGroup = () => {
  const dispatch = useDispatch();
  const { group } = useParams();
  const { data, loading } = useSelector((state) => state.endpointUsers.group);
  const allGroup = {
    id: 0,
    name: "All",
    slug: "/",
  };

  useEffect(() => {
    dispatch(getEndpointUserGroup());
  }, [group]);

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          height: "100% !important",
          maxHeight: "75vh",
          overflowY: "hidden",
          "&:hover": {
            overflowY: "auto",
          },
        }}
      >
        <UserGroup data={allGroup} />
        {data?.map((group) => (
          <UserGroup key={group.id} data={group} />
        ))}
      </Box>
      <Box sx={{ mt: 1 }}>
        <AddUserGroup />
      </Box>
    </>
  );
};

export default EndpointUserGroup;
