import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../components/endpoints/Loading";
import UserInfo from "../../components/endpoints/endpoint-user-details/UserInfo";
import AccessedHosts from "../../components/endpoints/endpoint-user-details/AccessedHosts";
import OpenAndClosedIncidentsTab from "../../components/endpoints/endpoint-details/OpenAndClosedIncidentsTab";
import IncidentsOvertimeBySeverity from "../../components/endpoints/endpoint-details/IncidentsOvertimeBySeverity";
import IncidentsOvertimeByType from "../../components/endpoints/endpoint-details/IncidentsOvertimeByType";
import NoDataFound from "../../components/endpoints/NoDataFound";
import { getEndpointUserDetails } from "../../redux/features/endpoint-users/endpointUserDetailSlice";
import PageWrapper from "./../../components/PageWrapper";

const EndpointUserDetails = () => {
  const { endpointUser } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: user, loading } = useSelector(
    (state) => state.endpointUserDetails
  );

  useEffect(() => {
    dispatch(getEndpointUserDetails({ endpointUser }));
  }, []);

  if (loading) return <Loading />;
  if (user.endpoint_user !== endpointUser) {
    return (
      <NoDataFound>
        <NoDataFound.Message>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4">Oops! No User Data Found!</Typography>
            <Typography variant="body2">This is the message.</Typography>
          </Box>
        </NoDataFound.Message>
        <NoDataFound.Link onClick={() => navigate(`/endpoint-users`)}>
          Go back to Endpoint Users page.
        </NoDataFound.Link>
      </NoDataFound>
    );
  }

  return (
    <PageWrapper>
      Endpoint User Details Page
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* First Row */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <UserInfo user={user} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{ mb: 1, ml: 2 }}>Host Accessed</Typography>
              <AccessedHosts hosts={user.hostAccessed} />
            </Grid>
          </Grid>
        </Grid>

        {/* Second Row */}
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <IncidentsOvertimeBySeverity
                incidentsOvertime={user?.incidentsCountOvertime}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <IncidentsOvertimeByType
                incidentsOvertime={user?.incidentsCountOvertime}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Third Row */}
        <Grid item xs={12}>
          <OpenAndClosedIncidentsTab
            openIncidents={user?.openIncidents || []}
            closedIncidents={user?.closedIncidents || []}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default EndpointUserDetails;
