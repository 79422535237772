import React from "react";

import { useDashboard } from "../../hooks/useDashboard";
import ChartTemplate from "./ChartTemplate";

const IncidentsByStatusChart = () => {
  const { incidentsByStatus } = useDashboard();
  const { labels, series } = incidentsByStatus;

  const options = {
    chart: {
      toolbar: {
        show: true,
      },
    },
    labels: labels,
    plotOptions: {
      pie: {
        offsetY: 20,
        customScale: 0.85,
        donut: {
          // size: "50%",
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              fontSize: 16,
            },
          },
        },
      },
    },

    legend: {
      position: "bottom",
      offsetY: -5,
    },
  };

  return (
    <ChartTemplate
      options={options}
      series={series}
      type="donut"
      style={{ paddingTop: "0px" }}
      // width="90%"
      // height="118%"
    />
  );
};

export default IncidentsByStatusChart;
