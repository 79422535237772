import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Header from "../../components/Header";
import BoardList from "../../components/forensics/BoardList";

const Forensics = () => {
  return (
    <Box
      m="21px"
      sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}
    >
      {/* Header */}
      <Grid container>
        <Grid item xs={12} sm={6} lg={8}>
          <Header
            title="Forensics"
            subtitle={`Welcome to the Forensics page!`}
          />
        </Grid>
        <BoardList />
      </Grid>
    </Box>
  );
};

export default Forensics;
