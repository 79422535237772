import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../components/Header";
import NewIncidentsWidget from "../../components/widgets/NewIncidentsWidget";
import IncidentsByStatus from "../../components/widgets/IncidentsByStatusWidget";
import IncidentsByAssignee from "../../components/widgets/IncidentsByAssigneeWidget";
import IncidentsOvertimeWidget from "../../components/widgets/IncidentsOvertimeWidget";
import IncidentsOverdueWidget from "../../components/widgets/IncidentsOverdueWidget";
import IncidentsStatusBoardWidget from "../../components/widgets/IncidentsStatusBoardWidget";
import ResolvedIncidentsByAssigneeWidget from "../../components/widgets/ResolveIncidentsByAssigneeWidget";
import IncidentsBySeverityWidget from "../../components/widgets/IncidentsBySeverityWidget";
import ResolvedIncidentsMTTRWidget from "../../components/widgets/ResolvedIncidentsMTTRWidget";
import TopHostsByIncidentWidget from "../../components/widgets/TopHostsByIncidentWidget";
import { capitalize } from "../../utils/functions";
import { getOperators } from "../../redux/features/users/operatorsSlice";
import { getAllActionArtifacts } from "../../redux/features/actions/actionSlice";

const DashboardAdmin = () => {
  const user = useSelector((state) => state.auth.user);

  // Get all operators  (we use it to select a user when we create a ticket)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOperators());
  }, [dispatch]);

  // Load artifacts for predefined actions
  useEffect(() => {
    dispatch(getAllActionArtifacts());
  }, []);

  return (
    <Box
      m="21px"
      sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}
    >
      {/* Header */}
      <Grid container>
        <Grid item xs={12} sm={6} lg={8}>
          <Header
            title="Executive Dashboard"
            subtitle={`Welcome to your dashboard, ${capitalize(user?.name)}!`}
          />
        </Grid>
      </Grid>

      {/* Dashboard Widget Cards*/}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={4}>
          <NewIncidentsWidget />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <IncidentsByAssignee />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <IncidentsOvertimeWidget />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <IncidentsByStatus />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <IncidentsStatusBoardWidget />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <IncidentsOverdueWidget />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <IncidentsBySeverityWidget />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <ResolvedIncidentsByAssigneeWidget />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <ResolvedIncidentsMTTRWidget />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TopHostsByIncidentWidget />
        </Grid>
      </Grid>
      <br />
    </Box>
  );
};

export default DashboardAdmin;
