import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Modal from "../../ui/Modal";
import GroupForm from "./GroupForm";
import { splitStringByCommaAndConvertToUniqueArray } from "../../../utils/functions";
import {
  getEndpoints,
  updateEndpointGroup,
} from "../../../redux/features/endpoints/endpointSlice";
import EditGroupButton from "./EditGroupButton";

const EditGroup = () => {
  const { data: selectedGroup } = useSelector((state) => state.endpoints.list);
  const { currentGroup } = useSelector((state) => state.endpoints.group);

  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState(selectedGroup?.groupName || "");
  const [description, setDescription] = useState(
    selectedGroup?.description || ""
  );
  const [recruitingRule, setRecruitingRule] = useState(
    selectedGroup?.recruitingRule || ""
  );

  const previouslyAddedHosts = selectedGroup?.groupItems?.join(",");
  const [hosts, setHosts] = useState(previouslyAddedHosts || ""); //Hosts in a group
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const handleShowEditModal = () => {
    setModalOpen(true);
  };

  // This function sets the form state based on the backend data
  const populatePersistedData = () => {
    setName(selectedGroup?.groupName || "");
    setDescription(selectedGroup?.description || "");
    setRecruitingRule(selectedGroup?.recruitingRule || "");
    // setHosts(previouslyAddedHosts || "");
  };

  const updateGroup = () => {
    const formattedHosts = splitStringByCommaAndConvertToUniqueArray(hosts);
    const body = {
      group_name: name,
      description,
      recruiting_rule: recruitingRule,
      hosts: formattedHosts,
    };

    populatePersistedData();

    dispatch(updateEndpointGroup({ body, groupId: selectedGroup?.id }));

    //Refetch if the host change
    if (hosts !== previouslyAddedHosts) {
      dispatch(getEndpoints({ groupId: currentGroup || 0 }));
    }
  };

  // Populate the from with the current data before updating
  useEffect(() => {
    populatePersistedData();
  }, [modalOpen]);

  return (
    <>
      <Box>
        <EditGroupButton onClick={handleShowEditModal} />
      </Box>

      <Modal
        title="Edit group"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <>
          <GroupForm
            setModalOpen={setModalOpen}
            ButtonLabel="Update Group"
            name={name}
            setName={setName}
            description={description}
            setDescription={setDescription}
            recruitingRule={recruitingRule}
            setRecruitingRule={setRecruitingRule}
            hosts={hosts}
            setHosts={setHosts}
            setError={setError}
            onSubmit={updateGroup}
            isEditing={true} //To prevent the form state from being cleared by the modal's "Cancel" button
          />
          <Typography color="error">{error && error}</Typography>
        </>
      </Modal>
    </>
  );
};

export default EditGroup;
