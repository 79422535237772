import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import WidgetContent from "./WidgetContent";

const WidgetLoading = () => {
  return (
    <WidgetContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Typography>Loading...</Typography>
      </Box>
    </WidgetContent>
  );
};

export default WidgetLoading;
