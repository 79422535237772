import { combineReducers } from "@reduxjs/toolkit";

import unassignedHighReducer from "./unassigned-by-severity/unassignedHighSlice";
import unassignedMediumReducer from "./unassigned-by-severity/unassignedMediumSlice";
import unassignedLowReducer from "./unassigned-by-severity/unassignedLowSlice";
import incidentInsightReducer from "./incidentInsightSlice";
import operatorInsightReducer from "./operatorInsightSlice";
import ticketsByStatusReducers from "./ticketsByStatusSlice";

const unassignedBySeverityReducer = combineReducers({
  High: unassignedHighReducer,
  Medium: unassignedMediumReducer,
  Low: unassignedLowReducer,
});

const ticketsReducer = combineReducers({
  unassignedBySeverity: unassignedBySeverityReducer,
  incidentInsight: incidentInsightReducer,
  operatorInsight: operatorInsightReducer,
  ticketsByStatus: ticketsByStatusReducers,
});

export default ticketsReducer;
