import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import BaseWidget from "./BaseWidget";
import WidgetHeader from "./WidgetHeader";
import WidgetLoading from "./WidgetLoading";
import useAuth from "./../../hooks/useAuth";
import { getMyIncidents } from "../../redux/features/dashboards/operator/myIncidentsSlice";
import { useDashboard } from "../../hooks/useDashboard";
import IncidentTable from "./IncidentTable";

export default function MyCompletedIncidentsWidget() {
  const { user } = useAuth();
  const { myIncidents } = useDashboard();
  const { completedIncidents: incidents, loading } = myIncidents;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getMyIncidents({ userId: user?.id, limit: 10, status: "Completed" })
    );
  }, [dispatch]);

  return (
    <BaseWidget>
      <WidgetHeader title="My Completed Incidents" />
      {loading ? (
        <WidgetLoading />
      ) : (
        <IncidentTable incidents={incidents} loading={loading} />
      )}
    </BaseWidget>
  );
}
