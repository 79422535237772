import { themeSettings as defaultTheme } from "./themeDefault";
import { themeSettings as themeOne } from "./themeOne";
import { themeSettings as themeTwo } from "./themeTwo";
import { themeSettings as themeThree } from "./themeThree";

const themes = {
  defaultTheme,
  themeOne,
  themeTwo,
  themeThree,
};

export default themes;
