import React from "react";
import Button from "@mui/material/Button";

const CTA = ({ onSubmit }) => {
  return (
    <Button variant="contained" onClick={onSubmit} sx={{}}>
      GO
    </Button>
  );
};

export default CTA;
