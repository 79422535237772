import { privateRequest } from "../../../utils/apiRequest";

export const fetchReopenRequests = async ({ status = "pending" }) => {
  const endpoint = `/reopen-requests/${status}`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    if (status === "pending") {
      return res.data.map((incident) => {
        // Check if all the needed information is available before returning
        if (!!incident?.info?.incident_id) {
          return { ...incident.info, status: "pending" };
        }
      });
    }
    return res.data.map((incident) => incident.info);
  }
};

export const approveReopenRequests = async ({ incidentId, body }) => {
  const endpoint = `/reopen-requests/${incidentId}/approve`;
  const res = await privateRequest.post(endpoint, body);

  if (res.status === 200) {
    return incidentId;
  }
};

export const rejectReopenRequests = async ({ incidentId }) => {
  const endpoint = `/reopen-requests/${incidentId}/reject`;
  const res = await privateRequest.post(endpoint);

  if (res.status === 200) {
    return incidentId;
  }
};
