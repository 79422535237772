import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";

import useFetch from "../../hooks/useFetch";
import ReportHeader from "../../components/reports/ReportHeader";
import OperatorsRank from "../../components/reports/operator-performance-summary/operators-rank";
import OperatorsAverageResolutionTime from "../../components/reports/operator-performance-summary/operators-average-resolution-time";
import OperatorsWorkload from "../../components/reports/operator-performance-summary/operators-workload";
import HistoricalPerformanceGraph from "../../components/reports/operator-performance-summary/historical-performance-graph";
import Timeliness from "../../components/reports/operator-performance-summary/timeliness";

const formatDate = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

const OperatorsPerformanceSummaryReport = ({ startDate, endDate }) => {
  const endpoint = `/reports/operators-summary?startDate=${formatDate(
    startDate
  )}&endDate=${formatDate(endDate)}`;
  const { data, loading } = useFetch(endpoint);

  if (loading) {
    return (
      <Box>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "flex-start",
        gap: 1,
        width: 700,
        marginTop: "-25px",
      }}
    >
      <ReportHeader
        template="Operator Performance Summary Report"
        startDate={startDate}
        endDate={endDate}
      />
      {data && data.operatorsRank ? (
        <OperatorsRank data={data?.operatorsRank} />
      ) : null}
      {data && data.operatorsAverageResolutionTime ? (
        <OperatorsAverageResolutionTime
          data={data?.operatorsAverageResolutionTime}
        />
      ) : null}
      {data && data.operatorsWorkload ? (
        <OperatorsWorkload data={data?.operatorsWorkload} />
      ) : null}
      {data && data.operatorsPerformance ? (
        <HistoricalPerformanceGraph data={data?.operatorsPerformance} />
      ) : null}
      {data && data.operatorsTimeliness ? (
        <Timeliness data={data?.operatorsTimeliness} />
      ) : null}
    </Box>
  );
};

export default OperatorsPerformanceSummaryReport;
