import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { capitalize } from "../../utils/functions";

const OperatorProfile = ({ profile }) => {
  const { name, email, role } = profile;

  return (
    <Box sx={{ display: "flex", gap: 4 }}>
      <Typography sx={{}}>
        Name: <span style={{ fontWeight: "bold" }}>{capitalize(name)}</span>
      </Typography>
      <Typography>
        Email: <span style={{ fontWeight: "bold" }}>{email}</span>
      </Typography>
      <Typography>
        Profession: <span style={{ fontWeight: "bold" }}>{role}</span>
      </Typography>
    </Box>
  );
};

export default OperatorProfile;
