import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Wrapper from "./Wrapper";
import { getUserAudit } from "../../../redux/features/users/userAuditSlice";

const Audit = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const { audit, loading } = useSelector((state) => state.users.userAudit);

  useEffect(() => {
    dispatch(getUserAudit({ userId, pageSize }));
  }, []);

  if (loading) return <Loading />;

  return (
    <Wrapper title="Audit">
      <Box>
        <ListHeader />
        {audit?.auditData?.map((incident, index) => (
          <Incident
            key={incident.pageLocation + index}
            incident={{
              activity: incident.activity || "",
              location: incident.pageLocation || "",
            }}
          />
        ))}
      </Box>
    </Wrapper>
  );
};

const Incident = ({ incident }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const { activity, location } = incident;

  return (
    <>
      <Box
        className="flex"
        sx={{
          borderBottom: `1px solid ${mode === "dark" ? "#202020" : "#ccc"}`,
          borderRadius: 1,
          overflowX: "hidden",
          "&:hover": {
            background: mode === "dark" ? "#222224" : theme.palette.grey[300],
          },
          mb: 1,
          paddingBlock: 0.5,
          display: "flex",
          alignItems: "center",
          gap: 2,
          // justifyContent: "space-between",
        }}
      >
        <Box sx={{ flex: 2 }}>
          <Typography variant="body2">{activity}</Typography>
        </Box>
        <Box sx={{ flex: 4, textAlign: "left" }}>
          <Typography variant="body2" noWrap sx={{ maxWidth: "180px" }}>
            <Link
              color={"primary"}
              size="small"
              component={RouterLink}
              sx={{
                fontStyle: "italic",
                fontSize: 10,
                textDecoration: "none",
                "&:hover": { textDecoration: "underline" },
              }}
              to={location}
            >
              {location}
            </Link>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const ListHeader = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${mode === "dark" ? "#202020" : "#ccc"}`,
        borderRadius: 1,
        overflowX: "hidden",
        mb: 1,
        paddingBlock: 0.5,
        display: "flex",
        alignItems: "center",
        gap: 2,
        // justifyContent: "space-between",
      }}
    >
      <Typography sx={{ flex: 1 }} variant="body2" color="gray">
        Activity
      </Typography>
      <Typography sx={{ flex: 1 }} variant="body2" color="gray">
        Location
      </Typography>
    </Box>
  );
};

const IncidentNotFound = () => {
  return (
    <Box sx={{ height: "100% !important" }}>
      <Typography>No Incident Found</Typography>
    </Box>
  );
};
const Loading = () => {
  return (
    <Box sx={{ height: "100% !important" }}>
      <Typography>Loading...</Typography>
    </Box>
  );
};

export default Audit;
