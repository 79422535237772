import { store } from "./../app/store";
import { clearIncidentState } from "./incidents/incidentSlice";
import { clearIncidentsState } from "./incidents/incidentsSlice";
import { clearIncidentByStatusAssignee } from "./dashboards/executive/incidentsByAssigneeSlice";
import { clearIncidentByStatusState } from "./dashboards/executive/incidentsByStatusSlice";
import { clearNewestIncidentsState } from "./dashboards/executive/newestIncidentsSlice";
import { clearIncidentsOvertimeState } from "./dashboards/executive/incidentsOvertimeSlice";
import { clearIncidentsOverdueState } from "./dashboards/executive/incidentsOverdueSlice";
import { clearIncidentsStatusBoardState } from "./dashboards/executive/incidentsStatusBoardSlice";
import { clearResolvedIncidentsByAssigneeState } from "./dashboards/executive/resolvedIncidentsByAssigneeSlice";
import { clearIncidentsBySeverityState } from "./dashboards/executive/incidentsBySeveritySlice";
import { clearResolvedIncidentsMTTRState } from "./dashboards/executive/resolvedIncidentsMTTRSlice";
import { clearTopHostsByIncidentState } from "./dashboards/executive/TopHostsByIncidentSlice";
import { clearOperatorsState } from "./users/operatorsSlice";
import { clearMyIncidentsState } from "./dashboards/operator/myIncidentsSlice";
import { clearOperatorIncidentsBySeverityState } from "./dashboards/operator/incidentsBySeveritySlice";
import { clearIncidentsByRemainingTimeState } from "./dashboards/operator/incidentsByRemainingTimeSlice";
import { clearOperatorResolvedIncidentsMTTRState } from "./dashboards/operator/resolvedIncidentsMTTRSlice";
import { clearOperatorIncidentsByStatusState } from "./dashboards/operator/incidentsByStatusSlice";
import { clearOperatorTopHostsByIncidentState } from "./dashboards/operator/TopHostsByIncidentSlice";
import { clearReopenState } from "./reopen/reopenSlice";
import { clearUnassignedHighState } from "./tickets/unassigned-by-severity/unassignedHighSlice";
import { clearUnassignedMediumState } from "./tickets/unassigned-by-severity/unassignedMediumSlice";
import { clearUnassignedLowState } from "./tickets/unassigned-by-severity/unassignedLowSlice";
import { clearIncidentInsightState } from "./tickets/incidentInsightSlice";
import { clearOperatorInsightState } from "./tickets/operatorInsightSlice";
import { clearTicketsByStatusState } from "./tickets/ticketsByStatusSlice";
import { clearAuditState } from "./audit/auditSlice";
import { clearResolutionSummaryState } from "./resolution-summaries/resolutionSummarySlice";
import { clearEndpointState } from "./endpoints/endpointSlice";
import { clearEndpointDetailsState } from "./endpoints/endpointDetailsSlice";
import { clearEndpointUserState } from "./endpoint-users/endpointUserSlice";
import { clearEndpointUserDetailState } from "./endpoint-users/endpointUserDetailSlice";
import { clearUserAuditState } from "./users/userAuditSlice";
import { clearUserState } from "./users/userSlice";
import { clearUserInprogressIncidentsByTypeState } from "./users/user-incidents-by-type/inProgressSlice";
import { clearUserOverdueIncidentsByTypeState } from "./users/user-incidents-by-type/overdueSlice";
import { clearUserCompletedIncidentsByTypeState } from "./users/user-incidents-by-type/completedSlice";
import { clearEndpointGroupState } from "./endpoints/endpointGroupSlice";
import { clearActionState } from "./actions/actionSlice";
import { clearUsersState } from "./users/usersSlice";

export const clearAllStates = () => {
  console.log("All states have been cleared!!");

  // Incident states
  store.dispatch(clearIncidentState());
  store.dispatch(clearIncidentsState());

  // Reopen states
  store.dispatch(clearReopenState());

  // Executive Dashboard states
  store.dispatch(clearNewestIncidentsState());
  store.dispatch(clearIncidentByStatusAssignee());
  store.dispatch(clearIncidentByStatusState());
  store.dispatch(clearIncidentsOvertimeState());
  store.dispatch(clearIncidentsOverdueState());
  store.dispatch(clearIncidentsStatusBoardState());
  store.dispatch(clearResolvedIncidentsByAssigneeState());
  store.dispatch(clearIncidentsBySeverityState());
  store.dispatch(clearResolvedIncidentsMTTRState());
  store.dispatch(clearTopHostsByIncidentState());

  // Executive Dashboard states
  store.dispatch(clearMyIncidentsState());
  store.dispatch(clearOperatorIncidentsBySeverityState());
  store.dispatch(clearIncidentsByRemainingTimeState());
  store.dispatch(clearOperatorResolvedIncidentsMTTRState());
  store.dispatch(clearOperatorIncidentsByStatusState());
  store.dispatch(clearOperatorTopHostsByIncidentState());

  // User states
  store.dispatch(clearOperatorsState());
  store.dispatch(clearUsersState());
  store.dispatch(clearUserAuditState());
  store.dispatch(clearUserState());
  store.dispatch(clearUserInprogressIncidentsByTypeState());
  store.dispatch(clearUserCompletedIncidentsByTypeState());
  store.dispatch(clearUserOverdueIncidentsByTypeState());

  // Tickets state
  store.dispatch(clearUnassignedHighState());
  store.dispatch(clearUnassignedMediumState());
  store.dispatch(clearUnassignedLowState());
  store.dispatch(clearIncidentInsightState());
  store.dispatch(clearOperatorInsightState());
  store.dispatch(clearTicketsByStatusState());

  //Audit state
  store.dispatch(clearAuditState());

  // Resolution Summary States
  store.dispatch(clearResolutionSummaryState());

  //Endpoint States
  store.dispatch(clearEndpointState());
  store.dispatch(clearEndpointDetailsState());
  store.dispatch(clearEndpointUserState());
  store.dispatch(clearEndpointUserDetailState());
  store.dispatch(clearEndpointGroupState());

  //Actions
  store.dispatch(clearActionState());
};
