import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Chip, TablePagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../audit/Loading";
import { getActions } from "../../redux/features/actions/actionSlice";
import { capitalize } from "./../../utils/functions";

const ActionList = () => {
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const DEFAULT_PAGE_SIZE = 20;

  const { data, loading } = useSelector((state) => state.actions);
  const rows = data.actions;
  const [rowsPerPage, setRowsPerPage] = useState(
    +searchParams.get("rowsPerPage") || DEFAULT_PAGE_SIZE
  );

  const dispatch = useDispatch();
  const params = {
    page: page + 1,
    limit: rowsPerPage,
  };

  useEffect(() => {
    dispatch(getActions({ params }));
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSearchParams((newSearchParams) => {
      newSearchParams.set(
        "rowsPerPage",
        parseInt(event.target.value, 10) || DEFAULT_PAGE_SIZE
      );
      return newSearchParams;
    });

    setPage(0);
  };

  useEffect(() => {
    setSearchParams((newSearchParams) => {
      newSearchParams.set("rowsPerPage", rowsPerPage);
      return newSearchParams;
    });
  }, []);

  useEffect(() => {
    setRowsPerPage(+searchParams.get("rowsPerPage") || DEFAULT_PAGE_SIZE);
  }, [searchParams]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  if (loading) return <Loading />;
  if (rows?.length < 1) return <NoActionFound />;

  return (
    <Paper sx={{ width: "100%", mb: 2 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750, width: "100%" }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <TableHeader />
          <TableBody
            sx={{
              "& > :last-child": { display: page !== 0 ? "none" : null }, // To remove the last unnecessary <tr> element of the table
            }}
          >
            {rows ? rows.map((row) => <Row key={row._id} row={row} />) : null}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ background: "#1B202A" }}
        rowsPerPageOptions={[10, 20, 25, 50, 100]}
        component="div"
        shape="rounded"
        variant="outlined"
        count={data?.total || rows?.length || 0}
        rowsPerPage={+rowsPerPage || DEFAULT_PAGE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ActionList;

const NoActionFound = () => {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          // gap: 3,
          minHeight: "calc(100vh - 12rem)",
          mb: 2,
          p: 2,
        }}
      >
        <Typography variant="h4" textAlign="center">
          Oops! There's no action yet.
        </Typography>
      </Paper>
    </Box>
  );
};

const TableHeader = () => {
  return (
    <TableHead>
      <TableRow sx={{ height: "45px" }}>
        <TableCell>Target Host</TableCell>
        <TableCell>Target Item</TableCell>
        <TableCell>Action</TableCell>
        <TableCell>Start Time</TableCell>
        <TableCell>Completion Time</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Response For</TableCell>
        <TableCell>Owner</TableCell>
      </TableRow>
    </TableHead>
  );
};
const Row = ({ row }) => {
  return (
    <TableRow
      sx={{
        "& td": {
          color: (theme) =>
            theme.palette.mode === "dark"
              ? theme.palette.grey["200"]
              : theme.palette.grey["700"],
          background: (theme) =>
            theme.palette.mode === "dark"
              ? "#1B202A"
              : theme.palette.grey["200"],
          border: 0,
        },
      }}
    >
      <TableCell>{row?.targetHost}</TableCell>
      <TableCell>
        {row?.parameters?.udp?.ReturnIp}:{row?.parameters?.udp?.ReturnPort}
      </TableCell>
      <TableCell>
        <Chip
          label={capitalize(row?.action)}
          //   color={row?.action === "Manager" ? "primary" : "default"}
          size="small"
        />
      </TableCell>
      <TableCell>{row?.time}</TableCell>
      <TableCell>{row?.updatedAt}</TableCell>
      <TableCell>{row?.status}</TableCell>
      <TableCell>{row?.responseFor}</TableCell>
      <TableCell>{capitalize(row?.owner)}</TableCell>
    </TableRow>
  );
};
