import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  approveReopenRequests,
  fetchReopenRequests,
  rejectReopenRequests,
} from ".";

export const getReopenRequests = createAsyncThunk(
  "incidents/re-open",
  fetchReopenRequests
);

export const approveRequest = createAsyncThunk(
  "incidents/approve",
  approveReopenRequests
);

export const rejectRequest = createAsyncThunk(
  "incidents/reject",
  rejectReopenRequests
);

const initialState = {
  incidents: [],
  pendingIncidentsCount: 0,
  loading: false,
  error: null,
};

const reopenSlice = createSlice({
  name: "re-open-request",
  initialState,
  reducers: {
    clearReopenState: (state) => {
      state.loading = false;
      state.incidents = [];
      state.pendingIncidentsCount = 0;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReopenRequests.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getReopenRequests.fulfilled, (state, action) => {
      state.loading = false;
      state.incidents = action.payload;
      if (action.payload[0] && action.payload[0].status) {
        state.pendingIncidentsCount = action.payload.length;
      }
      state.error = false;
    });
    builder.addCase(getReopenRequests.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(approveRequest.fulfilled, (state, action) => {
      state.incidents = state.incidents.filter(
        (incident) => incident.incident_id !== action.payload
      );
      state.pendingIncidentsCount = state.pendingIncidentsCount - 1;
    });
    builder.addCase(rejectRequest.fulfilled, (state, action) => {
      state.incidents = state.incidents.filter(
        (incident) => incident.incident_id !== action.payload
      );
      state.pendingIncidentsCount = state.pendingIncidentsCount - 1;
    });
  },
});

export const { clearReopenState } = reopenSlice.actions;
export default reopenSlice.reducer;
