import { privateRequest } from "../../../utils/apiRequest";
import { capitalize, getSlugFromString } from "./../../../utils/functions";

export const fetchEndpoints = async ({ groupId }) => {
  const isAll = !groupId || groupId === 0; // Check if the selected group is All
  const endpoint = isAll ? `/endpoints` : `/endpoints/group/${groupId}`;

  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    // If the user not selected a group (selected 'All')
    if (isAll) {
      return {
        id: 0,
        groupName: "All",
        description: "All Endpoints",
        recruitingRule: "No rule",
        matchedHosts: res.data,
      };
    }

    // If the user selected a specific group
    return {
      ...res.data,
      groupItems: res?.data?.matchedHosts?.map((host) => host?.name),
    };
  }
};

export const removeEndpointHost = async ({ groupId, body }) => {
  const endpoint = `/endpoints/hosts/${groupId}`;
  const res = await privateRequest.delete(endpoint, { data: body });

  if (res.status === 200) {
    return body;
  }
};

export const fetchEndpointDetails = async ({ endpointId }) => {
  const endpoint = `/endpoints/${endpointId}`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return res.data;
  }
};

export const fetchEndpointGroup = async () => {
  const endpoint = `/endpoints/host/groups`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    const group = res?.data?.map((item) => ({
      id: item.id,
      name: capitalize(item?.group_name),
      slug: getSlugFromString(item?.group_name),
    }));

    return group;
  }
};

export const createEndpointGroup = async ({ body }) => {
  const endpoint = `/endpoints/group`;
  const res = await privateRequest.post(endpoint, body);

  if (res.status === 201) {
    const group = {
      id: res?.data.id,
      name: capitalize(res?.data?.group_name),
      slug: getSlugFromString(res?.data?.group_name),
    };

    return group;
  }
};

export const editEndpointGroup = async ({ body, groupId }) => {
  const endpoint = `/endpoints/group/${groupId}`;
  const res = await privateRequest.put(endpoint, body);

  if (res.status === 200) {
    const updatedGroup = {
      id: res?.data.id,
      groupName: res?.data?.group_name,
      description: res?.data?.description,
      recruitingRule: res?.data?.recruiting_rule,
      groupItems: res?.data?.hosts,
    };

    return updatedGroup;
  }
};

export const removeEndpointGroup = async ({ groupId }) => {
  const endpoint = `/endpoints/group/${groupId}`;
  const res = await privateRequest.delete(endpoint);

  if (res.status === 200) {
    return groupId;
  }
};