import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchOperators } from ".";

export const getOperators = createAsyncThunk("users/operators", fetchOperators);

const initialState = {
  operators: [],
  loading: false,
  error: null,
};

export const operatorsSlice = createSlice({
  name: "operators",
  initialState,
  reducers: {
    clearOperatorsState: (state) => {
      state.operators = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOperators.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getOperators.fulfilled, (state, action) => {
      state.loading = false;
      state.operators = action.payload;
    });
    builder.addCase(getOperators.rejected, (state, action) => {
      state.loading = false;
      state.operators = [];
      state.error = action.error.message;
    });
  },
});

export const { clearOperatorsState } = operatorsSlice.actions;

export default operatorsSlice.reducer;
