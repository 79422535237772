import React, { useState } from "react";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import { ClickAwayListener } from "@mui/base";

const DropdownPopper = ({
  anchorEl,
  setAnchorEl,
  placement = "bottom",
  children,
  triggerComponent,
}) => {
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = (e) => {
    if (anchorEl === null) return; // Don't set the anchor element to null if it's already null. Just return here to prevent unnecessary re-renders.

    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        {/* Popper Trigger */}
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
          onClick={handleClick}
        >
          {triggerComponent}
        </Box>

        {/* Actual Popper */}
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper sx={{ p: 1 }}>{children}</Paper>
            </Fade>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default DropdownPopper;
