import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import SeverityBadge from "../../../ui/SeverityBadge";
import WidgetTitle from "../../WidgetTitle";
import { capitalize } from "../../../../utils/functions";

const TopIncidents = ({ incidents }) => {

  if (incidents.length < 1) return null;
  
  return (
    <Box sx={{ my: 2 }}>
      <WidgetTitle title="Top 10 Incidents" />
      <IncidentTable incidents={incidents} />
    </Box>
  );
};

export default TopIncidents;

const IncidentTable = ({ incidents }) => {

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Incident ID</TableCell>
            <TableCell>Assignee</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Severity</TableCell>
            <TableCell align="center">Score</TableCell>
            <TableCell align="center">Resolution Time </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {incidents?.map((incident, index) => (
            <TableRow
              key={`${incident?.incident_id}-${index}`}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell width={120}>{incident?._id}</TableCell>
              <TableCell width={120}>
                {capitalize(incident?.assignee)}
              </TableCell>
              <TableCell align="center">-</TableCell>
              <TableCell align="center" width={50}>
                <SeverityBadge riskClass={incident?.severity} />
              </TableCell>
              <TableCell align="center">{incident?.score}</TableCell>
              <TableCell align="center">-</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
