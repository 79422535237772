import React from "react";

import ChartTemplate from "./ChartTemplate";

const IncidentsBySeverityChart = ({ riskClasses, incidents }) => {
  const handleBarClick = (index) => {
    // navigate to the desired route
    // navigate("/incidents");
  };

  const series = [
    {
      name: "Incidents",
      data: incidents,
    },
  ];
  const options = {
    chart: {
      events: {
        dataPointSelection: function (event, chartContext, config) {
          handleBarClick(config.dataPointIndex);
        },
        // .bind(this)
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    colors: ["#32D74B", "#FF881A", "#FF431A", "#ddd"],
    xaxis: {
      categories: riskClasses,
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
  };

  return <ChartTemplate options={options} series={series} />;
};

export default IncidentsBySeverityChart;
