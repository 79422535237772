import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { LockClock, Visibility, VisibilityOff } from "@mui/icons-material";
import { z } from "zod";

import { ROLES } from "../../../utils/constants";
import { useLoginMutation } from "../../app/services/authApiSlice";
import { setCredentials } from "./authSlice";
import { compareString } from "../../../utils/functions";
import { IconButton, InputAdornment } from "@mui/material";

const loginValidationSchema = z.object({
  email: z
    .string()
    .min(1, { message: "Email is required" })
    .email({ message: "Provide a valid email" }),
  password: z
    .string()
    .min(1, { message: "Password is required" })
    .min(6, { message: "Password should be at least 6 characters" }),
});

export default function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, reset, watch, formState } = useForm({
    defaultValue: {
      email: "",
      password: "",
      rememberMe: false,
    },
    resolver: zodResolver(loginValidationSchema),
  });
  const [login] = useLoginMutation();
  const { user } = useSelector((state) => state.auth);
  const { mode } = useSelector((state) => state.themeMode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { errors } = formState;

  // Server Errors
  const [error, setError] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const onSubmit = async (data) => {
    // Control the checkbox rememberMe
    const rememberMe = watch("rememberMe");
    try {
      const { user, accessToken } = await login(data).unwrap();

      dispatch(setCredentials({ user, accessToken, rememberMe }));
      reset({
        email: "",
        password: "",
        rememberMe: false,
      });

      // Redirect to specific dashboard
      if (compareString(user?.role, ROLES.Operator))
        navigate("/dashboards/operator", { replace: true });
      if (compareString(user?.role, ROLES.Manager))
        navigate("/dashboards", { replace: true });
    } catch (error) {
      setError(error);
      console.log(error);
      console.error(error);
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/", { replace: true });
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        height: "100%",
        width: "100%",
      }}
    >
      <Container
        component={Paper}
        maxWidth="xs"
        sx={{
          boxShadow: "1",
          paddingBottom: "30px",
          border: `1px solid ${mode === "dark" ? "#1a1a1a" : "#ececec"}`,
          marginBlock: "auto",
          backgroundColor: mode === "dark" ? "#000" : "#fafafa",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "left",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockClock />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
            id="login-form"
            className={`${mode === "dark" ? "login-inputs" : null}`}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              {...register("email")}
              error={!!errors.email}
              helperText={errors.email?.message || " "}
            />
            <TextField
              // margin="dense"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="current-password"
              {...register("password")}
              error={!!errors.password}
              helperText={errors.password?.message || " "}
            />

            <Grid container sx={{ alignItems: "center" }}>
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...register("rememberMe")}
                      // value="remember"
                      color="primary"
                    />
                  }
                  label="Remember me"
                />
              </Grid>
              <Grid
                item
                xs
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>

            {error ? (
              <Alert severity="error">Invalid email or password</Alert>
            ) : null}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
