import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TablePagination from "@mui/material/TablePagination";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useDeleteUserMutation } from "../../redux/app/services/userApiSlice";
import { showToast } from "../../redux/features/toast";
import ConfirmDialog from "../ui/ConfirmDialog";

const UserList = ({ users }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deleteUser] = useDeleteUserMutation();
  const theme = useTheme();

  const [confirm, setConfirm] = React.useState({
    title: "",
    subtitle: "",
    isOpen: false,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = async (userId) => {
    try {
      await deleteUser(userId);
      showToast({
        type: "success",
        message: "User is deleted successfully!",
      });
    } catch (error) {
      console.error(error);
    } finally {
      setConfirm({
        ...confirm,
        isOpen: false,
      });
    }
  };

  return (
    <Paper sx={{ marginTop: theme.spacing(2) }}>
      <TableContainer
        component={Paper}
        sx={{ background: theme?.palette?.background?.paper, boxShadow: 0 }}
      >
        <Table size="small" sx={{ minWidth: 650 }} aria-label="user list table">
          <CustomTableHead />
          <TableBody>
            {[...users]
              ?.sort((a, b) => a.id - b.id)
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <CustomTableRow key={row.id}>
                  <CustomTableCell width="100">{row.id}</CustomTableCell>
                  <CustomTableCell scope="row">
                    <UserNameWithAvatar row={row} />
                  </CustomTableCell>
                  <CustomTableCell>{row?.title}</CustomTableCell>
                  <CustomTableCell>{row?.roles[0]?.name}</CustomTableCell>
                  <CustomTableCell>{row?.phone}</CustomTableCell>
                  <CustomTableCell>
                    <IconButton
                      onClick={() => {
                        setConfirm({
                          title: "Are you sure you want to delete this user?",
                          subtitle: "This operation can't be undo.",
                          isOpen: true,
                          onConfirm: () => handleDelete(row.id),
                        });
                      }}
                      aria-label="delete user"
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </CustomTableCell>
                </CustomTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={users?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ConfirmDialog confirm={confirm} setConfirm={setConfirm} />
    </Paper>
  );
};

export default UserList;

const CustomTableHead = () => {
  return (
    <TableHead
      sx={{
        paddingBlock: "2rem",
        // backgroundColor: theme.palette.primary.main,
      }}
    >
      <TableRow
        sx={{
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "#212121" : theme.palette.grey[200],
          "& th": {
            border: 0,
          },
        }}
      >
        <TableCell>User ID</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Title</TableCell>
        <TableCell>Role</TableCell>
        <TableCell>Phone No</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};
const CustomTableRow = ({ children, sx, ...rest }) => {
  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
          borderBottom: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "#212121" : theme.palette.grey[200],
        },
        "& > th": {
          border: 0,
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </TableRow>
  );
};

const CustomTableCell = ({ children, sx, ...rest }) => {
  return (
    <TableCell
      sx={{
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "#212121" : theme.palette.grey[200],
        ...sx,
      }}
      {...rest}
    >
      {children}
    </TableCell>
  );
};

const UserNameWithAvatar = ({ row }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClick = (userId) => {
    navigate(`/users/${userId}`, { replace: true });
  };
  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Box>
        <Avatar
          sx={{
            width: 32,
            height: 32,
            backgroundColor: theme.palette.primary.light,
            color: "#fafafa",
          }}
          alt={row?.name?.toUpperCase()}
          src="."
        />
      </Box>
      <Box>
        <Typography
          onClick={() => handleClick(row?.id)}
          variant="body1"
          sx={{
            fontWeight: "bold",
            cursor: "pointer",
            "&:hover": {
              color: "#cecece",
            },
          }}
        >
          {row?.name?.toUpperCase()}
        </Typography>
        <Typography variant="body2">{row?.email}</Typography>
      </Box>
    </Box>
  );
};