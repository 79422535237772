import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";

const ReportHeader = ({ template, startDate, endDate }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h2" sx={{ fontWeight: "bold" }}>
          {template}
        </Typography>
        <Typography variant="h6">
          {dayjs(startDate).format("MMM DD, YYYY")} -{" "}
          {dayjs(endDate).format("MMM DD, YYYY")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ReportHeader;
