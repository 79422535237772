import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";

import {
  deleteUserAccount,
  updateUserDetails,
} from "../../../redux/features/users/userSlice";
import { capitalize, compareString } from "./../../../utils/functions";
import { showToast } from "../../../redux/features/toast";
import { ROLES } from "../../../utils/constants";
import ConfirmDialog from "../../ui/ConfirmDialog";
import useAuth from "../../../hooks/useAuth";
import Wrapper from "./Wrapper";

const PersonalInfo = ({ user }) => {
  const [name, setName] = useState(user?.name);
  const [role, setRole] = useState(user?.role);
  const [title, setTitle] = useState(user?.title);
  const [phone, setPhone] = useState(user?.phone);
  const [email, setEmail] = useState(user?.email);
  const [password, setPassword] = useState("");

  const [editingMode, setEditingMode] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user: loggedInUser } = useAuth();
  const [confirm, setConfirm] = useState({
    title: "",
    subtitle: "",
    isOpen: false,
  });

  const updateUser = (e) => {
    e.preventDefault();

    const body = {
      userId: user.id,
    };

    //Check if a user changed at least one value
    if (
      (!name || user?.name === name) &&
      (!title || user?.title === title) &&
      (!role || user?.role === role) &&
      (!phone || user?.phone === phone) &&
      (!email || user?.email === email) &&
      !password
    ) {
      showToast({
        type: "error",
        message: "There must be at least one change to update!",
      });
      return;
    }

    // Compare all the states with the fetched data
    if (!!name && user?.name !== name) {
      body["name"] = name;
      setName(name);
    }
    if (!!title && user?.title !== title) {
      body["title"] = title;
      setTitle(title);
    }
    if (!!role && user?.role !== role) {
      body["roleName"] = role;
      setRole(role);
    }
    if (!!phone && user?.phone !== phone) {
      body["phone"] = phone;
      setPhone(phone);
    }
    if (!!email && user?.email !== email) {
      body["email"] = email;
      setEmail(email);
    }
    if (!!password) {
      body["password"] = password;
      setPassword("");
    }

    dispatch(updateUserDetails({ userId: body.userId, body }));

    setEditingMode(false);
  };

  const handleDeleteUser = () => {
    dispatch(deleteUserAccount({ userId: user.id }));

    setConfirm({
      ...confirm,
      isOpen: false,
    });

    navigate("/dashboards", { replace: true });
  };

  const cancelUpdate = () => {
    setEditingMode(false);
    setName(user?.name);
    setRole(user?.role);
    setTitle(user?.title);
    setPhone(user?.phone);
    setEmail(user?.email);
    setPassword("");
  };
  const activateEditingMode = () => {
    setEditingMode(true);
  };

  return (
    <>
      <Wrapper title="Personal Info" withCTA={true}>
        <Box>
          {!editingMode ? (
            <Box sx={{ display: "flex", gap: 1 }}>
              <Btn
                label="Edit User"
                onClick={activateEditingMode}
                color="primary"
                variant="outlined"
              />
              {compareString(loggedInUser?.role, ROLES.Manager) ? (
                <Btn
                  label="Delete"
                  size="small"
                  onClick={() => {
                    setConfirm({
                      title: "Are you sure you want to delete this user?",
                      subtitle: "This operation can't be undo.",
                      isOpen: true,
                      onConfirm: () => handleDeleteUser(user?.id),
                    });
                  }}
                  variant="outlined"
                  color="error"
                />
              ) : null}
            </Box>
          ) : null}
          {editingMode ? (
            <Box sx={{ display: "flex", gap: 1 }}>
              <Btn label="Update" onClick={updateUser} />
              <Btn label="Cancel" onClick={cancelUpdate} variant="outlined" />
            </Box>
          ) : null}
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Avatar
            variant="rounded"
            alt={capitalize(name)}
            src="/placeholder-image.jpg"
          />
          <Grid container>
            <Grid item xs={12} sm={6} md={3}>
              <Info
                title="Name"
                state={name}
                setState={setName}
                editingMode={editingMode}
              />
              <Info title="User ID" state={user?.id} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Info
                title="Title"
                state={title}
                setState={setTitle}
                editingMode={editingMode}
              />
              <RoleInfo
                title="Role"
                state={role}
                setState={setRole}
                editingMode={editingMode}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Info
                title="Phone"
                state={phone}
                setState={setPhone}
                editingMode={editingMode}
              />
              <Info
                title="Email"
                state={email}
                setState={setEmail}
                editingMode={editingMode}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Info
                title="Password"
                state={password}
                setState={setPassword}
                editingMode={editingMode}
                isPassword={true}
              />
            </Grid>
          </Grid>
        </Box>
      </Wrapper>
      <ConfirmDialog confirm={confirm} setConfirm={setConfirm} />
    </>
  );
};

const InfoWrapper = ({ children, title }) => {
  return (
    <Box
      sx={{
        p: 1,
        pt: 0,
        m: 0.25,
        mb: 1,
        borderRadius: 1,
        height: "50px",
      }}
    >
      <Typography>{title}</Typography>
      {children}
    </Box>
  );
};

const Info = ({
  title,
  state,
  setState,
  editingMode,
  isPassword = false,
  children,
}) => {
  const onChange = (e) => {
    setState(e.target.value);
  };

  return (
    <InfoWrapper title={title}>
      {!!editingMode ? (
        <Input
          placeholder={isPassword ? "Your password" : ""}
          defaultValue={isPassword ? "" : state}
          onChange={onChange}
        />
      ) : (
        children || (
          <Typography
            variant="body2"
            color="lightgrey"
            sx={{ fontSize: ".85rem", mt: 0.6 }}
          >
            {isPassword ? "********" : state}
          </Typography>
        )
      )}
    </InfoWrapper>
  );
};

const RoleInfo = ({ title, state, setState, editingMode }) => {
  const { user } = useAuth();
  return (
    <InfoWrapper title={title}>
      {!!editingMode && compareString(user?.role, ROLES.Manager) ? (
        <RoleSelect state={state} setState={setState} />
      ) : (
        <Typography
          variant="body2"
          color="lightgrey"
          sx={{ fontSize: ".85rem", mt: 0.6 }}
        >
          {state}
        </Typography>
      )}
    </InfoWrapper>
  );
};

const RoleSelect = ({ state, setState }) => {
  const handleChange = (event) => {
    setState(event.target.value);
  };

  return (
    <FormControl variant="standard" sx={{ minWidth: 120, width: "100%" }}>
      <Select
        labelId="user-role"
        id="role"
        value={state}
        onChange={handleChange}
        label="Role"
      >
        <MenuItem value={`Operator`}>Operator</MenuItem>
        <MenuItem value={`Manager`}>Manager</MenuItem>
      </Select>
    </FormControl>
  );
};

const Btn = ({
  label,
  variant = "contained",
  onClick,
  color = "primary",
  sx,
}) => {
  return (
    <Button
      size="small"
      color={color}
      variant={variant}
      onClick={onClick}
      sx={{ textTransform: "capitalize", padding: 0, height: "24px", ...sx }}
    >
      {label}
    </Button>
  );
};

export default PersonalInfo;
