import { IconButton, Tooltip } from "@mui/material";
import React from "react";

import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";

const QueryFilterToggleButton = ({ showFilters, setShowFilters }) => {
  const handleToggle = () => {
    setShowFilters((prev) => {
      return !prev;
    });
  };
  return (
    <Tooltip title="Filter">
      <IconButton onClick={handleToggle} sx={{ borderRadius: 2 }}>
        {showFilters ? <FilterListOffIcon /> : <FilterListIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default QueryFilterToggleButton;
