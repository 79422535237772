import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import EndpointTable from "./EndpointTable";
import { useTheme } from "@mui/material";

export default function EndpointList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { group } = useParams();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const DEFAULT_SIZE = 10;

  const [rowsPerPage, setRowsPerPage] = useState(
    +searchParams.get("rowsPerPage") || DEFAULT_SIZE
  );

  const { data, loading } = useSelector((state) => state.endpoints.list);

  useEffect(() => {
    setSearchParams((newSearchParams) => {
      newSearchParams.set("rowsPerPage", rowsPerPage || 10);
      return newSearchParams;
    });
  }, []);

  useEffect(() => {
    if (group || pathname === "/endpoints") {
      setSearchParams((newSearchParams) => {
        newSearchParams.set("rowsPerPage", rowsPerPage);
        return newSearchParams;
      });
    }
  }, [group]);

  useEffect(() => {
    setRowsPerPage(+searchParams.get("rowsPerPage") || DEFAULT_SIZE);
  }, [searchParams]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          height: "100%",
          // mb: 2,
          borderRadius: 0,
          background: isDark ? "#181C24" : "#FFFFFF",
        }}
      >
        <EndpointTable
          data={data}
          loading={loading}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
