import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sessionRequest } from ".";

export const sendSessionRequest = createAsyncThunk(
  "actions/send-session-request",
  sessionRequest
);

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const liveSessionSlice = createSlice({
  name: "live-session",
  initialState,
  reducers: {
    clearLiveSessionState: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendSessionRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendSessionRequest.fulfilled, (state, action) => {
      state.data = action.payload.body;
      state.loading = false;
    });
    builder.addCase(sendSessionRequest.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const { clearLiveSessionState } = liveSessionSlice.actions;
export default liveSessionSlice.reducer;
