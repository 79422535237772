import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchEndpointUserDetails } from ".";

export const getEndpointUserDetails = createAsyncThunk(
  "endpoints/user-details",
  fetchEndpointUserDetails
);

const initialState = {
  data: {},
  loading: false,
  error: null,
};
const endpointUserDetailSlice = createSlice({
  name: "endpoint-user-details",
  initialState,
  reducers: {
    clearEndpointUserDetailState: (state) => {
      state.data = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEndpointUserDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getEndpointUserDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.data = action.payload;
    });
    builder.addCase(getEndpointUserDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clearEndpointUserDetailState } = endpointUserDetailSlice.actions;

export default endpointUserDetailSlice.reducer;
