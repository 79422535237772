import React from "react";
import { Autocomplete, TextField } from "@mui/material";

const AutoComplete = ({
  label = "Select",
  state,
  setState,
  options,
  propertyName = "label",
  defaultValue,
}) => {
  //   const [state, setState] = useState();
  const defaultProps = {
    options: options,
    getOptionLabel: (option) => option[propertyName],
  };
  return (
    <Autocomplete
      {...defaultProps}
      disablePortal
      id={`AutoComplete-${label}`}
      //   value={state}
      onChange={(event, newValue) => {
        setState(newValue);
      }}
      defaultValue={defaultValue ?? null}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} size="small" variant="standard" label={label} />
      )}
    />
  );
};

export default AutoComplete;
