import { apiSlice } from "./apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: () => "/api/auth/users",
      providesTags: ["User"],
    }),
    addNewUser: builder.mutation({
      query(body) {
        return {
          url: `/api/auth/register`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    updateUser: builder.mutation({
      query({ id, ...rest }) {
        console.log("Rest: ", { id, rest });
        return {
          url: `/api/auth/users/${id}`,
          method: "PUT",
          body: rest,
        };
      },
      invalidatesTags: ["User"],
    }),
    deleteUser: builder.mutation({
      query(id) {
        return {
          url: `/api/auth/users/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["User"],
    }),
  }),
  // overrideExisting: module.hot?.status() === "apply",
  overrideExisting: false,
});

export const {
  useGetAllUsersQuery,
  useAddNewUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApiSlice;
