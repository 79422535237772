import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import goldRank from "../../assets/gold.svg";
import silverRank from "../../assets/silver.svg";
import bronzeRank from "../../assets/bronze.svg";

import Skeleton from "./Skeleton";
import { capitalize, isObjectEmpty } from "../../utils/functions";
import { getIncidentInsightData } from "../../redux/features/tickets/incidentInsightSlice";

export default function IncidentsInsightTable() {
  const { incidents: insight, loading } = useSelector(
    (state) => state.tickets.incidentInsight
  );
  const dispatch = useDispatch();
  const theme = useTheme();
  const mode = theme.palette.mode;

  useEffect(() => {
    dispatch(getIncidentInsightData());
  }, []);

  if (loading) return <Skeleton rows={3} height={100} />;

  return (
    <TableContainer component={Paper} sx={{ marginBlock: 1 }}>
      <Table
        size="small"
        sx={{ minWidth: 650 }}
        aria-label="incidents insight table"
      >
        <TableHead sx={{ background: mode === "dark" ? "#030303" : "#eee" }}>
          <TableRow sx={{ "&>": { textAlign: "center" } }}>
            <TableCell rowSpan={2}>Type</TableCell>
            <TableCell align="center" colSpan={3}>
              Average Resolution Time
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              Unassigned Incidents
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              Assigned Incidents
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              Top 3 Operators
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">H</TableCell>
            <TableCell align="center">M</TableCell>
            <TableCell align="center">L</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TypeRow row={insight["FILE"]} />
          <TypeRow row={insight["HOST"]} />
          <TypeRow row={insight["NETWORK"]} />
          {/* <TypeRow row={insight["USER"]} /> */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const TypeRow = ({ row }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  // If the row object is {} then we must not render a table row
  if (isObjectEmpty(row)) return;

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell
        component="th"
        scope="row"
        sx={{
          background: mode === "dark" ? "#030303" : "#eee",
        }}
      >
        {row?.type?.toUpperCase()}
      </TableCell>
      <TableCell align="center">{row?.averageMTTR["High"]}</TableCell>
      <TableCell align="center">{row?.averageMTTR["Medium"]}</TableCell>
      <TableCell align="center">{row?.averageMTTR["Low"]}</TableCell>
      <TableCell align="center">{row?.unAssignedIncidents}</TableCell>
      <TableCell align="center">{row?.assignedIncidents}</TableCell>
      <TableCell align="center">
        <Stack direction="row" spacing={"3px"}>
          {row?.topOperators[0] ? (
            <RankIcon
              label={row?.topOperators[0]?.username}
              image={goldRank}
              background="linear-gradient(to right bottom, #0FB7E6, #0A7A99)"
              color="#030303"
            />
          ) : null}
          {row?.topOperators[1] ? (
            <RankIcon
              label={row?.topOperators[1]?.username}
              image={silverRank}
              background="linear-gradient(to right bottom, #0FB7E6, #0A7A99)"
              color="#030303"
            />
          ) : null}
          {row?.topOperators[2] ? (
            <RankIcon
              label={row?.topOperators[2]?.username}
              image={bronzeRank}
              background="linear-gradient(to right bottom, #0FB7E6, #0A7A99)"
              color="#030303"
            />
          ) : null}
          {/* <TopOperator
            label={row?.topOperators[0]?.username}
            image={goldRankImage}
            sx={{ background: "linear-gradient(to right bottom, gold, orangered)", color: "#030303" }}
          />
          <TopOperator
            label={row?.topOperators[1]?.username}
            image={silverRankImage}
            sx={{ background: "linear-gradient(to right bottom, #F1F7FE, #C0CDE0)", color: "#030303" }}
          />
          <TopOperator
            label={row?.topOperators[2]?.username}
            image={bronzeRankImage}
            sx={{ background: "linear-gradient(to right bottom, #DF9D6A, #B86634)", color: "#030303" }}
          /> */}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

const RankIcon = ({ rank = 1, label, image, background }) => {
  return (
    <Box
      sx={{
        position: "relative",
        background: background,
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          // top: '-15%',
          // left: '-15%',
          // background: 'white',
          // paddingBlock: .5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          width: 24,
          height: 24,
          overflow: "hidden",
        }}
      >
        <img src={image} alt={label} width={24} height={24} />
      </Box>
      <Typography sx={{ marginLeft: 3.5, paddingRight: 1, fontSize: "12px" }}>
        {capitalize(label)}
      </Typography>
    </Box>
  );
};

/*
const TopOperator = ({ label, image, ...rest }) => {
  const theme = useTheme();
  // const mode = theme.palette.mode;

  return (
    <Chip
      label={label}
      size="small"
      avatar={
        <Avatar
          sx={{
            // background: mode === "dark" ? "black" : theme.palette.grey[200],
            margin: 0
          }}
          alt={label?.toUpperCase()}
          // src={}
        />
      }
      sx={{
        // borderRadius: 2,
        // display: "flex",
        // gap: 0.5,
        // "& .MuiChip-label": {
        //   paddingBlock: 0.5,
        //   paddingLeft: 0.5,
        //   paddingRight: 0.75,
        //   fontWeight: "bold",
        // },
      }}
      {...rest}
    />
  );
};
*/
