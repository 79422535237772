import * as React from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  capitalize,
  compareString,
  formatTimeAgo,
} from "../../../utils/functions";
import { useDispatch } from "react-redux";
import { markNotificationAsRead } from "../../../redux/features/notifications/notificationSlice";

const Notification = ({ item, handleClickAway }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { mode, grey } = theme.palette;
  const dispatch = useDispatch();

  const handleClick = async () => {
    // Don't send a put request if the user already seen the notification.
    if (item?.status === true) {
      handleClickAway();
      navigate(item.pageLocation);
      return;
    }

    dispatch(markNotificationAsRead({ item }));

    // Don't give managerial access to operators
    const activity = item?.activity?.toLowerCase();
    if (activity !== "forensic board created" && activity !== "save to board") {
      navigate(item.pageLocation);
    }
    handleClickAway();
  };

  return (
    <Box
      sx={{
        my: 1.5,
      }}
    >
      <ListItem
        component="article"
        alignItems="flex-start"
        onClick={handleClick}
        sx={{
          borderRadius: 1,
          p: "8px 6px",
          backgroundColor:
            mode === "dark"
              ? item.status
                ? grey[900]
                : grey[800]
              : item.status
              ? grey[200]
              : grey[300],
          "&:hover": {
            background: mode === "dark" ? grey[800] : grey[100],
            "& .page-location": {
              textDecoration: "underline",
            },
          },
          cursor: "pointer",
        }}
      >
        <ListItemAvatar>
          <Avatar
            alt={item?.name?.toUpperCase()}
            src="/static/images/avatar/1.jpg"
          />
        </ListItemAvatar>
        <NotificationType item={item} />
      </ListItem>
      <Divider variant="inset" component="li" />
    </Box>
  );
};

const NotificationType = ({ item }) => {
  const activity = item.activity;

  let currentActivity;

  // Incident Assignment
  if (compareString(activity, "assignment")) {
    currentActivity = (
      <NotificationItemText
        item={item}
        primaryText={`${capitalize(
          item.name
        )} assigned you to resolve this incident. `}
        secondaryText={`Click here to open the incident and resolve it ASAP`}
      />
    );
  }

  // Resolve Incident
  if (compareString(activity, "completion")) {
    currentActivity = (
      <NotificationItemText
        item={item}
        primaryText={`${capitalize(
          item.name
        )} resolved this incident assigned by you. `}
        secondaryText={`Click here to open the incident and confirm.`}
      />
    );
  }

  // Add a comment
  if (compareString(activity, "comment added")) {
    currentActivity = (
      <NotificationItemText
        item={item}
        primaryText={`${capitalize(item.name)} commented on this incident. `}
        secondaryText={`Click here to open the incident and see the comment.`}
      />
    );
  }

  // Create a board
  if (compareString(activity, "forensic board created")) {
    currentActivity = (
      <NotificationItemText
        item={item}
        primaryText={`${capitalize(item.name)} created a new board. `}
        secondaryText={`Click here to see the created board.`}
      />
    );
  }

  // Save to board
  if (compareString(activity, "save to board")) {
    currentActivity = (
      <NotificationItemText
        item={item}
        primaryText={`${capitalize(item.name)} saved a query to a board. `}
        secondaryText={`Click here to see the board view page.`}
      />
    );
  }

  // Create a board
  if (compareString(activity, "forensic completion")) {
    currentActivity = (
      <NotificationItemText
        item={item}
        primaryText={`${capitalize(
          item.name
        )} closed the investigation board. `}
        secondaryText={`Click here to see the closed board.`}
      />
    );
  }

  // Reopened Incident
  if (compareString(activity, "reopen incident")) {
    currentActivity = (
      <NotificationItemText
        item={item}
        primaryText={`${capitalize(item.name)} sent a reopen request.`}
        secondaryText={`Click here to review the incident.`}
      />
    );
  }

  // Reopened Approved
  if (compareString(activity, "Reopen request approved")) {
    currentActivity = (
      <NotificationItemText
        item={item}
        primaryText={`${capitalize(item.name)} approved your reopen request.`}
        secondaryText={`Click here to review the incident.`}
      />
    );
  }

  // Reopened Rejected
  if (compareString(activity, "Reopen request rejected")) {
    currentActivity = (
      <NotificationItemText
        item={item}
        primaryText={`${capitalize(item.name)} rejected your reopen request.`}
        secondaryText={`Click here to review the incident.`}
      />
    );
  }

  return currentActivity;
};

const NotificationItemText = ({
  item,
  primaryText = "",
  secondaryText = "",
}) => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  return (
    <ListItemText
      primary={
        <>
          <Typography
            variant="h6"
            sx={{
              color:
                mode === "dark"
                  ? theme.palette.grey[300]
                  : theme.palette.grey[800],
              fontWeight: "bold",
            }}
          >
            {primaryText}
            <Typography
              variant="span"
              sx={{
                color:
                  mode === "dark"
                    ? theme.palette.grey[400]
                    : theme.palette.grey[300],
                fontWeight: "normal",
                whiteSpace: "nowrap",
              }}
            >
              {formatTimeAgo(new Date(item?.created_at).getTime())}
            </Typography>
          </Typography>
        </>
      }
      secondary={
        <React.Fragment>
          <Typography
            sx={{
              display: "inline",
            }}
            variant="caption"
            color="text.primary"
          >
            {item.target}
          </Typography>
          {` — ${secondaryText}`}
        </React.Fragment>
      }
    />
  );
};

export default Notification;
