// CustomChart.js
import React from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import NoDataFound from "./NoDataFound";
import { Box } from "@mui/material";

// Define the custom palette
const customPalette = [
  "#05F2AF",
  "#0BBFBF",
  "#193559",
  "#203142",
  "#1E232E",
  "#252836",
  "#212429",
  "#495057",
  "#ACB5BD",
  "#DEE2E6",
  "#F8F9FA",
  "#007BFF",
  "#00A4DB",
  "#90E0EF",
  "#CAF0F8",
  "#007BFF",
  "#00A4DB",
  "#90E0EF",
  "#00E0FF",
  "#FFD700",
  "#FF0000",
];

const ChartTemplate = ({
  options,
  series,
  type,
  height = "100%",
  notFoundMessage,
  ...rest
}) => {
  const { mode } = useSelector((state) => state.themeMode);

  const chartOptions = {
    // Remove the colors property if you want to use one of the apexChart's color palettes
    colors: customPalette, // It should be place at the top so that users can override the default colors

    ...options,
    chart: {
      ...options.chart,
      background: "transparent",
    },
    theme: {
      mode,
      palette: "palette4",
    },
    grid: {
      ...options.grid,

      // Horizontal lines (dashed lines)
      borderColor: mode === "dark" ? "#444" : "#dedede",
      strokeDashArray: 4,
      show: true,
    },
    dataLabels: {
      enabled: false,
    },

    plotOptions: {
      ...options?.plotOptions,
      bar: {
        ...options?.plotOptions?.bar,
        columnWidth: 15,
        // A distributed column chart means all the columns in the chart will have different colors. It will override custom values
        // distributed: false,
      },
    },
    tooltip: {
      enabled: true,
      theme: mode,
      marker: {
        show: true,
      },
    },
  };

  return series && series?.length > 0 ? (
    <Box
      sx={{
        height,
        // width: "100%",
        // // overflow: "hidden",
        // "&:hover": {
        //   overflowX: "auto",
        // },
      }}
    >
      <Chart
        options={chartOptions}
        series={series}
        type={type || "bar"}
        height={height}
        {...rest}
        style={{
          ...rest?.style,

          // width: "100px !important",
        }}
      />
    </Box>
  ) : (
    <>
      {notFoundMessage ? (
        <NoDataFound message={notFoundMessage} />
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

export default ChartTemplate;
