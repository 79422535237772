import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useTheme } from "@emotion/react";

import { changeTicketStatus } from "../../../redux/features/incidents/incidentSlice";
import { useDispatch } from "react-redux";
import useIncident from "../../../hooks/useIncident";
import useAuth from "../../../hooks/useAuth";

const steps = ["Question 1", "Question 2", "Question 3", "Question 4"];

export default function ResolutionSummaryStepper({
  incidentId,
  setResolutionModalOpen,
}) {
  const { getIncidentById } = useIncident();
  const questions = getIncidentById?.resolutionSummaryQuestions;
  const [activeStep, setActiveStep] = React.useState(0);
  const [answer1, setAnswer1] = React.useState("");
  const [answer2, setAnswer2] = React.useState("");
  const [answer3, setAnswer3] = React.useState("");
  const [answer4, setAnswer4] = React.useState("");
  const { user } = useAuth();
  const dispatch = useDispatch();

  const handleStatusChange = (e) => {
    let incidentSummary = {
      comment: [answer1, answer2, answer3, answer4],
    };
    dispatch(changeTicketStatus({ incidentId, incidentSummary }));
    setResolutionModalOpen(false);
    clearAnswers();
  };

  const clearAnswers = () => {
    setAnswer1("");
    setAnswer2("");
    setAnswer3("");
    setAnswer4("");
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    clearAnswers();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label + index} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <QuestionsWithAnswers
            questions={questions}
            answers={[answer1, answer2, answer3, answer4]}
          />
          <Box sx={{ display: "flex", pt: 2 }}>
            {/* <Box sx={{ flex: "1 1 auto" }} /> */}
            <Button variant="contained" onClick={handleStatusChange}>
              Resolve Incident
            </Button>
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* Question 1 */}
          {activeStep === 0 ? (
            <>
              <Typography sx={{ py: 3, color: "grey.300" }}>
                Hi {user.name}! It's time to tell us your{" "}
                <Typography
                  component="span"
                  sx={{ fontWeight: "bold", fontStyle: "italic" }}
                >
                  resolution summary
                </Typography>
                . Please answer the following questions.
              </Typography>
              <Question
                question={questions[activeStep]}
                questionNo={1}
                lastStep={activeStep === steps.length - 1}
                handleNext={handleNext}
                handleBack={handleBack}
                answer={answer1}
                setAnswer={setAnswer1}
              />
            </>
          ) : null}

          {/* Question 2 */}
          {activeStep === 1 ? (
            <Question
              question={questions[activeStep]}
              questionNo={2}
              lastStep={activeStep === steps.length - 1}
              handleNext={handleNext}
              handleBack={handleBack}
              answer={answer2}
              setAnswer={setAnswer2}
            />
          ) : null}

          {/* Question 3 */}
          {activeStep === 2 ? (
            <Question
              question={questions[activeStep]}
              questionNo={3}
              lastStep={activeStep === steps.length - 1}
              handleNext={handleNext}
              handleBack={handleBack}
              answer={answer3}
              setAnswer={setAnswer3}
            />
          ) : null}

          {/* Question 4 */}
          {activeStep === 3 ? (
            <Question
              question={questions[activeStep]}
              questionNo={4}
              lastStep={activeStep === steps.length - 1}
              handleNext={handleNext}
              handleBack={handleBack}
              answer={answer4}
              setAnswer={setAnswer4}
            />
          ) : null}
        </React.Fragment>
      )}
    </Box>
  );
}

const Question = ({
  question,
  questionNo = 1,
  handleNext,
  handleBack,
  lastStep,
  answer,
  setAnswer,
}) => {
  // const [answer, setAnswer] = React.useState("");
  const [error, setError] = React.useState("");

  const handleTextChange = (e) => {
    setAnswer(e.target.value);
    if (answer.length > 1) setError("");
  };

  const handleAddToSummary = () => {
    if (answer?.trim() === "") {
      setError("Please provide the answer to go to the next question.");
      return;
    }

    handleNext();
    // setAnswer("");
  };

  return (
    <React.Fragment>
      <Typography sx={{ mt: 2, mb: 1 }}>
        {questionNo}. {question}
      </Typography>
      <TextField
        value={answer}
        onChange={handleTextChange}
        placeholder="Write your answer here ..."
        autoFocus
        multiline
        fullWidth
        // rows={2}
        variant="standard"
        // label={`Your answer`}
        error={!!error}
        helperText={error}
      />
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          color="inherit"
          disabled={questionNo - 1 === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button variant="contained" onClick={handleAddToSummary}>
          {lastStep ? "Finish" : "Next"}
        </Button>
      </Box>
    </React.Fragment>
  );
};

const QuestionsWithAnswers = ({ questions, answers }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const darkMode = mode === "dark";
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {questions.map((question, index) => (
        <Box
          key={question + index}
          sx={{
            background: darkMode ? "#333" : "#eaeaea",
            "&:hover": { background: darkMode ? "#373737" : "#fafafa" },
            p: 1,
            transition: "background 200ms, color 200ms",
            borderRadius: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              color: darkMode ? "grey.200" : "grey.800",
            }}
          >
            {index + 1}. {question}
          </Typography>
          <Typography color={darkMode ? "grey.400" : "grey.600"}>
            {answers[index]}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
