import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

const TextInputField = (props) => {
  return (
    <FormControl variant="outlined" sx={{ width: "100%", marginY: 1 }}>
      <TextField
        {...props}
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </FormControl>
  );
};

export default TextInputField;
