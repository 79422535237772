import React from "react";

const AverageRankIcon = ({ color = "#9378FF" }) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.61526 9.51736L0 5.23285L1.93366 3.43776L4.61526 5.92718L11 0L15.5605 4.2337L20.0663 0.0508044L22 1.84589L15.5605 7.82388L11 3.59018L4.61526 9.51736ZM7.35158 12.3793H4.61526V20H7.35158V12.3793ZM2.79104 10.6859H0.0547264V20H2.79104V10.6859ZM11.9121 7.2989H9.17579V20H11.9121V7.2989ZM16.4726 9.83912H13.7363V20H16.4726V9.83912ZM21.0332 7.2989H18.2968V20H21.0332V7.2989Z"
        fill={color}
      />
    </svg>
  );
};

export default AverageRankIcon;
