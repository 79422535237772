import React from "react";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const FilterWrapper = ({
  title = "Title",
  paddingX = 0,
  borderColor,
  background,
  children,
  onAddFilter,
}) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const bd = borderColor ? borderColor : mode === "dark" ? "#333" : "#ccc";
  const bg = background ? background : theme.palette.background.paper;
  return (
    <Box
      sx={{
        background: bg,
        borderRight: { md: `1px solid ${bd}` },
        // paddingInline: { md: paddingX },
        padding: 1,
        margin: 1,
        ml: 0,
        borderRadius: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 1 }}>
        <Typography variant="h6" sx={{ mb: 0.5 }}>
          {title}{" "}
        </Typography>
        <Button variant="text" sx={{ fontSize: 10 }} onClick={onAddFilter}>
          + ADD FILTER
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: { xs: "column" },
          alignItems: "flex-start",
          gap: 1,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default FilterWrapper;
