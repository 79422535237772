import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

import JegnaLogo from "./jegna.png";
import useAuth from "../../hooks/useAuth";
import { ROLES } from "../../utils/constants";

const MainLogo = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useAuth();

  const isHomePage =
    pathname === "/dashboards" || pathname === "/dashboards/operator";
  const destination =
    user?.role === ROLES.Manager ? "/dashboards" : "/dashboards/operator";

  const goHome = () => {
    if (isHomePage) return;
    navigate(destination, { replace: true });
  };
  return (
    <Tooltip title="Go to the Dashboard">
      <Box
        onClick={goHome}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: !isHomePage ? "pointer" : "unset",
        }}
      >
        <img src={JegnaLogo} alt="Jegna Logo" width="120" />
      </Box>
    </Tooltip>
  );
};

export default MainLogo;
