import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  type: "success", // error, warning
  message: "",
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setToast: (state, action) => {
      const { type, message } = action.payload;
      state.isOpen = true;
      state.type = type || "success";
      state.message = message;
    },
    hideToast: (state) => {
      state.isOpen = false;
      state.message = "";
    },
  },
});

export const { setToast, hideToast } = toastSlice.actions;

export default toastSlice.reducer;
