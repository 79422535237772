import React from "react";

const DashboardIcon = ({ fill = "white" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9734 2.5C12.7631 2.49998 12.5583 2.49995 12.3848 2.51413C12.1943 2.52969 11.9695 2.56641 11.7434 2.68166C11.4297 2.84145 11.1748 3.09642 11.015 3.41002C10.8997 3.63621 10.863 3.86099 10.8475 4.05148C10.8333 4.22494 10.8333 4.42977 10.8333 4.64009V7.02659C10.8333 7.2369 10.8333 7.44173 10.8475 7.6152C10.863 7.80569 10.8997 8.03047 11.015 8.25666C11.1748 8.57026 11.4297 8.82523 11.7434 8.98501C11.9695 9.10026 12.1943 9.13699 12.3848 9.15255C12.5583 9.16672 12.7631 9.1667 12.9734 9.16667H15.3599C15.5702 9.1667 15.7751 9.16672 15.9485 9.15255C16.139 9.13699 16.3638 9.10026 16.59 8.98501C16.9036 8.82523 17.1586 8.57026 17.3183 8.25666C17.4336 8.03047 17.4703 7.80569 17.4859 7.6152C17.5001 7.44173 17.5 7.2369 17.5 7.02659V4.64009C17.5 4.42977 17.5001 4.22494 17.4859 4.05148C17.4703 3.86099 17.4336 3.63621 17.3183 3.41002C17.1586 3.09642 16.9036 2.84145 16.59 2.68166C16.3638 2.56641 16.139 2.52969 15.9485 2.51413C15.7751 2.49995 15.5702 2.49998 15.3599 2.5L12.9734 2.5Z"
        fill={fill}
      />
      <path
        d="M4.64009 10.8333C4.42977 10.8333 4.22494 10.8333 4.05148 10.8475C3.86099 10.863 3.63621 10.8997 3.41002 11.015C3.09642 11.1748 2.84145 11.4297 2.68166 11.7434C2.56641 11.9695 2.52969 12.1943 2.51413 12.3848C2.49995 12.5583 2.49998 12.7631 2.5 12.9734L2.5 15.3599C2.49998 15.5702 2.49995 15.7751 2.51413 15.9485C2.52969 16.139 2.56641 16.3638 2.68166 16.59C2.84145 16.9036 3.09642 17.1586 3.41002 17.3183C3.63621 17.4336 3.86099 17.4703 4.05148 17.4859C4.22494 17.5001 4.42977 17.5 4.64009 17.5H7.02659C7.2369 17.5 7.44173 17.5001 7.6152 17.4859C7.80569 17.4703 8.03047 17.4336 8.25666 17.3183C8.57026 17.1586 8.82523 16.9036 8.98501 16.59C9.10026 16.3638 9.13699 16.139 9.15255 15.9485C9.16672 15.7751 9.1667 15.5703 9.16667 15.3599V12.9734C9.1667 12.7631 9.16672 12.5583 9.15255 12.3848C9.13699 12.1943 9.10026 11.9695 8.98501 11.7434C8.82523 11.4297 8.57026 11.1748 8.25666 11.015C8.03047 10.8997 7.80569 10.863 7.6152 10.8475C7.44173 10.8333 7.2369 10.8333 7.02659 10.8333H4.64009Z"
        fill={fill}
      />
      <path
        d="M12.9734 10.8333C12.7631 10.8333 12.5583 10.8333 12.3848 10.8475C12.1943 10.863 11.9695 10.8997 11.7434 11.015C11.4297 11.1748 11.1748 11.4297 11.015 11.7434C10.8997 11.9695 10.863 12.1943 10.8475 12.3848C10.8333 12.5583 10.8333 12.7631 10.8333 12.9734V15.3599C10.8333 15.5702 10.8333 15.7751 10.8475 15.9485C10.863 16.139 10.8997 16.3638 11.015 16.59C11.1748 16.9036 11.4297 17.1586 11.7434 17.3183C11.9695 17.4336 12.1943 17.4703 12.3848 17.4859C12.5583 17.5001 12.7631 17.5 12.9734 17.5H15.3599C15.5702 17.5 15.7751 17.5001 15.9485 17.4859C16.139 17.4703 16.3638 17.4336 16.59 17.3183C16.9036 17.1586 17.1586 16.9036 17.3183 16.59C17.4336 16.3638 17.4703 16.139 17.4859 15.9485C17.5001 15.7751 17.5 15.5702 17.5 15.3599V12.9734C17.5 12.7631 17.5001 12.5583 17.4859 12.3848C17.4703 12.1943 17.4336 11.9695 17.3183 11.7434C17.1586 11.4297 16.9036 11.1748 16.59 11.015C16.3638 10.8997 16.139 10.863 15.9485 10.8475C15.7751 10.8333 15.5702 10.8333 15.3599 10.8333H12.9734Z"
        fill={fill}
      />
      <path
        d="M4.64009 2.5C4.42977 2.49998 4.22494 2.49995 4.05148 2.51413C3.86099 2.52969 3.63621 2.56641 3.41002 2.68166C3.09642 2.84145 2.84145 3.09642 2.68166 3.41002C2.56641 3.63621 2.52969 3.86099 2.51413 4.05148C2.49995 4.22494 2.49998 4.42976 2.5 4.64008L2.5 7.02659C2.49998 7.2369 2.49995 7.44173 2.51413 7.6152C2.52969 7.80569 2.56641 8.03047 2.68166 8.25666C2.84145 8.57026 3.09642 8.82523 3.41002 8.98502C3.63621 9.10026 3.86099 9.13699 4.05148 9.15255C4.22494 9.16672 4.42975 9.1667 4.64006 9.16667H7.02659C7.23689 9.1667 7.44174 9.16672 7.6152 9.15255C7.80569 9.13699 8.03047 9.10026 8.25666 8.98502C8.57026 8.82523 8.82523 8.57026 8.98502 8.25666C9.10026 8.03047 9.13699 7.80569 9.15255 7.6152C9.16672 7.44174 9.1667 7.23692 9.16667 7.02661V4.64009C9.1667 4.42978 9.16672 4.22494 9.15255 4.05148C9.13699 3.86099 9.10026 3.63621 8.98502 3.41002C8.82523 3.09642 8.57026 2.84145 8.25666 2.68166C8.03047 2.56641 7.80569 2.52969 7.6152 2.51413C7.44173 2.49995 7.23691 2.49998 7.0266 2.5L4.64009 2.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default DashboardIcon;
