import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

import CopyToClipboard from "../../ui/CopyToClipboard";

const UserInfo = ({ user }) => {
  const {
    name,
    endpoint_user,
    domain,
    frequent_endpoint_id,
    frequent_endpoint_hostname,
    os,
    ip,
    mac,
  } = user;
  return (
    <Card variant="outlined" sx={{ height: "100%" }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Item label="Name" value={name} />
            <Item label="Domain" value={domain} />
            <Item label="Username" value={endpoint_user} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Item
              label="Frequent Endpoint ID"
              value={
                <CopyToClipboard item={frequent_endpoint_id}>
                  {frequent_endpoint_id}
                </CopyToClipboard>
              }
            />
            <Item
              label="Frequent Endpoint Host"
              value={
                <CopyToClipboard item={frequent_endpoint_hostname}>
                  {frequent_endpoint_hostname}
                </CopyToClipboard>
              }
            />
            <Item label="Status" value="Connected" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Item label="IP Address" value={ip[ip?.length - 1]} />
            <Item label="MAC Address" value={mac[mac?.length - 1]} />
            <Item label="OS" value={os?.name} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Item = ({ label, value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        gap: 1,
        mb: 2,
      }}
    >
      <Typography sx={{ minWidth: 110, fontSize: 12 }} color="gray">
        {label}
      </Typography>{" "}
      <Box>{value}</Box>{" "}
    </Box>
  );
};

export default UserInfo;
