import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import CircleIcon from "@mui/icons-material/Circle";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import dayjs from "dayjs";

import Wrapper from "./Wrapper";
import { getUserInprogressIncidentsByType } from "../../../redux/features/users/user-incidents-by-type/inProgressSlice";
import { getUserCompletedIncidentsByType } from "../../../redux/features/users/user-incidents-by-type/completedSlice";
import { getUserOverdueIncidentsByType } from "../../../redux/features/users/user-incidents-by-type/overdueSlice";
import { ROLES } from "../../../utils/constants";
import { capitalize } from "../../../utils/functions";
import useAuth from "../../../hooks/useAuth";

const IncidentsByType = ({ user }) => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { InProgress, Completed, Overdue } = useSelector(
    (state) => state.users.userIncidents
  );

  const title =
    user?.role === ROLES.Manager
      ? `${capitalize(user?.name)} has assigned the following incidents.`
      : `Incidents assigned to ${capitalize(user?.name)}`;

  useEffect(() => {
    dispatch(
      getUserInprogressIncidentsByType({ userId, status: "InProgress" })
    );
    dispatch(getUserOverdueIncidentsByType({ userId, status: "Overdue" }));
    dispatch(getUserCompletedIncidentsByType({ userId, status: "Completed" }));
  }, []);

  return (
    <Wrapper title={title}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          justifyContent: "stretch",
          minHeight: "20rem",
        }}
      >
        <UserIncidents
          status="InProgress"
          total={InProgress?.incidents?.pagination?.totalItems || 0}
          state={InProgress}
        />
        <UserIncidents
          status="Overdue"
          total={Overdue?.incidents?.pagination?.totalItems || 0}
          state={Overdue}
        />
        <UserIncidents
          status="Completed"
          total={Completed?.incidents?.pagination?.totalItems || 0}
          state={Completed}
        />
      </Box>
    </Wrapper>
  );
};

const UserIncidents = ({ status = "InProgress", total, state }) => {
  const { incidents, loading } = state;
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  if (loading) return <Loading />;

  return (
    <Paper
      sx={{
        flex: 1,
        background: isDark ? "#14181c" : "#fafafa",
        borderRadius: 0,
        overflowX: "none",
        minHeight: "10rem",
        // p: 1,
      }}
    >
      <UserIncidentTitle>
        {status === "InProgress" ? "In Progress" : status} ({total})
      </UserIncidentTitle>

      {incidents?.data?.length < 1 ? (
        <NoDataFound />
      ) : (
        <TableContainer
          sx={{
            maxHeight: 300,
            overflowY: "hidden",
            px: 1,
            "&:hover": {
              overflowY: "scroll",
              pr: 0,
              scrollbarColor: "red",
              // scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "0.6em",
              },
              "&::-webkit-scrollbar-track": {
                background: (theme) => theme.palette.background.alt,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: (theme) => theme.palette.primary.main,
              },
              // "&::-webkit-scrollbar-thumb:hover": {
              //   background: (theme) => theme.palette.background.alt,
              // },
            },
          }}
        >
          <Table size="small" stickyHeader>
            <UserIncidentTableHeader
              time={status === "Completed" ? "Resolution" : "Remaining"}
            />
            <UserIncidentTableBody>
              {incidents?.data?.map((incident) => (
                <UserIncidentTableRow
                  key={incident.incident_id}
                  incidentId={incident.incident_id}
                  assignedOperator={incident.assignedOperator}
                  time={
                    status === "Completed"
                      ? incident?.ticket_updated_time
                      : incident.mttr_remaining
                  }
                  severity={incident.severity}
                  status={incident.status}
                />
              ))}
            </UserIncidentTableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

const UserIncidentTitle = ({ children }) => {
  return <Box sx={{ ml: 1, mt: 1 }}>{children}</Box>;
};

const UserIncidentTableHeader = ({ children, time }) => {
  const { user } = useAuth();

  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Incident ID</TableCell>
        {user?.role === ROLES.Manager ? (
          <TableCell>Assignee</TableCell>
        ) : (
          <TableCell>{time} Time</TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

const UserIncidentTableBody = ({ children }) => {
  return <TableBody>{children}</TableBody>;
};

const UserIncidentTableRow = ({
  incidentId,
  assignedOperator,
  time,
  severity,
  status,
}) => {
  const { user } = useAuth();

  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  let iconColor = "secondary";
  if (severity === "Low") iconColor = "success";
  if (severity === "Medium") iconColor = "warning";
  if (severity === "High") iconColor = "error";

  const getHumanTime = (value) => dayjs(value).format("MMM DD, YYYY HH:mm");

  return (
    <TableRow
      sx={{
        "&:nth-of-type(odd)": {
          backgroundColor: isDark ? "#0f1317" : theme.palette.grey[100],
        },
      }}
    >
      <TableCell sx={{ borderColor: theme.palette.background.alt }}>
        <CircleIcon
          color={iconColor}
          fontSize="small"
          sx={{ width: ".75rem" }}
        />
      </TableCell>
      <TableCell sx={{ borderColor: theme.palette.background.alt }}>
        {incidentId}
      </TableCell>
      {user?.role === ROLES.Manager ? (
        <TableCell sx={{ borderColor: theme.palette.background.alt }}>
          {assignedOperator && capitalize(assignedOperator)}
        </TableCell>
      ) : (
        <TableCell sx={{ borderColor: theme.palette.background.alt }}>
          {status !== "Completed" ? time : getHumanTime(time)}
        </TableCell>
      )}
    </TableRow>
  );
};

const NoDataFound = () => {
  return (
    <Box
      sx={{
        height: "85%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: (theme) => theme.palette.grey[500],
      }}
    >
      No data found!
    </Box>
  );
};

const Loading = () => {
  return (
    <Paper
      sx={{
        flex: 1,
        background: (theme) => theme.palette.background.default,
        borderRadius: 0,
        // p: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // minHeight: "20rem",
      }}
    >
      <Typography>Loading...</Typography>
    </Paper>
  );
};

export default IncidentsByType;
