import React from "react";

import ResolutionSummaryStepper from "./ResolutionSummaryStepper";

const IncidentResolutionSummary = ({ incident, setResolutionModalOpen }) => {
  const incidentId = incident?.info?.incident_id;

  return (
    <div>
      <ResolutionSummaryStepper
        incidentId={incidentId}
        setResolutionModalOpen={setResolutionModalOpen}
      />
    </div>
  );
};

export default IncidentResolutionSummary;
