import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

const ClearFiltersButton = ({ label, onClick }) => {
  return (
    <Tooltip title="Clear Filter">
      <IconButton onClick={onClick} sx={{ borderRadius: 2 }}>
        <FilterAltOffIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ClearFiltersButton;
