import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import BaseWidget from "./BaseWidget";
import WidgetHeader from "./WidgetHeader";
import WidgetContent from "./WidgetContent";
import NoDataFound from "../charts/NoDataFound";
import WidgetLoading from "./WidgetLoading";
import useAuth from "../../hooks/useAuth";
import { getIncidentsByRemainingTime } from "../../redux/features/dashboards/operator/incidentsByRemainingTimeSlice";
import { useDashboard } from "../../hooks/useDashboard";
import { contentWidth } from "../../utils/constants";
import { useTheme } from "@mui/material";
import { hexToRgba } from "../../utils/functions";
import MuiMenu from "./MuiMenu";

export default function IncidentByRemainingTimeWidget() {
  const [days, setDays] = useState("7days");
  const { incidentsByRemainingTime } = useDashboard();
  const { loading } = incidentsByRemainingTime;

  const { user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIncidentsByRemainingTime({ userId: user?.id }));
  }, [dispatch]);

  return (
    <BaseWidget>
      <WidgetHeader title="Incident Remaining Time">
        <MuiMenu days={days} setDays={setDays} />
      </WidgetHeader>
      {loading ? <WidgetLoading /> : <RemainingIncidents days={days} />}
    </BaseWidget>
  );
}

const RemainingIncidents = ({ days }) => {
  const [incidents, setIncidents] = useState([]);
  const { incidentsByRemainingTime } = useDashboard();
  const { data } = incidentsByRemainingTime;

  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  useEffect(() => {
    setIncidents(data[days]?.incidents);
  }, [days]);

  return (
    <WidgetContent contentWidth={contentWidth.FULL_WIDTH}>
      {incidents?.length > 0 ? (
        <TableContainer
          sx={{
            borderRadius: 0,
            maxHeight: 200,
            overflow: "hidden",
            "&:hover": {
              overflowY: "auto",
            },
          }}
        >
          <Table
            sx={{
              // minWidth: 650,
              width: "100%",
            }}
            size="small"
            stickyHeader
            aria-label="incident remaining table"
          >
            <TableHead>
              <TableRow sx={{ textTransform: "uppercase" }}>
                <TableCell
                  sx={{
                    fontSize: 10,
                    width: 150,
                    background: isDark ? "#0f1317" : "#edebeb",
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: 10,
                    textAlign: "center",
                    background: isDark ? "#0f1317" : "#edebeb",
                  }}
                >
                  MTTR
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: 10,
                    textAlign: "center",
                    background: isDark ? "#0f1317" : "#edebeb",
                  }}
                >
                  Time Left
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {incidents?.map((incident, index) => (
                <RemainingIncident
                  key={incident?.id}
                  incident={incident}
                  index={index}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoDataFound />
      )}
    </WidgetContent>
  );
};

const RemainingIncident = ({ incident, index }) => {
  const navigate = useNavigate();
  const incidentId = incident.id.split(",")[0];
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  // TODO: Remove this if the api data is formatted as expected
  let timeLeft = incident?.remainingTime?.split(",");
  timeLeft = `${timeLeft[0]} : ${timeLeft[1]}`;

  const chipColor = theme.palette.warning.main;
  const customColor =
    chipColor !== "default" ? hexToRgba(chipColor, 0.15) : null;

  const handleClick = () => {
    navigate(`/incidents/${incidentId}`);
  };

  return (
    <TableRow
      key={incidentId}
      hover
      role="checkbox"
      tabIndex={-1}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell
        sx={{
          border: "none",
          background:
            index % 2 !== 0 ? "transparent" : isDark ? "#0B0E11" : "#fcfcfc",
        }}
      >
        <Button size="small" color={"inherit"} onClick={() => handleClick()}>
          {incidentId}
        </Button>
      </TableCell>
      <TableCell
        sx={{
          width: 100,
          textAlign: "center",
          border: "none",
          background:
            index % 2 !== 0 ? "transparent" : isDark ? "#0B0E11" : "#fcfcfc",
        }}
        component="th"
        scope="row"
      >
        {incident?.mttr}
      </TableCell>
      <TableCell
        sx={{
          width: 100,
          textAlign: "center",
          border: "none",
          background:
            index % 2 !== 0 ? "transparent" : isDark ? "#0B0E11" : "#fcfcfc",
        }}
        component="th"
        scope="row"
      >
        <Chip
          label={timeLeft}
          color="warning"
          size="small"
          sx={{
            display: "flex",
            alignItems: "center",
            width: "fit-content",
            backgroundColor: customColor,
            color: chipColor,
            borderRadius: "50px",
            p: "5px",
          }}
        />
      </TableCell>
    </TableRow>
  );
};
