import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import useIncident from "../../hooks/useIncident";
import useAuth from "../../hooks/useAuth";
import CommentForm from "./CommentForm";
import Comment from "./Comment";
import { compareString } from "../../utils/functions";
import { STATUS } from "../../utils/constants";

export default function Comments() {
  const [formState, setFormState] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const { getIncidentById } = useIncident();
  const { incident, comments } = getIncidentById;
  const status = incident?.info?.ticket_status;
  const { user } = useAuth();

  return (
    <>
      {/* Prevent unassigned incidents from accepting comments */}
      {compareString(status, STATUS.Backlog) ? <NotAllowedToComment /> : null}

      {/* Allow only In progress incidents to accept comments */}
      {compareString(status, STATUS.InProgress) ? (
        <CommentForm
          formState={formState}
          setFormState={setFormState}
          editMode={editMode}
          setEditMode={setEditMode}
          selectedComment={selectedComment}
        />
      ) : null}

      <Box sx={{ maxHeight: 320, overflowY: "auto" }}>
        {comments?.map((comment) => (
          <Comment
            comment={comment}
            setFormState={setFormState}
            commentClosed={compareString(status, STATUS.Completed)}
            setEditMode={setEditMode}
            setSelectedComment={setSelectedComment}
            key={comment?._id}
            isRight={
              comment?._source?.users &&
              user?.id === comment?._source?.users[0]?.id
                ? true
                : false
            }
          />
        ))}
      </Box>

      {/* Prevent users from commenting on this incident if it is completed */}
      {compareString(status, STATUS.Completed) ? <CommentClosed /> : null}
    </>
  );
}

const NotAllowedToComment = () => {
  return (
    <Box>
      <Typography variant="h5">Not allowed to comment.</Typography>
      <Typography variant="body2" color="grey">
        In order for you to comment on this incident, first it must be opened.
      </Typography>
    </Box>
  );
};

const CommentClosed = () => {
  return (
    <Box
      sx={{
        mt: 2,
        textAlign: "center",
        p: 2,
        borderRadius: 1,
      }}
    >
      <Typography variant="h5" color="grey">
        The Comment is closed.
      </Typography>
      <Typography variant="body2" color="grey">
        In order for you to comment on this incident it should be re-opened.
      </Typography>
    </Box>
  );
};