import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const RouteNotFound = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/", { replace: true });
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#555",
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "#555",
          minHeight: "100%",
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontSize: "2rem", color: "#cecece", fontWeight: "bold" }}
        >
          404 - Route Not Found
        </Typography>
        <Typography variant="h6" sx={{ fontSize: "1rem" }}>
          Please go back to the home page.
        </Typography>
        <Button variant="contained" sx={{ fontSize: "1rem" }} onClick={goHome}>
          GO HOME
        </Button>
      </Box>
    </Box>
  );
};

export default RouteNotFound;
