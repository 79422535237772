import React from "react";
import ReactApexChart from "react-apexcharts";

const PerformanceChart = ({
  performances,
  isDark = true,
  color = "#247BAA",
}) => {
  const series = [
    {
      name: "Performances",
      data: performances || [0, 0, 0, 0],
    },
  ];

  const options = {
    chart: {
      height: 250,
      type: "area",
      zoom: {
        enabled: false,
      },

      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      theme: isDark ? "dark" : "light",
    },
    dataLabels: {
      enabled: true,
    },
    colors: [color],
    stroke: {
      curve: "straight",
    },
    yaxis: {
      title: {
        text: "Operator Score",
      },
    },
    grid: {
      borderColor: isDark ? "#3A3A3A" : "#bebebe",
      strokeDashArray: 7,

      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      categories: ["Week 1", "Week 2", "Week 3", "Week 4"],
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={220}
    />
  );
};

export default PerformanceChart;
