import React from "react";
import Box from "@mui/material/Box";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

import WidgetTitle from "../../WidgetTitle";
import Rank from "../../Rank";

const OperatorRankByType = ({ data }) => {
  return (
    <div>
      <WidgetTitle title="Operator Rank By Type" />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Rank
          value="File Rank"
          color="info"
          label={data?.fileRank}
          icon={<FolderOutlinedIcon color="info" />}
        />
        <Rank
          value="Host Rank"
          color="warning"
          label={data?.hostRank}
          icon={<DesktopWindowsOutlinedIcon color="warning" />}
        />
        <Rank
          value="Network Rank"
          color="success"
          label={data?.networkRank}
          icon={<LanOutlinedIcon color="success" />}
        />
        <Rank
          value="Overall Rank"
          color="secondary"
          label={data?.avgRank}
          icon={<DocumentScannerIcon color="secondary" />}
        />
      </Box>
    </div>
  );
};

export default OperatorRankByType;
