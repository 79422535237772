import axios from "axios";
import { useEffect, useState } from "react";

import { BASE_URL } from "../utils/constants";
import useAuth from "./useAuth";

const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { token } = useAuth();

  const endpoint = BASE_URL + url;

  let cache = {};
  const fetch = async () => {
    setLoading(true);
    setError(null);
    try {
      if (cache[endpoint]) {
        const data = cache[endpoint];
        setData(data);
        // setLoading(false);
      } else {
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response?.data);
        cache[endpoint] = response?.data;
      }
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetch();
  }, [url, token]);

  return { data, loading, error };
};

export default useFetch;
