import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material";

import { deleteComment } from "../../redux/features/incidents/incidentSlice";
import { formatTimeAgo } from "../../utils/functions";
import ConfirmDialog from "../ui/ConfirmDialog";
import useAuth from "../../hooks/useAuth";

const Comment = ({
  setFormState,
  comment,
  commentClosed = false,
  setEditMode,
  setSelectedComment,
  isRight = false,
}) => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const [confirm, setConfirm] = React.useState({
    title: "",
    subtitle: "",
    isOpen: false,
  });
  const {
    _id: id,
    _source: { comment: text, created_at, updated_at, users },
  } = comment;
  const timestamp = new Date(updated_at || created_at).getTime();

  const handleEdit = async (commentId) => {
    setEditMode(true);
    setFormState(text);
    setSelectedComment(commentId);
  };

  const handleDelete = (commentId) => {
    dispatch(deleteComment({ commentId }));
    setConfirm({
      ...confirm,
      isOpen: false,
    });
  };

  return (
    <>
      <CommentWrapper isRight={isRight}>
        <Avatar
          sx={{
            width: 32,
            height: 32,
            position: "absolute",
            marginLeft: !isRight ? "-36px" : 0,
            marginRight: isRight ? "-12px" : 0,
            marginTop: "4px",
          }}
          alt={users && users[0]?.name?.toUpperCase()}
          src="/static/images/avatar/1.jpg"
        />
        <Box sx={{ width: "100%" }}>
          <ListItemText
            sx={{
              pr: isRight ? 3 : 0,
            }}
            primary={<CommentHeader users={users} timestamp={timestamp} />}
            secondary={<CommentContent comment={text} />}
          />
          {users && user?.id === users[0]?.id ? (
            <Box
              sx={{
                display: "flex",
                gap: "3px",
                alignItems: "center",
                marginBottom: 1,
              }}
            >
              <CommentButton
                disabled={commentClosed}
                hoverColor="green"
                onClick={() => handleEdit(id)}
              >
                Edit
              </CommentButton>
              <Divider
                orientation="vertical"
                variant="fullWidth"
                sx={{ color: "red", borderWidth: 2, justifySelf: "stretch" }}
              />

              <CommentButton
                disabled={commentClosed}
                onClick={() => {
                  setConfirm({
                    title: "Are you sure you want to delete this comment?",
                    subtitle: "This operation can't be undo.",
                    isOpen: true,
                    onConfirm: () => handleDelete(id),
                  });
                }}
              >
                Delete
              </CommentButton>
            </Box>
          ) : null}
        </Box>
      </CommentWrapper>
      <ConfirmDialog confirm={confirm} setConfirm={setConfirm} />
    </>
  );
};

const CommentWrapper = ({ children, isRight }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  return (
    <Paper
      sx={{
        mt: 1,
        background: mode === "dark" ? "#030303" : "#fcfcfc",
        mr: !isRight ? 6 : 0,
        ml: isRight ? 6 : 0,
        border: "1px solid",
        borderColor: mode === "dark" ? "#222" : "#efefef",
        borderTopLeftRadius: isRight ? "20px" : 0,
        borderTopRightRadius: !isRight ? "20px" : 0,
        position: "relative",
      }}
    >
      <ListItem
        alignItems="flex-start"
        sx={{
          flexDirection: isRight ? "row-reverse" : "row",
          paddingBlock: 0,
          pl: isRight ? 2 : 5,
          position: "relative",
        }}
      >
        {children}
      </ListItem>
    </Paper>
  );
};

const CommentHeader = ({ users, timestamp }) => {
  return (
    <Box
      component="span"
      sx={{ display: "flex", alignItems: "center", gap: 1 }}
    >
      <Typography
        sx={{ display: "inline", fontWeight: "bold" }}
        component="span"
        variant="body2"
        color="primary.main"
      >
        @{users && users[0]?.name}
      </Typography>
      <Typography component="span" variant="body2" color="gray">
        {formatTimeAgo(timestamp)}
      </Typography>
    </Box>
  );
};

const CommentContent = ({ comment }) => {
  return (
    <Box component="span">
      {/* 
          Check if the comment is an array to identify resolution summaries.
          This will happen if the incident is reopened.
          That way we can distinguish between regular comments and resolution summaries.
      */}
      {Array.isArray(comment) ? (
        <ResolutionSummaryAsAComment summary={comment} />
      ) : (
        <Typography sx={{ display: "inline" }} component="span" variant="body2">
          {comment}
        </Typography>
      )}
    </Box>
  );
};

const CommentButton = ({
  disabled = false,
  onClick,
  hoverColor = "red",
  children,
}) => {
  return (
    <Typography
      disabled={disabled}
      variant="button"
      component="button"
      onClick={onClick}
      sx={{
        textTransform: "capitalize",
        cursor: "pointer",
        fontSize: 11,
        background: "transparent",
        border: 0,
        color: "grey",
        "&:hover": {
          color: hoverColor,
          background: "#030303",
          "&:disabled": {
            color: "grey",
            cursor: "initial",
          },
        },
        paddingBlock: 0,
      }}
    >
      {children}
    </Typography>
  );
};

const ResolutionSummaryAsAComment = ({ summary }) => {
  const questions = useSelector(
    (state) => state.incident.resolutionSummaryQuestions
  );
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, paddingY: 1 }}>
      {summary.map((answer, index) => (
        <Box
          key={`answer-${index}`}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Typography
            variant="body1"
            color="lightgrey"
            sx={{ fontWeight: "bold" }}
          >
            {questions[index]}
          </Typography>
          <Typography
            component="span"
            variant="body2"
            sx={{ fontStyle: "italic" }}
          >
            {answer}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Comment;
