import { useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ListIcon from "@mui/icons-material/List";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import Header from "../Header";
import useAuth from "../../hooks/useAuth";
import { ROLES } from "../../utils/constants";

const IncidentHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type")?.toLowerCase();
  const { user } = useAuth();
  const isOperator = user.role === ROLES.Operator;

  const routes = [
    {
      label: "File",
      slug: "file",
      Icon: <FolderOutlinedIcon />,
    },
    {
      label: "Host",
      slug: "host",
      Icon: <DesktopWindowsOutlinedIcon />,
    },
    {
      label: "Network",
      slug: "network",
      Icon: <LanOutlinedIcon />,
    },
    {
      label: "Users",
      slug: "user",
      Icon: <Diversity3OutlinedIcon />,
    },
  ];

  const handleClick = (slug) => {
    setSearchParams((searchParams) => {
      searchParams.set("type", slug);
      return searchParams;
    });
  };

  return (
    <Box
      style={{
        width: "100%",
        height: "71px",
        padding: "21px",
      }}
    >
      <Grid
        container
        // sx={{ maxWidth: "1000px" }}
      >
        <Grid item xs={4}>
          <Header title="Incidents" subtitle="Incidents Page" />
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8} md={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: { xs: 0.25, md: 1 },
                  }}
                >
                  {!isOperator ? <FavoriteIncidents /> : null}
                  {type ? (
                    <TypeButton
                      onClick={() =>
                        setSearchParams((searchParams) => {
                          searchParams.delete("type");
                          return searchParams;
                        })
                      }
                      // backgroundColor={"#000" || theme.palette.background.paper}
                    >
                      All
                    </TypeButton>
                  ) : null}

                  {routes.map(({ label, slug, Icon }) => (
                    <TypeButton
                      key={slug}
                      onClick={() => handleClick(slug)}
                      isActive={type === slug}
                    >
                      {Icon}
                      {label}
                    </TypeButton>
                  ))}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={6}>
              {isOperator ? <OperatorSpecificButtons /> : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const OperatorSpecificButtons = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: { xs: 0.25, md: 1 },
        }}
      >
        <FavoriteIncidents />
        <MyIncidents />
      </Box>
    </Box>
  );
};

const MyIncidents = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const assignee = searchParams.get("assignee")?.toLowerCase();
  const { user } = useAuth();

  const handleMyIncidents = () => {
    if (user.role !== "Operator") return;

    setSearchParams((searchParams) => {
      if (assignee) {
        searchParams.delete("assignee");
        return searchParams;
      }
      searchParams.set("assignee", user.id);
      return searchParams;
    });
  };

  return (
    <TypeButton onClick={handleMyIncidents} isActive={!!assignee} enabled>
      <ListIcon />
      My Incidents
    </TypeButton>
  );
};

const FavoriteIncidents = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const star = searchParams.get("star")?.toLowerCase();

  const handleMyFavoriteIncidents = () => {
    setSearchParams((searchParams) => {
      if (star) {
        searchParams.delete("star");
        return searchParams;
      }
      searchParams.set("star", true);
      return searchParams;
    });
  };

  return (
    <TypeButton onClick={handleMyFavoriteIncidents} isActive={!!star} enabled>
      <AutoAwesomeIcon />
      Favorites
    </TypeButton>
  );
};

const TypeButton = ({
  children,
  onClick,
  isActive = false,
  enabled = false, // Used to prevent the button from being disabled
}) => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  return (
    <Button
      onClick={onClick}
      disabled={isActive && !enabled}
      sx={{
        my: 2,
        fontSize: { xs: 10, sm: 12 },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        textAlign: "center",
        backgroundColor: isActive
          ? mode === "dark"
            ? theme.palette.primary.main
            : theme.palette.primary.light
          : mode === "dark"
          ? theme.palette.background.paper
          : theme.palette.grey[200],
        color:
          mode === "dark" ? theme.palette.grey[400] : theme.palette.grey[800],
      }}
    >
      {children}
    </Button>
  );
};

export default IncidentHeader;
