import React from "react";
import Typography from "@mui/material/Typography";

const WidgetTitle = ({ title }) => {
  return (
    <Typography variant="h5" sx={{ marginBottom: 1, marginTop: 1 }}>
      {title}
    </Typography>
  );
};

export default WidgetTitle;
