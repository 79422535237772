import dayjs from "dayjs";

import { store } from "../../app/store";
import { privateRequest } from "../../../utils/apiRequest";
import { capitalize } from "../../../utils/functions";
import { showToast } from "../toast";

export const getAllIncidents = async (params) => {
  const res = await privateRequest.get("/incidents", { params });

  if (res.status === 200) {
    return {
      incidents: mappedIncidents(res.data.data),
      total: res.data.docCount,
      page: res.data.page,
      pages: res.data.pages,
    };
  }
};

function mappedIncidents(data) {
  return data?.map((incident) => ({
    star: incident.star || [],
    incident_id: incident.incident_id,
    incident_name: incident.incident_name,
    severity: incident.severity,
    ticketStatus: incident.ticket_status,
    type: capitalize(incident.type),
    // Stringify and parse to avoid a non-serialization error
    last_updated: JSON.parse(
      JSON.stringify(
        dayjs(incident.creation_time * 1000).format("YYYY-MM-DD HH:mm")
      )
    ),
    description: incident.description,
    engineer_assigned: incident.engineer_assigned,
    hostname: incident.host_name,
    engineer_id: incident.engineer_id,
    remark: incident?.remark,
  }));
}

export const getIncidentById = async ({ incidentId }) => {
  const endpoint = `/incidents/${incidentId}`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return res.data;
  }
};

export const updateStar = async ({ incidentId, starred, userId }) => {
  const endpoint = `/incidents/${incidentId}`;
  const body = {
    star: starred,
  };

  const res = await privateRequest.patch(endpoint, body);

  if (res.status === 200) {
    // Set the state
    return { incidentId, userId };
  }
};

export const updateIncidentName = async ({ incidentId, name }) => {
  const endpoint = `/incidents/${incidentId}`;
  const body = {
    incident_name: name,
  };

  const res = await privateRequest.patch(endpoint, body);
  if (res.status === 200) {
    // Set the state
    return name;
  }
};

export const updateStatus = async ({ incidentId, incidentSummary }) => {
  const endpoint = `/incidents/${incidentId}`;
  const commentEndpoint = `/comments/incidents/${incidentId}/comments`;
  const body = {
    ticket_status: "Completed",
    ticket_updated_time: Date.now(),
  };

  const res = await privateRequest.patch(endpoint, body);

  if (res.status === 200) {
    // Add it to the comments index
    await privateRequest.post(commentEndpoint, incidentSummary);

    return {
      incidentSummary: incidentSummary.comment,
      ticketStatus: "Completed",
    };
  }
};

export const createComment = async ({ incidentId, formState }) => {
  const endpoint = `/comments/incidents/${incidentId}/comments`;
  const body = { comment: formState };
  const { user } = store.getState().auth;

  const res = await privateRequest.post(endpoint, body);

  if (res.status === 200) {
    return {
      _id: res.data._id,
      _source: {
        comment: formState,
        created_at: new Date().getTime(),
        incident_id: incidentId,
        users: [
          {
            name: user?.name,
            id: user?.id,
          },
        ],
      },
    };
  }
};

export const removeComment = async ({ commentId }) => {
  const endpoint = `/comments/${commentId}`;

  const res = await privateRequest.delete(endpoint);

  if (res.status === 200) {
    // Set the state
    showToast({ type: "success", message: "Comment is deleted successfully!" });

    return commentId;
  }
};

export const updateComment = async ({ commentId, incidentId, formState }) => {
  const endpoint = `/comments/${commentId}`;
  const body = { comment: formState };
  const { user } = store.getState().auth;

  const res = await privateRequest.put(endpoint, body);
  if (res.status === 200) {
    // Set the state
    return {
      _id: commentId,
      _source: {
        comment: formState,
        updated_at: new Date().getTime(),
        incident_id: incidentId,
        users: [
          {
            name: user?.name,
            id: user?.id,
          },
        ],
      },
    };
  }
};

export const createNewTicket = async ({ incidentId, body }) => {
  const endpoint = `/incidents/${incidentId}`;
  const { mttr, ...rest } = body;

  const res = await privateRequest.put(endpoint, rest);

  if (res.status === 200) {
    showToast({
      message: "Ticket created successfully!",
    });
  }

  return body;
};

export const reopenRequest = async ({ incidentId, body }) => {
  const endpoint = `/reopen-requests/${incidentId}`;

  const res = await privateRequest.put(endpoint, body);

  if (res.status === 200) {
    showToast({
      message: "Reopen request sent successfully!",
    });
  }

  return body;
};

export const reAssign = async ({ incidentId, body }) => {
  const endpoint = `/reAssign/${incidentId}`;
  const { engineer_assigned, mttr, ...rest } = body;
  const res = await privateRequest.put(endpoint, rest);

  if (res.status === 200) {
    showToast({
      message: "Ticket re-assigned successfully!",
    });
    return body;
  }
};