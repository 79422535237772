import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const AccessedHosts = ({ hosts }) => {
  const navigate = useNavigate();

  const handleClick = (hostId) => {
    navigate(`/endpoints/${hostId}`);
  };

  return (
    <>
      {
        hosts?.length > 0 ? (
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: 0,
              maxHeight: 200,
              overflow: "hidden",
              "&:hover": {
                overflowY: "auto",
              },
            }}
          >
            <Table
              sx={{
                // minWidth: 650,
                width: "100%",
              }}
              size="small"
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow sx={{ textTransform: "uppercase" }}>
                  <TableCell sx={{ fontSize: 10, maxWidth: "70%" }}>
                    Hostname
                  </TableCell>
                  <TableCell sx={{ fontSize: 10 }}>Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hosts?.map((host) => (
                  <TableRow
                    key={host?.host_name}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Button
                        size="small"
                        color={"inherit"}
                        onClick={() => handleClick(host.host_id)}
                      >
                        {host?.host_name}
                      </Button>
                    </TableCell>
                    <TableCell>
                      {dayjs(host.time).format("MMM DD, YYYY HH:mm")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null //(<NoDataFound />)
      }
    </>
  );
};

export default AccessedHosts;
