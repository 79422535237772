import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Modal from "../../ui/Modal";
import EditGroupButton from "./EditGroupButton";
import { splitStringByCommaAndConvertToUniqueArray } from "../../../utils/functions";
import {
  getEndpointUsers,
  updateEndpointUserGroup,
} from "../../../redux/features/endpoint-users/endpointUserSlice";
import UserGroupForm from "./UserGroupForm";

const EditUserGroup = () => {
  const { data: selectedGroup } = useSelector(
    (state) => state.endpointUsers.list
  );
  const { currentUserGroup } = useSelector(
    (state) => state.endpointUsers.group
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState(selectedGroup?.groupName || "");
  const [description, setDescription] = useState(
    selectedGroup?.description || ""
  );
  const [recruitingRule, setRecruitingRule] = useState(
    selectedGroup?.recruitingRule || ""
  );

  const previouslyAddedUsers = selectedGroup?.groupItems?.join(",");
  const [users, setUsers] = useState(previouslyAddedUsers || ""); //Users in a group
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const handleShowEditModal = () => {
    setModalOpen(true);
  };

  // This function sets the form state based on the backend data
  const populatePersistedData = () => {
    setName(selectedGroup?.groupName || "");
    setDescription(selectedGroup?.description || "");
    setRecruitingRule(selectedGroup?.recruitingRule || "");
    // setUsers(previouslyAddedUsers || "");
  };

  const updateGroup = () => {
    const formattedUsers = splitStringByCommaAndConvertToUniqueArray(users);
    const body = {
      group_name: name,
      description,
      recruiting_rule: recruitingRule,
      users: formattedUsers,
    };

    populatePersistedData();

    dispatch(updateEndpointUserGroup({ body, groupId: selectedGroup?.id }));

    //Refetch if the host change
    if (users !== previouslyAddedUsers) {
      dispatch(getEndpointUsers({ groupId: currentUserGroup || 0 }));
    }
  };

  // Populate the from with the current data before updating
  useEffect(() => {
    populatePersistedData();
  }, [modalOpen]);

  return (
    <>
      <Box>
        <EditGroupButton onClick={handleShowEditModal} />
      </Box>

      <Modal
        title="Edit User Group"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <>
          <UserGroupForm
            setModalOpen={setModalOpen}
            ButtonLabel="Update Group"
            name={name}
            setName={setName}
            description={description}
            setDescription={setDescription}
            recruitingRule={recruitingRule}
            setRecruitingRule={setRecruitingRule}
            users={users}
            setUsers={setUsers}
            setError={setError}
            onSubmit={updateGroup}
            isEditing={true} //To prevent the form state from being cleared by the modal's "Cancel" button
          />
          <Typography color="error">{error && error}</Typography>
        </>
      </Modal>
    </>
  );
};

export default EditUserGroup;
