import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";

import EndpointInfo from "../../components/endpoints/endpoint-details/EndpointInfo";
import OpenAndClosedIncidentsTab from "../../components/endpoints/endpoint-details/OpenAndClosedIncidentsTab";
import IncidentsOvertimeBySeverity from "../../components/endpoints/endpoint-details/IncidentsOvertimeBySeverity";
import IncidentsOvertimeByType from "../../components/endpoints/endpoint-details/IncidentsOvertimeByType";
import Loading from "../../components/endpoints/Loading";
import NoDataFound from "../../components/endpoints/NoDataFound";
import { getEndpointDetails } from "../../redux/features/endpoints/endpointDetailsSlice";
import PageWrapper from "../../components/PageWrapper";

const EndpointDetails = () => {
  const { endpointId } = useParams();
  const [_, setSearchParams] = useSearchParams();
  const { data, loading } = useSelector((state) => state.endpointDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setSearchParams((newParams) => {
      newParams.set("rowsPerPage", 10);
      return newParams;
    });

    dispatch(getEndpointDetails({ endpointId }));
  }, []);

  const endpoint = data;

  if (loading) return <Loading />;
  if (endpointId !== endpoint?.host?.id) {
    return (
      <NoDataFound>
        <NoDataFound.Message>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4">Oops! No Endpoint Data Found!</Typography>
            <Typography variant="body2">
              Please go back to the endpoints page to navigate to an other
              route.
            </Typography>
          </Box>
        </NoDataFound.Message>
        <NoDataFound.Link onClick={() => navigate(`/endpoints`)}>
          Go back to Endpoint Users page.
        </NoDataFound.Link>
      </NoDataFound>
    );
  }

  return (
    <PageWrapper sx={{ height: "auto" }}>
      Endpoint Details
      <EndpointInfo endpoint={endpoint} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <IncidentsOvertimeBySeverity
                incidentsOvertime={endpoint?.incidentsOverTime}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <IncidentsOvertimeByType
                incidentsOvertime={endpoint?.incidentsOverTime}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <OpenAndClosedIncidentsTab
            openIncidents={endpoint?.openIncidents}
            closedIncidents={endpoint?.closedIncidents}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default EndpointDetails;

