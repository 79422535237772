import React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const AddGroupButton = ({ onClick }) => {
  return (
    <Button
      //   component="button"
      variant="contained"
      color="primary"
      fullWidth
      onClick={onClick}
      sx={{
        borderRadius: "5px",
        p: "10px",
        textTransform: "capitalize",
        color: "#000",
        fontWeight: 500,
        lineHeight: "18px",
        fontSize: "16px",
      }}
    >
      Add Group
    </Button>
  );
};

export default AddGroupButton;
