import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";

import BaseWidget from "./BaseWidget";
import WidgetHeader from "./WidgetHeader";
import TopHosts from "./TopHosts";
import WidgetLoading from "./WidgetLoading";
import { getTopHostsByIncident } from "../../redux/features/dashboards/executive/TopHostsByIncidentSlice";
import { useDashboard } from "../../hooks/useDashboard";
import MuiMenu from "./MuiMenu";

export default function TopHostsByIncidentWidget() {
  const [days, setDays] = useState("7days");
  const [openDetails, setOpenDetails] = useState(false);

  const { topHostsByIncident } = useDashboard();
  const { hosts, loading } = topHostsByIncident;
  const dispatch = useDispatch();

  const formatDate = () => {
    if (!days) return null;
    if (days === "24hours") return 1;
    if (days === "7days") return 7;
    if (days === "10days") return 10;
  };

  useEffect(() => {
    dispatch(getTopHostsByIncident({ days: formatDate() }));
  }, [dispatch, days]);

  const handleClose = () => {
    setOpenDetails(false);
  };

  return (
    <BaseWidget>
      <WidgetHeader title="Top Hosts by Incident">
        {!openDetails && (
          <Box>
            <MuiMenu
              days={days}
              setDays={setDays}
              options={[
                { key: "24hours", label: "Today" },
                { key: "7days", label: "7 Days" },
                { key: "10days", label: "10 Days" },
              ]}
            />
          </Box>
        )}
        {openDetails && (
          <Box>
            <IconButton onClick={handleClose}>
              <ArrowBackRoundedIcon size={14} />
            </IconButton>
          </Box>
        )}
      </WidgetHeader>
      {loading ? (
        <WidgetLoading />
      ) : (
        <TopHosts
          hosts={hosts}
          openDetails={openDetails}
          setOpenDetails={setOpenDetails}
        />
      )}
    </BaseWidget>
  );
}
