import React from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";

import TabButtons from "../../components/ui/TabButtons";
import { INCIDENT_RESPONSES } from "../../utils/constants";

const IncidentsAndResponse = () => {
  return (
    <Box sx={{ marginTop: 1 }}>
      <TabButtons items={INCIDENT_RESPONSES} />
      <Outlet />
    </Box>
  );
};

export default IncidentsAndResponse;
