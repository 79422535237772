import React from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";

import { markAllNotificationsAsRead } from "../../redux/features/notifications/notificationSlice";

const MarkAllAsRead = () => {
  const dispatch = useDispatch();

  const handleClick = async () => {
    dispatch(markAllNotificationsAsRead());
  };

  return (
    <Button
      onClick={handleClick}
      variant="text"
      color="inherit"
      sx={{ textTransform: "inherit" }}
    >
      Mark all as read
    </Button>
  );
};

export default MarkAllAsRead;
