import { privateRequest } from "../../../utils/apiRequest";
import { arrayFromObjectKeys, roundToTwo } from "../../../utils/functions";
import { compareString } from "../../../utils/functions";
import { showToast } from "../toast";

export const fetchOperators = async () => {
  const res = await privateRequest.get(`api/auth/roles/5/users`);

  if (res.status === 200) {
    let users = res.data.users;

    // Remove a password field if provided
    users = users.map((user) => {
      if (user.password) {
        const { password, ...rest } = user;
        return rest;
      }
      return user;
    });

    return users;
  }
};

export const fetchAllUsers = async () => {
  const res = await privateRequest.get(`api/auth/users`);

  if (res.status === 200) {
    let users = res.data.users;

    // Remove a password field if provided
    users = users.map((user) => {
      if (user.password) {
        const { password, ...rest } = user;
        return rest;
      }
      return user;
    });

    return users;
  }
};

export const fetchUserDetails = async ({ userId }) => {
  const endpoint = `/users/${userId}`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    const { operatorsPerformance, ...rest } = res.data.data;

    if (!!operatorsPerformance) {
      const weeks = arrayFromObjectKeys(operatorsPerformance);

      const performances = weeks.map((week) => {
        const weekPerformance =
          operatorsPerformance[week][0]?.average_score || 0;
        return roundToTwo(weekPerformance);
      });

      return { operatorsPerformance: performances, ...rest };
    }
    return rest;
  }
};

export const editUser = async ({ userId, body }) => {
  const endpoint = `/users/${userId}`;
  const res = await privateRequest.put(endpoint, body);

  if (res.status === 200) {
    // Update the state with a response data
    showToast({
      type: "success",
      message: "User updated successfully",
    });
    return res.data;
  }
};

export const deleteUser = async ({ userId }) => {
  const endpoint = `/users/${userId}/delete`;
  const res = await privateRequest.delete(endpoint);

  if (res.status === 200) {
    return res.data;
  }
};

export const fetchUserCompletedIncidentsByType = async ({ userId }) => {
  const endpoint = `/users/${userId}/Completed`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return res.data;
  }
};

export const fetchUserUnResolvedIncidentsByType = async ({
  userId,
  status,
}) => {
  let correctStatus;
  if (!!compareString(status, "inProgress")) {
    correctStatus = "InProgress";
  } else if (!!compareString(status, "overdue")) {
    correctStatus = "Overdue";
  } else {
    return;
  }

  const endpoint = `/users/inProgress/${userId}/status?status=${correctStatus}`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return {
      data: res.data.data,
      pagination: {
        totalItems: res.data.totalDocs,
        currentPage: res.data.page,
        limit: res.data.pageSize,
      },
    };
  }
};

export const fetchAudit = async ({ userId, pageSize = 10 }) => {
  const endpoint = `/users/${userId}/audit?pageSize=${pageSize}`;
  const res = await privateRequest(endpoint);

  if (res.status === 200) {
    return res.data;
  }
};