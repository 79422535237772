import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useDashboard } from "./../../hooks/useDashboard";
import { getIncidentsStatusBoard } from "../../redux/features/dashboards/executive/incidentsStatusBoardSlice";
import ChartTemplate from "./ChartTemplate";

const IncidentsStatusBoardChart = ({ days }) => {
  const { incidentsStatusBoard } = useDashboard();
  const { incidents } = incidentsStatusBoard;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIncidentsStatusBoard({ days }));
  }, [dispatch, days]);

  const chartData = [
    {
      name: "High",
      data: [
        incidents?.High?.Backlog?.doc_count || 0,
        incidents?.High?.InProgress?.doc_count || 0,
        incidents?.High?.Overdue?.doc_count || 0,
      ],
    },
    {
      name: "Medium",
      data: [
        incidents?.Medium?.Backlog?.doc_count || 0,
        incidents?.Medium?.InProgress?.doc_count || 0,
        incidents?.Medium?.Overdue?.doc_count || 0,
      ],
    },
    {
      name: "Low",
      data: [
        incidents?.Low?.Backlog?.doc_count || 0,
        incidents?.Low?.InProgress?.doc_count || 0,
        incidents?.Low?.Overdue?.doc_count || 0,
      ],
    },
    {
      name: "Unscored",
      data: [
        incidents?.Low?.Backlog?.doc_count || 0,
        incidents?.Low?.InProgress?.doc_count || 0,
        incidents?.Low?.Overdue?.doc_count || 0,
      ],
    },
  ];

  // Prepare options for the chart
  const chartOptions = {
    plotOptions: {
      bar: {
        horizontal: false,
        // borderRadius: 10,
      },
    },

    xaxis: {
      categories: ["Backlog", "In Progress", "Overdue"],
    },
    colors: ["#32D74B", "#FF881A", "#FF431A", "#ddd"],
  };

  return <ChartTemplate options={chartOptions} series={chartData} />;
};

export default IncidentsStatusBoardChart;
