import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import ArtifactRow from "./ArtifactRow";
import { useTheme } from "@mui/material";

export default function Artifacts({ incident }) {
  const theme = useTheme();
  let file = [];
  return (
    <TableContainer
      component={Paper}
      sx={{
        "&.MuiPaper-root": {
          backgroundColor: theme.palette.mode === "dark" ? "black" : null,
        },
      }}
    >
      <Table size="small" aria-label="collapsible table">
        <TableBody>
          <ArtifactRow
            row={incident?.details?.processes}
            hostname={incident?.info?.host}
            type="process"
            isOpen={true}
          />

          {incident?.details?.childProcess ? (
            <ArtifactRow
              row={[...file, incident?.details?.childProcess]}
              hostname={incident?.info?.host}
              type="child process"
            />
          ) : null}
          {incident?.details?.file ? (
            <ArtifactRow
              row={[...file, incident?.details?.file]}
              hostname={incident?.info?.host}
              type="file"
            />
          ) : null}
          {incident?.details?.registry ? (
            <ArtifactRow
              row={[...file, incident?.details?.registry]}
              hostname={incident?.info?.host}
              type="registry"
            />
          ) : null}
          {incident?.details?.network ? (
            <ArtifactRow
              row={[...file, incident?.details?.network]}
              hostname={incident?.info?.host}
              type="network"
            />
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
