import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchTicketsByStatus } from ".";

export const getTicketsByStatus = createAsyncThunk(
  "tickets/by-status",
  fetchTicketsByStatus
);

const initialState = {
  tickets: [],
  status: "inprogress",
  loading: false,
  error: null,
};

const ticketsByStatusSlice = createSlice({
  name: "tickets-by-status",
  initialState,
  reducers: {
    clearTicketsByStatusState: (state) => {
      state.tickets = [];
      state.status = "InProgress";
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTicketsByStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTicketsByStatus.fulfilled, (state, action) => {
        const { data, status } = action.payload;
        state.loading = false;
        state.error = null;
        state.tickets = data;
        state.status = status;
      })
      .addCase(getTicketsByStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearTicketsByStatusState } = ticketsByStatusSlice.actions;
export default ticketsByStatusSlice.reducer;
