import { privateRequest } from "../../../utils/apiRequest";

export const fetchNotifications = async () => {
  const endpoint = `/notifications`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return res.data;
  }
};

export const markAsRead = async ({ item }) => {
  const endpoint = `/notifications/${item.id}/mark-as-seen`;
  const res = await privateRequest.put(endpoint);

  if (res.status === 200) {
    return item;
  }
};

export const markAllAsRead = async () => {
  const endpoint = `/notifications/mark-all-as-seen`;
  const res = await privateRequest.put(endpoint);

  if (res.status === 200) {
    return true;
  }
};
