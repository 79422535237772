import * as React from "react";
import Grid from "@mui/material/Grid";

import Form from "../../ui/Form";
import Controls from "../../ui/controls";
import dayjs from "dayjs";

const LiveTerminalPrompt = ({
  socket,
  prompt,
  setPrompt,
  conversations,
  setConversations,
}) => {
  const handleInputChange = (event) => {
    if (event.target.name === "prompt") {
      setPrompt(event.target.value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setConversations([
      ...conversations,
      {
        message: prompt,
        type: "request",
        createdAt: dayjs().format("HH:MM:ss A"),
      },
    ]);
    socket.emit("send-message", prompt);
    setPrompt("");
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controls.TextInputField
            autoFocus
            name="prompt"
            variant="standard"
            label=""
            value={prompt}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default LiveTerminalPrompt;
