import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Tooltip, styled, useTheme } from "@mui/material";
import AvTimerIcon from "@mui/icons-material/AvTimer";

// This will be used as a default options if user doesn't provide one
const defaultOptions = [
  { key: "24hours", label: "Today" },
  { key: "7days", label: "7 Days" },
  { key: "30days", label: "30 Days" },
];

const ITEM_HEIGHT = 48;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({}));

export default function MuiMenu({ days, setDays, options = defaultOptions }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const handleMenu = (option) => {
    setDays(option?.key);
    handleClose();
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title="Date Selector" placement="top">
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <AvTimerIcon />
        </IconButton>
      </Tooltip>

      <StyledMenu
        id="date-selector-menu"
        MenuListProps={{
          "aria-labelledby": "date-selector-menu",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "15ch",
          },
        }}
      >
        {options?.map((option) => (
          <MenuItem
            key={option?.key}
            selected={option?.key === "Pyxis"}
            onClick={() => handleMenu(option)}
            sx={{
              background: isDark ? "#232323" : "white",
            }}
          >
            {option?.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
