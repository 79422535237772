import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";

import MyIncidentsWidget from "../../components/widgets/MyIncidentsWidget";
import IncidentsByStatusWidget from "../../components/widgets/operator/IncidentsByStatusWidget";
import IncidentsBySeverityAndOperatorWidget from "../../components/widgets/operator/IncidentsBySeverityAndOperatorWidget";
import TopHostsByIncidentOperatorWidget from "../../components/widgets/operator/TopHostsByIncidentOperatorWidget";
import IncidentByRemainingTimeWidget from "../../components/widgets/IncidentsByRemainingTimeWidget";
import ResolvedIncidentsMTTRWidget from "../../components/widgets/operator/ResolvedIncidentsMTTRWidget";
import { capitalize } from "../../utils/functions";
import Header from "../../components/Header";
import { getOperators } from "../../redux/features/users/operatorsSlice";
import MyCompletedIncidentsWidget from "../../components/widgets/MyCompletedIncidentsWidget";
import { getAllActionArtifacts } from "../../redux/features/actions/actionSlice";

const OperatorDashboard = () => {
  const user = useSelector((state) => state.auth.user);
  // Get all operators  (we use it to select a user when we create a ticket)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOperators());
  }, [dispatch]);

  // Load artifacts for predefined actions
  useEffect(() => {
    dispatch(getAllActionArtifacts());
  }, []);

  return (
    <Box
      m="21px"
      sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}
    >
      {/* Header */}
      <Grid container>
        <Grid item xs={12} sm={6} lg={8}>
          <Header
            title="Operator Dashboard"
            subtitle={`Welcome to your Dashboard, ${capitalize(user?.name)}!`}
          />
        </Grid>
      </Grid>

      {/* Dashboard Widget Cards*/}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <MyIncidentsWidget />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MyCompletedIncidentsWidget />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IncidentByRemainingTimeWidget />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IncidentsByStatusWidget />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IncidentsBySeverityAndOperatorWidget />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ResolvedIncidentsMTTRWidget />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TopHostsByIncidentOperatorWidget />
        </Grid>
      </Grid>

      <br />
      <br />
    </Box>
  );
};

export default OperatorDashboard;
