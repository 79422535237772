import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { deleteEndpointGroup } from "../../../redux/features/endpoints/endpointGroupSlice";
import { deleteEndpointUserGroup } from "../../../redux/features/endpoint-users/endpointUserGroupSlice";
import ConfirmDialog from "../../ui/ConfirmDialog";
import { useTheme } from "@mui/material";

const DeleteGroupButton = ({ groupId, type = "host" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [confirm, setConfirm] = useState({
    title: "",
    subtitle: "",
    isOpen: false,
  });

  const handleDeleteGroup = () => {
    let destination = `/endpoints`;
    if (type === "host") {
      dispatch(deleteEndpointGroup({ groupId }));
    }
    if (type === "user") {
      dispatch(deleteEndpointUserGroup({ groupId }));
      destination = "/endpoint-users";
    }

    setConfirm({
      ...confirm,
      isOpen: false,
    });

    navigate(destination, { replace: true });
  };

  return (
    <>
      <Tooltip title="Delete Group">
        <IconButton
          sx={{
            borderRadius: 1,
            "&:hover > *": {
              color: theme.palette.error.main,
            },
          }}
          onClick={() => {
            setConfirm({
              title: "Are you sure you want to delete this group?",
              subtitle: "This operation can't be undo.",
              isOpen: true,
              onConfirm: () => handleDeleteGroup(),
            });
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <ConfirmDialog confirm={confirm} setConfirm={setConfirm} />
    </>
  );
};

export default DeleteGroupButton;
