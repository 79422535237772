import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchIncidentsStatusBoard } from "./index";

export const getIncidentsStatusBoard = createAsyncThunk(
  "incidents/statusBoard",
  fetchIncidentsStatusBoard
);

const initialState = {
  incidents: {},
  loading: false,
  error: null,
};

const incidentsStatusBoardSlice = createSlice({
  name: "incidents-status-board",
  initialState,
  reducers: {
    clearIncidentsStatusBoardState: (state) => {
      state.incidents = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIncidentsStatusBoard.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getIncidentsStatusBoard.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.incidents = action.payload;
    });
    builder.addCase(getIncidentsStatusBoard.rejected, (state, action) => {
      state.loading = false;
      state.incidents = {};
      state.error = action.error.message;
    });
  },
});

export const { clearIncidentsStatusBoardState } =
  incidentsStatusBoardSlice.actions;
export default incidentsStatusBoardSlice.reducer;
