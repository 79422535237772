import { privateRequest } from "../../../utils/apiRequest";

export const fetchAuditActivities = async ({ params }) => {
  const endpoint = `/audit/logs`;

  const res = await privateRequest.get(endpoint, { params });

  if (res.status === 200) {
    return { data: res.data.auditData, total: res.data.totalCount };
  }
};
