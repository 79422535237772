import React, { useState } from "react";

import BaseWidget from "./BaseWidget";
import WidgetContent from "./WidgetContent";
import WidgetHeader from "./WidgetHeader";
import IncidentsStatusBoardChart from "../charts/IncidentsStatusBoardChart";
import MuiMenu from "./MuiMenu";

const IncidentsStatusBoardWidget = () => {
  const [days, setDays] = useState("7days");

  return (
    <BaseWidget>
      <WidgetHeader title="Incidents Status Board">
        <MuiMenu days={days} setDays={setDays} />
      </WidgetHeader>
      <WidgetContent>
        <IncidentsStatusBoardChart days={days} />
      </WidgetContent>
    </BaseWidget>
  );
};

export default IncidentsStatusBoardWidget;
