import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const EditGroupButton = ({ onClick }) => {
  return (
    <>
      <Tooltip title="Edit Group">
        <IconButton
          onClick={onClick}
          sx={{
            borderRadius: 1,
            "&:hover > *": {
              color: (theme) => theme.palette.success.main,
            },
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default EditGroupButton;
