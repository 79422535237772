import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import desktop from "../../assets/desktop.png";
import ActionIcon from "../actions/action-icon";
import { actions } from "../../utils/constants";
import { useParams } from "react-router-dom";
import { sendPredefinedAction } from "../../redux/features/actions/actionSlice";
import { useDispatch } from "react-redux";
import Modal from "../ui/Modal";
import ShutdownHost from "../actions/host/ShutdownHost";
import RestartHost from "../actions/host/RestartHost";
import HostForm from "../actions/host/HostForm";
import LiveTerminalTrigger from "../actions/live-session/LiveTerminalTrigger";
import LiveSession from "../actions/live-session/LiveSession";

const Assets = ({ incident }) => {
  return (
    <Paper sx={{ padding: "10px", overflowX: "auto" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <AssetCard asset={incident?.assets && incident?.assets[0]} />
        </Grid>

        {/* <Grid item sx={12} lg={6}>
          <AssetCard asset={incident?.assets && incident?.assets[0]} />
        </Grid> */}
      </Grid>
    </Paper>
  );
};

const AssetCard = ({ asset }) => {
  const [showDetails, setShowDetails] = useState(false);

  // Host action states
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [selectedAction, setSelectedAction] = useState();
  const [countdown, setCountdown] = useState();

  // Live Session states
  const [liveSessionModalOpen, setLiveSessionModalOpen] = useState(false);

  const { incidentId } = useParams();
  const dispatch = useDispatch();

  const handleSendAction = (menu) => {
    const body = {
      incidentId,
      targetHost: asset?.hostname,
      action: menu?.action,
      name: menu?.actionType,
      others: {},
    };

    setSelectedAction(menu?.action);

    // Shutdown and restart actions - Host
    if (menu?.action === actions.KILL_PROCESS) {
      body.others = {
        countdown,
      };
    }

    // Open modal for host actions
    if (menu?.action === actions.SHUTDOWN_HOST) {
      setModalOpen(true);
      setModalTitle("Shutdown Host");
    }
    if (menu?.action === actions.RESTART_HOST) {
      setModalOpen(true);
      setModalTitle("Restart Host");
    }

    // Send action for all actions except for shutdown and restart actions
    if (
      menu?.action !== actions.SHUTDOWN_HOST &&
      menu?.action !== actions.RESTART_HOST
    ) {
      dispatch(sendPredefinedAction({ body }));
    }
  };

  const handleShutdownHostAction = () => {
    const body = {
      incidentId,
      targetHost: asset?.hostname,
      action: actions?.SHUTDOWN_HOST,
      name: "shutdownHost",
      others: {
        countdown: +countdown,
      },
    };

    dispatch(sendPredefinedAction({ body }));
  };

  const handleRestartHostAction = () => {
    const body = {
      incidentId,
      targetHost: asset?.hostname,
      action: actions?.RESTART_HOST,
      name: "restartHost",
      others: {
        countdown: +countdown,
      },
    };

    dispatch(sendPredefinedAction({ body }));
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          // minHeight: 260,
          height: 270,
          // height: "100%",
          width: "100% !important",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              ml: "auto",
              mr: 2,
              mt: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <LiveTerminalTrigger
              onClick={() => setLiveSessionModalOpen(true)}
            />
            <ActionIcon
              targetItem={asset?.name}
              handleSendAction={handleSendAction}
            />
          </Box>
        </Box>
        <CardContent>
          {!showDetails ? (
            <AssetsCardFront
              assetName={asset?.name}
              setShowDetails={setShowDetails}
            />
          ) : (
            <AssetCardDetails asset={asset} setShowDetails={setShowDetails} />
          )}
        </CardContent>
      </Card>
      <Modal
        title={modalTitle}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        {selectedAction === actions?.SHUTDOWN_HOST ? (
          <ShutdownHost>
            <HostForm
              value={countdown}
              setValue={setCountdown}
              onSubmit={handleShutdownHostAction}
              onClose={() => setModalOpen(false)}
              mode="shutdown"
            />
          </ShutdownHost>
        ) : null}
        {selectedAction === actions.RESTART_HOST ? (
          <RestartHost>
            <HostForm
              value={countdown}
              setValue={setCountdown}
              onSubmit={handleRestartHostAction}
              onClose={() => setModalOpen(false)}
              mode="restart"
            />
          </RestartHost>
        ) : null}
      </Modal>

      <Modal
        title="Live Terminal"
        modalOpen={liveSessionModalOpen}
        setModalOpen={setLiveSessionModalOpen}
      >
        <LiveSession
          targetHost={asset?.hostname}
          modalOpen={liveSessionModalOpen}
          setModalOpen={setLiveSessionModalOpen}
        />
      </Modal>
    </>
  );
};

const AssetsCardFront = ({ assetName, setShowDetails }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100% !important",
        height: "100% !important",
      }}
    >
      <img
        style={{ maxWidth: 200 }}
        src={desktop}
        alt="Desktop"
        // loading="lazy"
      />

      <Button
        variant="contained"
        size="small"
        sx={{
          background: (theme) =>
            theme.palette.mode === "dark" ? "#030303" : "#fafafa",
          color: (theme) =>
            theme.palette.mode === "dark" ? "#fafafa" : "#030303",
        }}
        onClick={() => setShowDetails(true)}
      >
        {assetName}
      </Button>
    </Box>
  );
};

const AssetCardDetails = ({ asset, setShowDetails }) => {
  return (
    <Box
      sx={{
        height: "100% important",
        width: "100% important",
        overflowY: "auto",
        maxWidth: "272px",
      }}
    >
      <IconButton
        sx={{
          borderRadius: 2,
          px: 1,
          py: 0.5,
          display: "flex",
          alignItems: "center",
          gap: "4px",
          mb: 1,
        }}
        onClick={() => setShowDetails(false)}
      >
        <KeyboardBackspaceIcon /> <small style={{ fontSize: 12 }}>Back</small>
      </IconButton>
      <Info label="Host Name" value={asset?.hostname} />
      <Info label="Architecture" value={asset?.architecture} />
      <Info label="IP Address" value={asset?.ip} />
      <Info label="Mac Address" value={asset?.mac} />
      <Info label="OS" value={asset?.os?.name} />
      <Info label="Kernel" value={asset?.os?.kernel} />
    </Box>
  );
};

const Info = ({ label, value }) => {
  return (
    <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
      <Typography sx={{ color: "gray", flex: 1 }}>{label}: </Typography>
      <Typography title={value} noWrap sx={{ flex: 2 }}>
        {value}
      </Typography>
    </Box>
  );
};

export default Assets;
