import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";

import { getEndpointUsers } from "../../redux/features/endpoint-users/endpointUserSlice";
import EndpointUsersList from "../../components/endpoints/EndpointUsersList";
import { setCurrentUserGroup } from "../../redux/features/endpoint-users/endpointUserGroupSlice";
import TabButtons from "../../components/ui/TabButtons";
import { assetsMenu } from "../../utils/constants";

const EndpointUsers = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { currentUserGroup } = useSelector(
    (state) => state.endpointUsers.group
  );

  useEffect(() => {
    if (pathname === "/endpoint-users") {
      dispatch(getEndpointUsers({ groupId: 0 })); //Fetch all endpoint users (base endpoint users page)
    }
  }, [pathname]);

  useEffect(() => {
    if (pathname === "/endpoint-users") {
      dispatch(getEndpointUsers({ groupId: 0 })); //Fetch all endpoint users (base endpoint users page)
      dispatch(setCurrentUserGroup(0));
    } else {
      dispatch(getEndpointUsers({ groupId: currentUserGroup }));
    }
  }, [currentUserGroup]);

  return (
    <>
      <TabButtons items={assetsMenu} />
      <Grid
        container
        columns={16}
        sx={{
          height: "calc(100% - 60px) !important", // 60px is the approximate height of the navbar
        }}
      >
        <Grid item xs={16}>
          <EndpointUsersList />
        </Grid>
      </Grid>
    </>
  );
};

export default EndpointUsers;
