import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { styled, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import Loading from "./Loading";
import EndpointSidebar from "./EndpointSidebar";
import GroupDetails from "./groups/GroupDetails";
import RemoveRow from "./RemoveRow";
import { useDispatch } from "react-redux";
import { deleteEndpointHost } from "../../redux/features/endpoints/endpointSlice";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.mode === "dark" ? "#252b36" : "#fff",
  },
  td: {
    border: 0,
  },
}));

const EndpointTable = ({ data, loading, rowsPerPage, setRowsPerPage }) => {
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [_, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const DEFAULT_SIZE = 10;

  const { matchedHosts: rows, ...rest } = data;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSearchParams((newSearchParams) => {
      newSearchParams.set(
        "rowsPerPage",
        parseInt(event.target.value, DEFAULT_SIZE)
      );
      return newSearchParams;
    });
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  return (
    <>
      <Grid container columns={16} sx={{ height: "100%" }}>
        <Grid item xs={16} md={3}>
          <EndpointSidebar />
        </Grid>

        <Grid
          item
          xs={16}
          md={13}
          sx={{
            minHeight: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <Loading />
          ) : (
            <Box
              sx={{
                minHeight: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 1,
                marginInline: 2,
                // background: "black",
              }}
            >
              <GroupDetails data={rest} type="host" />

              <Box
                sx={{
                  border: `1px solid ${isDark ? "#2D3342" : "#ddd"}`,
                  flex: 1,
                  display: "flex",
                }}
              >
                {rows?.length < 1 ? (
                  <NoEndpointFound message="Oops! There's no endpoint available." />
                ) : (
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750, width: "100%" }}
                      aria-labelledby="tableTitle"
                      size={dense ? "small" : "medium"}
                    >
                      <EnhancedTableHead />
                      <TableBody>
                        {rows
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          ?.map((row) => (
                            <Row key={row.id} row={row} groupId={rest?.id} />
                          ))}
                        {emptyRows > 0 && (
                          <StyledTableRow
                            style={{
                              height: (dense ? 33 : 53) * emptyRows,
                            }}
                          >
                            <TableCell colSpan={12} />
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      <TablePagination
        rowsPerPageOptions={[1, 5, 10, 25, 100]}
        component="div"
        count={rows?.length}
        rowsPerPage={+rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ background: isDark ? "#181C24" : null }}
      />
    </>
  );
};

const EnhancedTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell rowSpan={2}></TableCell>
        <TableCell rowSpan={2}>Name</TableCell>
        <TableCell rowSpan={2}>IP</TableCell>
        <TableCell rowSpan={2}>OS</TableCell>
        <TableCell rowSpan={2}>Status</TableCell>
        <TableCell rowSpan={2}>Endpoint ID</TableCell>
        <TableCell colSpan={4} align="center">
          Incidents
        </TableCell>
        <TableCell rowSpan={2} align="center">
          Action
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="center">High</TableCell>
        <TableCell align="center">Medium</TableCell>
        <TableCell align="center">Low</TableCell>
        <TableCell align="center">Unscored</TableCell>
      </TableRow>
    </TableHead>
  );
};

const Row = ({ row, groupId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDeleteRow = () => {
    const body = {
      host: row?.name,
    };
    dispatch(deleteEndpointHost({ groupId, body }));
  };

  return (
    <StyledTableRow hover tabIndex={-1}>
      <TableCell padding="checkbox"></TableCell>
      <TableCell padding="none">
        <Box
          onClick={() => {
            navigate(`/endpoints/${row.id}`, { replace: true });
          }}
          sx={{
            fontWeight: 600,
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
          }}
          size="small"
          variant="text"
        >
          {row.name}
        </Box>
      </TableCell>
      <TableCell>{row.ip[row.ip.length - 1]}</TableCell>
      <TableCell>{row.os.name}</TableCell>
      <TableCell>Connected</TableCell>
      <TableCell width={300}>{row.id}</TableCell>
      <TableCell align="center">{row.incidentsCount.high}</TableCell>
      <TableCell align="center">{row.incidentsCount.medium}</TableCell>
      <TableCell align="center">{row.incidentsCount.low}</TableCell>
      <TableCell align="center">{row.incidentsCount.unscored}</TableCell>

      <TableCell align="center">
        <RemoveRow type="Host" onClick={handleDeleteRow} />
      </TableCell>
    </StyledTableRow>
  );
};

const NoEndpointFound = ({ message }) => {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          minHeight: "100% !important",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          background: "transparent",
          // gap: 3,
          mb: 2,
          p: 2,
        }}
      >
        <Typography variant="h4" textAlign="center">
          {message || "Oops! There's no item available."}
        </Typography>
      </Paper>
    </Box>
  );
};

export default EndpointTable;
