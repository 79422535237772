import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import BlurCircularIcon from "@mui/icons-material/BlurCircular";
import { EditNote } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import Loading from "../endpoints/Loading";
import NoDataFound from "../endpoints/NoDataFound";
import { capitalize } from "../../utils/functions";
import Modal from "../ui/Modal";
import useAuth from "../../hooks/useAuth";
import ResolutionSummaryResult from "../incidents/resolution-summary/ResolutionSummaryResult";
import { getResolutionSummaries } from "../../redux/features/resolution-summaries/resolutionSummarySlice";

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Incident ID</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Severity</TableCell>
        <TableCell>Time</TableCell>
        <TableCell>Operator</TableCell>
        <TableCell>Resolution Summary</TableCell>
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { denseOption } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Incident Resolution Summaries
      </Typography>

      <Box sx={{ display: "flex", gap: 2 }}>{denseOption}</Box>
    </Toolbar>
  );
}

export default function ResolutionSummaries() {
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [resolutionModalOpen, setResolutionModalOpen] = useState(false);
  const [selectedSummary, setSelectedSummary] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = useState(
    +searchParams.get("rowsPerPage") || 10
  );
  const { summaries: rows, loading } = useSelector(
    (state) => state.resolutionSummary
  );

  const handleViewMore = (summary) => {
    if (summary?.resolution_summary) {
      setSelectedSummary(summary.resolution_summary);
      setResolutionModalOpen(true);
    }
  };

  const handleEditSummary = (flag) => {
    setEditMode(flag);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSearchParams((newSearchParams) => {
      newSearchParams.set("rowsPerPage", parseInt(event.target.value, 10));
      return newSearchParams;
    });
    setPage(0);
  };

  //   Reset the editMode state if the user closes the modal while in the edit mode
  useEffect(() => {
    if (resolutionModalOpen === false) {
      setEditMode(false);
    }
  }, [resolutionModalOpen]);

  useEffect(() => {
    setSearchParams((newSearchParams) => {
      newSearchParams.set("rowsPerPage", rowsPerPage);
      return newSearchParams;
    });

    dispatch(getResolutionSummaries());
  }, []);

  useEffect(() => {
    setRowsPerPage(+searchParams.get("rowsPerPage") || 10);
  }, [searchParams]);

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleNavigateToIncident = (incidentId) => {
    navigate(`/incidents/${incidentId}`);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  if (loading) return <Loading />;

  return (
    <>
      {rows?.length < 1 || rowsPerPage < 1 ? (
        <NoDataFound>
          <NoDataFound.Message>
            <Typography variant="h4" textAlign="center">
              Oops! No incident has been resolved yet.
            </Typography>
          </NoDataFound.Message>
        </NoDataFound>
      ) : (
        <>
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              {/* <EnhancedTableToolbar
              denseOption={
                <FormControlLabel
                  control={
                    <Switch checked={dense} onChange={handleChangeDense} />
                  }
                  label="Dense"
                />
              }
            /> */}
              <TableContainer>
                <Table
                  sx={{ minWidth: 750, width: "100%" }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead />
                  <TableBody>
                    {rows
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        let firstSummary =
                          (row.resolution_summary &&
                            row?.resolution_summary[0]) ||
                          "";
                        return (
                          <TableRow
                            hover
                            // onClick={(event) => handleClick(event, row.endpoint_user)}
                            // role="checkbox"
                            tabIndex={-1}
                            key={row.incident_id}
                            //   sx={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox"></TableCell>
                            <TableCell width={200}>
                              <Button
                                size="small"
                                color={"inherit"}
                                onClick={() =>
                                  handleNavigateToIncident(row.incident_id)
                                }
                              >
                                {row.incident_id}
                              </Button>
                            </TableCell>
                            <TableCell width={50}>
                              {capitalize(row.type)}
                            </TableCell>
                            <TableCell width={50}>
                              {
                                <IncidentCountBySeverity
                                  value={capitalize(row.severity)}
                                />
                              }
                            </TableCell>
                            <TableCell width={150}>
                              {dayjs(row.time).format("MMM DD, YYYY HH:mm")}
                            </TableCell>
                            <TableCell width={50}>
                              {capitalize(row.operator.name)}
                            </TableCell>
                            <TableCell>
                              {firstSummary}
                              {firstSummary ? (
                                <Button
                                  onClick={() => handleViewMore(row)}
                                  sx={{
                                    ml: 0.5,
                                    fontWeight: 600,
                                    textTransform: "unset",
                                  }}
                                  size="small"
                                  variant="text"
                                >
                                  Read more
                                </Button>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={12} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[1, 5, 10, 25, 100]}
                component="div"
                count={rows?.length || 0}
                rowsPerPage={+rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
          <Modal
            title="Incident Resolution Summary Result"
            modalOpen={resolutionModalOpen}
            setModalOpen={setResolutionModalOpen}
          >
            {editMode ? (
              <>
                <Box sx={{ mb: 2 }}>Edit Mode</Box>
                <Button
                  variant="outlined"
                  onClick={() => handleEditSummary(false)}
                >
                  View Summary Result
                </Button>
              </>
            ) : (
              <>
                <Box sx={{ color: "grey.400", mb: 2 }}>
                  These are the resolution summary results submitted by operator{" "}
                  <span styles="font-weight: bold">{user.name}</span>.
                </Box>
                <ResolutionSummaryResult answers={selectedSummary} />
                <Box
                  sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => handleEditSummary(true)}
                  >
                    <EditNote sx={{ mr: 1 }} /> Edit Summary
                  </Button>
                </Box>
              </>
            )}
          </Modal>
        </>
      )}
    </>
  );
}

const IncidentCountBySeverity = ({ value }) => {
  let color;
  if (value === "High") color = "error";
  if (value === "Medium") color = "warning";
  if (value === "Low") color = "success";

  let customColor = `${color}.main`;
  return (
    <Tooltip
      title={value}
      color="inherit"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: customColor,
            "& .MuiTooltip-arrow": {
              color: customColor,
            },
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 0.15,
          border: "1px solid transparent",
          alignItems: "center",
          padding: "2px 6px",
          borderRadius: 1,
          "&:hover": {
            border: "1px solid inherit",
            borderColor: customColor,
          },
        }}
      >
        <BlurCircularIcon color={color} sx={{ fontSize: 14 }} />
        <Typography>{value}</Typography>
      </Box>
    </Tooltip>
  );
};
