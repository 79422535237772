import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const Loading = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          background: "transparent",
          // gap: 3,
          // minHeight: "calc(100vh - 12rem)",
          mb: 2,
          p: 2,
        }}
      >
        <Typography variant="h4" textAlign="center">
          Loading...
        </Typography>
        <Typography
          variant="subtitle1"
          textAlign="center"
          color={(theme) => theme.palette.grey[500]}
          sx={{ mb: 2 }}
        >
          Please wait a moment.
        </Typography>
      </Paper>
    </Box>
  );
};

export default Loading;
