import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { editEndpointGroup, fetchEndpoints, removeEndpointHost } from ".";

export const getEndpoints = createAsyncThunk("endpoints", fetchEndpoints);

export const deleteEndpointHost = createAsyncThunk(
  "endpoints/delete-host",
  removeEndpointHost
);

export const updateEndpointGroup = createAsyncThunk(
  "endpoint/update-group",
  editEndpointGroup
);

const initialState = {
  data: [],
  loading: false,
  error: null,
};
const endpointSlice = createSlice({
  name: "endpoints",
  initialState,
  reducers: {
    clearEndpointState: (state) => {
      state.data = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEndpoints.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getEndpoints.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getEndpoints.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(updateEndpointGroup.fulfilled, (state, action) => {
      const groupItems = action.payload.groupItems;
      const currentState = JSON.parse(JSON.stringify(state.data));
      const updatedHosts = currentState?.matchedHosts?.filter((host) => {
        if (groupItems?.includes(host?.name)) return host;
      });

      state.loading = false;
      state.error = null;
      state.data = {
        ...action.payload,
        matchedHosts: updatedHosts,
      };
    });

    builder.addCase(deleteEndpointHost.fulfilled, (state, action) => {
      const { host } = action.payload;

      state.data.matchedHosts = state.data.matchedHosts.filter((item) => {
        return item?.name !== host;
      });
    });
  },
});

export const { clearEndpointState } = endpointSlice.actions;
export default endpointSlice.reducer;
