import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createEndpointGroup,
  fetchEndpointGroup,
  removeEndpointGroup,
} from ".";

export const getEndpointGroup = createAsyncThunk(
  "endpoint/group",
  fetchEndpointGroup
);
export const addEndpointGroup = createAsyncThunk(
  "endpoint/add-group",
  createEndpointGroup
);

export const deleteEndpointGroup = createAsyncThunk(
  "endpoint/delete-group",
  removeEndpointGroup
);

const initialState = {
  data: [],
  currentGroup: 0,
  loading: false,
  error: null,
};
const endpointGroupSlice = createSlice({
  name: "endpoint-group",
  initialState,
  reducers: {
    setCurrentGroup: (state, action) => {
      state.currentGroup = action.payload;
    },
    clearEndpointGroupState: (state) => {
      state.data = [];
      state.currentGroup = 0;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEndpointGroup.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getEndpointGroup.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getEndpointGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(addEndpointGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = [...state.data, action.payload];
      state.currentGroup = action.payload.id;
    });

    builder.addCase(deleteEndpointGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = state.data.filter(
        (endpoint) => endpoint.id !== action.payload
      );
    });
  },
});

export const { setCurrentGroup, clearEndpointGroupState } =
  endpointGroupSlice.actions;
export default endpointGroupSlice.reducer;
