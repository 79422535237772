import { Box } from "@mui/material";
import { useState } from "react";

import DropdownPopper from "../../../ui/DropdownPopper";
import CreateTicketFormAlt from "../../CreateTicketFormAlt";
import TriggerButton from "./TriggerButton";

const AssignEngineer = ({ incidentId }) => {
  const [anchorEl, setAnchorEl] = useState(null); //Dropdown popper state

  return (
    <DropdownPopper
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      triggerComponent={<TriggerButton />}
    >
      <Box sx={{ maxWidth: 200 }}>
        <CreateTicketFormAlt
          incidentId={incidentId}
          setAnchorEl={setAnchorEl}
        />
      </Box>
    </DropdownPopper>
  );
};

export default AssignEngineer;
