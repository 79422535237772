import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { editUser, deleteUser, fetchUserDetails } from ".";

export const getUserDetails = createAsyncThunk(
  "user/details",
  fetchUserDetails
);
export const updateUserDetails = createAsyncThunk("user/update", editUser);

export const deleteUserAccount = createAsyncThunk("user/delete", deleteUser);

const initialState = {
  data: {},
  loading: false,
  error: null,
};

export const userSlice = createSlice({
  name: "user-details",
  initialState,
  reducers: {
    clearUserState: (state) => {
      state.data = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // User Info
    builder.addCase(getUserDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = action.error.message;
    });
    // Update User Info
    builder.addCase(updateUserDetails.fulfilled, (state, action) => {
      state.data.user = action.payload;
    });
    // Delete User Account
    builder.addCase(deleteUserAccount.fulfilled, (state, action) => {
      // TODO: update the local state when the users state is moved to the redux store
      // state.data.user = action.payload;
    });
  },
});

export const { clearUserState } = userSlice.actions;

export default userSlice.reducer;
