import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchNewestIncidents } from "./index";

export const getNewestIncidents = createAsyncThunk(
  "incidents/newest",
  fetchNewestIncidents
);

const initialState = {
  incidents: [],
  loading: false,
  error: null,
};

const newestIncidentsSlice = createSlice({
  name: "newest-incidents",
  initialState,
  reducers: {
    clearNewestIncidentsState: (state) => {
      state.incidents = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNewestIncidents.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getNewestIncidents.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.incidents = action.payload;
    });
    builder.addCase(getNewestIncidents.rejected, (state, action) => {
      state.loading = false;
      state.incidents = [];
      state.error = action.error.message;
    });
  },
});

export const { clearNewestIncidentsState } = newestIncidentsSlice.actions;
export default newestIncidentsSlice.reducer;
