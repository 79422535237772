import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

import WidgetTitle from "../../WidgetTitle";
import { capitalize } from "../../../../utils/functions";

const OperatorsRank = ({ data }) => {
  return (
    <div>
      <WidgetTitle title="Operators Rank" />
      <IncidentTable data={data} />
    </div>
  );
};

export default OperatorsRank;

const IncidentTable = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Operator Name</TableCell>
            <TableCell align="center">File</TableCell>
            <TableCell align="center">Host</TableCell>
            <TableCell align="center">Network</TableCell>
            <TableCell align="center">Total </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((operator) => (
            <TableRow
              key={operator?.user_id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell width={40}>
                <PersonAddAltIcon color="info" />
              </TableCell>
              <TableCell>{capitalize(operator?.name)}</TableCell>
              <TableCell align="center" width={80}>
                {operator?.file?.rank}
              </TableCell>
              <TableCell align="center" width={80}>
                {operator?.host?.rank}
              </TableCell>
              <TableCell align="center" width={80}>
                {operator?.network?.rank}
              </TableCell>
              <TableCell align="center" width={80}>
                {operator?.overall?.rank}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
