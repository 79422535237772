import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchEndpointDetails } from ".";

export const getEndpointDetails = createAsyncThunk(
  "endpoints-details",
  fetchEndpointDetails
);

const initialState = {
  data: {},
  loading: false,
  error: null,
};
const endpointDetailsSlice = createSlice({
  name: "endpoint-details",
  initialState,
  reducers: {
    clearEndpointDetailsState: (state) => {
      state.data = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEndpointDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getEndpointDetails.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getEndpointDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { clearEndpointDetailsState } = endpointDetailsSlice.actions;
export default endpointDetailsSlice.reducer;
