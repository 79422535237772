import React from "react";
import Box from "@mui/material/Box";

import Avatar from "@mui/material/Avatar";
import MuiSkeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

const SkeletonLoading = ({ rows = 5, height = 50 }) => {
  const newArray = Array.from(
    { length: rows },
    (_, index) => "row - " + (index + 1)
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: height,
      }}
    >
      {newArray.map((value) => (
        <Box sx={{ display: "flex", width: "100%", gap: 2, mb: 2 }}>
          <Box>
            <MuiSkeleton variant="circular">
              <Avatar />
            </MuiSkeleton>
          </Box>
          <Box sx={{ flex: 1 }}>
            <MuiSkeleton width="100%">
              <Typography>.</Typography>
            </MuiSkeleton>
            <MuiSkeleton width="100%">
              <Typography>.</Typography>
            </MuiSkeleton>
            <MuiSkeleton width="70%">
              <Typography>.</Typography>
            </MuiSkeleton>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default SkeletonLoading;
