import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Select as MuiSelect, Typography } from "@mui/material";

import Form from "../ui/Form";
import Controls from "../ui/controls";
import { getMTTREndDate } from "../../utils/functions";
import { getOperators } from "../../redux/features/users/operatorsSlice";
import {
  createTicket,
  reAssignIncident,
} from "../../redux/features/incidents/incidentSlice";

const timeOptions = [
  { id: "minutes", value: "Minute(s)" },
  { id: "hours", value: "Hour(s)" },
  { id: "days", value: "Day(s)" },
  { id: "weeks", value: "Week(s)" },
  { id: "months", value: "Month(s)" },
];

const CreateTicketForm = ({
  setModalOpen,
  incidentId,
  selected,
  reAssign = false,
}) => {
  const initialState = {
    assignedEngineer: selected?.engineer_id ?? "",
    meanTimeToResolve: 5,
    timeOption: { id: "minutes", value: "Minute(s)" },
    remark: selected?.remark || "",
  };
  const [state, setState] = useState(initialState);
  const [operators, setOperators] = useState([]);
  const { operators: allOperators, loading } = useSelector(
    (state) => state.users.operators
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOperators());
  }, [dispatch]);

  /** Get list of operators from state */
  useEffect(() => {
    const formatted = allOperators.map((operation) => ({
      id: operation.id,
      value: operation.name,
    }));
    setOperators(formatted);
  }, []);

  const handleTimeOptionChange = (e) => {
    const timeOption = timeOptions.find(
      (option) => option.id === e.target.value
    );
    setState({
      ...state,
      timeOption,
    });
  };

  const handleInputControlChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value;

    if (e.target.name === "meanTimeToResolve") inputValue = Number(value);

    setState({
      ...state,
      [name]: inputValue,
    });
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    const currentAssignedEngineer = operators.find(
      (operator) => operator.id === state.assignedEngineer
    );

    const remaining = getMTTREndDate(
      state.meanTimeToResolve,
      state.timeOption.id
    );

    const updatedIncident = {
      engineer_id: state.assignedEngineer,
      engineer_assigned: currentAssignedEngineer?.value,
      mttr_end_time: Number(new Date().getTime() + remaining),
      mttr: state.meanTimeToResolve + state.timeOption.id, // For the redux state only
      remark: state.remark,
    };

    if (reAssign) {
      updatedIncident["ticket_created_time"] = Date.now();
      dispatch(reAssignIncident({ incidentId, body: updatedIncident }));
    } else {
      dispatch(createTicket({ incidentId, body: updatedIncident }));
    }

    setState(initialState);
    setModalOpen(false);
  };

  // if(loading) return <Typography>Loading...</Typography>

  return (
    <Form>
      <Controls.Select
        name="assignedEngineer"
        label="Assigned Engineer"
        value={state.assignedEngineer}
        onChange={handleInputControlChange}
        options={operators}
      ></Controls.Select>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Controls.NumberInput
            name="meanTimeToResolve"
            label="Time to Resolve"
            value={state.meanTimeToResolve}
            onChange={handleInputControlChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            name="timeOptionAlt"
            label="Time Options"
            value={state.timeOption || {}}
            onChange={handleTimeOptionChange}
            options={timeOptions}
          ></Select>
        </Grid>
        <Grid item xs={12}>
          <Controls.TextInputField
            multiline
            name="remark"
            label="Remark"
            value={state.remark}
            onChange={handleInputControlChange}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        <Button
          onClick={handleUpdate}
          type="submit"
          variant="contained"
          color="primary"
        >
          {reAssign ? "Reassign" : "Assign"}
        </Button>
        <Button variant="contained" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
      </Box>
    </Form>
  );
};

const Select = (props) => {
  const { name, label, value, onChange, options } = props;
  return (
    <FormControl variant="outlined" sx={{ width: "100%", marginY: 1 }}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect name={name} label={label} value={value.id} onChange={onChange}>
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.value}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

export default CreateTicketForm;
