import { store } from "../../app/store";
import { setToast } from "./toastSlice";

export const showToast = ({ type = null, message }) => {
  store.dispatch(
    setToast({
      type: type,
      message: message,
    })
  );
};
