import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  editEndpointUserGroup,
  fetchEndpointUsers,
  removeEndpointUser,
} from ".";

export const getEndpointUsers = createAsyncThunk(
  "endpoint-users/list",
  fetchEndpointUsers
);

export const deleteEndpointUser = createAsyncThunk(
  "endpoint-users/delete",
  removeEndpointUser
);

export const updateEndpointUserGroup = createAsyncThunk(
  "endpoint-users/update-group",
  editEndpointUserGroup
);

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const endpointUserSlice = createSlice({
  name: "endpoint-users",
  initialState,
  reducers: {
    clearEndpointUserState: (state) => {
      state.data = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEndpointUsers.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getEndpointUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.data = action.payload;
    });
    builder.addCase(getEndpointUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateEndpointUserGroup.fulfilled, (state, action) => {
      const groupItems = action.payload.groupItems;
      const currentState = JSON.parse(JSON.stringify(state.data));
      const updatedUsers = currentState?.matchedUsers?.filter((user) => {
        if (groupItems?.includes(user?.name)) return user;
      });

      state.loading = false;
      state.error = null;
      state.data = {
        ...action.payload,
        matchedUsers: updatedUsers,
      };
    });
    builder.addCase(deleteEndpointUser.fulfilled, (state, action) => {
      const { user } = action.payload;

      state.data.matchedUsers = state.data.matchedUsers.filter((item) => {
        return item?.name !== user;
      });
      state.data.groupItems = state.data.groupItems.filter((item) => {
        return item !== user;
      });
    });
  },
});

export const { clearEndpointUserState } = endpointUserSlice.actions;
export default endpointUserSlice.reducer;
