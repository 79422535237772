import { useState } from "react";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import { approveRequest } from "../../redux/features/reopen/reopenSlice";
import { getMTTREndDate } from "../../utils/functions";
import Controls from "../ui/controls";

const timeOptions = [
  { id: "minutes", value: "Minute(s)" },
  { id: "hours", value: "Hour(s)" },
  { id: "days", value: "Day(s)" },
  { id: "weeks", value: "Week(s)" },
  { id: "months", value: "Month(s)" },
];

const ReopenApprovalForm = ({ incidentId }) => {
  const [mttr, setMttr] = useState(5);
  const [timeOption, setTimeOption] = useState(timeOptions[0]);
  const dispatch = useDispatch();

  const handleApprove = (e) => {
    e.preventDefault();

    const remaining = getMTTREndDate(mttr, timeOption?.id);

    let body = {
      mttr: Number(new Date().getTime() + remaining),
    };

    dispatch(approveRequest({ incidentId, body }));
  };

  return (
    <Box
      sx={{
        minHeight: 120,
        display: "flex",
        flexDirection: "column",
        border: (theme) => `1px solid ${theme.palette.divider}`,
        padding: 1,
        borderRadius: 1,
      }}
    >
      <Box>
        <TextField
          type="number"
          size="small"
          variant="standard"
          name="meanTimeToResolve"
          label="Time to Resolve"
          value={mttr}
          onChange={(e) => setMttr(e.target.value)}
        />
        <Controls.AutoComplete
          label="Time Options"
          options={timeOptions}
          propertyName="value"
          state={timeOption}
          setState={setTimeOption}
          defaultValue={timeOptions[0]}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
        <Button
          size="small"
          variant="contained"
          color="success"
          onClick={handleApprove}
        >
          Approve
        </Button>
      </Box>
    </Box>
  );
};

export default ReopenApprovalForm;
