import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";

import AddGroupButton from "./AddGroupButton";
import Modal from "../../ui/Modal";
import GroupForm from "./GroupForm";
import {
  splitStringByCommaAndConvertToUniqueArray,
  getSlugFromString,
} from "../../../utils/functions";
import { addEndpointGroup } from "../../../redux/features/endpoints/endpointGroupSlice";

const AddGroup = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [recruitingRule, setRecruitingRule] = useState("");
  const [hosts, setHosts] = useState(""); //Hosts in a group
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleShowEditModal = () => {
    setModalOpen(true);
  };

  const createGroup = () => {
    const formattedHosts = splitStringByCommaAndConvertToUniqueArray(hosts);
    const body = {
      group_name: name,
      description,
      recruitingRule,
      hosts: formattedHosts,
    };

    // Dispatch the action here
    dispatch(addEndpointGroup({ body }));

    const slug = getSlugFromString(name);
    navigate(`/endpoints/group/${slug}`, { replace: true });
  };

  return (
    <>
      <Box>
        <AddGroupButton onClick={handleShowEditModal} />
      </Box>

      <Modal
        title="Create a group"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <>
          <GroupForm
            setModalOpen={setModalOpen}
            ButtonLabel="Create Group"
            name={name}
            setName={setName}
            description={description}
            setDescription={setDescription}
            recruitingRule={recruitingRule}
            setRecruitingRule={setRecruitingRule}
            hosts={hosts}
            setHosts={setHosts}
            setError={setError}
            onSubmit={createGroup}
          />
          <Typography color="error">{error && error}</Typography>
        </>
      </Modal>
    </>
  );
};

export default AddGroup;
