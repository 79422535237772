import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

const NoOfEvents = ({ eventLength }) => {
  return (
    <Box sx={{}}>
      <Typography variant="h6" sx={{ mb: 0.5 }}>
        <Chip label={eventLength} size="small" sx={{ mr: ".25rem" }} />
        Events
      </Typography>
    </Box>
  );
};

export default NoOfEvents;
