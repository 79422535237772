import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useLocation } from "react-router-dom";

const RemoveRow = ({ onClick, type = "Host" }) => {
  const { pathname } = useLocation();

  //   Check if the group is all
  if (pathname === "/endpoint-users" || pathname === "/endpoints") {
    return null;
  }

  return (
    <>
      <Tooltip title={`Remove ${type}`}>
        <IconButton
          onClick={onClick}
          sx={{
            borderRadius: 1,
            "&:hover > *": {
              color: (theme) => theme.palette.error.main,
            },
          }}
        >
          <HighlightOffIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default RemoveRow;
