import { combineReducers } from "@reduxjs/toolkit";

import userAudit from "./userAuditSlice";
import operatorsReducer from "./operatorsSlice";
import userReducer from "./userSlice";
import allUsersReducer from "./usersSlice";

import inProgressReducer from "./user-incidents-by-type/inProgressSlice";
import completedReducer from "./user-incidents-by-type/completedSlice";
import overdueReducer from "./user-incidents-by-type/overdueSlice";

const userIncidentsByTypeReducer = combineReducers({
  InProgress: inProgressReducer,
  Completed: completedReducer,
  Overdue: overdueReducer,
});
const usersReducer = combineReducers({
  operators: operatorsReducer,
  users: allUsersReducer,
  user: userReducer,
  userAudit,
  userIncidents: userIncidentsByTypeReducer,
});

export default usersReducer;
