import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";

import EventList from "../../EventList";
import SaveToBoard from "../SaveToBoard";
import { compareString, isObjectEmpty } from "../../../../utils/functions";
import {
  clearEvents,
  forensicQuery,
} from "../../../../redux/features/forensics/boardBuilderSlice";
import Filters from "./Filters";
import ArtifactSelector from "./ArtifactSelector";
import RangeSelector from "./RangeSelector";
import HostSelector from "./HostSelector";
import UserSelector from "./UserSelector";
import NoOfEvents from "./NoOfEvents";
import QueryHeader from "./QueryHeader";

const QueryForm = ({ data, setShowBoardViewButton }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [type, setType] = useState("");
  const [ip, setIp] = useState("");
  const [mac, setMac] = useState("");
  const [hostname, setHostname] = useState("");
  const [os, setOS] = useState("");
  const [username, setUsername] = useState("");
  const [domain, setDomain] = useState("");
  const [query, setQuery] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [showSaveToBoardButton, setShowSaveToBoardButton] = useState(false);
  const { events: allEvents } = useSelector(
    (state) => state.forensics.boardBuilder
  );
  const dispatch = useDispatch();
  const { boardId } = useParams();

  const events = {
    data: allEvents?.filteredData,
    totalCount: allEvents?.totalCount,
  };

  //Artifact File
  const [fileData, setFileData] = useState("");

  //Artifact File and Host
  const [pathName, setPathName] = useState("");
  const [name, setName] = useState("");
  const [hash, setHash] = useState("");

  //Artifact Network
  const [remoteIP, setRemoteIP] = useState("");
  const [remotePort, setRemotePort] = useState("");
  const [url, setUrl] = useState("");
  const [localIP, setLocalIP] = useState("");
  const [localPort, setLocalPort] = useState("");

  // Filter States
  const [rangeArr, setRangeArr] = useState([]);
  const [artifactArr, setArtifactArr] = useState([]);
  const [hostArr, setHostArr] = useState([]);
  const [userArr, setUserArr] = useState([]);
  const [searchParams] = useSearchParams();

  // Filter toggle
  const [showFilters, setShowFilters] = useState(false);

  const filterApplied =
    !!startDate ||
    !!endDate ||
    !!type ||
    !!ip ||
    !!mac ||
    !!hostname ||
    !!os ||
    !!username ||
    !!domain ||
    !!fileData ||
    !!pathName ||
    !!name ||
    !!hash ||
    !!remoteIP ||
    !!remotePort ||
    !!url ||
    !!localIP ||
    !!localPort;

  const isBoardCompleted = compareString(data[0]?.status, "completed");

  useEffect(() => {
    // Make sure that their is no response.
    dispatch(clearEvents());
  }, []);

  const addRangeFilter = () => {
    const range = {};
    if (!!startDate) range["from"] = startDate;
    if (!!endDate) range["to"] = endDate;

    if (!isObjectEmpty(range)) {
      setRangeArr((prev) => {
        return [...prev, range];
      });
      setError(null);
    }
  };

  const addArtifactFilter = () => {
    const artifact = {};

    // Artifact
    const isFile = !!compareString(type, "file");
    const isHost = !!compareString(type, "host");
    const isNetwork = !!compareString(type, "network");

    if (!!type) {
      artifact["group"] = type;
    }

    if (isFile && !!fileData) {
      artifact["data"] = fileData;
    }
    if (isFile || isHost) {
      if (!!name) artifact["name"] = name;
      if (!!pathName) artifact["pathName"] = pathName;
      if (!!hash) artifact["hash"] = hash;
    }

    if (isNetwork) {
      if (!!remoteIP) artifact["remoteIp"] = remoteIP;
      if (!!remotePort) artifact["remotePort"] = remotePort;
      if (!!url) artifact["url"] = url;
      if (!!localIP) artifact["localIp"] = localIP;
      if (!!localPort) artifact["localPort"] = localPort;
    }

    if (!isObjectEmpty(artifact)) {
      setArtifactArr((prev) => {
        return [...prev, artifact];
      });
      setError(null);
    }
  };

  const addHostFilter = () => {
    const host = {};
    if (!!ip) host["ip"] = ip;
    if (!!mac) host["mac"] = mac;
    if (!!hostname) host["name"] = hostname;
    if (!!os) host["os"] = os;

    if (!isObjectEmpty(host)) {
      setHostArr((prev) => {
        return [...prev, host];
      });
      setError(null);
    }
  };

  const addUserFilter = () => {
    const user = {};
    if (!!username) user["name"] = username;
    if (!!domain) user["domain"] = domain;

    if (!isObjectEmpty(user)) {
      setUserArr((prev) => {
        return [...prev, user];
      });
    }
    setError(null);
  };

  const constructedObject = () => {
    const query = {
      index: searchParams.get("index") || "INCIDENT_INDEX",
      boardId,
    };

    const range = {};
    if (!!startDate) range["from"] = startDate;
    if (!!endDate) range["to"] = endDate;

    if (!isObjectEmpty(range)) {
      if (!query.hasOwnProperty("ranges")) query["ranges"] = [];
      query.ranges.push(range);
    }

    if (hostArr.length > 0) query["hosts"] = [...hostArr];
    if (userArr.length > 0) query["users"] = [...userArr];
    if (artifactArr.length > 0) query["artifacts"] = [...artifactArr];

    return query;
  };

  const handleSubmit = async () => {
    // At least one filter must be applied.
    if (
      rangeArr.length === 0 &&
      artifactArr.length === 0 &&
      hostArr.length === 0 &&
      userArr.length === 0
    ) {
      setError("At least one filter must be applied.");
      return;
    }

    const requestQuery = constructedObject();

    dispatch(forensicQuery({ body: requestQuery }));
    setShowSaveToBoardButton(true);
    setQuery(requestQuery);
    // clearForm();
  };

  const clearForm = () => {
    setStartDate(null);
    setEndDate(null);
    setType("");
    setIp("");
    setMac("");
    setHostname("");
    setOS("");
    setUsername("");
    setDomain("");
    setError(null);

    setFileData("");
    setPathName("");
    setName("");
    setHash("");
    setRemoteIP("");
    setRemotePort("");
    setUrl("");
    setLocalIP("");
    setLocalPort("");
  };

  const clearFilters = () => {
    clearForm();
    // Clear filter states
    setRangeArr([]);
    setArtifactArr([]);
    setHostArr([]);
    setUserArr([]);

    dispatch(clearEvents());
  };

  return (
    <QueryAndResponseWrapper>
      {/* Title and Clear filter button */}
      <QueryHeader
        filterApplied={filterApplied}
        clearFilters={clearFilters}
        onClickGo={handleSubmit}
        isBoardCompleted={isBoardCompleted}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
      />
      {showFilters ? (
        <Filters
          rangeArr={rangeArr}
          setRangeArr={setRangeArr}
          artifactArr={artifactArr}
          setArtifactArr={setArtifactArr}
          hostArr={hostArr}
          setHostArr={setHostArr}
          userArr={userArr}
          setUserArr={setUserArr}
        />
      ) : null}
      {/* Query Form */}
      <QueryFormWrapper>
        <RangeSelector
          title="Range"
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          onAddFilter={addRangeFilter}
        />
        <ArtifactSelector
          title="Artifacts"
          type={type}
          setType={setType}
          data={fileData}
          setData={setFileData}
          pathName={pathName}
          setPathName={setPathName}
          name={name}
          setName={setName}
          hash={hash}
          setHash={setHash}
          remoteIP={remoteIP}
          setRemoteIP={setRemoteIP}
          remotePort={remotePort}
          setRemotePort={setRemotePort}
          url={url}
          setUrl={setUrl}
          localIP={localIP}
          setLocalIP={setLocalIP}
          localPort={localPort}
          setLocalPort={setLocalPort}
          onAddFilter={addArtifactFilter}
        />
        <HostSelector
          title="Host"
          ip={ip}
          setIp={setIp}
          mac={mac}
          setMac={setMac}
          hostname={hostname}
          setHostname={setHostname}
          os={os}
          setOS={setOS}
          onAddFilter={addHostFilter}
        />
        <UserSelector
          title="Users"
          username={username}
          setUsername={setUsername}
          domain={domain}
          setDomain={setDomain}
          onAddFilter={addUserFilter}
        />
      </QueryFormWrapper>

      {/* Error Message */}
      <ValidationError error={error} filterApplied={filterApplied} />

      {/* Response */}
      <ResponseWrapper events={events}>
        <EventList events={events} open={open} setOpen={setOpen} />
      </ResponseWrapper>

      {/* Save to board button */}
      <QueryFooter events={events} isBoardCompleted={isBoardCompleted}>
        <SaveToBoard
          query={query}
          showSaveToBoardButton={showSaveToBoardButton}
          setShowSaveToBoardButton={setShowSaveToBoardButton}
          setShowBoardViewButton={setShowBoardViewButton}
        />
        <NoOfEvents eventLength={events?.pageSize || 0} />
      </QueryFooter>
    </QueryAndResponseWrapper>
  );
};

export default QueryForm;

const QueryAndResponseWrapper = ({ children }) => {
  return (
    <Paper
      sx={{
        padding: 2,
        borderRadius: 1,
      }}
    >
      {children}
    </Paper>
  );
};

const QueryFormWrapper = ({ children }) => {
  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        gap: 0,
        flexDirection: { xs: "column", md: "row" },
        justifyContent: { lg: "space-between" },
        alignItems: "flex-start",
        // flexWrap: "wrap",
      }}
    >
      {children}
    </Box>
  );
};

const ResponseWrapper = ({ events, children }) => {
  if (events?.data?.length < 1 || !events?.data) return null;
  return (
    <Box
      sx={{
        maxHeight: "30rem",
        overflowY: "scroll",
      }}
    >
      {children}
    </Box>
  );
};

const QueryFooter = ({ events, isBoardCompleted, children }) => {
  if (!events?.data || events?.data?.length < 1 || isBoardCompleted)
    return null;

  return (
    <Box
      sx={{
        marginTop: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {children}
    </Box>
  );
};

const ValidationError = ({ error }) => {
  if (!error) return null;
  return <Typography color="error">{error}</Typography>;
};
