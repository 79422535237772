import { useSelector } from "react-redux";

export const useDashboard = () => {
  // Executive Dashboard
  const newestIncidents = useSelector(
    (state) => state.executiveDashboard.newestIncidents
  );
  const incidentsByAssignee = useSelector(
    (state) => state.executiveDashboard.incidentsByAssignee
  );
  const incidentsByStatus = useSelector(
    (state) => state.executiveDashboard.incidentsByStatus
  );
  const incidentsOvertime = useSelector(
    (state) => state.executiveDashboard.incidentsOvertime
  );
  const incidentsOverdue = useSelector(
    (state) => state.executiveDashboard.incidentsOverdue
  );
  const incidentsStatusBoard = useSelector(
    (state) => state.executiveDashboard.incidentsStatusBoard
  );
  const resolvedIncidentsByAssignee = useSelector(
    (state) => state.executiveDashboard.resolvedIncidentsByAssignee
  );
  const incidentsBySeverity = useSelector(
    (state) => state.executiveDashboard.incidentsBySeverity
  );
  const resolvedIncidentsMTTR = useSelector(
    (state) => state.executiveDashboard.resolvedIncidentsMTTR
  );
  const topHostsByIncident = useSelector(
    (state) => state.executiveDashboard.topHostsByIncident
  );

  // Operator Dashboard
  const myIncidents = useSelector(
    (state) => state.operatorDashboard.myIncidents
  );
  const operatorIncidentsBySeverity = useSelector(
    (state) => state.operatorDashboard.incidentsBySeverity
  );
  const incidentsByRemainingTime = useSelector(
    (state) => state.operatorDashboard.incidentsByRemainingTime
  );
  const operatorResolvedIncidentsMTTR = useSelector(
    (state) => state.operatorDashboard.resolvedIncidentsMTTR
  );
  const operatorIncidentsByStatus = useSelector(
    (state) => state.operatorDashboard.incidentsByStatus
  );
  const topOperatorHostsByIncident = useSelector(
    (state) => state.operatorDashboard.topHostsByIncident
  );

  return {
    newestIncidents,
    incidentsByAssignee,
    incidentsByStatus,
    incidentsOvertime,
    incidentsOverdue,
    incidentsStatusBoard,
    resolvedIncidentsByAssignee,
    incidentsBySeverity,
    resolvedIncidentsMTTR,
    topHostsByIncident,
    myIncidents,
    operatorIncidentsBySeverity,
    incidentsByRemainingTime,
    operatorResolvedIncidentsMTTR,
    operatorIncidentsByStatus,
    topOperatorHostsByIncident,
  };
};
