import React from "react";
import Grid from "@mui/material/Grid";

import Header from "../../components/Header";
import ActivityList from "../../components/audit/ActivityList";
import PageWrapper from "../../components/PageWrapper";

const AuditPage = () => {
  return (
    <PageWrapper>
      <Grid container>
        <Grid item xs={12} sm={6} lg={8}>
          <Header title="Audit Page" subtitle={`Welcome to the Audit page!`} />
        </Grid>
        <ActivityList />
      </Grid>
    </PageWrapper>
  );
};

export default AuditPage;
