import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchUnassignedIncidentsBySeverity } from "..";

export const getUnassignedMediumIncidents = createAsyncThunk(
  "tickets/unassigned_medium",
  fetchUnassignedIncidentsBySeverity
);

const initialState = {
  incidents: [],
  loading: false,
  error: null,
};
const unassignedMediumSlice = createSlice({
  name: "unassigned_medium",
  initialState,
  reducers: {
    clearUnassignedMediumState: (state) => {
      state.incidents = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnassignedMediumIncidents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUnassignedMediumIncidents.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.incidents = action.payload;
      })
      .addCase(getUnassignedMediumIncidents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearUnassignedMediumState } = unassignedMediumSlice.actions;
export default unassignedMediumSlice.reducer;
