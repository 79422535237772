import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createComment,
  createNewTicket,
  getIncidentById,
  reAssign,
  removeComment,
  reopenRequest,
  updateComment,
  updateIncidentName,
  updateStar,
  updateStatus,
} from ".";

export const getIncident = createAsyncThunk(
  "incidents/getIncidentById",
  getIncidentById
);
export const starIncident = createAsyncThunk("incident/updateStar", updateStar);
export const changeIncidentName = createAsyncThunk(
  "incident/updateIncidentName",
  updateIncidentName
);
export const changeTicketStatus = createAsyncThunk(
  "incident/updateStatus",
  updateStatus
);
export const addComment = createAsyncThunk(
  "incident/createComment",
  createComment
);
export const deleteComment = createAsyncThunk(
  "incident/removeComment",
  removeComment
);
export const editComment = createAsyncThunk(
  "incident/updateComment",
  updateComment
);
export const createTicket = createAsyncThunk(
  "incident/create-ticket",
  createNewTicket
);
export const sendReopenRequest = createAsyncThunk(
  "incident/reopen-request",
  reopenRequest
);

export const reAssignIncident = createAsyncThunk("incident/reassign", reAssign);

const resolutionSummaryQuestions = [
  "How did the incident occur?",
  "What actions did you take and why?",
  "What are the overall consequence of those actions?",
  "What are your sources if any?",
];

const initialState = {
  incident: {},
  incidentName: "",
  star: [],
  comments: [],
  resolutionSummary: [],
  resolutionSummaryQuestions,
  loading: false,
  error: null,
};

const incidentSlice = createSlice({
  name: "incident",
  initialState,
  reducers: {
    clearIncidentState: (state) => {
      state.incident = {};
      state.incidentName = "";
      state.star = [];
      state.comments = [];
      state.resolutionSummary = [];
      state.resolutionSummaryQuestions = resolutionSummaryQuestions;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIncident.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getIncident.fulfilled, (state, action) => {
      const status = action.payload.info.ticket_status;
      const incidentName = action.payload.info.incident_name;
      const comments = action.payload.comments;
      const starred = action.payload.info.star;

      state.loading = false;
      state.incident = action.payload;
      state.incidentName = incidentName;
      state.star = starred;
      state.resolutionSummaryQuestions = resolutionSummaryQuestions;
      state.comments = getAllComments(status, comments);
      state.resolutionSummary = getResolutionSummary(status, comments);
      state.error = null;
    });

    builder.addCase(getIncident.rejected, (state, action) => {
      state.loading = false;
      state.incident = {};
      state.error = action.error.message;
    });

    builder.addCase(starIncident.fulfilled, (state, action) => {
      const { userId } = action.payload;

      if (
        state.star?.includes(userId) &&
        state.incident?.info?.star?.includes(userId)
      ) {
        // Remove it form the array
        state.star = state.star.filter((value) => value !== userId);
        state.incident.info.star = state?.incident?.info?.star?.filter(
          (value) => value !== userId
        );
      } else {
        state.star?.push(userId);
        state?.incident?.info?.star?.push(userId);
      }
    });

    builder.addCase(changeIncidentName.fulfilled, (state, action) => {
      state.incidentName = action.payload;
    });

    builder.addCase(changeTicketStatus.fulfilled, (state, action) => {
      const { ticketStatus, incidentSummary } = action.payload;
      // Update ticket status
      state.incident.info.ticket_status = ticketStatus;
      // Update the resolution summary
      state.resolutionSummary = incidentSummary;
    });

    builder.addCase(addComment.fulfilled, (state, action) => {
      state.comments = [action.payload, ...state.comments];
      state.incident.comments = [action.payload, ...state.incident.comments];
    });

    builder.addCase(deleteComment.fulfilled, (state, action) => {
      const filtered = state.comments.filter(
        (comment) => comment._id !== action.payload
      );
      state.comments = filtered;
      state.incident.comments = filtered;
    });

    builder.addCase(editComment.fulfilled, (state, action) => {
      const index = state.comments.findIndex(
        (comment) => comment._id === action.payload._id
      );
      state.comments.splice(index, 1, action.payload);
      state.incident.comments.splice(index, 1, action.payload);
    });

    builder.addCase(createTicket.fulfilled, (state, action) => {
      const { engineer_assigned, engineer_id, mttr_end_time, mttr } =
        action.payload;
      // update the state

      state.incident.info = {
        ...state.incident.info,
        engineer_assigned,
        engineer_id,
        mttr_end_time,
        mttr,
        ticket_status: "InProgress",
      };
    });

    builder.addCase(sendReopenRequest.fulfilled, (state, action) => {
      console.log("Request Sent");
    });
    builder.addCase(reAssignIncident.fulfilled, (state, action) => {
      const { engineer_assigned, engineer_id, mttr_end_time, mttr } =
        action.payload;

      // update the state
      state.incident.info = {
        ...state.incident.info,
        engineer_assigned,
        engineer_id,
        mttr_end_time,
        mttr,
      };
    });
  },
});

const getAllComments = (status, comments) => {
  if (status?.toLowerCase() !== "completed") return comments;

  let allComments = structuredClone(comments); // Copy the comments array
  allComments.pop();
  return allComments;
};

const getResolutionSummary = (status, comments) => {
  if (status?.toLowerCase() !== "completed") return [];

  let lastComment = comments[comments.length - 1]?._source?.comment;

  if (Array.isArray(lastComment)) {
    return lastComment;
  }
};

export const { clearIncidentState } = incidentSlice.actions;
export default incidentSlice.reducer;
