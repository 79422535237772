import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Artifacts from "./Artifacts";
import ProcessTree from "./ProcessTree";
import Assets from "./Assets";

const IncidentItems = ({ incident }) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <AppBar
        position="static"
        sx={{ background: "inherit", boxShadow: "none" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          // textColor="inherit"
          variant="fullWidth"
          aria-label="incident items"
        >
          <Tab label="Process Tree" id={0} />
          <Tab label="Artifacts" id={1} />
          <Tab label="Assets" id={2} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <ProcessTree incident={incident} />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <Artifacts incident={incident} />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <Assets incident={incident} />
      </TabPanel>
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      sx={{ backgroundColor: "orange" }}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ "&.MuiBox-root": { padding: "12px 0 0" } }}>{children}</Box>
      )}
    </div>
  );
}

export default IncidentItems;
