import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArchiveIcon from "@mui/icons-material/Archive";
import { IconButton } from "@mui/material";

import ActionIconButton from "../../../assets/icons/ActionIconButton";
import { capitalize } from "../../../utils/functions";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "0 6px 6px 6px",
    border: `1px solid ${
      theme.palette.mode === "light" ? "transparent" : theme.palette.divider
    }`,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function CustomMenus({
  openDropdown,
  actionMenus,
  handleSendAction,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // Open the dropdown menu
  const handleClick = (event) => {
    openDropdown();
    setAnchorEl(event.currentTarget);
  };

  const onClick = (menu) => {
    handleSendAction(menu);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <MenuTrigger onClick={handleClick} />
      <StyledMenu
        id="action-customized-menu"
        MenuListProps={{
          "aria-labelledby": "action-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {actionMenus?.map((menu, index) => (
          <MenuItem
            key={`action-customized-menu-${index}`}
            // sx={{ background: index % 2 !== 0 ? "#252b36" : null }}
            onClick={() => onClick(menu)}
            disableRipple
          >
            <ArchiveIcon />
            {capitalize(menu?.label || "")}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}

const MenuTrigger = ({ onClick }) => {
  return (
    <IconButton onClick={onClick} sx={{ borderRadius: "5px", padding: "5px" }}>
      <ActionIconButton />
    </IconButton>
  );
};
