import React from "react";

const ActionIconButton = ({ color = "#05F2AF" }) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 2.33333C0 1.71449 0.253778 1.121 0.705506 0.683417C1.15723 0.245833 1.76991 0 2.40875 0H12.0437C12.6826 0 13.2953 0.245833 13.747 0.683417C14.1987 1.121 14.4525 1.71449 14.4525 2.33333V11.6667C14.4525 12.2855 14.1987 12.879 13.747 13.3166C13.2953 13.7542 12.6826 14 12.0437 14H2.40875C1.76991 14 1.15723 13.7542 0.705506 13.3166C0.253778 12.879 0 12.2855 0 11.6667V2.33333ZM2.67371 4.63167C2.50456 4.79573 2.40954 5.01812 2.40954 5.25C2.40954 5.48188 2.50456 5.70427 2.67371 5.86833L3.84196 7L2.67371 8.13167C2.58497 8.21177 2.51378 8.30837 2.46441 8.41571C2.41504 8.52304 2.3885 8.6389 2.38636 8.75639C2.38422 8.87388 2.40653 8.99058 2.45196 9.09953C2.49739 9.20848 2.56501 9.30746 2.65078 9.39054C2.73656 9.47363 2.83873 9.53913 2.9512 9.58314C3.06368 9.62715 3.18415 9.64876 3.30543 9.64669C3.42672 9.64462 3.54633 9.6189 3.65713 9.57108C3.76793 9.52325 3.86765 9.4543 3.95035 9.36833L5.75691 7.61833C5.92607 7.45427 6.02108 7.23188 6.02108 7C6.02108 6.76812 5.92607 6.54573 5.75691 6.38167L3.95035 4.63167C3.78098 4.46781 3.5514 4.37577 3.31203 4.37577C3.07266 4.37577 2.84308 4.46781 2.67371 4.63167ZM8.12953 7.58333C7.88996 7.58333 7.66021 7.67552 7.49081 7.83961C7.32142 8.00371 7.22625 8.22627 7.22625 8.45833C7.22625 8.6904 7.32142 8.91296 7.49081 9.07705C7.66021 9.24115 7.88996 9.33333 8.12953 9.33333H11.1405C11.38 9.33333 11.6098 9.24115 11.7792 9.07705C11.9486 8.91296 12.0437 8.6904 12.0437 8.45833C12.0437 8.22627 11.9486 8.00371 11.7792 7.83961C11.6098 7.67552 11.38 7.58333 11.1405 7.58333H8.12953Z"
        fill={color}
      />
    </svg>
  );
};

export default ActionIconButton;
