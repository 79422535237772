import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchBoardInformation, fetchForensicBoards } from ".";

export const getForensicBoards = createAsyncThunk(
  "forensic/boards",
  fetchForensicBoards
);
export const getBoardInformation = createAsyncThunk(
  "forensic/board-info",
  fetchBoardInformation
);

const initialState = {
  boards: [],
  loading: false,
  error: null,
};

const forensicsSlice = createSlice({
  name: "forensic-boards",
  initialState,
  reducers: {
    clearForensicBoardsState: (state) => {
      state.loading = false;
      state.boards = [];
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getForensicBoards.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getForensicBoards.fulfilled, (state, action) => {
      state.loading = false;
      state.boards = action.payload;
      state.error = false;
    });
    builder.addCase(getForensicBoards.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clearForensicBoardsState } = forensicsSlice.actions;
export default forensicsSlice.reducer;
