import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchIncidentsOvertime } from "./index";

export const getIncidentsOvertime = createAsyncThunk(
  "incidents/overtime",
  fetchIncidentsOvertime
);

const initialState = {
  incidents: {},
  loading: false,
  error: null,
};

const incidentsOvertimeSlice = createSlice({
  name: "incidents-overtime",
  initialState,
  reducers: {
    clearIncidentsOvertimeState: (state) => {
      state.incidents = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIncidentsOvertime.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getIncidentsOvertime.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.incidents = action.payload;
    });
    builder.addCase(getIncidentsOvertime.rejected, (state, action) => {
      state.loading = false;
      state.incidents = {};
      state.error = action.error.message;
    });
  },
});

export const { clearIncidentsOvertimeState } = incidentsOvertimeSlice.actions;
export default incidentsOvertimeSlice.reducer;
