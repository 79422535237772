import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

/* Alias for WithHighestIncidents */
// import User from "../WithHighestIncidents";
import WidgetTitle from "../../WidgetTitle";

const UsersWithHighestIncidents = ({ users }) => {
  return (
    <div>
      <WidgetTitle title="Users with Highest Incidents Count" />
      {/* {users?.map((user) => (
        <User
          key={user?.name}
          data={user}
          icon={<LanOutlinedIcon color="info" />}
        />
      ))} */}
      <IncidentTable users={users} />
    </div>
  );
};

export default UsersWithHighestIncidents;

const IncidentTable = ({ users }) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Username</TableCell>
            <TableCell align="center">High</TableCell>
            <TableCell align="center">Medium</TableCell>
            <TableCell align="center">Low</TableCell>
            <TableCell align="center">Total </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((user) => (
            <TableRow
              key={user?.name}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell width={20}>
                <PersonAddAltIcon color="info" />
              </TableCell>
              <TableCell>{user?.name}</TableCell>
              <TableCell align="center" width={80}>
                {user?.high}
              </TableCell>
              <TableCell align="center" width={80}>
                {user?.medium}
              </TableCell>
              <TableCell align="center" width={80}>
                {user?.low}
              </TableCell>
              <TableCell align="center" width={80}>
                {user?.total}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
