import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Form from "../../ui/Form";
import Controls from "../../ui/controls";
import { compareString } from "../../../utils/functions";

const HostForm = ({
  onSubmit,
  onClose,
  value,
  setValue,
  mode = "shutdown",
}) => {
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    if (value !== "") setError(null);
    if (event.target.name === "value") {
      setValue(event.target.value);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (value === "") {
      setError(`Countdown value is required.`);
      return;
    }
    onSubmit();
    handleClose();
  };

  const clearForm = () => {
    setValue("");
  };
  const handleClose = () => {
    clearForm();
    onClose(); // close registry modal
  };

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controls.NumberInput
            name="value"
            label="Countdown (in seconds)"
            onChange={handleInputChange}
          />
          {error && (
            <Typography variant="subtitle2" color="error">
              {error}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        <Button
          onClick={handleClick}
          type="submit"
          variant="contained"
          color="primary"
        >
          {compareString(mode, "shutdown") ? "Shutdown" : null}
          {compareString(mode, "restart") ? "Restart" : null}
        </Button>
        <Button variant="contained" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
      </Box>
    </Form>
  );
};
export default HostForm;
