import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchIncidentsByAssignee } from "./index";

export const getIncidentsByAssignee = createAsyncThunk(
  "incidents/byAssignee",
  fetchIncidentsByAssignee
);

const initialState = {
  incidents: [],
  columns: [],
  opened: [],
  aged: [],
  total: [],
  loading: false,
  error: null,
};

const incidentsByAssigneeSlice = createSlice({
  name: "incidents-by-assignee",
  initialState,
  reducers: {
    clearIncidentByStatusAssignee: (state) => {
      state.incidents = [];
      state.columns = [];
      state.opened = [];
      state.aged = [];
      state.total = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIncidentsByAssignee.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getIncidentsByAssignee.fulfilled, (state, action) => {
      const { incidents, columns, opened, aged, total } = action.payload;

      state.loading = false;
      state.error = "";
      state.incidents = incidents;
      state.columns = columns;
      state.opened = opened;
      state.aged = aged;
      state.total = total;
    });
    builder.addCase(getIncidentsByAssignee.rejected, (state, action) => {
      state.loading = false;
      state.incidents = [];
      state.error = action.error.message;
    });
  },
});

export const { clearIncidentByStatusAssignee } =
  incidentsByAssigneeSlice.actions;
export default incidentsByAssigneeSlice.reducer;
