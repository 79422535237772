import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useTheme } from "@emotion/react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ThemeAccordion from "./ThemeAccordion";

export default function ThemeCustomizer({
  themeCustomizerOpen,
  setThemeCustomizerOpen,
}) {
  const theme = useTheme();
  const drawerWidth = 360;

  const handleClose = () => {
    setThemeCustomizerOpen(false);
  };

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={themeCustomizerOpen}
        onClose={handleClose}
        onOpen={() => setThemeCustomizerOpen(true)}
        PaperProps={{
          sx: {
            padding: "0px",
            width: {
              xs: drawerWidth - 100,
              sm: drawerWidth - 60,
              md: drawerWidth,
            },
            // backgroundColor: theme?.palette?.background?.default,
          },
        }}
      >
        <Box
          sx={{
            p: "1rem",
            backgroundColor: theme?.palette?.primary?.main,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" color="common.white">
            Theme Customizer
          </Typography>
          <IconButton onClick={handleClose}>
            <HighlightOffIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            padding: "0px",
            height: "100%",
            backgroundColor: theme?.palette?.background?.default,
          }}
        >
          <ThemeAccordion />
        </Box>
      </SwipeableDrawer>
    </div>
  );
}
