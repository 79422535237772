import { combineReducers } from "@reduxjs/toolkit";
import endpointUserListReducer from "./endpointUserSlice";
import endpointUserGroupReducer from "./endpointUserGroupSlice";

const endpointUserReducer = combineReducers({
  group: endpointUserGroupReducer,
  list: endpointUserListReducer,
});

export default endpointUserReducer;
