import { privateRequest } from "../../../utils/apiRequest";
import { store } from "../../app/store";

export const fetchActions = async ({ params }) => {
  const endpoint = `/action/getAction`;
  const res = await privateRequest.get(endpoint, { params });

  if (res.status === 200) {
    return res.data;
  }
};

export const predefinedAction = async ({ body }) => {
  const userId = store.getState().auth.user.id;
  const endpoint = `/action/send_action/${userId}`;

  const res = await privateRequest.post(endpoint, body);
  if (res.status === 200) {
    // console.log(res.data);
    // return res.data;
  }
};

export const fetchAllArtifactsOfAction = async () => {
  const endpoint = `/action/artifact`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return res.data;
  }
};

export const sessionRequest = async ({ userId, body }) => {
  const endpoint = `/action/sessionRequest/${userId}`;
  const res = await privateRequest.post(endpoint, body);
  console.log(endpoint, res.data);

  if (res.status === 200) {
    return res.data;
  }
};
