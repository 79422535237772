import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";

const Dropdown = ({ children }) => {
  return (
    <Box>
      <Box>{children[0]}</Box>
      <Box>{children[1]}</Box>
    </Box>
  );
};

export const DropdownTrigger = ({ handleClick, children }) => {
  return (
    <div type="button" onClick={handleClick}>
      {children}
    </div>
  );
};

export const DropdownContent = ({ id, open, anchorEl, children }) => {
  return (
    <Popper
      sx={{ zIndex: 20 }}
      placement="bottom-end"
      disablePortal={false}
      id={id}
      open={open}
      anchorEl={anchorEl}
      transition
      modifiers={[
        {
          name: "flip",
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: "document",
            padding: 8,
          },
        },
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: "document",
            padding: 8,
          },
        },
        {
          name: "arrow",
          enabled: false,
          options: {
            //   element: arrowRef,
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box
            sx={{
              mt: 1,
              borderRadius: 1.5,
              border: "2px solid transparent",
              borderColor: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.grey[800]
                  : theme.palette.grey[300],
              p: 2,
              bgcolor: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.grey[900]
                  : theme.palette.grey[200],
              height: "65vh",
              overflowY: "auto",
            }}
          >
            {children}
          </Box>
        </Fade>
      )}
    </Popper>
  );
};

export default Dropdown;
