import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: { user: null, token: null, rememberMe: false },
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken, rememberMe } = action.payload;
      state.user = user;
      state.token = accessToken;
      state.rememberMe = rememberMe;
    },
    logOut: (state) => {
      state.user = null;
      state.token = null;
      state.rememberMe = false;

      localStorage.removeItem("state");
      // localStorage.clear();
    }
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;
