import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { contentWidth as widgetContentWidth } from "../../utils/constants";

function WidgetContent({ children, contentWidth = "boxed", ...rest }) {
  const containerRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom when the component mounts
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, []);

  return (
    <Box
      ref={containerRef}
      id="chart"
      sx={{
        height: 220,
        paddingX: contentWidth === widgetContentWidth.BOXED ? "20px" : 0,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export default WidgetContent;
