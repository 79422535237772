import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import useFetch from "../../../hooks/useFetch";
import { STATUS } from "../../../utils/constants";
import { compareString } from "./../../../utils/functions";
import { closeForensicBoard } from "../../../redux/features/forensics/boardBuilderSlice";

const BoardInformation = ({
  data,
  showBoardViewButton,
  setShowBoardViewButton,
}) => {
  const { boardId } = useParams();
  const { data: boardViewItems } = useFetch(`/forensic/board-view/${boardId}`);
  const boardViewItemsExist = boardViewItems?.results?.length > 0;
  const [information, setInformation] = useState(data);

  useEffect(() => {
    setShowBoardViewButton(boardViewItemsExist);
  }, [boardViewItemsExist]);

  useEffect(() => {
    setInformation(data);
  }, [data]);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/forensics/board-view/${boardId}`);
  };

  return !!information ? (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "space-between",
      }}
    >
      <Box>
        <BoardInformationItem
          label="Board ID"
          value={information[0]?.boardId}
        />
        <BoardInformationItem label="Board Name" value={information[0]?.name} />
        {!!information[0]?.description ? (
          <BoardInformationItem
            label="Description"
            value={information[0]?.description}
          />
        ) : null}
      </Box>
      <Box>
        <BoardInformationItem
          label="Incident ID"
          value={information[0]?.incidentId}
        />
        <BoardInformationItem
          label="Creation Time"
          value={
            information[0]?.createdAt
              ? dayjs(information[0]?.createdAt).format("MMM DD, YYYY HH:mm")
              : null
          }
        />
        {information[0]?.updatedAt ? (
          <BoardInformationItem
            label="Completion Time"
            value={dayjs(information[0]?.updatedAt).format(
              "MMM DD, YYYY HH:mm"
            )}
          />
        ) : null}
      </Box>
      <Box>
        {!!information[0]?.status ? (
          <StatusSelect boardStatus={information[0]?.status} />
        ) : null}
      </Box>
      <Box>
        {showBoardViewButton ? (
          <Button variant="contained" onClick={handleClick}>
            Board View
          </Button>
        ) : null}
      </Box>
    </Box>
  ) : null;
};

export default BoardInformation;

const BoardInformationItem = ({ label, value }) => {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", gap: 1, marginBottom: 1 }}
    >
      <Typography color="gray" variant="body2">
        {label}:
      </Typography>
      <Typography variant="body2">{value}</Typography>
    </Box>
  );
};

function StatusSelect({ boardStatus }) {
  const [status, setStatus] = useState(boardStatus || "inProgress");
  const { boardId } = useParams();
  const dispatch = useDispatch();

  const handleStatusChange = async (e) => {
    setStatus(e.target.value);
    const requestBody = {
      _id: boardId,
      status: e.target.value,
    };

    dispatch(closeForensicBoard({ body: requestBody }));
  };

  const renderValue = (value) => {
    // Customize how the selected value is displayed
    const formattedValue = compareString(value, "inprogress")
      ? "In Progress"
      : "Completed";
    return (
      <Button
        variant="contained"
        color="inherit"
        size="small"
        sx={{ textTransform: "initial" }}
      >
        {formattedValue}
      </Button>
    );
  };

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", gap: 1, marginBottom: 1 }}
    >
      <Typography color="gray" variant="body2">
        Status:
      </Typography>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <Select
          id="board-id"
          value={status}
          onChange={handleStatusChange}
          label="Status"
          // variant="filled"
          size="small"
          renderValue={renderValue}
          disabled={compareString(status, STATUS.Completed)}
          sx={{
            // paddingRight: 1, // Adjust the padding as needed
            "&:before": { display: "none" },
            "&:focus": {
              backgroundColor: "transparent",
            },
            "& .MuiSelect-icon": {
              display: "none", // Hide the caret
            },
            "&.MuiInput-underline:before": {
              borderBottom: "none", // Remove the underline
            },
            "&.MuiInput-underline:after": {
              borderBottom: "none", // Remove the underline
            },
          }}
        >
          <MenuItem
            // disabled={status?.toLowerCase() === "completed"}
            value={"inProgress"}
          >
            In Progress
          </MenuItem>
          <MenuItem value={"completed"}>Completed</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
