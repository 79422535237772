import React from "react";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import Wrapper from "./Wrapper";
import AverageRankIcon from "../../../assets/icons/AverageRankIcon";

const RankByType = ({ rank }) => {
  const theme = useTheme();

  return (
    <Wrapper title="Rank By Type">
      <Rank
        label="File Rank"
        rank={rank?.file?.rank}
        Icon={InsertDriveFileOutlinedIcon}
        color={theme.palette.primary["500"]}
      />
      <Rank
        label="Host Rank"
        rank={rank?.host?.rank}
        Icon={DesktopWindowsOutlinedIcon}
        color={theme.palette.primary["400"]}
      />
      <Rank
        label="Network Rank"
        rank={rank?.network?.rank}
        Icon={LanOutlinedIcon}
        color={theme.palette.primary["300"]}
      />
      <Rank
        label="User Rank"
        rank={rank?.user?.rank}
        Icon={PersonRoundedIcon}
        color={theme.palette.primary["200"]}
      />
      <OverallRank
        label="Average Rank"
        rank={rank?.overall?.rank}
        color={theme.palette.secondary.main}
      />
    </Wrapper>
  );
};

const Rank = ({ label = "Rank", rank = "N/A", Icon, color = "#333" }) => {
  return (
    <RankWrapper>
      <Icon sx={{ color: color, m: "auto", ml: 1 }} />
      <Typography sx={{ flex: 1, p: 1 }}>{label}</Typography>
      <Box
        sx={{
          background: color,
          width: "40px",
          display: " flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.grey[900],
            fontWeight: "bold",
          }}
        >
          {rank}
        </Typography>
      </Box>
    </RankWrapper>
  );
};

const OverallRank = ({ label = "Rank", rank = "N/A", color = "#333" }) => {
  return (
    <RankWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          width: "100%",
        }}
      >
        <AverageRankIcon color={color} />
        <Typography sx={{ p: 1 }}>{label}</Typography>
        <Box
          sx={{
            background: color,
            width: "30px",
            height: "30px",
            display: " flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10%",
          }}
        >
          <Typography
            sx={{
              color: (theme) => theme.palette.grey[900],
              fontWeight: "bold",
            }}
          >
            {rank}
          </Typography>
        </Box>
      </Box>
    </RankWrapper>
  );
};

const RankWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        borderRadius: 1,
        overflow: "hidden",
        background: (theme) => theme.palette.background.default,
        mt: 1,
      }}
    >
      {children}
    </Box>
  );
};
export default RankByType;
