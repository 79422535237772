import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { compareString } from "../../utils/functions";
import { ROLES } from "../../utils/constants";

const NoUserFound = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const dashboard = compareString(user?.role, ROLES.Manager)
    ? "/dashboards/"
    : "/dashboards/operator";

  const handleClick = () => {
    navigate(dashboard, { replace: true });
  };
  return (
    <Box
      sx={{
        height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography sx={{ marginBottom: "4px" }} variant="h3">
          User Not Found!
        </Typography>
        <Button variant="contained" onClick={handleClick}>
          Go to the Dashboard
        </Button>
      </Box>
    </Box>
  );
};

export default NoUserFound;
