import React from "react";
import { Chip, useTheme } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import { hexToRgba } from "../../utils/functions";

const SeverityBadge = ({ riskClass = "unScored" }) => {
  let color, iconColor;
  const theme = useTheme();

  switch (riskClass?.toLowerCase()) {
    case "high":
      color = theme.palette.error.main;
      iconColor = "error";
      break;
    case "medium":
      color = theme.palette.warning.main;
      iconColor = "warning";
      break;
    case "low":
      color = theme.palette.success.main;
      iconColor = "success";
      break;
    default:
      color = "default";
      iconColor = "default";
  }

  const customColor = color !== "default" ? hexToRgba(color, 0.15) : null;

  return (
    <Chip
      icon={
        <CircleIcon color={iconColor} sx={{ fontSize: "10px !important" }} />
      }
      label={riskClass?.toUpperCase()}
      sx={{
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        backgroundColor: customColor,
        color,
        borderRadius: "50px",
        p: "5px",
      }}
      size="small"
    />
  );
};

export default SeverityBadge;
