import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchOperatorInsights } from ".";

export const getOperatorInsightData = createAsyncThunk(
  "tickets/operator-insights",
  fetchOperatorInsights
);

const initialState = {
  operators: [],
  loading: false,
  error: null,
};

const operatorInsightSlice = createSlice({
  name: "operator-insight",
  initialState,
  reducers: {
    clearOperatorInsightState: (state) => {
      state.operators = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOperatorInsightData.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getOperatorInsightData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.operators = action.payload;
      })
      .addCase(getOperatorInsightData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { clearOperatorInsightState } = operatorInsightSlice.actions;
export default operatorInsightSlice.reducer;
