import { CssBaseline, ThemeProvider } from "@mui/material";

import "./App.css";
import Routes from "./routes";
import useTheme from "./hooks/useTheme";
import useSocket from "./hooks/useSocket";
import { useEffect } from "react";
import useAuth from "./hooks/useAuth";
import { showToast } from "./redux/features/toast";

function App() {
  const theme = useTheme();

  // Show toast notification when a user takes an action (Predefined Action)

  /**
   * UseSocket hook to establish a socket connection.
   *
   * @param {string} [URL] The URL of the socket server.
   * @returns {Object} An object containing the socket instance and a function to update it.
   */

  const { socket } = useSocket({ URL: process.env.REACT_APP_NOTIFICATION_URL });
  const { user } = useAuth();

  useEffect(() => {
    if (!socket) return;

    /**
     * Event listener for "actionResponse" events from the socket.
     *
     * @param {Object} data The data object containing the event details.
     */
    socket.on("actionResponse", (data) => {
      let message;
      const owner = data?.owner;
      const content = data?.content;
      const status = data?.status?.toLowerCase();

      // Format message
      if (status === "success") {
        message =
          content?.toLowerCase() === "none" || content?.toLowerCase() === ""
            ? "successfully completed"
            : content;
      } else {
        message =
          content === null || content === ""
            ? "Oops! Something went wrong."
            : content;
      }

      const messageWithAction = `${owner?.action} ${message}`;

      // Check if the notification is sent to the logged-in user
      if (user?.id === owner?.userId) {
        showToast({
          type: status,
          message: messageWithAction,
        });
      }
    });
  }, [socket]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes />
    </ThemeProvider>
  );
}

export default App;
