import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

function Header({ title, subtitle }) {
  const theme = useTheme();
  const mode = theme.palette.mode;

  return (
    <Box sx={{ marginBottom: "1rem" }}>
      <Typography
        variant="h2"
        color={
          mode === "dark"
            ? theme.palette.secondary[100]
            : theme.palette.grey[800]
        }
        fontWeight="bold"
        sx={{ mb: "5px", fontSize: 18 }}
      >
        {title}
      </Typography>
      <Typography
        variant="h5"
        color={
          mode === "dark" ? theme.palette.grey[500] : theme.palette.grey[700]
        }
        sx={{ fontSize: 14, opacity: 0.8 }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
}

export default Header;
