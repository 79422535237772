import { privateRequest } from "../../../utils/apiRequest";

export const fetchUnassignedIncidentsBySeverity = async ({
  severity = "High",
  limit,
  page,
}) => {
  const endpoint = `/tickets/incidents/unassigned/severity?severity=${severity}&pageSize=${limit}&page=${page}`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return res.data.data;
  }
};

export const fetchIncidentInsights = async () => {
  const endpoint = `/tickets/incidents/insights`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return formatIncidentInsight(res.data);
  }
};

function formatIncidentInsight(data) {
  const response = {};
  data?.operatorRankScores?.map((fileType) => {
    const type = fileType?.type?.toLowerCase();
    const averageMTTR = data?.incidentsInsights?.typeSeverities[type];
    const incident = data?.incident[type];

    response[fileType.type] = {
      type: type,
      averageMTTR: {
        High: averageMTTR?.high?.averageMTTR || "0",
        Medium: averageMTTR?.medium?.averageMTTR || "0",
        Low: averageMTTR?.low?.averageMTTR || "0",
      },
      unAssignedIncidents: incident ? incident?.unassignedCount : 0,
      assignedIncidents: incident ? incident?.assignedCount : 0,
      topOperators: fileType.assignee_rank?.splice(0, 3),
    };
    return null;
  });
  return response;
}

export const fetchOperatorInsights = async () => {
  const endpoint = `/tickets/operators/insights`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return formatOperatorInsight(res.data);
  }
};

function formatOperatorInsight(data) {
  const response = data?.map(({ data, userId }) => {
    return {
      id: userId,
      operatorName: data?.name,
      openTickets: {
        high: data["High"]?.active_incident_count,
        medium: data["Medium"]?.active_incident_count,
        low: data["Low"]?.active_incident_count,
      },
      averageResolutionTime: {
        high: data["High"]?.avg_resolution_time || "0",
        medium: data["Medium"]?.avg_resolution_time || "0",
        low: data["Low"]?.avg_resolution_time || "0",
      },
      historicalHighestCapacity: {
        high: data?.insights["High"]?.active_incident_count || 0,
        medium: data?.insights["Medium"]?.active_incident_count || 0,
        low: data?.insights["Low"]?.active_incident_count || 0,
      },
    };
  });
  return response;
}

export const fetchTicketsByStatus = async ({ limit, page, status }) => {
  const endpoint = `/tickets/status?pageSize=${limit}&page=${page}&status=${status}`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return { data: res.data.data, status };
  }
};