import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { updateBoardItemComment } from "../../../redux/features/forensics/boardViewSlice";

const Comment = ({ boardItemId, remark }) => {
  const [isEditing, setEditing] = useState(false);
  const [text, setText] = useState(remark?.toString() || "");

  return (
    <Box
      sx={{
        padding: "8px",
        borderRadius: "4px",
        marginBottom: "8px",
        minHeight: "85px",
        // "@media print": {
        //   display: "none",
        // },
      }}
    >
      {isEditing ? (
        <CommentForm
          boardItemId={boardItemId}
          text={text}
          setText={setText}
          setEditing={setEditing}
        />
      ) : (
        <RenderComment
          text={text}
          isEditing={isEditing}
          setEditing={setEditing}
        />
      )}
    </Box>
  );
};

const CommentForm = ({ boardItemId, text, setText, setEditing }) => {
  const { boardId } = useParams();
  const dispatch = useDispatch();

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleKeyPressed = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setText((prev) => prev + "\n");
    }
  };

  const handleSubmit = () => {
    const body = {
      _id: boardId,
      boardItemId,
      remark: text,
    };

    dispatch(updateBoardItemComment({ body }));
    setEditing(false);
  };

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <TextField
        value={text}
        onChange={handleTextChange}
        onKeyDown={handleKeyPressed}
        // onBlur={handleEditToggle}
        placeholder="Write your comment here ..."
        autoFocus
        multiline
        fullWidth
        // rows={2}
        variant="standard"
        label="User's comment"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 0.5,
          justifyContent: "flex-end",
        }}
      >
        <Button
          size="small"
          sx={{
            textTransform: "initial",
          }}
          variant="contained"
          onClick={handleSubmit}
        >
          Save
        </Button>
        <Button
          size="small"
          sx={{
            textTransform: "initial",
          }}
          variant="outline"
          onClick={() => setEditing(false)}
        >
          Back
        </Button>
      </Box>
    </Box>
  );
};

const RenderComment = ({ text, isEditing, setEditing }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box onClick={() => setEditing(true)}>
        {text?.split("\n").map((p, index) => {
          return <Typography key={`item-${index}`}>{p}</Typography>;
        })}
      </Box>
      <Button
        sx={{
          display: !text ? "block" : "none",
          color: ({ palette }) =>
            palette.mode === "dark" ? palette.grey[200] : palette.grey[700],
          textTransform: "initial",
          cursor: "text",
        }}
        onClick={() => setEditing(!isEditing)}
      >
        Leave your comment here.
      </Button>
    </Box>
  );
};

export default Comment;
