import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import BaseWidget from "./BaseWidget";
import WidgetContent from "./WidgetContent";
import WidgetHeader from "./WidgetHeader";
import WidgetLoading from "./WidgetLoading";
import ResolvedIncidentsMTTRChart from "../charts/ResolvedIncidentsMTTRChart";
import { getResolvedIncidentsMTTR } from "../../redux/features/dashboards/executive/resolvedIncidentsMTTRSlice";
import { useDashboard } from "../../hooks/useDashboard";
import MuiMenu from "./MuiMenu";

const ResolvedIncidentsMTTRWidget = () => {
  const [days, setDays] = useState("7days");
  const { resolvedIncidentsMTTR } = useDashboard();
  const { riskClasses, MTTRAverage, averageResolutionTime, loading } =
    resolvedIncidentsMTTR;
  const dispatch = useDispatch();

  const formatDate = () => {
    if (!days) return null;
    if (days === "24hours") return 1;
    if (days === "7days") return 7;
    if (days === "30days") return 30;
  };

  useEffect(() => {
    dispatch(getResolvedIncidentsMTTR({ days: formatDate() }));
  }, [dispatch, days]);

  return (
    <BaseWidget>
      <WidgetHeader title="Resolved Incidents MTTR">
        <MuiMenu days={days} setDays={setDays} />
      </WidgetHeader>
      {loading ? (
        <WidgetLoading />
      ) : (
        <WidgetContent>
          <ResolvedIncidentsMTTRChart
            riskClasses={riskClasses}
            MTTRAverage={MTTRAverage}
            averageResolutionTime={averageResolutionTime}
          />
        </WidgetContent>
      )}
    </BaseWidget>
  );
};

export default ResolvedIncidentsMTTRWidget;
