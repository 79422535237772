import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchOperatorIncidentsByStatus } from "./index";

export const getOperatorIncidentsByStatus = createAsyncThunk(
  "incidents/operator-incidents-by-status",
  fetchOperatorIncidentsByStatus
);

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const incidentsByStatusSlice = createSlice({
  name: "incidents-by-status",
  initialState,
  reducers: {
    clearOperatorIncidentsByStatusState: (state) => {
      state.data = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOperatorIncidentsByStatus.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getOperatorIncidentsByStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.data = action.payload;
    });
    builder.addCase(getOperatorIncidentsByStatus.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export const { clearOperatorIncidentsByStatusState } =
  incidentsByStatusSlice.actions;
export default incidentsByStatusSlice.reducer;
