import Select from "./Select";
import NumberInput from "./NumberInput";
import TextInputField from "./TextInputField";
import AutoComplete from "./AutoComplete";

const Controls = {
  Select,
  NumberInput,
  TextInputField,
  AutoComplete,
};

export default Controls;
