import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material";

import EndpointUsersTable from "./EndpointUsersTable";

export default function EndpointUsersList() {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          height: "100%",
          // mb: 2,
          borderRadius: 0,
          background: isDark ? "#181C24" : "#fff",
        }}
      >
        <EndpointUsersTable />
      </Paper>
    </Box>
  );
}
