import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import BaseWidget from "../BaseWidget";
import WidgetHeader from "../WidgetHeader";
import WidgetContent from "../WidgetContent";
import IncidentsBySeverityChart from "../../charts/IncidentsBySeverityChart";
import WidgetLoading from "../WidgetLoading";
import useAuth from "../../../hooks/useAuth";
import { getIncidentsBySeverity } from "../../../redux/features/dashboards/operator/incidentsBySeveritySlice";
import { useDashboard } from "../../../hooks/useDashboard";

function IncidentsBySeverityAndOperatorWidget() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { operatorIncidentsBySeverity } = useDashboard();
  const { riskClasses, incidents, loading } = operatorIncidentsBySeverity;

  useEffect(() => {
    dispatch(getIncidentsBySeverity({ userId: user?.id }));
  }, [dispatch]);

  return (
    <BaseWidget>
      <WidgetHeader title="Incidents By Severity" />
      {loading ? (
        <WidgetLoading />
      ) : (
        <WidgetContent>
          <IncidentsBySeverityChart
            riskClasses={riskClasses}
            incidents={incidents}
          />
        </WidgetContent>
      )}
    </BaseWidget>
  );
}

export default IncidentsBySeverityAndOperatorWidget;
