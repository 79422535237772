import React from "react";
import Box from "@mui/material/Box";

const PageWrapper = ({ sx, children }) => {
  return (
    <Box
      p="21px"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        height: "100%",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default PageWrapper;
