import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Palette } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  setThemeMode,
  changeColorScheme,
} from "../../redux/features/theme/themeModeSlice";
import darkModeIcon from "../../assets/darkmode.svg";
import lightModeIcon from "../../assets/lightmode.svg";

export default function ThemeAccordion() {
  return (
    <div>
      <AccordionItem
        title="Theme Mode"
        subtitle="Choose light or dark mode"
        icon={<SettingsBrightnessIcon />}
        id="theme-mode-selector"
      >
        <ToggleButtons />
      </AccordionItem>
      <AccordionItem
        title="Color Scheme"
        subtitle="Choose your primary theme color"
        icon={<Palette />}
        id="color-scheme-selector"
      >
        <ToggleColorSchemeButtons />
      </AccordionItem>
    </div>
  );
}

export const AccordionItem = ({ title, subtitle, icon, id, children }) => {
  const theme = useTheme();

  return (
    <Accordion
      sx={{
        backgroundColor: "transparent",
        overflowX: "auto",
      }}
      disableGutters
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon sx={{ color: theme?.palette?.primary?.main }} />
        }
        aria-controls="panel2a-content"
        id={id}
      >
        <ToggleButtonGroup
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              backgroundColor: theme?.palette?.primary?.main,
              padding: ".5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: ".25rem",
            }}
          >
            {icon}
          </Box>
          <Box>
            <Typography variant="h6">{title}</Typography>
            <Typography sx={{ fontSize: "12px", opacity: ".5" }}>
              {subtitle}
            </Typography>
          </Box>
        </ToggleButtonGroup>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const ToggleButtons = () => {
  const response = JSON.parse(localStorage.getItem("theme"));
  const mode = response?.mode || "dark";
  const [view, setView] = useState(mode);
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    setView(mode);
    dispatch(setThemeMode(mode));
  }, [mode, dispatch]);

  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  return (
    <>
      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleChange}
        style={{ display: "flex", gap: "1rem" }}
      >
        <ToggleButton
          onClick={() => dispatch(setThemeMode("light"))}
          value="light"
          aria-label="light mode"
          sx={{
            "&.Mui-selected": {
              backgroundColor: theme?.palette?.primary?.alt,
            },
          }}
        >
          <ThemeCard title="Light" icon={lightModeIcon} />
        </ToggleButton>
        <ToggleButton
          onClick={() => dispatch(setThemeMode("dark"))}
          value="dark"
          aria-label="dark mode"
        >
          <ThemeCard title="Dark" icon={darkModeIcon} />
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

export const ThemeCard = ({ title, icon }) => {
  return (
    <>
      <Card
        sx={{
          padding: ".2rem",
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
        }}
      >
        <img src={icon} width="60px" alt={title + " mode"} />
        <Typography>{title}</Typography>
      </Card>
    </>
  );
};

// Color Scheme
const ToggleColorSchemeButtons = () => {
  const response = JSON.parse(localStorage.getItem("theme"));
  const themeColor = response?.themeColor || "themeThree";

  const [view, setView] = useState(themeColor);
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    setView(themeColor);
    dispatch(changeColorScheme(themeColor));
  }, [themeColor, dispatch]);

  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  return (
    <>
      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleChange}
        style={{ display: "flex", gap: "1rem" }}
      >
        <Grid container rowSpacing={2}>
          <Grid item xs={12} md={4}>
            <ToggleButton
              onClick={() => dispatch(changeColorScheme("default"))}
              value="default"
              aria-label="default color"
              sx={{
                "&.Mui-selected": {
                  backgroundColor:
                    theme?.palette?.mode === "light"
                      ? theme?.palette?.primary[400]
                      : theme?.palette?.primary?.alt,
                },
              }}
            >
              <ThemeCard title="Default" icon={lightModeIcon} />
            </ToggleButton>
          </Grid>

          <Grid item xs={12} md={4}>
            <ToggleButton
              onClick={() => dispatch(changeColorScheme("themeOne"))}
              value="themeOne"
              aria-label="Theme One"
            >
              <ThemeCard title="Theme 1" icon={lightModeIcon} />
            </ToggleButton>
          </Grid>

          <Grid item xs={12} md={4}>
            <ToggleButton
              onClick={() => dispatch(changeColorScheme("themeTwo"))}
              value="themeTwo"
              aria-label="Theme Two"
            >
              <ThemeCard title="Theme 2" icon={lightModeIcon} />
            </ToggleButton>
          </Grid>

          <Grid item xs={12} md={4}>
            <ToggleButton
              onClick={() => dispatch(changeColorScheme("themeThree"))}
              value="themeThree"
              aria-label="Theme Three"
            >
              <ThemeCard title="Theme 3" icon={lightModeIcon} />
            </ToggleButton>
          </Grid>
        </Grid>
      </ToggleButtonGroup>
    </>
  );
};
