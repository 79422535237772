import React, { useState } from "react";
import Box from "@mui/material/Box";

import CustomMenus from "./CustomMenu";
import { arrayFromObjectKeys } from "../../../utils/functions";
import { getSelectedAction, identifyActionType } from "../functions";

const ActionIcon = ({ targetItem, handleSendAction, triggerVariant }) => {
  const [actions, setActions] = useState([]);

  const formatActionName = (arr) => {
    const array = arr.split(/(?=[A-Z])/);
    if (array[1] === "Ip" && array[2] === "Rules") return array.join(" ");
    if (array[1] === "Domain" && array[2] === "Rules") return array.join(" ");

    return array[0];
  };

  const chooseAction = ({ actionType }) => {
    const selectedAction = getSelectedAction({ actionType });
    // Construct an array from the object keys
    const actionsToArray = arrayFromObjectKeys(selectedAction);

    return actionsToArray?.map((actionName) => ({
      action: selectedAction[actionName]?.actionCode,
      label: formatActionName(actionName),
      actionType: actionName,
    }));
  };

  const handleOpenDropdown = async () => {
    const actionType = await identifyActionType({ target: targetItem });
    const selected = chooseAction({ actionType });
    setActions(selected);
  };

  return (
    <Box>
      {/* <MenuTrigger /> */}
      <CustomMenus
        openDropdown={handleOpenDropdown}
        actionMenus={actions}
        handleSendAction={handleSendAction}
        triggerVariant={triggerVariant}
      />
    </Box>
  );
};

export default ActionIcon;
