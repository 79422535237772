import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";

import WidgetContent from "./WidgetContent";
import NoDataFound from "../charts/NoDataFound";
import SeverityBadge from "../ui/SeverityBadge";
import WidgetLoading from "./WidgetLoading";
import { contentWidth } from "../../utils/constants";

export default function IncidentTable({ incidents, loading }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  if (loading) {
    return <WidgetLoading />;
  }

  return (
    <WidgetContent contentWidth={contentWidth.FULL_WIDTH}>
      {incidents?.length > 0 ? (
        <TableContainer
          sx={{
            borderRadius: 0,
            maxHeight: 200,
            overflow: "hidden",
            "&:hover": {
              overflowY: "auto",
            },
          }}
        >
          <Table
            // sx={{ minWidth: 650 }}
            size="small"
            stickyHeader
            aria-label="incident table"
          >
            <TableHead>
              <TableRow sx={{ textTransform: "uppercase" }}>
                <TableCell
                  sx={{
                    width: {
                      xs: 100,
                      lg: 180,
                    },
                    background: isDark ? "#0f1317" : "#edebeb",
                  }}
                >
                  Severity
                </TableCell>
                <TableCell
                  sx={{ background: isDark ? "#0f1317" : "#edebeb" }}
                  align="left"
                >
                  Incident ID
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {incidents?.map((incident, index) => (
                <TableRow
                  key={incident?.incident_id}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    scope="row"
                    sx={{
                      border: "none",
                      background:
                        index % 2 !== 0
                          ? "transparent"
                          : isDark
                          ? "#0B0E11"
                          : "#fcfcfc",
                    }}
                  >
                    <SeverityBadge riskClass={incident?.severity} />
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      background:
                        index % 2 !== 0
                          ? "transparent"
                          : isDark
                          ? "#0B0E11"
                          : "#fcfcfc",
                    }}
                  >
                    <Button
                      size="small"
                      color={"inherit"}
                      onClick={() =>
                        navigate(`/incidents/${incident?.incident_id}`)
                      }
                    >
                      {incident?.incident_id}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoDataFound />
      )}
    </WidgetContent>
  );
}
