import React from "react";

import IncidentHeader from "../../components/incidents/IncidentHeader";
import IncidentsPage from "./IncidentsPage";

const Incidents = () => {
  return (
    <>
      <IncidentHeader />
      <IncidentsPage />
    </>
  );
};

export default Incidents;
