import React from "react";

import { useDashboard } from "../../hooks/useDashboard";
import ChartTemplate from "./ChartTemplate";

const ResolvedIncidentsByAssigneeChart = () => {
  const { resolvedIncidentsByAssignee } = useDashboard();
  const { assignees, resolvedIncidents } = resolvedIncidentsByAssignee;

  const chartOptions = {
    series: [
      {
        name: "Incidents",
        data: resolvedIncidents,
      },
    ],

    plotOptions: {
      bar: {
        columnWidth: 20,
        distributed: false, // make it true if you want to have different color for all the bars
        align: "left",
      },
    },
    xaxis: {
      categories: assignees,
    },
    yaxis: {
      labels: {
        formatter: (val) => {
          return Math.trunc(val);
        },
      },
    },
  };

  return <ChartTemplate options={chartOptions} series={chartOptions.series} />;
};

export default ResolvedIncidentsByAssigneeChart;
