import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material";

import Header from "../../components/Header";
import BoardViewItem from "../../components/forensics/board-view/BoardViewItem";
import useSaveToAudit from "../../hooks/useSaveToAudit";
import { getBoardViewList } from "../../redux/features/forensics/boardViewSlice";
import { SortableList } from "../../components/SortableList";

const BoardView = () => {
  const { boardId } = useParams();
  const forensicRef = useRef();
  const [openCollapse, setOpenCollapse] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { responses, loading } = useSelector(
    (state) => state.forensics.boardView
  );

  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const grey200 = theme.palette.grey[200];
  const grey900 = theme.palette.grey[900];

  const body = {
    activity: "Download Board",
    pageLocation: location.pathname,
    details: ``,
  };

  const { saveToAudit } = useSaveToAudit({ body });

  useEffect(() => {
    dispatch(getBoardViewList({ boardId }));
  }, []);

  /***
   * This local state is used instead of the global redux state because
   * the DndContext uses it to move (drag and drop) elements around (boardViewItems)
   */
  const [boardItems, setBoardItems] = useState([]);
  const formattedBoardItems = responses?.map((val, index) => {
    return {
      filteredData: val?.filteredResponse,
      sentence: val?.Sentence,
      boardItemId: val?.boardItemId,
      id: index + 1,
      remark: val?.remark,
    };
  });

  const handlePrint = useReactToPrint({
    content: () => forensicRef.current,
  });

  const handleClick = () => {
    saveToAudit();
    setOpenCollapse(true);
  };
  const handleBackToBuilder = () => {
    navigate(`/forensics/board-builder/${boardId}`);
  };

  useEffect(() => {
    setBoardItems(formattedBoardItems || []);
  }, [responses]);

  if (loading) return <Typography>Loading...</Typography>;

  return (
    <Box
      m="21px"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      {/* Header */}
      <Grid container>
        <Grid item xs={12} xl={10}>
          <Header
            title="Board View Page"
            subtitle={`Welcome to the Board View page!`}
          />
          {!!responses.length ? (
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
              <Button
                onClick={handleBackToBuilder}
                size="small"
                color="primary"
                variant="outlined"
                sx={{
                  paddingBlock: 1,
                  paddingInline: 2,
                  color: isDark ? grey200 : grey900,
                  background: isDark
                    ? theme.palette.background.paper
                    : theme.palette.primary.main,
                  "&:hover": {
                    color: isDark ? grey200 : grey900,
                    background: isDark ? theme.palette.primary.light : grey200,
                  },
                }}
              >
                Back to Builder
              </Button>

              <Button
                // onClick={handlePrint}
                size="small"
                color="primary"
                variant="contained"
                sx={{
                  paddingBlock: 1,
                  paddingInline: 2,
                  ml: 1,
                  color: isDark ? grey200 : grey900,
                  background: isDark
                    ? theme.palette.background.paper
                    : theme.palette.primary.main,
                  "&:hover": {
                    color: isDark ? grey200 : grey900,
                    background: isDark ? theme.palette.primary.light : grey200,
                  },
                }}
                onMouseDown={handleClick}
                onMouseUp={handlePrint}
              >
                Download
              </Button>
            </Box>
          ) : null}
        </Grid>
        {!responses.length ? (
          <NoBoardViewItemFound />
        ) : (
          <Grid
            ref={forensicRef}
            item
            xs={12}
            xl={10}
            sx={{
              background: ({ palette }) => "transparent",
              border: ({ palette }) =>
                `2px dashed ${
                  palette.grey[palette.mode === "light" ? 300 : 900]
                }`,
              "@media print": {
                border: "none",
              },
              minHeight: "100vh",
              width: "100%",
              // maxWidth: "60rem !important",
              padding: 5,
              paddingInline: 2,
              boxSizing: "border-box",
              // display: "flex",
              gap: 3,
            }}
          >
            <SortableList
              items={boardItems}
              onChange={setBoardItems}
              renderItem={(item) => (
                <SortableList.Item id={item.id}>
                  <SortableList.DragHandle />
                  <BoardViewItem
                    item={item}
                    boardItems={boardItems}
                    setBoardItems={setBoardItems}
                    open={openCollapse}
                    setOpen={setOpenCollapse}
                  />
                </SortableList.Item>
              )}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default BoardView;

const NoBoardViewItemFound = () => {
  const { boardId } = useParams();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/forensics/board-builder/${boardId}`);
  };

  return (
    <Grid
      item
      xs={12}
      xl={10}
      sx={{
        background: ({ palette }) => palette?.background?.paper,
        border: ({ palette }) =>
          `2px dashed ${palette.grey[palette.mode === "light" ? 300 : 900]}`,
        "@media print": {
          border: "none",
          background: "transparent !important",
        },
        minHeight: "75vh",
        width: "100%",
        // maxWidth: "60rem !important",
        padding: 5,
        paddingInline: 2,
        boxSizing: "border-box",
        // display: "flex",
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
          gap: 2,
        }}
      >
        <Typography variant="h4">No Board Item</Typography>
        <Button
          onClick={handleClick}
          size="small"
          // color="primary"
          variant="contained"
          sx={{
            paddingBlock: 1,
            paddingInline: 2,
            color: (theme) => theme.palette.grey[200],
            background: (theme) =>
              theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : "#313131",
            "&:hover": {
              background: (theme) => theme.palette.primary.dark,
            },
          }}
        >
          Go back to Query Builder
        </Button>
      </Box>
    </Grid>
  );
};
