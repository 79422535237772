import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
// import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

import Modal from "../ui/Modal";
import CreateBoardForm from "./CreateBoardForm";
import BoardBuilder from "./board-builder";
import { compareString } from "../../utils/functions";
import { STATUS } from "../../utils/constants";
import { getForensicBoards } from "../../redux/features/forensics/forensicsSlice";
import { useDispatch, useSelector } from "react-redux";

const tableHead = [
  {
    id: "boardId",
    numeric: true,
    align: "left",
    disablePadding: true,
    label: "Board ID",
  },
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  {
    id: "incidentId",
    numeric: false,
    disablePadding: false,
    label: "Incident ID",
  },
  {
    id: "firstSeen",
    numeric: false,
    disablePadding: false,
    label: "First Seen",
  },
  { id: "lastSeen", numeric: false, disablePadding: true, label: "Last Seen" },
  {
    id: "builderPage",
    numeric: false,
    disablePadding: true,
    label: "Builder Page",
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all boards",
            }}
          /> */}
        </TableCell>
        {tableHead.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, denseOption, setWizardOpen } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          All Boards
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Box sx={{ display: "flex", gap: 2 }}>
          {denseOption}

          {/* <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip> */}
          <CreateBoardButton setWizardOpen={setWizardOpen} />
        </Box>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function BoardList() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("status");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [wizardOpen, setWizardOpen] = React.useState(false);
  const [boardBuilderOpen, setBoardBuilderOpen] = React.useState(false);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const { boards: rows, loading } = useSelector(
    (state) => state.forensics.forensicBoards
  );

  useEffect(() => {
    dispatch(getForensicBoards());
  }, []);
  // const [rows, setRows] = React.useState([]);
  // const rows = data;
  // console.log(rows);

  // useEffect(() => {
  //   setRows(data);
  //   console.log("reload");
  // }, [rows, data]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => {
    return selected.indexOf(id) !== -1;
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [order, orderBy, page, rowsPerPage]);

  useEffect(() => {
    if (searchParams.has("incidentId")) {
      setWizardOpen(true);
      return;
    }
  }, []);

  if (loading) return <Loading />;

  return (
    <>
      {rows.length < 1 ? (
        <NoBoardFound setWizardOpen={setWizardOpen} />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              wizardOpen={wizardOpen}
              setWizardOpen={setWizardOpen}
              denseOption={
                <FormControlLabel
                  control={
                    <Switch checked={dense} onChange={handleChangeDense} />
                  }
                  label="Dense"
                />
              }
            />
            <TableContainer>
              <Table
                sx={{ minWidth: 750, width: "100%" }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {rows
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => (
                      <Row
                        key={row._id}
                        row={row}
                        isItemSelected={isSelected(row._id)}
                        boardId={`enhanced-table-checkbox-${index}`}
                      />
                    ))}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={12} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
      <Modal
        title={
          <Box>
            <Typography>Create a Board Wizard</Typography>
            <Typography variant="body2" color="darkgray">
              Please fill in the information below.
            </Typography>
          </Box>
        }
        modalOpen={wizardOpen}
        setModalOpen={setWizardOpen}
      >
        <CreateBoardForm
          setModalOpen={setWizardOpen}
          setBoardBuilderOpen={setBoardBuilderOpen}
        />
      </Modal>
      <Modal
        title="Board Builder"
        modalOpen={boardBuilderOpen}
        setModalOpen={setBoardBuilderOpen}
        maxWidth="lg"
        sx={{ backgroundColor: "red" }}
      >
        <BoardBuilder setModalOpen={setBoardBuilderOpen} />
      </Modal>
    </>
  );
}

const Row = ({ row, isItemSelected, boardId }) => {
  const navigate = useNavigate();

  return (
    <TableRow
      hover
      // onClick={(event) => handleClick(event, row._id)}
      // role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
      sx={{ cursor: "pointer" }}
    >
      <TableCell padding="checkbox">
        {/* <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                "aria-labelledby": boardId,
              }}
            /> */}
      </TableCell>
      <TableCell component="th" id={boardId} scope="row" padding="none">
        {row._id}
      </TableCell>
      <TableCell>{row._source.name}</TableCell>
      <TableCell>{row._source.description}</TableCell>
      <TableCell>
        {compareString(row._source.status, STATUS.InProgress)
          ? "In Progress"
          : "Completed"}
      </TableCell>
      <TableCell>{row._source.incidentId}</TableCell>
      <TableCell>
        {dayjs(row._source.createdAt).format("MMM DD, YYYY HH:mm")}
      </TableCell>
      <TableCell>
        {(row._source.updatedAt &&
          dayjs(row._source.updatedAt).format("MMM DD, YYYY HH:mm")) ||
          "-"}
      </TableCell>
      <TableCell>
        <Button
          onClick={() => {
            // setBoardBuilderOpen(true)
            navigate(`/forensics/board-builder/${row._id}`);
          }}
          sx={{ fontWeight: 600 }}
          size="small"
          variant="text"
        >
          View Builder
        </Button>
      </TableCell>
    </TableRow>
  );
};

const CreateBoardButton = ({ setWizardOpen, size = "small" }) => {
  return (
    <Button
      onClick={() => {
        setWizardOpen(true);
      }}
      variant="contained"
      size={size}
      color="inherit"
      sx={{
        whiteSpace: "nowrap",
        background: (theme) => theme?.palette?.primary?.main,
        "&:hover": {
          // background: theme?.palette?.primary?.light,
          filter: "brightness(125%)",
          transition: "1s ease",
        },
      }}
    >
      Create a Board
    </Button>
  );
};

const NoBoardFound = ({ setWizardOpen }) => {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          // gap: 3,
          minHeight: "calc(100vh - 12rem)",
          mb: 2,
          p: 2,
        }}
      >
        <Typography variant="h4" textAlign="center">
          Oops! There's no board available.
        </Typography>
        <Typography
          variant="subtitle1"
          textAlign="center"
          color={(theme) => theme.palette.grey[500]}
          sx={{ mb: 2 }}
        >
          Why not create a new board?
        </Typography>
        <CreateBoardButton setWizardOpen={setWizardOpen} size="large" />
      </Paper>
    </Box>
  );
};

const Loading = () => {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          // gap: 3,
          minHeight: "calc(100vh - 12rem)",
          mb: 2,
          p: 2,
        }}
      >
        <Typography variant="h4" textAlign="center">
          Loading...
        </Typography>
        <Typography
          variant="subtitle1"
          textAlign="center"
          color={(theme) => theme.palette.grey[500]}
          sx={{ mb: 2 }}
        >
          Please wait a moment.
        </Typography>
      </Paper>
    </Box>
  );
};
