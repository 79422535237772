import React from "react";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

const ReportLineChart = ({ data }) => {
  const { mode } = useSelector((state) => state.themeMode);
  const theme = useTheme();

  const chartOptions = {
    series: [...data],
    chart: {
      height: 380,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    // title: {
    //   text: "Historical Performance Graph",
    //   align: "left",
    // },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#000", "transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: ["Week 1", "Week 2", "Week 3", "Week 4"],
      title: {
        text: "Historical Performance Graph",
      },
    },
    yaxis: {
      title: {
        text: "Operator Score",
      },
      min: 5,
      max: 40,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      //   offsetY: -25,
      offsetX: -5,
    },
  };

  return (
    <Box
      id="chart"
      sx={{
        backgroundColor:
          mode === "dark" ? theme.palette.background.default : "#fefefe",
        paddingTop: "1rem",
      }}
    >
      <ReactApexChart
        options={chartOptions}
        series={chartOptions.series}
        type="line"
        height={350}
      />
    </Box>
  );
};

export default ReportLineChart;
