import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const Loading = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Typography variant="h4" textAlign="center">
        Loading...
      </Typography>
      <Typography
        variant="subtitle1"
        textAlign="center"
        color={(theme) => theme.palette.grey[500]}
        sx={{ mb: 2 }}
      >
        Please wait a moment.
      </Typography>
    </Box>
  );
};

export default Loading;
