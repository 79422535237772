import { privateRequest } from "../../../../utils/apiRequest";
import { capitalize } from "../../../../utils/functions";

export const fetchNewestIncidents = async () => {
  const res = await privateRequest.get("/dashboards/executive/newest");
  if (res.status === 200) {
    return res.data;
  }
};

export const fetchIncidentsByAssignee = async ({ limit }) => {
  const res = await privateRequest.get(
    `/dashboards/executive/assignee?limit=${limit}`
  );

  if (res.status === 200) {
    const data = res.data?.filter(
      (item) => item.aged.doc_count > 0 || item.opened.doc_count > 0
    );

    const obj = {
      columns: [],
      opened: [],
      aged: [],
      total: [],
    };

    data.map((item) => {
      const username = capitalize(item?.user?.name) || "";

      if (username) {
        obj.columns.push(username);
      }
      obj.opened.push(item.opened.doc_count);
      obj.aged.push(item.aged.doc_count);
      obj.total.push(item.doc_count);
    });

    return { incidents: res.data, ...obj };
  }
};

export const fetchIncidentsByStatus = async () => {
  const res = await privateRequest.get(`/dashboards/executive/status`);
  const data = {
    labels: [],
    series: [],
  };

  if (res.status === 200) {
    res.data.map((item) => {
      data.labels?.push(item.key);
      data.series?.push(item.doc_count);
    });

    return data;
  }
};

export const fetchIncidentsOvertime = async ({ limit }) => {
  const res = await privateRequest.get(
    `/dashboards/executive/overtime?limit=${limit}`
  );

  if (res.status === 200) {
    return res.data;
  }
};

export const fetchIncidentsOverdue = async () => {
  const res = await privateRequest.get(`/dashboards/executive/overdue`);

  if (res.status === 200) {
    return res.data;
  }
};

export const fetchIncidentsStatusBoard = async ({ days }) => {
  const res = await privateRequest.get(
    `/dashboards/executive/status_board/${days}`
  );

  if (res.status === 200) {
    return res.data;
  }
};

export const fetchResolvedIncidentsByAssignee = async ({ days }) => {
  const res = await privateRequest.get(
    `/dashboards/executive/resolved?days=${days}`
  );
  const resolved = {
    assignees: [],
    resolvedIncidents: [],
  };

  if (res.status === 200) {
    res.data.map((incident) => {
      resolved.assignees?.push(capitalize(incident?.name) || " - ");
      resolved.resolvedIncidents?.push(incident.doc_count);
    });

    return resolved;
  }
};

export const fetchIncidentsBySeverity = async ({ days }) => {
  const res = await privateRequest.get(
    `/dashboards/executive/severity?days=${days}`
  );

  if (res.status === 200) {
    const obj = {
      riskClasses: [],
      incidents: [],
    };

    // Put the severity levels in order (i.e. H -> M -> L)
    let temp = [res.data[0], res.data[2], res.data[1], res.data[3]];

    temp.map((incident) => {
      obj.riskClasses?.push(capitalize(incident.key));
      obj.incidents?.push(Math.abs(incident.doc_count));
    });

    return obj;
  }
};

export const fetchResolvedIncidentsMTTR = async ({ days }) => {
  const res = await privateRequest.get(
    `/dashboards/executive/resolved_incidents_mttr?days=${days}`
  );

  const obj = {
    riskClasses: [],
    MTTRAverage: [],
    averageResolutionTime: [],
  };
  const data = res.data.severity;

  if (res.status === 200) {
    for (const severity in data) {
      // Push riskClasses - ["High", "Medium", "Low"]
      obj.riskClasses.push(severity);

      // push MTTR Average
      const a_mttr = Math.round(Math.abs(data[severity]?.avg_mttr));
      obj.MTTRAverage.push(a_mttr);

      // push average resolution time
      const a_r_t = Math.round(Math.abs(data[severity]?.avg_resolution_time));
      obj.averageResolutionTime.push(a_r_t);
    }
    return obj;
  }
};

export const fetchTopHostsByIncident = async ({ days }) => {
  const res = await privateRequest.get(
    `/dashboards/executive/hosts?days=${days}`
  );

  if (res.status === 200) {
    return res.data;
  }
};
