import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";

import { SettingsOutlined } from "@mui/icons-material";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import Logout from "@mui/icons-material/Logout";

import { logOut } from "../../redux/features/auth/authSlice";
import { capitalize, compareString } from "../../utils/functions";
import Notifications from "../../components/notifications";
import CustomIconButton from "../../components/ui/CustomIconButton";
import { getReopenRequests } from "../../redux/features/reopen/reopenSlice";
import { clearAllStates } from "../../redux/features";
import { ROLES } from "../../utils/constants";
import useAuth from "../../hooks/useAuth";

export default function Navbar({
  isSidebarOpen,
  setIsSidebarOpen,
  setThemeCustomizerOpen,
}) {
  const theme = useTheme();
  const mode = theme.palette.mode;

  return (
    <Box>
      <AppBar
        position="static" //comment this to make the app bar full size
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          borderBottom: `1px solid ${theme.palette.background.paper}`,
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            paddingBlock: ".5rem",
            backgroundColor: theme.palette.background.default,
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{
              mr: 2,
              color:
                mode === "dark"
                  ? theme.palette.common.white
                  : theme.palette.common.black,
            }}
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              //   display: { xs: "none", md: "flex" },
              display: "flex",
              marginLeft: "auto",
              gap: "1rem",
              "& > *": {
                borderRadius: "10px",
                backgroundColor: theme.palette.background.paper,
                border: "1px solid transparent",
              },
              "& > *:hover": {
                borderRadius: "10px",
                backgroundColor: theme.palette.primary.main,
                borderColor:
                  mode === "dark"
                    ? theme.palette.primary.main
                    : theme.palette.neutral[700],
              },
            }}
          >
            <ReopenCount />
            <Notifications />

            <CustomIconButton
              size="large"
              aria-label="open color scheme customizer"
              onClick={() => setThemeCustomizerOpen(true)}
            >
              <SettingsOutlined sx={{ fontSize: "25px" }} />
            </CustomIconButton>
            <AccountSettings />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const ReopenCount = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const { user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { pendingIncidentsCount } = useSelector(
    (state) => state.reopenRequests
  );

  const handleNavigateToReopen = () => {
    if (pathname === "/reopen-requests") return;
    navigate(`/reopen-requests`);
  };

  /** Get the latest reopen request count, even if the logged in user is not in the reopen page.  */
  useEffect(() => {
    if (pathname === "/reopen-requests") return;
    dispatch(getReopenRequests({ status: "pending" }));
  }, [dispatch]);

  return compareString(user?.role, ROLES.Manager) ? (
    <>
      <Tooltip title="Reopen Requests">
        <IconButton
          size="large"
          edge="end"
          aria-label="reopen count"
          // color="inherit"
          onClick={handleNavigateToReopen}
          sx={{
            color:
              mode === "dark"
                ? theme.palette.grey[100]
                : theme.palette.grey[800],
            backgroundColor:
              mode === "dark"
                ? theme.palette.background.paper
                : theme.palette.grey[200],
            borderRadius: 2,
          }}
        >
          <Badge
            badgeContent={pendingIncidentsCount || 0}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: theme.palette.secondary.light,
              },
            }}
          >
            <EditNotificationsIcon sx={{ fontSize: "25px" }} />
          </Badge>
        </IconButton>
      </Tooltip>
    </>
  ) : null;
};

const AccountSettings = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth?.user);
  const dispatch = useDispatch();
  const theme = useTheme();
  const mode = theme.palette.mode;

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(logOut());
    clearAllStates();
    setAnchorEl(null);
    navigate("/");
  };
  return (
    <>
      <Tooltip title="Account settings">
        <IconButton
          size="large"
          edge="end"
          aria-label="account of current user"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          onClick={handleOpenMenu}
          // color="inherit"
          sx={{
            color:
              mode === "dark"
                ? theme.palette.grey[100]
                : theme.palette.grey[800],
            backgroundColor:
              mode === "dark"
                ? theme.palette.background.paper
                : theme.palette.grey[200],
            borderRadius: 2,
          }}
        >
          <AccountCircle sx={{ fontSize: "25px" }} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleCloseMenu}>
          <Avatar />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {capitalize(user?.name) || "Profile"}
            </Typography>
            <Typography
              variant="caption"
              sx={{ marginTop: -0.5, opacity: 0.5 }}
            >
              {user?.email}
            </Typography>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleCloseMenu}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleCloseMenu}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
