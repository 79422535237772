import Box from "@mui/material/Box";
import MuiSkeleton from "@mui/material/Skeleton";

const Skeleton = ({ rows = 5, height = 300 }) => {
  const newArray = Array.from(
    { length: rows },
    (_, index) => "row - " + (index + 1)
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: height,
      }}
    >
      {newArray.map((value) => (
        <MuiSkeleton
          key={value}
          sx={{
            width: "100%",
            flex: 1,
          }}
        />
      ))}
    </Box>
  );
};

export default Skeleton;
