import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import {
  compareString,
  getRemainingTime,
  isIncidentOverdue,
} from "../../utils/functions";
import useIncident from "../../hooks/useIncident";
import { STATUS } from "../../utils/constants";

const IncidentRemainingTime = ({ endDate }) => {
  const [remainingTime, setRemainingTime] = useState(null);
  const { getIncidentById } = useIncident();
  const { incident } = getIncidentById;

  const isAssigned = !compareString(
    incident?.info?.engineer_assigned,
    "unassigned"
  );

  const isCompleted = compareString(
    incident?.info?.ticket_status,
    STATUS.Completed
  );

  const isOverdue = isIncidentOverdue(endDate);

  useEffect(() => {
    //Don't show the remaining time and stop the countdown timer.
    if (!isAssigned) return;

    //Stop the countdown timer if the incident is completed.
    if (isCompleted) return;

    //Stop the countdown timer if the incident is overdue.
    if (isOverdue) return;

    const timer = setInterval(() => {
      updateRemainingTime(endDate);
    }, 1000);

    return () => clearInterval(timer);
  }, [endDate]);

  const updateRemainingTime = (endDate) => {
    setRemainingTime(getRemainingTime(endDate));
  };

  return isAssigned ? (
    <Box>
      <Typography
        variant="body2"
        sx={{
          width: 120,
          marginRight: "-10px",
          color: "gray",
          textTransform: "uppercase",
        }}
      >
        Remaining Time
      </Typography>
      {isOverdue ? (
        <Overdue />
      ) : (
        <RemainingTime remainingTime={remainingTime} />
      )}
    </Box>
  ) : null;
};

export default IncidentRemainingTime;

const RemainingTime = ({ remainingTime }) => {
  return (
    <Typography color="success.main" sx={{ width: 120, marginRight: "-10px" }}>
      {remainingTime?.days || "00"} : {remainingTime?.hours || "00"} :{" "}
      {remainingTime?.minutes || "00"} : {remainingTime?.seconds || "00"}
    </Typography>
  );
};

const Overdue = () => {
  return (
    <Typography color="error" sx={{ width: 120, marginRight: "-10px" }}>
      Overdue
    </Typography>
  );
};