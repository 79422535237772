import { Box, ListItem, useTheme } from "@mui/material";
import React from "react";

const SortableItemWrapper = ({ children, reference, style }) => {
  const theme = useTheme();

  return (
    <ListItem
      className="SortableItem"
      ref={reference}
      sx={{
        ...style,
        // background: "red",

        background: (theme) =>
          theme.palette.mode === "dark" ? "#00040411" : null,
        "@media print": {
          background: "transparent",
          boxShadow: "none !important",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          position: "relative",
          padding: 2,
          paddingLeft: 4,
          color: theme.palette.grey[400],
        }}
      >
        {children}
      </Box>
    </ListItem>
  );
};

export default SortableItemWrapper;
