import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

import FilterWrapper from "./FilterWrapper";

const RangeSelector = ({
  title,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  onAddFilter,
}) => {
  return (
    <FilterWrapper title={title} paddingX="0px 8px" onAddFilter={onAddFilter}>
      <>
        <QueryDatePicker
          value={startDate}
          setValue={setStartDate}
          label="Start Date"
          defaultValue={dayjs()}
          //   isStartDate={true}
        />
        <QueryDatePicker
          value={endDate}
          setValue={setEndDate}
          label="End Date"
          defaultValue={dayjs()}
          //   isStartDate={true}
        />
      </>
    </FilterWrapper>
  );
};

const QueryDatePicker = ({ value, setValue, label = "Range", isStartDate }) => {
  const [, setSearchParams] = useSearchParams();
  const [pickerOpen, setPickerOpen] = useState(false);

  function handleChange(newValue) {
    const date = dayjs(newValue).format("YYYY-MM-DD");

    setSearchParams((newSearchParams) => {
      newSearchParams.set(isStartDate ? "startDate" : "endDate", date);
      return newSearchParams;
    });
    setValue(dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss"));
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        size="small"
        disableFuture
        label={label}
        value={value}
        onChange={(newValue) => handleChange(newValue)}
        onOpen={() => {
          setPickerOpen(true);
        }}
        onClose={() => setPickerOpen(false)}
        className={`query-form-date-picker ${
          !pickerOpen && !value && "is-closed"
        }`}
        slotProps={{
          openPickerIcon: { fontSize: "large" },
          openPickerButton: { color: "primary" },
          textField: {
            variant: "standard",
            // focused: true,
            color: "primary",
          },
        }}
        sx={{
          minWidth: "125px !important",
          width: "100% !important",
          // background: "green",
          outline: "none",
          "& .MuiInputBase-input": {
            padding: "8px",
            paddingRight: 0,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default RangeSelector;
