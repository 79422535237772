import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import Form from "../ui/Form";
import { showToast } from "../../redux/features/toast";
import { privateRequest } from "../../utils/apiRequest";

const newBoardValidationSchema = z.object({
  incidentId: z.string().min(1, { message: "Incident ID is required" }),
  boardName: z.string().min(1, { message: "Board Name is required" }),
  description: z.string(),
});

const CreateBoardForm = () => {
  const { register, handleSubmit, reset, formState, setValue } = useForm({
    defaultValue: {
      incidentId: "",
      boardName: "",
      description: "",
    },
    resolver: zodResolver(newBoardValidationSchema),
  });
  const { errors } = formState;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  // Disable the "Create Board" button after submitting
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (searchParams.has("incidentId")) {
      setValue("incidentId", searchParams.get("incidentId"));
      return;
    }
  }, []);

  const onSubmit = async (data) => {
    const { incidentId, boardName, description } = data;
    const endpoint = `forensic/create-board`;
    const body = {
      incidentId,
      name: boardName,
      description,
    };

    try {
      const res = await privateRequest.post(endpoint, body);

      reset({
        incidentId: "",
        boardName: "",
        description: "",
      });

      setIsSubmitted(true);
      showToast({
        type: "success",
        message: "Board is created successfully!",
      });
      setError(null);
      searchParams.delete("incidentId");
      setSearchParams(searchParams);
      if (res.status === 200) {
        navigate(`/forensics/board-builder/${res.data.body.boardId}`);
      }
    } catch (error) {
      setError("Invalid incident ID, please provide a valid ID.");
      console.error(error);
    }
  };

  return (
    <Form>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          required
          fullWidth
          id="incidentId"
          label="Incident Id"
          name="incidentId"
          autoComplete="incidentId"
          autoFocus
          {...register("incidentId")}
          error={!!errors.incidentId || !!error}
          helperText={errors.incidentId?.message || error || " "}
        />
        <TextField
          required
          fullWidth
          id="boardName"
          label="Board Name"
          name="boardName"
          autoComplete="boardName"
          {...register("boardName")}
          error={!!errors.boardName}
          helperText={errors.boardName?.message || " "}
        />
        <TextField
          margin="normal"
          fullWidth
          id="description"
          label="Description"
          name="description"
          autoComplete="description"
          {...register("description")}
          error={!!errors.description}
          helperText={errors.description?.message || " "}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 3,
          gap: 2,
        }}
      >
        <Button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitted}
        >
          Create a Board
        </Button>
      </Box>
    </Form>
  );
};

export default CreateBoardForm;
