import { useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import EditOffOutlinedIcon from "@mui/icons-material/EditOffOutlined";

import Header from "../Header";

const ReopenHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("status")?.toLowerCase();

  const routes = [
    {
      label: "Pending",
      slug: "pending",
      Icon: <PendingActionsIcon />,
    },
    {
      label: "Approved",
      slug: "approve",
      Icon: <AssignmentTurnedInOutlinedIcon />,
    },
    {
      label: "Rejected",
      slug: "reject",
      Icon: <EditOffOutlinedIcon />,
    },
  ];

  const handleClick = (slug) => {
    setSearchParams((searchParams) => {
      searchParams.set("status", slug);
      return searchParams;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Header
        title="Reopen Requests"
        subtitle="Welcome to the reopen requests page."
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: { xs: 0.25, md: 1 },
          }}
        >
          {routes.map(({ label, slug, Icon }) => (
            <TypeButton
              key={slug}
              onClick={() => handleClick(slug)}
              isActive={status === slug}
            >
              {Icon}
              {label}
            </TypeButton>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const TypeButton = ({
  children,
  onClick,
  isActive = false,
  enabled = false, // Used to prevent the button from being disabled
}) => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  return (
    <Button
      onClick={onClick}
      disabled={isActive && !enabled}
      sx={{
        my: 2,
        fontSize: { xs: 10, sm: 12 },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        textAlign: "center",
        backgroundColor: isActive
          ? mode === "dark"
            ? theme.palette.primary.main
            : theme.palette.primary.light
          : mode === "dark"
          ? theme.palette.background.paper
          : theme.palette.grey[200],
        color:
          mode === "dark" ? theme.palette.grey[400] : theme.palette.grey[800],
      }}
    >
      {children}
    </Button>
  );
};

export default ReopenHeader;
