import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";

import ReOpenRequestList from "../../components/reopen";
import ReopenHeader from "../../components/reopen/ReopenHeader";
import { getReopenRequests } from "../../redux/features/reopen/reopenSlice";

const ReOpenRequests = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const status = searchParams?.get("status");

  useEffect(() => {
    dispatch(getReopenRequests({ status: status }));
  }, [dispatch, searchParams]);

  return (
    <Box
      m="21px"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        height: "100%",
      }}
    >
      <ReopenHeader />
      <ReOpenRequestList />
    </Box>
  );
};

export default ReOpenRequests;
