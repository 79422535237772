import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { INDEX } from "../../../../utils/constants";
import QueryFilterToggleButton from "./QueryFilterToggleButton";
import CTA from "./CTA";
import ClearFiltersButton from "./ClearFiltersButton";

const QueryHeader = ({
  clearFilters,
  filterApplied,
  onClickGo,
  isBoardCompleted,
  showFilters,
  setShowFilters,
}) => {
  const [indexType, setIndexType] = useState(INDEX.INCIDENT_INDEX);
  const [searchParams, setSearchParams] = useSearchParams();

  const selectIndexType = (index) => {
    setIndexType(index);
    setSearchParams((searchParams) => {
      searchParams.set("index", index);
      return searchParams;
    });
  };

  // A default index value
  useEffect(() => {
    if (!searchParams.has("index")) {
      setSearchParams((searchParams) => {
        searchParams.set("index", INDEX.INCIDENT_INDEX);
        return searchParams;
      });
    }
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 1,
        minHeight: "2rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Button
          onClick={() => selectIndexType(INDEX.INCIDENT_INDEX)}
          variant={indexType === "INCIDENT_INDEX" ? "outlined" : "text"}
        >
          Events
        </Button>
        <Button
          onClick={() => selectIndexType(INDEX.MAIN_INDEX)}
          variant={indexType === "MAIN_INDEX" ? "outlined" : "text"}
        >
          Alerts
        </Button>
      </Box>

      <Box sx={{ display: "flex", gap: 1 }}>
        {/* Clear filters */}
        {filterApplied ? (
          <ClearFiltersButton label="- Clear filters" onClick={clearFilters} />
        ) : null}
        <QueryFilterToggleButton
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        {!isBoardCompleted ? <CTA onSubmit={onClickGo} /> : null}
      </Box>
    </Box>
  );
};

export default QueryHeader;
