import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAudit } from ".";
export const getUserAudit = createAsyncThunk("user/audit", fetchAudit);

const initialState = {
  audit: {},
  loading: false,
  error: null,
};

export const userAuditSlice = createSlice({
  name: "user-audit",
  initialState,
  reducers: {
    clearUserAuditState: (state) => {
      state.audit = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // User Info
    builder.addCase(getUserAudit.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getUserAudit.fulfilled, (state, action) => {
      state.loading = false;
      state.audit = action.payload;
    });
    builder.addCase(getUserAudit.rejected, (state, action) => {
      state.loading = false;
      state.audit = {};
      state.error = action.error.message;
    });
  },
});

export const { clearUserAuditState } = userAuditSlice.actions;

export default userAuditSlice.reducer;
