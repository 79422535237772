import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, capitalize } from "@mui/material";
import BlurCircularIcon from "@mui/icons-material/BlurCircular";
import Button from "@mui/material/Button";
import AddTaskIcon from "@mui/icons-material/AddTask";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

import Loading from "./Loading";
import { rejectRequest } from "../../redux/features/reopen/reopenSlice";
import ReopenApprovalForm from "./ReopenApprovalForm";
import DropdownPopper from "../ui/DropdownPopper";
import ConfirmDialog from "../ui/ConfirmDialog";

function EnhancedTableToolbar(props) {
  const { denseOption } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        List of Re-open Requests
      </Typography>

      <Box sx={{ display: "flex", gap: 2 }}>
        {denseOption}

        {/* <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip> */}
      </Box>
    </Toolbar>
  );
}

export default function ReOpenRequestList() {
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = searchParams?.get("status");
  const [confirm, setConfirm] = React.useState({
    title: "",
    subtitle: "",
    isOpen: false,
  });

  const [rowsPerPage, setRowsPerPage] = useState(
    +searchParams.get("rowsPerPage") || 20
  );
  const { incidents, loading } = useSelector((state) => state.reopenRequests);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSearchParams((newSearchParams) => {
      newSearchParams.set("rowsPerPage", parseInt(event.target.value, 10));
      return newSearchParams;
    });
    setPage(0);
  };

  const handleNavigateToIncident = (incidentId) => {
    navigate(`/incidents/${incidentId}`);
  };

  const handleRejectRequest = (incidentId) => {
    dispatch(rejectRequest({ incidentId }));
    setConfirm({
      ...confirm,
      isOpen: false,
    });
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  useEffect(() => {
    setSearchParams((newSearchParams) => {
      newSearchParams.set("rowsPerPage", rowsPerPage);
      newSearchParams.set("status", "pending");
      return newSearchParams;
    });
  }, []);

  useEffect(() => {
    setRowsPerPage(+searchParams.get("rowsPerPage"));
  }, [searchParams]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - incidents.length) : 0;

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableCell>Incident ID</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Severity</TableCell>
        <TableCell>Operator</TableCell>
        <TableCell>Reason</TableCell>
        {status === "pending" ? <TableCell>Actions</TableCell> : null}
      </TableRow>
    </TableHead>
  );

  const tableBody = (
    <TableBody>
      {incidents
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          return !!row?.incident_id ? (
            <TableRow
              key={row?.incident_id}
              sx={{
                "& td": {
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.grey["200"]
                      : theme.palette.grey["700"],
                },
              }}
            >
              <TableCell width="300">
                <Button
                  size="small"
                  color={"inherit"}
                  onClick={() => handleNavigateToIncident(row.incident_id)}
                >
                  {row.incident_id}
                </Button>
              </TableCell>
              <TableCell width="120">{capitalize(row.type)}</TableCell>
              <TableCell width="120">
                <IncidentCountBySeverity value={row.severity} />
              </TableCell>
              <TableCell width="120">
                {capitalize(row.engineer_assigned)}
              </TableCell>
              <TableCell>{row.reason}</TableCell>
              {status === "pending" ? (
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    {/* Approve */}
                    <ApproveButton incidentId={row.incident_id} />
                    {/* Reject */}
                    <Button
                      size="small"
                      variant="contained"
                      color={"error"}
                      onClick={() => {
                        setConfirm({
                          title:
                            "Are you sure you want to reject this request?",
                          subtitle: "This operation can't be undo.",
                          isOpen: true,
                          onConfirm: () => handleRejectRequest(row.incident_id),
                        });
                      }}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <HighlightOffOutlinedIcon sx={{ mr: 0.5 }} />
                      Reject
                    </Button>
                  </Box>
                </TableCell>
              ) : null}
            </TableRow>
          ) : null;
        })}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: (dense ? 33 : 53) * emptyRows,
          }}
        >
          <TableCell colSpan={12} />
        </TableRow>
      )}
    </TableBody>
  );

  return (
    <>
      <Box sx={{ width: "100%", minHeight: "90%" }}>
        <Paper sx={{ width: "100%", mb: 2, height: "100%" }}>
          {loading ? (
            <Loading />
          ) : (
            <>
              {incidents?.length > 0 ? (
                <>
                  <EnhancedTableToolbar
                    denseOption={
                      <FormControlLabel
                        control={
                          <Switch
                            checked={dense}
                            onChange={handleChangeDense}
                          />
                        }
                        label="Dense"
                      />
                    }
                  />
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750, width: "100%" }}
                      aria-labelledby="tableTitle"
                      size={dense ? "small" : "medium"}
                    >
                      {tableHeader}
                      {tableBody}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 25, 50, 100]}
                    component="div"
                    count={incidents?.length}
                    rowsPerPage={+rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <NoReopenRequestFound />
              )}
            </>
          )}
        </Paper>
      </Box>

      <ConfirmDialog confirm={confirm} setConfirm={setConfirm} />
    </>
  );
}

const NoReopenRequestFound = () => {
  return (
    <Box
      sx={{
        width: "100%",
        flex: 1,
        height: "100%",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          // background: "transparent",
        }}
      >
        <Typography variant="h4" textAlign="center">
          Oops! There's no re-open request yet.
        </Typography>
      </Paper>
    </Box>
  );
};

const IncidentCountBySeverity = ({ value }) => {
  let color;
  if (value === "High") color = "error";
  if (value === "Medium") color = "warning";
  if (value === "Low") color = "success";

  let customColor = `${color}.main`;
  return (
    <Tooltip
      title={value}
      color="inherit"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: customColor,
            "& .MuiTooltip-arrow": {
              color: customColor,
            },
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 0.15,
          border: "1px solid transparent",
          alignItems: "center",
          padding: "2px 6px",
          borderRadius: 1,
          "&:hover": {
            border: "1px solid inherit",
            borderColor: customColor,
          },
        }}
      >
        <BlurCircularIcon color={color} sx={{ fontSize: 14 }} />
        <Typography>{value}</Typography>
      </Box>
    </Tooltip>
  );
};

const ApproveButton = ({ incidentId }) => {
  const [anchorEl, setAnchorEl] = useState(null); //Dropdown popper state

  return (
    <DropdownPopper
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      triggerComponent={
        <Button
          size="small"
          variant="contained"
          color={"success"}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <AddTaskIcon sx={{ mr: 1 }} /> Approve
        </Button>
      }
    >
      <ReopenApprovalForm incidentId={incidentId} />
    </DropdownPopper>
  );
};
