import React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import EventList from "../EventList";
import Comment from "./Comment";
import { deleteBoardItem } from "../../../redux/features/forensics/boardViewSlice";
import ConfirmDialog from "../../ui/ConfirmDialog";

const BoardViewItem = ({ item, open, setOpen }) => {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        gap: 4,
        maxHeight: "35rem",
        "@media print": {
          mb: 10,
          maxHeight: "100%",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: (theme) =>
              theme.palette.mode === "dark" ? null : theme.palette.grey[700],
          }}
        >
          {item?.sentence}
        </Typography>
        <DeleteBoardItem boardItemId={item.boardItemId} />
      </Box>
      <EventList
        open={open}
        setOpen={setOpen}
        events={item?.filteredData}
        bg="transparent"
      />
      <Comment boardItemId={item.boardItemId} remark={item?.remark} />
    </Box>
  );
};

const DeleteBoardItem = ({ boardItemId }) => {
  const { boardId } = useParams();
  const dispatch = useDispatch();
  const [confirm, setConfirm] = React.useState({
    title: "",
    subtitle: "",
    isOpen: false,
  });

  const handleDelete = () => {
    const body = {
      boardId: boardId,
      boardItemId: boardItemId,
    };

    setConfirm({
      ...confirm,
      isOpen: false,
    });
    dispatch(deleteBoardItem({ body }));
  };

  return (
    <>
      <Tooltip title="Delete">
        <IconButton
          onClick={() => {
            setConfirm({
              title: "Are you sure you want to delete this board item?",
              subtitle: "This operation can't be undo.",
              isOpen: true,
              onConfirm: () => handleDelete(),
            });
          }}
          sx={{
            "@media print": {
              display: "none",
            },
          }}
        >
          <Delete />
        </IconButton>
      </Tooltip>
      <ConfirmDialog confirm={confirm} setConfirm={setConfirm} />
    </>
  );
};

export default BoardViewItem;
