import React from "react";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function WidgetHeader({ title, children, ...rest }) {
  const theme = useTheme();
  const mode = theme.palette.mode;
  return (
    <header
      style={{
        color: theme.palette.primary.main,
        padding: "4px 17px 4px 16px",
        height: "42px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 2,
        // background: "red",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flex: 1,
          height: "100%",
        }}
      >
        <Box sx={{ display: "flex", gap: 1, flex: 1 }}>
          <BlurOnIcon sx={{ width: 20 }} />
          <Typography
            variant="p"
            sx={{
              color:
                mode === "dark"
                  ? theme?.palette?.grey?.[400]
                  : theme?.palette?.grey?.[700],
              fontSize: 15,
              fontWeight: 600,
              maxWidth: {
                xs: "80%",
                md: "100%",
              },
            }}
            noWrap
          >
            {title}
          </Typography>
        </Box>
        {/* Widget Ellipsis  */}
        <Box>
          {
            children
            // || (
            //   <IconButton size="small">
            //     <MoreVertIcon fontSize="small" />
            //   </IconButton>
            // )
          }
        </Box>
      </Box>
    </header>
  );
}

export default WidgetHeader;
