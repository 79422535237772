import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";


import IncidentsByStatusChart from "../../charts/operator/IncidentsByStatusChart";
import BaseWidget from "../BaseWidget";
import WidgetHeader from "../WidgetHeader";
import WidgetContent from "../WidgetContent";
import WidgetLoading from "../WidgetLoading";
import useAuth from "../../../hooks/useAuth";
import { getOperatorIncidentsByStatus } from "../../../redux/features/dashboards/operator/incidentsByStatusSlice";
import { useDashboard } from "../../../hooks/useDashboard";
import MuiMenu from "../MuiMenu";

function IncidentsByStatusWidget() {
  const [days, setDays] = useState("7days");
  const { user } = useAuth();
  const { operatorIncidentsByStatus } = useDashboard();
  const { data, loading } = operatorIncidentsByStatus;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOperatorIncidentsByStatus({ userId: user?.id }));
  }, [dispatch, days]);

  return (
    <BaseWidget>
      <WidgetHeader title="My Incidents By Status">
        <MuiMenu days={days} setDays={setDays} />
      </WidgetHeader>
      {loading ? (
        <WidgetLoading />
      ) : (
        <WidgetContent>
          <IncidentsByStatusChart incidents={data[days]} days={days} />
        </WidgetContent>
      )}
    </BaseWidget>
  );
}

export default IncidentsByStatusWidget;
