import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";

import ReportHeader from "../../components/reports/ReportHeader";
import useFetch from "../../hooks/useFetch";
import EndpointTable from "../../components/reports/endpoint-summary-report/EndpointTable";

const formatDate = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

const EndpointSummaryReport = ({ startDate, endDate }) => {
  const apiEndpoint = `/reports/endpoints-summary?startDate=${formatDate(
    startDate
  )}&endDate=${formatDate(endDate)}`;
  const { data, loading } = useFetch(apiEndpoint);

  if (loading) {
    return (
      <Box
        sx={{
          height: "15rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        component={Paper}
      >
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <div>
      <Box
        sx={{
          marginTop: "-25px",
        }}
      >
        <ReportHeader
          template="Endpoint Summary Report"
          startDate={startDate}
          endDate={endDate}
        />
        {data && data?.endpoints ? (
          <EndpointTable endpoints={data?.endpoints} />
        ) : null}
      </Box>
    </div>
  );
};

export default EndpointSummaryReport;
