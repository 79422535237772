import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

import WidgetTitle from "../../WidgetTitle";
import { capitalize } from "../../../../utils/functions";

const OperatorsWorkload = ({ data }) => {
  return (
    <div>
      <WidgetTitle title="Operators Workload" />
      <IncidentTable data={data} />
    </div>
  );
};

export default OperatorsWorkload;

const IncidentTable = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Operator Name</TableCell>
            <TableCell align="center">High</TableCell>
            <TableCell align="center">Medium</TableCell>
            <TableCell align="center">Low</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((operator, index) => (
            <TableRow
              key={operator?.user_id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell width={40}>
                {/* <PersonAddAltIcon color="info" /> */} {index + 1}
              </TableCell>
              <TableCell>{capitalize(operator?.name)}</TableCell>
              <TableCell align="center" width={80}>
                {operator?.high}
              </TableCell>
              <TableCell align="center" width={80}>
                {operator?.medium}
              </TableCell>
              <TableCell align="center" width={80}>
                {operator?.low}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
