import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";

import Group from "./Group";
import AddGroup from "./AddGroup";
import { getEndpointGroup } from "../../../redux/features/endpoints/endpointGroupSlice";

const EndpointGroup = () => {
  const dispatch = useDispatch();
  const { group } = useParams();
  const { data, loading } = useSelector((state) => state.endpoints.group);
  const allGroup = {
    id: 0,
    name: "All",
    slug: "/",
  };

  useEffect(() => {
    dispatch(getEndpointGroup());
  }, [group]);

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          height: "100% !important",
          maxHeight: "75vh",
          overflowY: "hidden",
          "&:hover": {
            overflowY: "auto",
          },
        }}
      >
        <Group data={allGroup} />
        {data?.map((group) => (
          <Group key={group.id} data={group} />
        ))}
      </Box>
      <Box sx={{ mt: 1 }}>
        <AddGroup />
      </Box>
    </>
  );
};

export default EndpointGroup;
