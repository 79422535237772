import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";

import IncidentCountByType from "../../components/reports/incident-summary-report/incident-count-by-type";
import ResolutionTimeByType from "../../components/reports/incident-summary-report/resolution-time-by-type";
import ReportHeader from "../../components/reports/ReportHeader";
import useFetch from "../../hooks/useFetch";
import TopIncidents from "../../components/reports/incident-summary-report/top-incidents";
import LeadingOperators from "../../components/reports/incident-summary-report/leading-operators";
import HostsWithHighestIncidents from "../../components/reports/incident-summary-report/hosts-with-highest-incidents";
import UsersWithHighestIncidents from "../../components/reports/incident-summary-report/users-with-highest-incidents";
import OverdueIncidents from "../../components/reports/incident-summary-report/overdue-incidents";
import { isObjectEmpty } from "../../utils/functions";

const formatDate = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

const IncidentSummaryReport = ({ startDate, endDate }) => {
  const endpoint = `/reports/incidents-summary?startDate=${formatDate(
    startDate
  )}&endDate=${formatDate(endDate)}`;
  const { data, loading } = useFetch(endpoint);

  if (loading) return <Loading />;

  // Check if there is data available to show
  if (
    data?.avg_resolution_time?.total?.value === "0m:0s" &&
    data?.hosts_with_highest_incidents?.length === 0 &&
    data?.incidents?.total?.value === 0 &&
    isObjectEmpty(data?.leading_operator) &&
    data?.top_incidents?.length === 0 &&
    data?.top_overdue_incidents?.length === 0 &&
    data?.users_with_highest_incidents?.length === 0
  )
    return <NoDataFound />;

  return (
    <Box
      sx={{
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "flex-start",
        gap: 1,
        width: 700,
        marginTop: "-25px",
      }}
    >
      <ReportHeader
        template="Incident Summary Report"
        startDate={startDate}
        endDate={endDate}
      />
      {data && data?.incidents ? (
        <IncidentCountByType data={data?.incidents} />
      ) : null}
      <div className="page-break"></div>
      {data && data?.avg_resolution_time ? (
        <ResolutionTimeByType data={data?.avg_resolution_time} />
      ) : null}
      <div className="page-break"></div>

      {data &&
      data?.leading_operator &&
      !isObjectEmpty(data?.leading_operator) ? (
        <LeadingOperators operators={data?.leading_operator} />
      ) : null}

      {data &&
      data?.hosts_with_highest_incidents &&
      data?.hosts_with_highest_incidents?.length > 0 ? (
        <HostsWithHighestIncidents hosts={data?.hosts_with_highest_incidents} />
      ) : null}

      {data?.top_incidents?.length > 0 ? (
        <TopIncidents incidents={data?.top_incidents} />
      ) : null}
      <div className="page-break"></div>
      {data &&
      data?.users_with_highest_incidents &&
      data?.users_with_highest_incidents?.length > 0 ? (
        <UsersWithHighestIncidents users={data?.users_with_highest_incidents} />
      ) : null}

      {data?.top_overdue_incidents?.length > 0 ? (
        <OverdueIncidents incidents={data?.top_overdue_incidents} />
      ) : null}
    </Box>
  );
};

export default IncidentSummaryReport;

const Loading = () => {
  return (
    <Box>
      <Typography>Loading...</Typography>
    </Box>
  );
};

const NoDataFound = () => {
  return (
    <Box>
      <Typography>No data found for the selected time range.</Typography>
    </Box>
  );
};
