import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Tab, Tabs } from "@mui/material";
import IncidentsTable from "./IncidentsTable";
import { useSearchParams } from "react-router-dom";

const OpenAndClosedIncidentsTab = ({ openIncidents, closedIncidents }) => {
  const [value, setValue] = useState(0);
  const [_, setSearchParams] = useSearchParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);

    setSearchParams((newSearchParam) => {
      if (newValue === 0) newSearchParam.set("tab", "open-incidents");
      if (newValue === 1) newSearchParam.set("tab", "closed-incidents");
      return newSearchParam;
    });
  };

  useEffect(() => {
    setSearchParams((newSearchParam) => {
      newSearchParam.set("tab", "open-incidents");
      return newSearchParam;
    });
  }, []);

  return (
    <Card variant="outlined" sx={{ height: "100%", minHeight: 380 }}>
      <CardContent>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="standard"
          >
            <Tab label="Open Incidents" sx={{ paddingLeft: 0 }} />
            <Tab label="Closed Incidents" sx={{ paddingLeft: 0 }} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <IncidentsTable incidents={openIncidents} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <IncidentsTable incidents={closedIncidents} status="closed" />
        </CustomTabPanel>
      </CardContent>
    </Card>
  );
};

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`status-tabpanel-${index}`}
      aria-labelledby={`status-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingBlock: 2 }}>{children}</Box>}
    </div>
  );
};

export default OpenAndClosedIncidentsTab;
