import React from "react";

import { useDashboard } from "../../hooks/useDashboard";
import ChartTemplate from "./ChartTemplate";

const IncidentsByAssigneeChart = () => {
  const { incidentsByAssignee } = useDashboard();
  const { columns, opened, aged, total } = incidentsByAssignee;

  const options = {
    chart: {
      id: "incidents-by-operators",
    },
    xaxis: {
      categories: columns,
    },
  };

  return (
    <ChartTemplate
      options={options}
      series={[
        { name: "Overdue", data: aged }, // Overdue <- Aged
        { name: "InProgress", data: opened }, // In Progress <- Opened
        { name: "Assigned", data: total }, // Assigned <- Total
      ]}
      type="bar"
      style={{
        width: "100%",
      }}
      notFoundMessage="No incident is assigned."
    />
  );
};

export default IncidentsByAssigneeChart;
