import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";

import { compareString } from "../../../../utils/functions";

const Filters = ({
  rangeArr,
  setRangeArr,
  artifactArr,
  setArtifactArr,
  hostArr,
  setHostArr,
  userArr,
  setUserArr,
}) => {
  const handleDeleteRange = (index) => {
    setRangeArr((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };
  const handleDeleteArtifact = (index) => {
    setArtifactArr((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };
  const handleDeleteHost = (index) => {
    setHostArr((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };
  const handleDeleteUser = (index) => {
    setUserArr((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  // Check if there is no filter is applied
  const isEmpty =
    rangeArr?.length === 0 &&
    artifactArr?.length === 0 &&
    hostArr?.length === 0 &&
    userArr?.length === 0;

  if (isEmpty) return <NoFilterApplied />;

  return (
    <Paper sx={{ display: "flex", width: "100%", justifyContent: "stretch" }}>
      <Grid container>
        <Grid item xs={12} md={3}>
          <RangeFilters
            rangeArr={rangeArr}
            handleDeleteRange={handleDeleteRange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ArtifactFilters
            artifactArr={artifactArr}
            handleDeleteArtifact={handleDeleteArtifact}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <HostFilters hostArr={hostArr} handleDeleteHost={handleDeleteHost} />
        </Grid>
        <Grid item xs={12} md={3}>
          <UserFilters userArr={userArr} handleDeleteUser={handleDeleteUser} />
        </Grid>
      </Grid>
    </Paper>
  );
};

const FilterULWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        listStyle: "none",
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {children}
    </Box>
  );
};

const FilterGroupTitle = ({ title = "Title" }) => {
  return (
    <Typography variant="h6" sx={{ padding: "3px", ml: "8px", mt: "2px" }}>
      {title}
    </Typography>
  );
};

const FilterItem = ({ value, onClick, icon, multiline = "false" }) => {
  const multilineStyle = multiline
    ? {
        padding: "4px 1px",
        height: "auto",
        alignItems: "flex-start",
        borderRadius: "5px",
        "& .MuiChip-label": {
          display: "block",
          whiteSpace: "normal",
        },
      }
    : {};

  return (
    <ListItem sx={{ margin: "0px", padding: "3px" }}>
      <Chip
        size="small"
        icon={icon || <TagFacesIcon />}
        label={value}
        onDelete={onClick}
        sx={{
          ...multilineStyle,
          width: "100% !important",
          "&>*:last-child": {
            marginLeft: "auto",
          },
        }}
      />
    </ListItem>
  );
};

const RangeFilters = ({ rangeArr, handleDeleteRange }) => {
  const getValue = (range) => {
    let val = "",
      start = range?.from,
      end = range?.to;

    // Set the value to null if the provided date is 'Invalid Date'
    // The date will be an 'Invalid Date', if the user selected a date and remove it manually.
    if (compareString(start, "Invalid Date")) start = null;
    if (compareString(end, "Invalid Date")) end = null;

    // If EndDate is not provided
    if (!end && !!start) val = start + " - now";

    // If both start and end dates are provided
    if (!!end && !!start) val = start + " - " + end;

    // If StartDate is not provided
    if (!!end && !start) val = "Until " + end;

    return val;
  };
  return (
    <Box>
      <FilterGroupTitle title="Ranges" />

      <FilterULWrapper>
        {rangeArr &&
          rangeArr.map((range, index) => {
            return (
              <FilterItem
                key={index}
                value={getValue(range)}
                onClick={() => handleDeleteRange(index)}
              />
            );
          })}
      </FilterULWrapper>
    </Box>
  );
};

const ArtifactFilters = ({ artifactArr, handleDeleteArtifact }) => {
  return (
    <Box>
      <FilterGroupTitle title="Artifacts" />

      <FilterULWrapper>
        {artifactArr &&
          artifactArr.map((artifact, index) => {
            const { group, ...rest } = artifact;
            const selectedValues = Object.values(rest);

            let icon, val;
            if (group === "file") icon = <FolderOutlinedIcon />;
            if (group === "host") icon = <DesktopWindowsOutlinedIcon />;
            if (group === "network") icon = <LanOutlinedIcon />;

            if (selectedValues.length === 1) {
              val = selectedValues[0];
            } else {
              if (selectedValues.length === 0) val = group;
              val = (
                <Box>
                  {selectedValues.map((value, index) => (
                    <Typography key={index}>{value}</Typography>
                  ))}
                </Box>
              );
            }

            return (
              <FilterItem
                key={index}
                value={val}
                onClick={() => handleDeleteArtifact(index)}
                icon={icon}
                // multiline={true}
              />
            );
          })}
      </FilterULWrapper>
    </Box>
  );
};
const HostFilters = ({ hostArr, handleDeleteHost }) => {
  return (
    <Box>
      <FilterGroupTitle title="Hosts" />

      <FilterULWrapper>
        {hostArr &&
          hostArr.map((host, index) => {
            const selectedValues = Object.values(host);
            let val;

            if (selectedValues.length === 1) {
              val = selectedValues[0];
            } else {
              if (selectedValues.length === 0) return;
              val = (
                <Box>
                  {selectedValues.map((value, index) => (
                    <Typography key={index}>{value}</Typography>
                  ))}
                </Box>
              );
            }

            return (
              <FilterItem
                key={index}
                value={val}
                onClick={() => handleDeleteHost(index)}
              />
            );
          })}
      </FilterULWrapper>
    </Box>
  );
};
const UserFilters = ({ userArr, handleDeleteUser }) => {
  return (
    <Box>
      <FilterGroupTitle title="Users" />
      <FilterULWrapper>
        {userArr &&
          userArr.map((user, index) => {
            const selectedValues = Object.values(user);
            let val;

            if (selectedValues.length === 1) {
              val = selectedValues[0];
            } else {
              if (selectedValues.length === 0) return;
              val = (
                <Box>
                  {selectedValues.map((value, index) => (
                    <Typography key={index}>{value}</Typography>
                  ))}
                </Box>
              );
            }
            return (
              <FilterItem
                key={index}
                value={val}
                onClick={() => handleDeleteUser(index)}
              />
            );
          })}
      </FilterULWrapper>
    </Box>
  );
};

const NoFilterApplied = () => {
  return (
    <Paper sx={{ display: "flex", width: "100%", justifyContent: "stretch" }}>
      <Typography variant="h6" sx={{ width: "100%", padding: "10px" }}>
        No filter applied.
      </Typography>
    </Paper>
  );
};
export default Filters;
