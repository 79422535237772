import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import BaseWidget from "./BaseWidget";
import WidgetHeader from "./WidgetHeader";
import WidgetContent from "./WidgetContent";
import WidgetLoading from "./WidgetLoading";
import IncidentsBySeverityChart from "../charts/IncidentsBySeverityChart";
import { getIncidentsBySeverity } from "../../redux/features/dashboards/executive/incidentsBySeveritySlice";
import { useDashboard } from "./../../hooks/useDashboard";
import MuiMenu from "./MuiMenu";

function IncidentsBySeverityWidget() {
  const [days, setDays] = useState("7days");
  const dispatch = useDispatch();

  const { incidentsBySeverity } = useDashboard();
  const { riskClasses, incidents, loading } = incidentsBySeverity;
  const formatDate = () => {
    if (!days) return null;
    if (days === "24hours") return 1;
    if (days === "7days") return 7;
    if (days === "10days") return 10;
    if (days === "30days") return 30;
  };

  useEffect(() => {
    dispatch(getIncidentsBySeverity({ days: formatDate() }));
  }, [dispatch, days]);

  return (
    <BaseWidget>
      <WidgetHeader title="Incidents By Severity">
        <MuiMenu
          days={days}
          setDays={setDays}
          options={[
            { key: "24hours", label: "Today" },
            { key: "7days", label: "7 Days" },
            { key: "10days", label: "10 Days" },
            { key: "30days", label: "30 Days" },
          ]}
        />
      </WidgetHeader>
      {loading ? (
        <WidgetLoading />
      ) : (
        <WidgetContent>
          <IncidentsBySeverityChart
            riskClasses={riskClasses}
            incidents={incidents}
          />
        </WidgetContent>
      )}
    </BaseWidget>
  );
}

export default IncidentsBySeverityWidget;
