import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import BaseWidget from "./BaseWidget";
import WidgetContent from "./WidgetContent";
import WidgetHeader from "./WidgetHeader";
import WidgetLoading from "./WidgetLoading";
import IncidentsOvertimeChart from "../charts/IncidentsOvertimeChart";
import { getIncidentsOvertime } from "../../redux/features/dashboards/executive/incidentsOvertimeSlice";
import { useDashboard } from "../../hooks/useDashboard";
import MuiMenu from "./MuiMenu";

const IncidentsOvertimeWidget = () => {
  const [limit, setLimit] = useState("7days");
  const { incidentsOvertime } = useDashboard();
  const { loading } = incidentsOvertime;
  const dispatch = useDispatch();

  const formatDate = () => {
    if (!limit) return null;
    if (limit === "24hours") return 1;
    if (limit === "7days") return 7;
    if (limit === "14days") return 14;
  };

  useEffect(() => {
    dispatch(getIncidentsOvertime({ limit: formatDate() }));
  }, [dispatch, limit]);

  return (
    <BaseWidget>
      <WidgetHeader title="Incidents Overtime">
        <MuiMenu
          days={limit}
          setDays={setLimit}
          options={[
            { key: "24hours", label: "Today" },
            { key: "7days", label: "7 Days" },
            { key: "14days", label: "14 Days" },
          ]}
        />
      </WidgetHeader>
      {loading ? (
        <WidgetLoading />
      ) : (
        <WidgetContent>
          <IncidentsOvertimeChart />
        </WidgetContent>
      )}
    </BaseWidget>
  );
};

export default IncidentsOvertimeWidget;
