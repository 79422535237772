import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

import { capitalize } from "../../../utils/functions";
import NoDataFound from "../NoDataFound";

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ width: 20 }}>Incident ID</TableCell>
        <TableCell>Description</TableCell>
        <TableCell align="center">Type</TableCell>
        <TableCell align="center">Severity</TableCell>
        <TableCell>Creation Time</TableCell>
        <TableCell>Assignee</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function IncidentsTable({ incidents, status = "open" }) {
  const [page, setPage] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [rowsPerPage, setRowsPerPage] = React.useState(
    +searchParams.get("rowsPerPage") || 10
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSearchParams((newSearchParams) => {
      newSearchParams.set("rowsPerPage", +event.target.value);
      return newSearchParams;
    });
  };

  React.useEffect(() => {
    setSearchParams((newSearchParams) => {
      newSearchParams.set("rowsPerPage", rowsPerPage);
      return newSearchParams;
    });
    setPage(0);
  }, []);

  React.useEffect(() => {
    setRowsPerPage(+searchParams.get("rowsPerPage") || 10);
  }, [searchParams]);

  // Avoid values other than 'open' or 'closed'.
  // This will not only default the status to 'open', but also enforce the user to pass only 'open' and 'closed'.
  if (status !== "closed") status = "open";

  const navigateTo = pathname?.split("/")[1];

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - incidents.length) : 0;

  return (
    <>
      {incidents?.length < 1 ? (
        // <NoEndpointFound status={status} />
        <NoDataFound>
          <NoDataFound.Message>
            <Typography variant="h2" textAlign="center">
              Oops!
            </Typography>
            <Typography variant="h5" color="grey" textAlign="center">
              There's no {status} incident available.
            </Typography>
          </NoDataFound.Message>
          <NoDataFound.Link onClick={() => navigate(`/${navigateTo}`)}>
            Go back to the{" "}
            {navigateTo === "endpoints" ? "Endpoints" : "Endpoint Users"} page
          </NoDataFound.Link>
        </NoDataFound>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ height: 380 }}>
              <Table
                sx={{ minWidth: 750, width: "100%" }}
                aria-labelledby="tableTitle"
                size="small"
              >
                <EnhancedTableHead />
                <TableBody>
                  {incidents
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.id)}
                          // role="checkbox"
                          tabIndex={-1}
                          key={`${row._id}-${index}`}
                          //   sx={{ cursor: "pointer" }}
                        >
                          <IncidentTableCell>{row._id}</IncidentTableCell>
                          <IncidentTableCell>
                            {row.description}
                          </IncidentTableCell>
                          <IncidentTableCell>
                            {capitalize(row.type)}
                          </IncidentTableCell>
                          <IncidentTableCell>{row.severity}</IncidentTableCell>
                          <IncidentTableCell>
                            {dayjs(row["@timestamp"]).format(
                              "MMM DD, YYYY HH:mm"
                            )}
                          </IncidentTableCell>
                          <IncidentTableCell>{row?.assignee}</IncidentTableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 33 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={12} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[1, 5, 10, 25, 100]}
              component="div"
              count={incidents?.length}
              rowsPerPage={+rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
    </>
  );
}

const IncidentTableCell = ({ children }) => {
  return (
    <TableCell
      sx={{
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "rgb(43,43,43)" : null,
      }}
    >
      {children}
    </TableCell>
  );
};
