import { privateRequest } from "../../../../utils/apiRequest";

export const fetchMyIncidents = async ({
  userId,
  limit = 5,
  status = "InProgress",
}) => {
  const res = await privateRequest.get(
    `/dashboards/users/${userId}/my-incidents/${status}?limit=${limit}`
  );
  if (res.status === 200) {
    return { incidents: res.data.data, status };
  }
};

export const fetchIncidentsBySeverity = async ({ userId, limit = 5 }) => {
  const res = await privateRequest.get(
    `/dashboards/users/${userId}/incidents/severity?limit=${limit}`
  );

  if (res.status === 200) {
    const obj = {
      riskClasses: [],
      incidents: [],
    };

    res.data.map((incident) => {
      obj.riskClasses?.push(incident.key);
      obj.incidents?.push(incident.docCounts);
    });

    return obj;
  }
};

export const fetchIncidentsByRemainingTime = async ({ userId }) => {
  const endpoint = `/dashboards/users/${userId}/incidents_remaning_time`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return res.data;
  }
};

export const fetchResolvedIncidentsMTTR = async ({ userId, days }) => {
  const endpoint = `/dashboards/users/${userId}/resolved_incidents_mttr`;
  const res = await privateRequest.get(endpoint);

  const obj = {
    riskClasses: [],
    MTTRAverage: [],
    averageResolutionTime: [],
  };
  const data = res.data.severity;
  if (res.status === 200) {
    for (const severity in data) {
      // Push riskClasses - ["High", "Medium", "Low"]
      obj.riskClasses.push(severity);
      // push MTTR Average
      const a_mttr = Math.round(Math.abs(data[severity]?.avg_mttr));
      obj.MTTRAverage.push(a_mttr);
      // push average resolution time
      const a_r_t = Math.round(Math.abs(data[severity]?.avg_resolution_time));
      obj.averageResolutionTime.push(a_r_t);
    }

    return obj;
  }
};

export const fetchOperatorIncidentsByStatus = async ({ userId }) => {
  const endpoint = `/dashboards/users/${userId}/incidents/status`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return res.data;
  }
};

export const fetchTopHostsByIncident = async ({ userId, days }) => {
  const endpoint = `/dashboards/users/${userId}/topHosts?days=${days}`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return res.data;
  }
};
