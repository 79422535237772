import { useState } from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import WidgetContent from "./WidgetContent";
import NoDataFound from "../charts/NoDataFound";
import IncidentsBySeverityChart from "../charts/IncidentsBySeverityChart";
import { contentWidth } from "../../utils/constants";
import { useTheme } from "@mui/material";

const TopHosts = ({ hosts, openDetails, setOpenDetails }) => {
  const [selectedHost, setSelectedHost] = useState();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const handleClick = (host) => {
    setSelectedHost(host);
    setOpenDetails(true);
  };

  return openDetails ? (
    <WidgetContent contentWidth={contentWidth.FULL_WIDTH}>
      <HostDetails host={selectedHost} setOpen={setOpenDetails} />
    </WidgetContent>
  ) : (
    <WidgetContent contentWidth={contentWidth.FULL_WIDTH}>
      {hosts?.length > 0 ? (
        <TableContainer
          sx={{
            borderRadius: 0,
            maxHeight: 200,
            overflow: "hidden",
            "&:hover": {
              overflowY: "auto",
            },
          }}
        >
          <Table
            sx={{
              // minWidth: 650,
              width: "100%",
            }}
            size="small"
            stickyHeader
            aria-label="Top hosts table"
          >
            <TableHead>
              <TableRow sx={{ textTransform: "uppercase" }}>
                <TableCell
                  sx={{
                    fontSize: 10,
                    width: 150,
                    background: isDark ? "#0f1317" : "#edebeb",
                  }}
                >
                  Hostname
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: 10,
                    background: isDark ? "#0f1317" : "#edebeb",
                  }}
                >
                  Total Incidents
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: 10,
                    background: isDark ? "#0f1317" : "#edebeb",
                  }}
                >
                  Mean Risk Score
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hosts?.map((host, index) => (
                <TableRow
                  key={host?.hostname}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      background:
                        index % 2 !== 0
                          ? "transparent"
                          : isDark
                          ? "#0B0E11"
                          : "#fcfcfc",
                    }}
                  >
                    {/* It uses both MUI and react-router-dom Link components as the same time */}
                    <Button
                      size="small"
                      color={"inherit"}
                      onClick={() => handleClick(host)}
                    >
                      {host?.hostname}
                    </Button>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: 100,
                      background:
                        index % 2 !== 0
                          ? "transparent"
                          : isDark
                          ? "#0B0E11"
                          : "#fcfcfc",
                    }}
                    component="th"
                    scope="row"
                  >
                    {host?.docCount}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: 100,
                      background:
                        index % 2 !== 0
                          ? "transparent"
                          : isDark
                          ? "#0B0E11"
                          : "#fcfcfc",
                    }}
                    component="th"
                    scope="row"
                  >
                    {host?.mean_risk_score}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoDataFound message="There is no host to show" />
      )}
    </WidgetContent>
  );
};

const HostDetails = ({ host }) => {
  const incidentsByRisk = [
    { riskClasses: "High", incidents: host.severity["High"].docCount },
    { riskClasses: "Medium", incidents: host.severity["Medium"].docCount },
    { riskClasses: "Low", incidents: host.severity["Low"].docCount },
    { riskClasses: "Unscored", incidents: host.severity["Unscored"]?.docCount },
  ];
  const incidents = incidentsByRisk.map((incident) => incident.incidents);
  const riskClasses = incidentsByRisk.map((incident) => incident.riskClasses);

  return (
    <Box sx={{ height: "100%" }}>
      <IncidentsBySeverityChart
        riskClasses={riskClasses}
        incidents={incidents}
      />
    </Box>
  );
};

export default TopHosts;
