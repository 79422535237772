import { Box, Typography } from "@mui/material";

const ErrorState = ({ message }) => {
  return (
    <Box
      sx={{
        minHeight: 300,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography sx={{ color: "gray" }}>{message}</Typography>
    </Box>
  );
};

export default ErrorState;
