import React from "react";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material";

import EndpointGroup from "./groups";

const EndpointSidebar = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  return (
    <Paper
      sx={{
        minHeight: "100%",
        borderRadius: 0,
        p: 1,
        background: isDark ? "#181C24" : "#efefef",
        border: `1px solid ${isDark ? "#2D3342" : "#eee"}`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <EndpointGroup />
    </Paper>
  );
};

export default EndpointSidebar;
