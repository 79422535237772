import React, { useState, useRef, useEffect } from "react";
import { Routes, Route, useLocation, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";

import ReportTemplateSelector from "../../components/reports/ReportTemplateSelector";
import OperatorsPerformanceSummaryReport from "./OperatorsPerformanceSummaryReport";
import EndpointSummaryReport from "./EndpointSummaryReport";
import IncidentSummaryReport from "./IncidentSummaryReport";
import Header from "../../components/Header";
import MyOverview from "./MyOverview";
import ProtectedRoute from "../../redux/features/auth/ProtectedRoute";
import useSaveToAudit from "../../hooks/useSaveToAudit";
import { ROLES } from "../../utils/constants";

const Reports = () => {
  const [template, setTemplate] = useState("incident-summary-report");
  const [startDate, setStartDate] = useState(dayjs().subtract(7, "day"));
  const [endDate, setEndDate] = useState(dayjs());
  const [searchParams, setSearchParams] = useSearchParams();

  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const reportRef = useRef();
  const location = useLocation();

  const body = {
    activity: "Download Report",
    pageLocation: location.pathname + location.search,
    startDate: startDate.format("MMM DD, YYYY"),
    endDate: endDate.format("MMM DD, YYYY"),
    template,
    details: ``,
  };
  const { saveToAudit } = useSaveToAudit({ body });

  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
  });
  const handleClick = () => {
    handlePrint();

    // Handle the saveToAudit
    saveToAudit();
  };

  /**
   * This useEffect is responsible for rendering when a user clicks on the 'reports' menu on the sidebar.
   * (This will only work if the user is inside either the 'operator-performance-summary-report' or the 'endpoint-summary-report).
   */
  useEffect(() => {
    // Don't execute if the user is redirected to the reports page for the first time.
    if (!searchParams.get("startDate") || !searchParams.get("endDate")) return;

    const templateFromPathname = location.pathname.substring(9); // remove '/reports/' from pathname so we can use it as a template

    setTemplate(templateFromPathname);
    setSearchParams((searchParams) => {
      searchParams.set("startDate", startDate.format("YYYY-MM-DD"));
      searchParams.set("endDate", endDate.format("YYYY-MM-DD"));
      return searchParams;
    });
    console.log(Object.entries(searchParams));
  }, [location.pathname]);

  useEffect(() => {
    // If startDate and endDate are not provided, set default date range
    if (!searchParams.get("startDate") || !searchParams.get("endDate")) {
      setSearchParams((searchParams) => {
        searchParams.set(
          "startDate",
          dayjs().subtract(7, "day").format("YYYY-MM-DD")
        );
        searchParams.set("endDate", dayjs().format("YYYY-MM-DD"));
        return searchParams;
      });
    }
  }, []);

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Reports" subtitle="Chart Reports with Date Range" />
      <ReportTemplateSelector
        template={template}
        setTemplate={setTemplate}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        handlePrint={handleClick}
      />
      <Box
        ref={reportRef}
        sx={{
          background: theme?.palette?.background?.paper,
          border: `2px dashed ${theme.palette.grey[isDark ? 900 : 300]}`,
          "@media print": {
            border: "none",
            background: "transparent !important",
          },
          minHeight: "100vh",
          width: "100%",
          maxWidth: "60rem !important",
          padding: 5,
          paddingInline: 2,
          boxSizing: "border-box",
          // display: "flex",
          gap: 3,
        }}
      >
        <Routes>
          <Route
            path="incident-summary-report"
            element={
              <IncidentSummaryReport startDate={startDate} endDate={endDate} />
            }
          />
          <Route
            path="operators-performance-summary-report"
            element={
              <OperatorsPerformanceSummaryReport
                startDate={startDate}
                endDate={endDate}
              />
            }
          />
          <Route
            path="endpoint-summary-report"
            element={
              <EndpointSummaryReport startDate={startDate} endDate={endDate} />
            }
          />
          <Route element={<ProtectedRoute roles={[ROLES.Operator]} />}>
            <Route
              path="my-overview"
              element={<MyOverview startDate={startDate} endDate={endDate} />}
            />
          </Route>
        </Routes>
      </Box>
    </Box>
  );
};

export default Reports;
