import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchIncidentsOverdue } from "./index";

export const getIncidentsOverdue = createAsyncThunk(
  "incidents/overdue",
  fetchIncidentsOverdue
);

const initialState = {
  incidents: [],
  loading: false,
  error: null,
};

const incidentsOverdueSlice = createSlice({
  name: "incidents-overdue",
  initialState,
  reducers: {
    clearIncidentsOverdueState: (state) => {
      state.incidents = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIncidentsOverdue.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getIncidentsOverdue.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.incidents = action.payload;
    });
    builder.addCase(getIncidentsOverdue.rejected, (state, action) => {
      state.loading = false;
      state.incidents = [];
      state.error = action.error.message;
    });
  },
});

export const { clearIncidentsOverdueState } = incidentsOverdueSlice.actions;
export default incidentsOverdueSlice.reducer;
