import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import IncidentsByStatusChart from "../charts/IncidentsByStatusChart";
import WidgetHeader from "./WidgetHeader";
import BaseWidget from "./BaseWidget";
import WidgetContent from "./WidgetContent";
import WidgetLoading from "./WidgetLoading";
import { useDashboard } from "../../hooks/useDashboard";
import { getIncidentsByStatus } from "../../redux/features/dashboards/executive/incidentsByStatusSlice";

function IncidentsByStatus() {
  const { incidentsByStatus } = useDashboard();
  const { loading } = incidentsByStatus;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIncidentsByStatus());
  }, [dispatch]);

  return (
    <BaseWidget>
      <WidgetHeader title="Incidents By Status" />
      {loading ? (
        <WidgetLoading />
      ) : (
        <WidgetContent>
          <IncidentsByStatusChart />
        </WidgetContent>
      )}
    </BaseWidget>
  );
}

export default IncidentsByStatus;
