import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";

import EndpointList from "../../components/endpoints/EndpointList";
import { getEndpoints } from "../../redux/features/endpoints/endpointSlice";
import { setCurrentGroup } from "../../redux/features/endpoints/endpointGroupSlice";
import TabButtons from "../../components/ui/TabButtons";
import { assetsMenu } from "../../utils/constants";

const Endpoints = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { currentGroup } = useSelector((state) => state.endpoints.group);

  useEffect(() => {
    if (pathname === "/endpoints") dispatch(getEndpoints({ groupId: 0 })); //Fetch all endpoints (base endpoint page)
  }, [pathname]);

  useEffect(() => {
    if (pathname === "/endpoints") {
      dispatch(getEndpoints({ groupId: 0 })); //Fetch all endpoints (base endpoint page)
      dispatch(setCurrentGroup(0));
    } else {
      dispatch(getEndpoints({ groupId: currentGroup }));
    }
  }, [currentGroup]);

  return (
    <>
      <TabButtons items={assetsMenu} />
      <Grid
        container
        columns={16}
        sx={{
          height: "calc(100% - 60px) !important", // 60px is the approximate height of the navbar
        }}
      >
        <Grid item xs={16}>
          <EndpointList />
        </Grid>
      </Grid>
    </>
  );
};

export default Endpoints;
