import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { capitalize, removeExtension } from "../../utils/functions";
import CopyToClipboard from "../ui/CopyToClipboard";
import ActionIcon from "../actions/action-icon";
import { actions } from "../../utils/constants";
import Modal from "../ui/Modal";
import RenameFile from "../actions/RenameFile";
import { sendPredefinedAction } from "../../redux/features/actions/actionSlice";
import CreateRegistry from "../actions/registry/CreateRegistry";
import EditRegistry from "./../actions/registry/EditRegistry";
import SetRegistry from "./../actions/registry/SetRegistry";
import RegistryForm from "../actions/registry/RegistryForm";

function Row(props) {
  const { row, type, hostname, isOpen = false } = props;
  const [open, setOpen] = React.useState(isOpen);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {/* Name of the artifact */}
        <TableCell component="th" scope="row">
          {capitalize(type)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <InnerHeader type={type} />
                <TableBody>
                  {row?.map((rowItem, i) => (
                    <InnerRow
                      key={`${i}-${rowItem?.name}`}
                      rowItem={rowItem}
                      type={type}
                      hostname={hostname}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default Row;

const InnerHeader = ({ type }) => {
  return (
    <TableHead>
      <TableRow>
        {type !== "network" ? <TableCell align="left">Name</TableCell> : null}
        {type !== "network" ? <TableCell align="left">Path</TableCell> : null}

        {type === ("process" || "child process") ? (
          <TableCell align="center">Verdict</TableCell>
        ) : null}
        {type === ("process" || "child process" || "file") ? (
          <TableCell align="left">Hash</TableCell>
        ) : null}

        {type === "child process" ? (
          <TableCell align="left">Script Content</TableCell>
        ) : null}
        {type === "registry" ? (
          <TableCell align="center">value</TableCell>
        ) : null}
        {type === "network" ? (
          <>
            <TableCell align="center">Remote IP</TableCell>
            <TableCell align="center">Remote Port</TableCell>
            <TableCell align="center">URL</TableCell>
            <TableCell align="center">Local IP</TableCell>
            <TableCell align="center">Local Port</TableCell>
            <TableCell align="center">Protocol</TableCell>
          </>
        ) : null}
      </TableRow>
    </TableHead>
  );
};

const InnerRow = ({ rowItem, type, hostname }) => {
  // Rename states
  const [modalOpen, setModalOpen] = React.useState(false);
  const [originalFile, setOriginalFile] = React.useState();
  const [renameTo, setRenameTo] = React.useState("");

  // Registry states
  const [registryModalOpen, setRegistryModalOpen] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("Registry");
  const [registryName, setRegistryName] = React.useState();
  const [propertyName, setPropertyName] = React.useState();
  const [value, setValue] = React.useState();
  const [selectedAction, setSelectedAction] = React.useState();

  const { incidentId } = useParams();
  const dispatch = useDispatch();
  const showImagePathAction = true;
  const showProcessNameAction = type !== "file" && !!rowItem?.name;

  const handleSendAction = (menu) => {
    const body = {
      incidentId,
      targetHost: hostname,
      action: menu?.action,
      name: menu?.actionType,
      others: {},
    };

    setSelectedAction(menu?.action);

    // Process actions - kill Process
    if (menu?.action === actions.KILL_PROCESS) {
      body.others = {
        processName: removeExtension(rowItem?.name),
      };
    }

    // Delete File
    if (menu?.action === actions.DELETE_FILE) {
      body.others = {
        path: rowItem?.imagePath || rowItem?.path,
        type: "trash", // trash || erase
      };
    }

    //  Rename File
    if (menu?.action === actions.RENAME_FILE) {
      setOriginalFile(rowItem?.imagePath || rowItem?.path);
      // Open rename modal
      setModalOpen(true);
    }

    // Allow and Block IP
    if (
      menu?.action === actions.ALLOW_IP ||
      menu?.action === actions.BLOCK_IP
    ) {
      body.others = {
        ipv4: rowItem?.remoteIp,
      };
    }

    // CLEAR IP RULES
    if (menu?.action === actions.CLEAR_IP_RULES) {
      body.others = {
        ip: rowItem?.remoteIp,
      };
    }

    // Allow and Block URL
    if (
      menu?.action === actions.ALLOW_DOMAIN ||
      menu?.action === actions.BLOCK_DOMAIN ||
      menu?.action === actions.CLEAR_DOMAIN_RULES
    ) {
      body.others = {
        domain: rowItem?.url,
      };
    }

    // Delete Registry
    if (menu?.action === actions.DELETE_REGISTRY) {
      body.others = {
        path: rowItem?.path,
      };
    }

    // Open modal for registry actions
    if (menu?.action === actions.CREATE_REGISTRY) {
      setRegistryModalOpen(true);
      setModalTitle("Create Registry");
    }
    if (menu?.action === actions.EDIT_REGISTRY) {
      setRegistryModalOpen(true);
      setModalTitle("Edit Registry");
      setRegistryName(rowItem?.name);
    }
    if (menu?.action === actions.SET_REGISTRY) {
      setRegistryModalOpen(true);
      setModalTitle("Set Registry");
      setPropertyName(rowItem?.name);
    }

    // Send action for all actions except for rename
    if (
      menu?.action !== actions.RENAME_FILE &&
      menu?.action !== actions.CREATE_REGISTRY &&
      menu?.action !== actions.EDIT_REGISTRY &&
      menu?.action !== actions.SET_REGISTRY
    ) {
      dispatch(sendPredefinedAction({ body }));
    }
  };

  const handleSendRenameAction = () => {
    const renameBody = {
      incidentId: incidentId,
      targetHost: hostname,
      action: actions.RENAME_FILE,
      name: "renameFile",
      others: {
        from: rowItem?.imagePath || rowItem?.path,
        to: renameTo,
      },
    };

    dispatch(sendPredefinedAction({ body: renameBody }));
  };

  const handleCreateRegistryAction = () => {
    const body = {
      incidentId,
      targetHost: hostname,
      action: actions.CREATE_REGISTRY,
      name: "createRegistry",
      others: {
        path: rowItem?.path,
        name: registryName,
        value: value,
      },
    };

    dispatch(sendPredefinedAction({ body }));
  };

  const handleEditRegistryAction = () => {
    const body = {
      incidentId,
      targetHost: hostname,
      action: actions.EDIT_REGISTRY,
      name: "editRegistry",
      others: {
        path: rowItem?.path,
        name: registryName,
        value: value,
      },
    };

    dispatch(sendPredefinedAction({ body }));
  };

  const handleSetRegistryAction = () => {
    const body = {
      incidentId,
      targetHost: hostname,
      action: actions.SET_REGISTRY,
      name: "setRegistry",
      others: {
        path: rowItem?.path,
        name: propertyName,
        value: value,
      },
    };
    dispatch(sendPredefinedAction({ body }));
  };

  const processName = (
    <TableCell scope="row" sx={{}}>
      <Box sx={{ minHeight: "18px", display: "flex", alignItems: "center" }}>
        <Typography>{rowItem?.name}</Typography>
        {showProcessNameAction ? (
          <ActionIcon
            targetItem={rowItem?.name}
            handleSendAction={handleSendAction}
          />
        ) : null}
      </Box>
    </TableCell>
  );

  const imagePath = (
    <TableCell
      sx={{
        maxWidth: "300px",
        overflowX: "hidden",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ maxWidth: "260px" }}>
          <CopyToClipboard
            title={rowItem?.imagePath || rowItem?.path}
            item={rowItem?.imagePath || rowItem?.path}
          >
            <Typography noWrap sx={{ fontSize: ".75rem" }}>
              {rowItem?.imagePath || rowItem?.path}
            </Typography>
          </CopyToClipboard>
        </Box>
        {showImagePathAction ? (
          <ActionIcon
            targetItem={rowItem?.imagePath || rowItem?.path}
            handleSendAction={handleSendAction}
          />
        ) : null}
      </Box>
    </TableCell>
  );

  return (
    <>
      <TableRow>
        {type !== "network" ? processName : null}
        {type !== "network" ? imagePath : null}
        {type === ("process" || "child process") ? (
          <TableCell
            sx={{
              maxWidth: "100px",
              overflowX: "hidden",
              fontSize: ".75rem",
            }}
            align="center"
          >
            <Typography noWrap>{rowItem?.verdict}</Typography>
          </TableCell>
        ) : null}
        {type === ("process" || "child process" || "file") ? (
          <TableCell sx={{ maxWidth: "100px" }}>
            <CopyToClipboard
              title={rowItem?.imageHash || rowItem?.hash}
              item={rowItem?.imageHash || rowItem?.hash}
            >
              <Typography noWrap sx={{ fontSize: ".75rem" }}>
                {rowItem?.imageHash || rowItem?.hash}
              </Typography>
            </CopyToClipboard>
          </TableCell>
        ) : null}
        {/* {type === ("process" || "child process" || "file") ? ( */}
        {type === ("child process" || "file") ? (
          <TableCell sx={{ maxWidth: "100px", overflowX: "hidden" }}>
            {rowItem?.scriptContent}
          </TableCell>
        ) : null}
        {type === "registry" ? (
          <TableCell sx={{ maxWidth: "100px", overflowX: "hidden" }}>
            {rowItem?.data}
          </TableCell>
        ) : null}

        {type === "network" ? (
          <>
            <TableCell
              align="center"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {rowItem?.remoteIp}
              <ActionIcon
                targetItem={rowItem?.remoteIp}
                handleSendAction={handleSendAction}
              />
            </TableCell>
            <TableCell align="center">{rowItem?.remotePort}</TableCell>
            <TableCell
              align="center"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {rowItem?.url}
              <ActionIcon
                targetItem={rowItem?.url}
                handleSendAction={handleSendAction}
              />
            </TableCell>
            <TableCell align="center">{rowItem?.localIp}</TableCell>
            <TableCell align="center">{rowItem?.localPort}</TableCell>
            <TableCell align="center">{rowItem?.protocol}</TableCell>
          </>
        ) : null}
      </TableRow>
      <Modal
        title="Rename File"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <RenameFile
          originalFile={originalFile}
          renameTo={renameTo}
          setRenameTo={setRenameTo}
          onSubmit={handleSendRenameAction}
          setModalOpen={setModalOpen}
        />
      </Modal>
      <Modal
        title={modalTitle}
        modalOpen={registryModalOpen}
        setModalOpen={setRegistryModalOpen}
      >
        {selectedAction === actions.CREATE_REGISTRY ? (
          <CreateRegistry>
            <RegistryForm
              name={registryName}
              setName={setRegistryName}
              value={value}
              setValue={setValue}
              path={rowItem?.path}
              onSubmit={handleCreateRegistryAction}
              onClose={() => setRegistryModalOpen(false)}
              mode="create"
            />
          </CreateRegistry>
        ) : null}
        {selectedAction === actions.EDIT_REGISTRY ? (
          <EditRegistry>
            <RegistryForm
              name={registryName}
              setName={setRegistryName}
              value={value}
              setValue={setValue}
              path={rowItem?.path}
              onSubmit={handleEditRegistryAction}
              onClose={() => setRegistryModalOpen(false)}
              mode="edit"
            />
          </EditRegistry>
        ) : null}
        {selectedAction === actions.SET_REGISTRY ? (
          <SetRegistry>
            <RegistryForm
              name={propertyName}
              setName={setPropertyName}
              value={value}
              setValue={setValue}
              path={rowItem?.path}
              onSubmit={handleSetRegistryAction}
              onClose={() => setRegistryModalOpen(false)}
              mode="set"
            />
          </SetRegistry>
        ) : null}
      </Modal>
    </>
  );
};