import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useSearchParams } from "react-router-dom";
import { Chip } from "@mui/material";
import dayjs from "dayjs";

import Loading from "./Loading";
import { capitalize } from "../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { getAuditActivities } from "../../redux/features/audit/auditSlice";

function EnhancedTableToolbar(props) {
  const { denseOption } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        List of Activities
      </Typography>

      <Box sx={{ display: "flex", gap: 2 }}>
        {denseOption}

        {/* <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip> */}
      </Box>
    </Toolbar>
  );
}

export default function ActivityList() {
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const DEFAULT_PAGE_SIZE = 20;

  const [rowsPerPage, setRowsPerPage] = useState(
    +searchParams.get("rowsPerPage") || DEFAULT_PAGE_SIZE
  );

  const dispatch = useDispatch();
  const { activities, total, loading } = useSelector((state) => state.audit);
  const params = {
    page: page + 1,
    limit: rowsPerPage,
  };
  useEffect(() => {
    dispatch(getAuditActivities({ params }));
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSearchParams((newSearchParams) => {
      newSearchParams.set(
        "rowsPerPage",
        parseInt(event.target.value, 10) || DEFAULT_PAGE_SIZE
      );
      return newSearchParams;
    });

    setPage(0);
  };

  useEffect(() => {
    setSearchParams((newSearchParams) => {
      newSearchParams.set("rowsPerPage", rowsPerPage);
      return newSearchParams;
    });
  }, []);

  useEffect(() => {
    setRowsPerPage(+searchParams.get("rowsPerPage") || DEFAULT_PAGE_SIZE);
  }, [searchParams]);

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - activities.length) : 0;

  if (loading) return <Loading />;
  if (activities.length < 1) return <NoActivityFound />;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          denseOption={
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense"
            />
          }
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750, width: "100%" }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <TableHeader />
            <TableBody
              sx={{
                "& > :last-child": { display: page !== 0 ? "none" : null }, // To remove the last unnecessary <tr> element of the table
              }}
            >
              {activities
                ? activities.map((row) => <Row key={row.id} row={row} />)
                : null}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 25, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={+rowsPerPage || DEFAULT_PAGE_SIZE}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

const TableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>User ID</TableCell>
        <TableCell>User Name</TableCell>
        <TableCell>Role</TableCell>
        <TableCell>Activity</TableCell>
        <TableCell>Location</TableCell>
        <TableCell>Target User</TableCell>
        <TableCell>Creation Time</TableCell>
        <TableCell>Details</TableCell>
      </TableRow>
    </TableHead>
  );
};
const Row = ({ row }) => {
  return (
    <TableRow
      sx={{
        "& td": {
          color: (theme) =>
            theme.palette.mode === "dark"
              ? theme.palette.grey["200"]
              : theme.palette.grey["700"],
        },
      }}
    >
      <TableCell>{row.user_id}</TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>
        <Chip
          label={row.role}
          color={row.role === "Manager" ? "primary" : "default"}
          size="small"
        />
      </TableCell>
      <TableCell>{row.activity}</TableCell>
      <TableCell>{row.pageLocation}</TableCell>
      <TableCell>{row?.details && capitalize(row?.details)}</TableCell>
      <TableCell>{dayjs(row.createdAt).format("MMM DD, YYYY HH:mm")}</TableCell>
      <TableCell>{row?.details}</TableCell>
    </TableRow>
  );
};

const NoActivityFound = () => {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          // gap: 3,
          minHeight: "calc(100vh - 12rem)",
          mb: 2,
          p: 2,
        }}
      >
        <Typography variant="h4" textAlign="center">
          Oops! There's no activity yet.
        </Typography>
      </Paper>
    </Box>
  );
};