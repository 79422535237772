import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Box from "@mui/material/Box";

import NoDataFound from "../NoDataFound";
import ChartTemplate from "../ChartTemplate";

const IncidentsByStatusChart = ({ incidents, days }) => {
  const [labels, setLabels] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const data = incidents?.incidents?.filter(
      (incident) =>
        incident.key === "InProgress" || incident.key === "Completed"
    );

    setLabels(data?.map((item) => item?.key));
    setSeries(data?.map((item) => item?.doc_count));
  }, [incidents]);

  const options = {
    series: series,
    labels: labels,
    chart: {
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      pie: {
        offsetY: 20,
        customScale: 0.85,
        donut: {
          // size: "50%",
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              fontSize: 16,
            },
          },
        },
      },
    },
    legend: {
      position: "bottom",
      offsetY: -5,
    },
  };

  return (
    <ChartTemplate
      options={options}
      series={options.series}
      type="donut"
      style={{ paddingTop: "0px" }}
      notFoundMessage={`No Incidents in the last ${days}.`}
      // width="90%"
      // height="118%"
    />
  );
};

export default IncidentsByStatusChart;
