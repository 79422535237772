import { Typography, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ChartTemplate from "./../../charts/ChartTemplate";

const IncidentsOvertimeBySeverity = ({ incidentsOvertime }) => {
  const high = [];
  const medium = [];
  const low = [];
  const weeks = [];

  incidentsOvertime?.forEach((element, index) => {
    const tempElement = element[`week-${index + 1}`];
    const week = tempElement?.date?.split(" ");

    const severity = tempElement?.severity;
    high.push(severity["high"]);
    medium.push(severity["medium"]);
    low.push(severity["low"]);

    weeks.push(`${week[1]} ${week[2]}`);
  });

  return (
    <Card variant="outlined" sx={{ height: "100%" }}>
      <CardContent>
        <Typography variant="body2" color="grey">
          Incidents Overtime (by Severity)
        </Typography>
        <BySeverityChart incidents={{ high, medium, low }} weeks={weeks} />
      </CardContent>
    </Card>
  );
};

const BySeverityChart = ({ incidents, weeks }) => {
  const { high, medium, low } = incidents;

  const theme = useTheme();
  const mode = theme.palette.mode;

  const chartData = {
    series: [
      {
        name: "High",
        data: high,
      },
      {
        name: "Medium",
        data: medium,
      },
      {
        name: "Low",
        data: low,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [5, 7, 5],
        curve: "straight",
        dashArray: [0, 8, 5],
      },
      title: {
        // text: "Page Statistics",
        align: "left",
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - " +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            ""
          );
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: weeks,
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + ": ";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + ": ";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },
      tooltip: {
        enabled: true,
        theme: mode === "dark" ? "dark" : "light",
        marker: {
          show: true,
        },
      },
      colors: ["#FF0A0E", "#EDB007", "#18A07E"],
      grid: {
        borderColor: mode === "dark" ? "#444" : "#dedede",
        strokeDashArray: 4,
        // borderColor: "#f1f1f1",
      },
    },
  };

  return (
    <div id="chart">
      <ChartTemplate
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={200}
      />
    </div>
  );
};

export default IncidentsOvertimeBySeverity;
