import Box from "@mui/material/Box";

import { capitalize, sortObjectByKey } from "../../../../utils/functions";
import WidgetTitle from "../../WidgetTitle";
import ReportLineChart from "../ReportLineChart";

function convertJsonData(json) {
  const convertedData = [];

  for (const weekNumber in json) {
    const weekData = json[weekNumber];

    for (const operator of weekData) {
      const operatorName = operator.name;
      const operatorScore = +(+operator.average_score).toFixed(2);

      const operatorData = {
        name: capitalize(operatorName),
        data: [operatorScore],
      };

      if (convertedData.some((op) => op.operatorName === operatorName)) {
        const existingOperatorData = convertedData.find(
          (op) => op.operatorName === operatorName
        );
        existingOperatorData.data.push(operatorScore);
      } else {
        convertedData.push(operatorData);
      }
    }
  }

  return convertedData;
}

const HistoricalPerformanceGraph = ({ data }) => {
  const res = convertJsonData(sortObjectByKey(data));

  return (
    <Box sx={{ marginBlock: 4 }}>
      <WidgetTitle title="Historical Performance Graph" />
      <ReportLineChart data={res} />
    </Box>
  );
};

export default HistoricalPerformanceGraph;
