import React from "react";

import { ROLES, STATUS } from "../../../utils/constants";
import { compareString } from "../../../utils/functions";

const ReAssignIncident = ({ ticketStatus, role, children }) => {
  const canReAssign =
    !compareString(ticketStatus, STATUS.Completed) &&
    !compareString(ticketStatus, STATUS.Backlog) &&
    compareString(role, ROLES.Manager);

  return canReAssign ? <div>{children}</div> : null;
};

export default ReAssignIncident;
