import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { compareString } from "../../utils/functions";
import { ROLES } from "../../utils/constants";

const ReportTemplateSelector = ({
  template,
  setTemplate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handlePrint,
}) => {
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.auth.user);

  const onChange = (e) => {
    setTemplate(e.target.value);
    navigate(`/reports/${e.target.value}`);
  };

  const handleClick = () => {
    handlePrint();
  };

  useEffect(() => {
    navigate(template);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { md: "center" },
          // justifyContent: "center",
          gap: 3,
        }}
      >
        <FormControl sx={{ m: 1, mt: 2.3, minWidth: 120 }}>
          <InputLabel id="report-template">Report Template</InputLabel>
          <Select
            labelId="report-template"
            id="report-template"
            value={template}
            label="Template"
            // sx={{ minWidth: 320 }}
            onChange={onChange}
          >
            <MenuItem value="incident-summary-report">
              Incident Summary Report
            </MenuItem>
            <MenuItem value="operators-performance-summary-report">
              Operators Performance Summary Report
            </MenuItem>
            <MenuItem value="endpoint-summary-report">
              Endpoint Summary Report
            </MenuItem>
            {compareString(role, ROLES.Operator) ? (
              <MenuItem value="my-overview">My Overview</MenuItem>
            ) : null}
          </Select>
        </FormControl>
        <ReportDatePicker
          value={startDate}
          setValue={setStartDate}
          label="Start Date"
          // defaultValue={dayjs("2022-04-17")}
          isStartDate={true}
        />
        <ReportDatePicker
          value={endDate}
          setValue={setEndDate}
          label="End Date"
          // defaultValue={dayjs("2022-04-17")}
          isStartDate={false}
        />
        <Button
          onClick={handleClick}
          color="primary"
          variant="contained"
          disabled={!startDate || !endDate}
          sx={{ paddingBlock: 2, paddingInline: 4 }}
        >
          Download
        </Button>
      </Box>
    </>
  );
};

function ReportDatePicker({
  value,
  setValue,
  defaultValue,
  label = "Report Range Picker",
  isStartDate,
}) {
  const [, setSearchParams] = useSearchParams();

  function handleChange(newValue) {
    const date = dayjs(newValue).format("YYYY-MM-DD");

    setSearchParams((newSearchParams) => {
      newSearchParams.set(isStartDate ? "startDate" : "endDate", date);
      return newSearchParams;
    });
    setValue(newValue);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          // size="small"
          disableFuture
          label={label}
          value={value}
          onChange={(newValue) => handleChange(newValue)}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
export default ReportTemplateSelector;
