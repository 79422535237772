import { useSelector } from "react-redux";

const useIncident = () => {
  const getAllIncidents = useSelector((state) => state.incidents);
  const getIncidentById = useSelector((state) => state.incident);

  return {
    getAllIncidents,
    getIncidentById,
  };
};

export default useIncident;
