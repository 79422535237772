import React from "react";

import ChartTemplate from "./ChartTemplate";

const ResolvedIncidentsMTTRChart = ({
  riskClasses,
  MTTRAverage,
  averageResolutionTime,
}) => {
  const series = [
    { name: "MTTR Average", data: MTTRAverage },
    { name: "Average Resolution Time", data: averageResolutionTime },
  ];

  const chartOptions = {
    chart: {
      id: "resolved-incidents-MTTR",
    },
    xaxis: {
      categories: riskClasses,
    },
    yaxis: {
      labels: {
        formatter: (val) => {
          /** Change the milliseconds to hours */
          return Math.round(val / (1000 * 60 * 60)) + " hr";
        },
      },
    },
  };

  return <ChartTemplate options={chartOptions} series={series} />;
};

export default ResolvedIncidentsMTTRChart;
