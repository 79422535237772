import { Box, Snackbar } from "@mui/material";
import { useState } from "react";

const CopyToClipboard = ({ title = "Click to Copy", item, children }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(item);
  };

  return (
    <>
      <Box
        title={title}
        sx={{
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        {children}
      </Box>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied to clipboard"
      />
    </>
  );
};

export default CopyToClipboard;
