import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchMyIncidents } from "./index";
import { STATUS } from "../../../../utils/constants";
import { compareString } from "../../../../utils/functions";

export const getMyIncidents = createAsyncThunk(
  "incidents/my-incidents",
  fetchMyIncidents
);

const initialState = {
  inProgressIncidents: [],
  completedIncidents: [],
  loading: false,
  error: null,
};

const myIncidentsSlice = createSlice({
  name: "my-incidents",
  initialState,
  reducers: {
    clearMyIncidentsState: (state) => {
      state.inProgressIncidents = [];
      state.completedIncidents = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyIncidents.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getMyIncidents.fulfilled, (state, action) => {
      const { incidents, status } = action.payload;

      state.loading = false;
      state.error = "";
      if (compareString(status, STATUS.InProgress)) {
        state.inProgressIncidents = incidents;
      }
      if (compareString(status, STATUS.Completed)) {
        state.completedIncidents = incidents;
      }
    });
    builder.addCase(getMyIncidents.rejected, (state, action) => {
      state.loading = false;
      state.inProgressIncidents = [];
      state.completedIncidents = [];
      state.error = action.error.message;
    });
  },
});

export const { clearMyIncidentsState } = myIncidentsSlice.actions;
export default myIncidentsSlice.reducer;
