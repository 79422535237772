import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";

import ThemeCustomizer from "../../components/customizer/ThemeCustomizer";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import SidebarOption3 from "./SidebarOption3";

const Layout = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [themeCustomizerOpen, setThemeCustomizerOpen] = useState(false);
  const mode = theme.palette.mode;

  // Close the sidebar for smaller breakpoints by default
  useEffect(() => {
    if (isTablet) {
      setIsSidebarOpen(false);
    } else {
      setIsSidebarOpen(true);
    }
  }, [isTablet]);

  return (
    <Box
      sx={{
        background: mode === "light" ? theme.palette.neutral[900] : null,
        flex: 1,
        height: "100%",
      }}
      display={isNonMobile ? "flex" : "block"}
      width="100%"
      height="100%"
    >
      {/* <Sidebar
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      /> */}

      <SidebarOption3
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />

      <Box
        flexGrow={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Navbar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          themeCustomizerOpen={themeCustomizerOpen}
          setThemeCustomizerOpen={setThemeCustomizerOpen}
        />

        <Box
          sx={{
            flex: 1,
            height: "100% !important",
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <ThemeCustomizer
        themeCustomizerOpen={themeCustomizerOpen}
        setThemeCustomizerOpen={setThemeCustomizerOpen}
      />
    </Box>
  );
};

export default Layout;
