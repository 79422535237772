import { privateRequest } from "../../../utils/apiRequest";
import { showToast } from "../toast";

export const fetchForensicBoards = async () => {
  const endpoint = `/forensic/boards`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return res.data;
  }
};

export const fetchBoardInformation = async ({ boardId }) => {
  const endpoint = `/forensic/board/${boardId}`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return res.data;
  }
};

export const updateBoardStatus = async ({ body }) => {
  const endpoint = `/forensic/update-board-status`;
  const res = await privateRequest.patch(endpoint, body);

  if (res.status === 200) {
    return "completed";
  }
};

export const forensicQueryRequest = async ({ body }) => {
  const endpoint = `/forensic/query`;
  const res = await privateRequest.post(endpoint, body);

  if (res.status === 200) {
    return res.data;
  }
};

export const saveResponseToBoard = async ({ body }) => {
  const endpoint = `/forensic/update-board`;
  const res = await privateRequest.put(endpoint, body);

  if (res.status === 200) {
    showToast({
      type: "success",
      message: "Result is saved successfully to the board!",
    });
  }
};

export const fetchBoardViewResponse = async ({ boardId }) => {
  const endpoint = `/forensic/board-view/${boardId}`;
  const res = await privateRequest.get(endpoint);

  if (res.status === 200) {
    return res.data.results;
  }
};

export const editBoardItemComment = async ({ body }) => {
  const endpoint = `/forensic/update-remark`;
  const res = await privateRequest.put(endpoint, body);

  if (res.status === 200) {
    showToast({
      type: "success",
      message: "Comment updated successfully!",
    });

    return { remark: body.remark, boardItemId: body.boardItemId };
  }
};

export const removeBoardItem = async ({ body }) => {
  const endpoint = `/forensic/delete-query`;
  const res = await privateRequest.delete(endpoint, { data: body });

  if (res.status === 200) {
    showToast({
      message: "Board view item deleted successfully!",
    });
    return body.boardItemId;
  }
};