import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

import CreateTicketForm from "../../components/tickets/CreateTicketForm";
import { capitalize, compareString } from "../../utils/functions";
import Modal from "../../components/ui/Modal";
import useAuth from "../../hooks/useAuth";
import useIncident from "../../hooks/useIncident";
import {
  getIncidents,
  starSingleIncident,
} from "../../redux/features/incidents/incidentsSlice";
import { ROLES, STATUS } from "../../utils/constants";
import Loading from "../../components/incidents/Loading";
import SeverityBadge from "./../../components/ui/SeverityBadge";

const IncidentsPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [incidentId, setIncidentId] = useState("");
  const [selectedIncident, setSelectedIncident] = useState({});
  const { user } = useAuth();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getAllIncidents } = useIncident();
  const { incidents, total, loading } = getAllIncidents;

  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationModel, setPaginationModel] = useState({
    page: parseInt(searchParams.get("page")) - 1 || 0,
    pageSize: parseInt(searchParams.get("limit")) || 100,
  });

  useEffect(() => {
    // Don't execute if the user is redirected to incidents page for the first time.
    if (!searchParams.get("limit")) return;

    const urlParams = Object.fromEntries(searchParams.entries());

    setSearchParams((searchParams) => {
      searchParams.set("limit", paginationModel.pageSize);
      searchParams.set("page", paginationModel.page + 1);
      return searchParams;
    });

    dispatch(getIncidents(urlParams));
  }, [paginationModel.page, paginationModel.pageSize]);

  useEffect(() => {
    // If limit and page are not provided, set default limit -
    if (!searchParams.get("limit")) {
      setSearchParams((searchParams) => {
        searchParams.set("page", 1);
        searchParams.set("limit", 100);
        return searchParams;
      });
      const urlParams = Object.fromEntries(searchParams.entries());
      dispatch(getIncidents(urlParams));
    }
  }, []);

  const columns = [
    {
      field: "star",
      headerName: " ",
      width: 25,
      color: theme.palette.secondary[221],
      headerClassName: "myHeaderClass",
      sortable: false,
      filterable: false,

      // Make incidentId Columns data Clickable
      renderCell: (params) => {
        return <Star incident={params.row} />;
      },
    },

    // Incident Id

    {
      field: "incident_id",
      headerName: "Incident ID",
      width: 100,
      color: theme.palette.secondary[221],
      headerClassName: "myHeaderClass",
      sortable: false,
      filterable: false,

      // Make incidentId Columns data Clickable
      renderCell: (params) => {
        return (
          <Link to={`/incidents/${params.value}`}>
            <Button
              variant="text"
              sx={{
                // color: "white",
                "&:hover": {
                  color: theme?.palette?.primary?.light,
                  textDecoration: "underline",
                },
              }}
            >
              {params.value}
            </Button>
          </Link>
        );
      },
    },

    // Incident Name
    {
      field: "incident-name",
      headerName: "Incident Name",
      width: 100,
      filterable: false,

      // valueGetter: (tableData) => console.log(tableData),
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            title={params.value}
            sx={{ wordWrap: "break-word" }}
          >
            {params.incident_name}
          </Typography>
        );
      },
    },

    // Description
    {
      field: "description",
      headerName: "Description",
      width: 300,
      filterable: false,

      // valueGetter: (tableData) => console.log(tableData),
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            title={params.value}
            sx={{ wordWrap: "break-word" }}
          >
            {params.value}
          </Typography>
        );
      },
    },

    // Hostname
    {
      field: "hostname",
      headerName: "Host",
      width: 150,
      filterable: false,
      color: theme.palette.secondary[221],
      headerClassName: "myHeaderClass",
    },

    { field: "type", headerName: "Type", width: 80, filterable: false },

    // Severity
    {
      field: "severity",
      headerName: "Severity",
      width: 140,
      filterable: false,
      renderCell: (params) => {
        return <SeverityBadge riskClass={params.value} />;
      },
    },

    // Ticket Status
    {
      field: "ticketStatus",
      headerName: "Status",
      width: 100,
      filterable: false,
      renderCell: (params) => {
        return (
          <Chip
            label={params.value}
            color={
              compareString(params.value, STATUS.InProgress)
                ? "secondary"
                : compareString(params.value, STATUS.Completed)
                ? "primary"
                : "default"
            }
            size="small"
          />
        );
      },
    },

    // Make assignee Columns data Clickable
    {
      field: "engineer_assigned",
      headerName: "Assignee",
      width: 110,
      filterable: false,
      color: theme.palette.secondary[221],
      headerClassName: "myHeaderClass",
      renderCell: (params) => {
        return !compareString(user?.role, ROLES.Operator) ? (
          <Button
            onClick={() => {
              setModalOpen(true);
              setIncidentId(params.row.incident_id);
              setSelectedIncident(
                incidents?.find(
                  (incident) => incident.incident_id === params.row.incident_id
                )
              );
            }}
            variant="text"
            sx={{
              // color: "white",
              color: theme.palette.grey[700],
              "&:hover": {
                color: theme?.palette?.primary?.light,
                textDecoration: "underline",
              },
            }}
          >
            {params.value}
          </Button>
        ) : (
          <Typography variant="text">{capitalize(params.value)}</Typography>
        );
      },
    },

    {
      field: "last_updated",
      headerName: "Creation Time",
      flex: 1,
      filterable: false,
    },

    { field: "remark", headerName: "Remark", flex: 1, filterable: false },
  ];

  return (
    <>
      <Box p="21px" sx={{ minHeight: "90%", display: "flex" }}>
        <Card
          variant="outlined"
          sx={{
            width: "100%",
            // minHeight: "100% !important",
          }}
        >
          <CardContent sx={{ height: "100%" }}>
            <Box sx={{ height: "100%" }}>
              <Box sx={{ minHeight: "100%", display: "flex" }}>
                <DataGrid
                  paginationMode="server"
                  density="compact"
                  sx={{ border: 0, width: "100%", height: "76vh" }}
                  loading={loading}
                  getRowId={(row) => row.incident_id}
                  rows={incidents || []}
                  columns={columns}
                  rowCount={total || 0}
                  pageSizeOptions={[5, 10, 25, 50, 100]}
                  pagination
                  paginationModel={paginationModel}
                  page={paginationModel.page}
                  pageSize={paginationModel.pageSize}
                  onPaginationModelChange={setPaginationModel}
                  slots={{
                    // toolbar: GridToolbar,
                    toolbar: CustomToolbar,
                  }}
                  disableColumnMenu //disables the dropdown column menu
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Modal
        title="Create Ticket"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <CreateTicketForm
          setModalOpen={setModalOpen}
          incidentId={incidentId}
          selected={selectedIncident}
        />
      </Modal>
    </>
  );
};

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <Box
        sx={{
          // marginLeft: "auto"
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <GridToolbarFilterButton /> */}
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
      </Box>
    </GridToolbarContainer>
  );
};


const Star = ({ incident }) => {
  const { user } = useAuth();
  const initialStarValue = incident?.star?.includes(user?.id);
  const [star, setStar] = useState(initialStarValue);

  const dispatch = useDispatch();

  const toggleStar = async (starred) => {
    dispatch(
      starSingleIncident({
        incidentId: incident?.incident_id,
        starred,
        userId: user.id,
      })
    );
    setStar(starred);
  };

  const handleAddStar = () => {
    toggleStar(true);
  };

  const handleRemoveStar = () => {
    toggleStar(false);
  };

  return star ? (
    <IconButton onClick={handleRemoveStar}>
      <StarIcon color="warning" />
    </IconButton>
  ) : (
    <IconButton onClick={handleAddStar}>
      <StarOutlineIcon />
    </IconButton>
  );
};

export default IncidentsPage;
