import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { STATUS } from "../../utils/constants";
import { capitalize, compareString } from "../../utils/functions";
import { getTicketsByStatus } from "../../redux/features/tickets/ticketsByStatusSlice";

const TicketsByStatus = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        minHeight: "400px",
      }}
    >
      <div>Tickets By Status</div>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="in progress and overdue incidents"
          variant="fullWidth"
        >
          <Tab label="In Progress" sx={{ padding: 0 }} />
          <Tab label="Overdue" sx={{ padding: 0 }} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Incidents status="InProgress" />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Incidents status="Overdue" />
      </TabPanel>
    </Box>
  );
};

const Incidents = ({ status = "InProgress" }) => {
  const [limit] = useState(10);
  const [page] = useState(1);
  const dispatch = useDispatch();
  const { tickets, loading, error } = useSelector(
    (state) => state.tickets.ticketsByStatus
  );

  useEffect(() => {
    dispatch(getTicketsByStatus({ limit, page, status }));
  }, []);

  if (loading) return <Typography>Loading...</Typography>;

  if (tickets?.length < 0) return <IncidentNotFound />;

  if (error || !Array.isArray(tickets))
    return <Typography>Something went wrong</Typography>;

  return (
    <Box>
      <ListHeader status={status} />
      {tickets?.map((incident) => (
        <Incident key={incident?.incident_id} incident={incident} />
      ))}
    </Box>
  );
};

const Incident = ({ incident }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const { incident_id, engineer_assigned, Overdue, mttr_remaining } = incident;
  const remainingTime = !Overdue ? mttr_remaining : mttr_remaining?.slice(8);

  return (
    <>
      <Box
        className="flex"
        sx={{
          background: mode === "dark" ? "#141414" : theme.palette.grey[300],
          borderBottom: `1px solid ${mode === "dark" ? "#202020" : "#ccc"}`,
          borderRadius: 1,
          overflowX: "hidden",
          "&:hover": {
            background: mode === "dark" ? "#222224" : theme.palette.grey[300],
          },
          mb: 1,
          paddingBlock: 0.5,
          paddingInline: 1,
          display: "flex",
          alignItems: "center",
          gap: 2,
          // justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: 130 }}>
          <Typography variant="body2">
            <Link
              color={"primary"}
              size="small"
              component={RouterLink}
              sx={{
                fontStyle: "italic",
                fontSize: 10,
                textDecoration: "none",
                "&:hover": { textDecoration: "underline" },
              }}
              to={`/incidents/${incident_id}`}
            >
              {incident_id}
            </Link>
          </Typography>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant="body2">
            {capitalize(engineer_assigned)}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body2"
            color={
              !Overdue
                ? theme?.palette?.success?.main
                : theme?.palette?.error?.main
            }
          >
            {Overdue ? "-" : "+"} {remainingTime}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const ListHeader = ({ status = "InProgress" }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  return (
    <Box
      sx={{
        background: mode === "dark" ? "#141414" : theme.palette.grey[300],
        borderBottom: `1px solid ${mode === "dark" ? "#202020" : "#ccc"}`,
        borderRadius: 1,
        overflowX: "hidden",
        mb: 1,
        paddingBlock: 0.5,
        paddingInline: 1,
        display: "flex",
        alignItems: "center",
        gap: 2,
        // justifyContent: "space-between",
      }}
    >
      <Typography sx={{ width: 130 }} variant="body2" color="gray">
        Incident Id
      </Typography>
      <Typography sx={{ flex: 1 }} variant="body2" color="gray">
        Assignee
      </Typography>

      {compareString(status, STATUS.InProgress) && (
        <Typography variant="body2" color="gray">
          Remaining
        </Typography>
      )}
      {compareString(status, "overdue") && (
        <Typography variant="body2" color="gray">
          {/* Time Passed || overdue */}
          Time Passed
        </Typography>
      )}
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`status-tabpanel-${index}`}
      aria-labelledby={`status-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingBlock: 2 }}>{children}</Box>}
    </div>
  );
}

const IncidentNotFound = () => {
  return (
    <Box sx={{ height: "100% !important" }}>
      <Typography>No Incident Found</Typography>
    </Box>
  );
};

export default TicketsByStatus;
