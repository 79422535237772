import { useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ChartTemplate from "../../charts/ChartTemplate";

const IncidentsOvertimeByType = ({ incidentsOvertime }) => {
  const file = [];
  const host = [];
  const network = [];
  const weeks = [];

  incidentsOvertime?.forEach((element, index) => {
    const tempElement = element[`week-${index + 1}`];
    const week = tempElement?.date?.split(" ");

    file.push(tempElement.type["file"]);
    host.push(tempElement.type["host"]);
    network.push(tempElement.type["network"]);
    weeks.push(`${week[1]} ${week[2]}`);
  });

  return (
    <Card variant="outlined" sx={{ height: "100%" }}>
      <CardContent>
        <Typography variant="body2" color="grey">
          Incidents Overtime (by Type)
        </Typography>{" "}
        <ByTypeChart incidents={{ file, host, network }} weeks={weeks} />
      </CardContent>
    </Card>
  );
};

const ByTypeChart = ({ incidents, weeks }) => {
  const { file, host, network } = incidents;
  const theme = useTheme();
  const mode = theme.palette.mode;

  const chartData = {
    series: [
      {
        name: "File",
        data: file,
      },
      {
        name: "Host",
        data: host,
      },
      {
        name: "Network",
        data: network,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          //   borderRadius: 10,
          columnWidth: 40,

          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      tooltip: {
        enabled: true,
        theme: mode === "dark" ? "dark" : "light",
        marker: {
          show: true,
        },
      },
      //   colors: ["#FF0A0E", "#EDB007", "#18A07E"],
      grid: {
        borderColor: mode === "dark" ? "#444" : "#dedede",
        strokeDashArray: 4,
      },
      xaxis: {
        type: "string",
        categories: weeks,
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  };

  return (
    <div id="chart">
      <ChartTemplate
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={200}
      />
    </div>
  );
};

export default IncidentsOvertimeByType;
