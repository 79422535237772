import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { getEndpoints } from "../../../redux/features/endpoints/endpointSlice";
import { setCurrentGroup } from "../../../redux/features/endpoints/endpointGroupSlice";
import { useTheme } from "@mui/material";

const Group = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { group } = useParams();
  const baseEndpoint = data?.name?.toLowerCase() === "all";
  let active = group === data?.slug;
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  // Check if the current selected group is All (base group) and make it active
  if (group === undefined && baseEndpoint) active = true;

  const handleClick = () => {
    const endpoint = baseEndpoint
      ? "/endpoints"
      : `/endpoints/group/${data?.slug}`;

    if (baseEndpoint) {
      dispatch(setCurrentGroup(0));
      dispatch(getEndpoints({ groupId: 0 }));
    } else {
      dispatch(setCurrentGroup(data?.id));
      dispatch(getEndpoints({ groupId: data?.id }));
    }

    navigate(endpoint, { replace: true });
  };

  return (
    <Link
      component="button"
      variant="body2"
      onClick={handleClick}
      sx={{
        background: isDark ? "#2D3342" : "#fff",
        p: "10px",
        borderRight: "11px solid ",
        borderColor: active ? "#05F2AF" : "transparent",
        color: active ? "#05F2AF" : isDark ? "white" : "#333",
        textDecoration: "none",
        textAlign: "left",
        "&:hover": {
          color: "#05F2AF",
          borderColor: "#05F2AF",
          transition: "all 200ms linear ",
        },
      }}
    >
      <Typography>{data?.name}</Typography>
    </Link>
  );
};

export default Group;
