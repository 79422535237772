import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import { useGetAllUsersQuery } from "../../redux/app/services/userApiSlice";
import AddNewUserForm from "../../components/users/AddNewUserForm";
import UserList from "../../components/users/UserList";
import Header from "../../components/Header";
import Modal from "../../components/ui/Modal";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../../redux/features/users/usersSlice";

const Users = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const theme = useTheme();

  const res = useGetAllUsersQuery();
  const { data, isLoading, isError } = res;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  return (
    <Box m="21px" sx={{ display: "flex", flexDirection: "column" }}>
      {/* Header */}
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <Header title="Users" subtitle={`User Management Dashboard`} />
            {!isLoading && !isError && (
              <Button
                onClick={() => setModalOpen(true)}
                variant="contained"
                sx={{
                  background: theme?.palette?.primary?.main,
                  "&:hover": {
                    background: theme?.palette?.primary?.light,
                    filter: "brightness(125%)",
                    transition: "1s ease",
                  },
                }}
              >
                Add New User
              </Button>
            )}
          </Box>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            // lg={9}
            sx={{ minHeight: "10rem" }}
          >
            {isLoading ? (
              <Typography>Loading...</Typography>
            ) : !isError ? (
              <UserList users={data?.users} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Typography>Something went wrong!</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Modal
        title="Create New User"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <AddNewUserForm setModalOpen={setModalOpen} />
      </Modal>
    </Box>
  );
};

export default Users;
