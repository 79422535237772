import React from "react";
import Box from "@mui/material/Box";

import ReportTable from "../../ReportTable";
import ReportDonutChart from "../../ReportDonutChart";
import WidgetTitle from "../../WidgetTitle";

const IncidentCountByType = ({ data }) => {
  if (data.total.value === 0) return null;

  const typeChart = {
    labels: ["File", "Host", "Network"],
    series: [
      data?.file?.value || 0,
      data?.host?.value || 0,
      data?.network?.value || 0,
    ],
  };
  const severityChart = {
    labels: ["High", "Medium", "Low"],
    series: [
      data?.total?.high?.value || 0,
      data?.total?.medium?.value || 0,
      data?.total?.low?.value || 0,
    ],
  };

  return (
    <div>
      <WidgetTitle title="Incidents Count by Type" />

      <ReportTable data={data} />
      <Box
        sx={{ display: "flex", justifyContent: "space-evenly", marginBlock: 3 }}
      >
        {data ? (
          <>
            <ReportDonutChart
              title="By Type"
              labels={typeChart.labels}
              series={typeChart.series}
            />
            <ReportDonutChart
              title="By Severity"
              labels={severityChart.labels}
              series={severityChart.series}
            />
          </>
        ) : null}
      </Box>
    </div>
  );
};

export default IncidentCountByType;
