import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchIncidentsByRemainingTime } from "./index";

export const getIncidentsByRemainingTime = createAsyncThunk(
  "incidents/remaining-time",
  fetchIncidentsByRemainingTime
);

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const incidentsByRemainingTimeSlice = createSlice({
  name: "remaining-incidents",
  initialState,
  reducers: {
    clearIncidentsByRemainingTimeState: (state) => {
      state.data = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIncidentsByRemainingTime.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getIncidentsByRemainingTime.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.data = action.payload;
    });
    builder.addCase(getIncidentsByRemainingTime.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export const { clearIncidentsByRemainingTimeState } =
  incidentsByRemainingTimeSlice.actions;
export default incidentsByRemainingTimeSlice.reducer;
