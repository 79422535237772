import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchActions,
  fetchAllArtifactsOfAction,
  predefinedAction,
  sessionRequest,
} from ".";

export const getActions = createAsyncThunk("actions/getActions", fetchActions);
export const sendPredefinedAction = createAsyncThunk(
  "actions/send-predefined-action",
  predefinedAction
);

export const getAllActionArtifacts = createAsyncThunk(
  "actions/getAllActionArtifacts",
  fetchAllArtifactsOfAction
);

export const sendSessionRequest = createAsyncThunk(
  "actions/send-session-request",
  sessionRequest
);

const initialState = {
  data: [],
  actionArtifacts: {},
  liveSession: {
    data: {},
    loading: false,
    error: null,
  },
  loading: false,
  error: null,
};

const actionSlice = createSlice({
  name: "action",
  initialState,
  reducers: {
    clearActionState: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActions.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getActions.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getActions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(sendPredefinedAction.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAllActionArtifacts.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.actionArtifacts = action.payload;
    });

    builder.addCase(sendSessionRequest.pending, (state) => {
      state.liveSession.loading = true;
    });
    builder.addCase(sendSessionRequest.fulfilled, (state, action) => {
      state.liveSession.loading = false;
      state.liveSession.data = action.payload.body;
    });

    builder.addCase(sendSessionRequest.rejected, (state, action) => {
      state.liveSession.loading = false;
      state.liveSession.error = action.error.message;
    });
  },
});

export const { clearActionState } = actionSlice.actions;
export default actionSlice.reducer;
