import React from "react";
import Chart from "react-apexcharts";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

import NoDataFound from "../charts/NoDataFound";
import WidgetTitle from "./WidgetTitle";

const ReportDonutChart = ({ title, labels, series }) => {
  const noDataFound = series[0] === 0 && series[1] === 0 && series[2] === 0;

  // Check if all the values are zero
  if (noDataFound) {
    return (
      <ChartWrapper title={title}>
        <Box sx={{ marginBottom: 5, height: "90%" }}>
          <NoDataFound message="No Data Found" />
        </Box>
      </ChartWrapper>
    );
  }

  const options = {
    series: series,
    labels: labels,
    chart: {
      type: "donut",
    },
    // title: {
    //   text: title,
    //   align: "left",
    // },
    // colors: ["#00A676", "#775DD0"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
            height: "auto",
          },
          legend: {
            position: "bottom",
          },
          dataLabels: {
            enabled: false,
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "40%", // Adjust the thickness of the pie by modifying the size percentage
        },
      },
    },
    // legend: {
    //   position: "bottom", // Place the legend at the bottom
    // },
  };

  return (
    <ChartWrapper title={title}>
      <Chart options={options} series={options.series} type="donut" />
    </ChartWrapper>
  );
};

const ChartWrapper = ({ title, children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme?.palette?.background?.default,
        borderRadius: 2,
        marginInline: 1,
        padding: 2,
        width: "100%",
      }}
    >
      <WidgetTitle title={title} />
      {children}
    </Box>
  );
};
export default ReportDonutChart;
