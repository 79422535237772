// color design tokens export

import { teal } from "@mui/material/colors";

const getVariableValue = (variableName) => {
  const element = document.documentElement;
  let value = getComputedStyle(element).getPropertyValue(`--${variableName}`);
  return value;
};

const primary = getVariableValue("primary");
export const tokens = {
  grey: {
    0: "#ffffff", // manually adjusted
    10: "#f6f6f6", // manually adjusted
    50: "#f0f0f0", // manually adjusted
    100: "#e0e0e0",
    200: "#c2c2c2",
    300: "#a3a3a3",
    400: "#858585",
    500: "#666666",
    600: "#525252",
    700: "#3d3d3d",
    800: "#292929",
    900: "#141414",
    1000: "#000000", // manually adjusted
  },
  primary: {
    // blue
    50: "#E2EDF8",
    100: "#d3d4de",
    200: "#a6a9be",
    300: "#7a7f9d",
    400: primary || "#3423A6",
    500: "#121212",
    600: "#0c0c0c", // manually adjusted
    700: "#141937",
    800: "#0d1025",
    900: "#070812",
  },
  secondary: {
    // yellow
    50: "#fefefe", // manually adjusted
    100: "#fff6e0",
    200: "#ffedc2",
    300: "#7768DF",
    400: "#ffda85",
    500: "#ffd166",
    600: "#cca752",
    700: "#997d3d",
    800: "#665429",
    900: "#332a14",
  },
};

// mui theme settings

export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: teal,
            secondary: {
              main: tokens.secondary[300],
              50: "#F3F6F9",
              100: "#E5EAF2",
              200: "#DAE2ED",
              300: "#C7D0DD",
              400: "#B0B8C4",
              500: "#9DA8B7",
              600: "#6B7A90",
              700: "#434D5B",
              800: "#303740",
              900: "#1C2025",
              contrastText: "#2F3A46",
            },
            primaryDark: {
              50: "#EAEDF1",
              100: "#DAE0E7",
              200: "#ACBAC8",
              300: "#7B91A7",
              400: "#4B5E71",
              500: "#3B4A59",
              600: "#2F3A46",
              700: "#1F262E",
              800: "#141A1F",
              900: "#101418",
              main: "#7B91A7",
            },
            neutral: {
              ...tokens.grey,
              main: tokens.grey[600],
            },
            background: {
              default: "#141A1F",
              alt: tokens.primary[500],
              paper: "#101418",
            },
            success: {
              main: "#32D74B",
            },
            warning: {
              main: "#FF881A",
            },
            error: {
              main: "#FF431A",
            },
          }
        : {
            // palette values for light mode
            primary: teal,
            secondary: {
              main: "#9DA8B7",
              50: "#F3F6F9",
              100: "#E5EAF2",
              200: "#DAE2ED",
              300: "#C7D0DD",
              400: "#B0B8C4",
              500: "#9DA8B7",
              600: "#6B7A90",
              700: "#434D5B",
              800: "#303740",
              900: "#1C2025",
              contrastText: "#2F3A46",
            },
            primaryDark: {
              main: "#7B91A7",
              50: "#EAEDF1",
              100: "#DAE0E7",
              200: "#ACBAC8",
              300: "#7B91A7",
              400: "#4B5E71",
              500: "#3B4A59",
              600: "#2F3A46",
              700: "#1F262E",
              800: "#141A1F",
              900: "#101418",
            },
            neutral: {
              main: "#757575",
            },
            background: {
              default: "#fafafa",
              alt: "#9DA8B7",
              paper: "#f5f5f5",
            },
            text: {
              primary: "#2F3A46",
              secondary: "#6B7A90",
              accent: "#9DA8B7",
              error: "#FF3B30",
              success: "#4CAF50",
            },
          }),
    },

    typography: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};
