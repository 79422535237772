import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { compareString } from "../../../../utils/functions";
import FilterWrapper from "./FilterWrapper";

const ArtifactSelector = ({
  title,
  type,
  setType,
  data,
  setData,
  pathName,
  setPathName,
  name,
  setName,
  hash,
  setHash,
  remoteIP,
  setRemoteIP,
  remotePort,
  setRemotePort,
  url,
  setUrl,
  localIP,
  setLocalIP,
  localPort,
  setLocalPort,
  onAddFilter,
}) => {
  const handleChange = (event) => {
    setType(event.target.value);
  };

  return (
    <FilterWrapper title={title} paddingX="8px" onAddFilter={onAddFilter}>
      <FormControl
        sx={{ minWidth: 120, width: "100% !important" }}
        size="small"
      >
        <InputLabel id="Selector-type-select-label">Type</InputLabel>
        <Select
          labelId="artifacts-type-select-label"
          id="artifacts-type-select"
          value={type}
          label="Type"
          onChange={handleChange}
        >
          <MenuItem value="file">File</MenuItem>
          <MenuItem value="host">Host</MenuItem>
          <MenuItem value="network">Network</MenuItem>
        </Select>
      </FormControl>
      {!!compareString(type, "file") ? (
        <FileArtifact
          pathName={pathName}
          setPathName={setPathName}
          name={name}
          setName={setName}
          hash={hash}
          setHash={setHash}
          data={data}
          setData={setData}
        />
      ) : null}

      {!!compareString(type, "host") ? (
        <HostArtifact
          pathName={pathName}
          setPathName={setPathName}
          name={name}
          setName={setName}
          hash={hash}
          setHash={setHash}
        />
      ) : null}
      {!!compareString(type, "network") ? (
        <NetworkArtifact
          remoteIP={remoteIP}
          setRemoteIP={setRemoteIP}
          remotePort={remotePort}
          setRemotePort={setRemotePort}
          url={url}
          setUrl={setUrl}
          localIP={localIP}
          setLocalIP={setLocalIP}
          localPort={localPort}
          setLocalPort={setLocalPort}
        />
      ) : null}
    </FilterWrapper>
  );
};

const FileArtifact = ({
  data,
  setData,
  pathName,
  setPathName,
  name,
  setName,
  hash,
  setHash,
}) => {
  return (
    <>
      <TextField
        label="Path"
        id="path"
        size="small"
        value={pathName}
        onChange={(e) => setPathName(e.target.value)}
        sx={{ minWidth: "125px", width: "100% !important" }}
      />
      <TextField
        label="Name"
        id="art-name"
        size="small"
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={{ minWidth: "125px", width: "100% !important" }}
      />
      <TextField
        label="Hash"
        id="hash"
        size="small"
        value={hash}
        onChange={(e) => setHash(e.target.value)}
        sx={{ minWidth: "125px", width: "100% !important" }}
      />
      <TextField
        label="Data"
        id="data"
        size="small"
        value={data}
        onChange={(e) => setData(e.target.value)}
        sx={{ minWidth: "125px", width: "100% !important" }}
      />
    </>
  );
};

const HostArtifact = ({
  pathName,
  setPathName,
  name,
  setName,
  hash,
  setHash,
}) => {
  return (
    <>
      <TextField
        label="Path"
        id="path"
        size="small"
        value={pathName}
        onChange={(e) => setPathName(e.target.value)}
        sx={{ minWidth: "125px", width: "100% !important" }}
      />
      <TextField
        label="Name"
        id="art-name"
        size="small"
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={{ minWidth: "125px", width: "100% !important" }}
      />
      <TextField
        label="Hash"
        id="hash"
        size="small"
        value={hash}
        onChange={(e) => setHash(e.target.value)}
        sx={{ minWidth: "125px", width: "100% !important" }}
      />
    </>
  );
};

const NetworkArtifact = ({
  remoteIP,
  setRemoteIP,
  remotePort,
  setRemotePort,
  url,
  setUrl,
  localIP,
  setLocalIP,
  localPort,
  setLocalPort,
}) => {
  return (
    <>
      <TextField
        label="Remote IP"
        id="remote-ip"
        size="small"
        value={remoteIP}
        onChange={(e) => setRemoteIP(e.target.value)}
        sx={{ minWidth: "125px", width: "100% !important" }}
      />
      <TextField
        label="Remote Port"
        id="remote-port"
        size="small"
        value={remotePort}
        onChange={(e) => setRemotePort(e.target.value)}
        sx={{ minWidth: "125px", width: "100% !important" }}
      />
      <TextField
        label="URL"
        id="url"
        size="small"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        sx={{ minWidth: "125px", width: "100% !important" }}
      />
      <TextField
        label="Local IP"
        id="local-ip"
        size="small"
        value={localIP}
        onChange={(e) => setLocalIP(e.target.value)}
        sx={{ minWidth: "125px", width: "100% !important" }}
      />
      <TextField
        label="Local Port"
        id="local-port"
        size="small"
        value={localPort}
        onChange={(e) => setLocalPort(e.target.value)}
        sx={{ minWidth: "125px", width: "100% !important" }}
      />
    </>
  );
};

export default ArtifactSelector;
