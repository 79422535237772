import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchUnassignedIncidentsBySeverity } from "..";

export const getUnassignedLowIncidents = createAsyncThunk(
  "tickets/unassigned_low",
  fetchUnassignedIncidentsBySeverity
);

const initialState = {
  incidents: [],
  loading: false,
  error: null,
};
const unassignedLowSlice = createSlice({
  name: "unassigned_low",
  initialState,
  reducers: {
    clearUnassignedLowState: (state) => {
      state.incidents = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnassignedLowIncidents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUnassignedLowIncidents.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.incidents = action.payload;
      })
      .addCase(getUnassignedLowIncidents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearUnassignedLowState } = unassignedLowSlice.actions;
export default unassignedLowSlice.reducer;
