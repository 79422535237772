import { combineReducers } from "@reduxjs/toolkit";

import myIncidentsReducer from "./myIncidentsSlice";
import incidentsBySeverityReducer from "./incidentsBySeveritySlice";
import incidentsByRemainingTimeReducer from "./incidentsByRemainingTimeSlice";
import resolvedIncidentsMTTRReducer from "./resolvedIncidentsMTTRSlice";
import incidentsByStatusReducer from "./incidentsByStatusSlice";
import topHostsByIncidentReducer from "./TopHostsByIncidentSlice";

const operatorDashboardReducer = combineReducers({
  myIncidents: myIncidentsReducer,
  incidentsBySeverity: incidentsBySeverityReducer,
  incidentsByRemainingTime: incidentsByRemainingTimeReducer,
  resolvedIncidentsMTTR: resolvedIncidentsMTTRReducer,
  incidentsByStatus: incidentsByStatusReducer,
  topHostsByIncident: topHostsByIncidentReducer,
});

export default operatorDashboardReducer;
