import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import Controls from "../../ui/controls";
import Form from "./../../ui/Form";

const GroupForm = ({
  setModalOpen,
  ButtonLabel,
  name = "",
  setName,
  description = "",
  setDescription,
  recruitingRule = "",
  setRecruitingRule,
  hosts = "",
  setHosts,
  setError,
  onSubmit,
  isEditing = false,
}) => {
  const handleInputControlChange = (event) => {
    if (event.target.name === "name") {
      setName(event.target.value);
      setError(null);
    }
    if (event.target.name === "description") {
      setDescription(event.target.value);
      setError(null);
    }
    if (event.target.name === "recruitingRule") {
      setRecruitingRule(event.target.value);
      setError(null);
    }
    if (event.target.name === "hosts") {
      setHosts(event.target.value);
      setError(null);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      name === "" &&
      description === "" &&
      recruitingRule === "" &&
      hosts === ""
    ) {
      // At least one value should be provided
      setError("At least one value should be provided");
      return;
    }

    onSubmit();
    handleClose();
  };

  const clearForm = () => {
    setName("");
    setDescription("");
    setRecruitingRule("");
    setHosts("");
    setError(null);
  };

  const handleClose = () => {
    if (!isEditing) clearForm();
    setModalOpen(false);
  };

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controls.TextInputField
            multiline
            name="name"
            label="Group Name"
            value={name}
            onChange={handleInputControlChange}
          />
          <Controls.TextInputField
            multiline
            name="description"
            label="Description"
            value={description}
            onChange={handleInputControlChange}
          />
          <Controls.TextInputField
            multiline
            name="recruitingRule"
            label="Recruiting Rule"
            value={recruitingRule}
            onChange={handleInputControlChange}
          />
          <Controls.TextInputField
            multiline
            name="hosts"
            label="Hosts"
            value={hosts}
            placeholder="Please use comma to separate hosts. (e.g. host1, host2, ...)"
            onChange={handleInputControlChange}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        <Button
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          color="primary"
        >
          {ButtonLabel}
        </Button>
        <Button variant="contained" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
      </Box>
    </Form>
  );
};

export default GroupForm;
