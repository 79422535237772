import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchUserCompletedIncidentsByType } from "..";

export const getUserCompletedIncidentsByType = createAsyncThunk(
  "user/completed-incidents-by-type",
  fetchUserCompletedIncidentsByType
);

const initialState = {
  incidents: {},
  loading: false,
  error: null,
};

export const userCompletedIncidentsByTypeSlice = createSlice({
  name: "user-completed-incidents-by-type",
  initialState,
  reducers: {
    clearUserCompletedIncidentsByTypeState: (state) => {
      state.incidents = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // User Info
    builder.addCase(getUserCompletedIncidentsByType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getUserCompletedIncidentsByType.fulfilled,
      (state, action) => {
        state.loading = false;
        state.incidents = action.payload;
      }
    );
    builder.addCase(
      getUserCompletedIncidentsByType.rejected,
      (state, action) => {
        state.loading = false;
        state.incidents = {};
        state.error = action.error.message;
      }
    );
  },
});

export const { clearUserCompletedIncidentsByTypeState } =
  userCompletedIncidentsByTypeSlice.actions;

export default userCompletedIncidentsByTypeSlice.reducer;
