import React from "react";
import Box from "@mui/material/Box";

import ReportDonutChart from "../../ReportDonutChart";

const Timeliness = ({ data }) => {
  return (
    <Box sx={{ width: "400px" }}>
      <ReportDonutChart
        title="Timeliness"
        labels={["Resolved", "Overdue"]}
        series={[data?.resolved_count, data?.overdue_count]}
      />
    </Box>
  );
};

export default Timeliness;
