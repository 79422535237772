import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import Header from "../../components/Header";
import TicketsByStatus from "../../components/tickets/TicketsByStatus";
import IncidentsInsightTable from "../../components/tickets/IncidentsInsightTable";
import OperatorInsightTable from "../../components/tickets/OperatorInsightTable";
import UnassignedIncidentsBySeverity from "../../components/tickets/unassigned-incidents-by-severity";

const Tickets = () => {
  const theme = useTheme();
  return (
    <Box
      m="21px"
      sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}
    >
      {/* Header */}
      <Grid container>
        <Grid item xs={12} sm={6} lg={8}>
          <Header title="Tickets" subtitle={`Tickets Page`} />
        </Grid>
      </Grid>
      {/* Unassigned Incidents by Severity Section */}
      <Grid container spacing={1} sx={{ marginTop: "-30px" }}>
        <Grid item xs={12} md={4}>
          <GroupWrapper label="High" background={theme?.palette?.error.main}>
            <UnassignedIncidentsBySeverity severity="High" />
          </GroupWrapper>
        </Grid>
        <Grid item xs={12} md={4}>
          <GroupWrapper
            label="Medium"
            background={theme?.palette?.warning.main}
          >
            <UnassignedIncidentsBySeverity severity="Medium" />
          </GroupWrapper>
        </Grid>
        <Grid item xs={12} md={4}>
          <GroupWrapper label="Low" background={theme?.palette?.success.main}>
            <UnassignedIncidentsBySeverity severity="Low" />
          </GroupWrapper>
        </Grid>
      </Grid>
      {/* Insights Section */}
      <Grid container spacing={1} sx={{ marginTop: "-30px" }}>
        <Grid item xs={12} md={8} sx={{ height: "100%" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {/* Incidents Insight Section */}
              <GroupWrapper label="Incidents Insight" color="#aaa">
                <IncidentsInsightTable />
              </GroupWrapper>
            </Grid>
            <Grid item xs={12}>
              {/* Operator Insight Section */}
              <GroupWrapper label="Operator Insight" color="#aaa">
                <OperatorInsightTable />
              </GroupWrapper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          {/* Incidents by status */}
          <GroupWrapper>
            <TicketsByStatus />
          </GroupWrapper>
        </Grid>
      </Grid>
      <br /> <br />
    </Box>
  );
};

const GroupWrapper = ({
  label,
  color = "#fafafa",
  background = "transparent",
  children,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme?.palette?.background?.paper,
        padding: 1,
        borderRadius: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${theme.palette.mode === "dark" ? "#222" : "#ddd"}`,
        borderTop: `3px solid ${background}`,
      }}
    >
      <Typography
        variant="h5"
        sx={{
          ml: 1,
          background: background,
          color: color,
          // textTransform: "uppercase",
          alignSelf: "flex-start",
          paddingInline: 1,
          borderRadius: 0.5,
        }}
      >
        {label}
      </Typography>
      {children}
    </Box>
  );
};

export default Tickets;
